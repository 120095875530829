import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
} from "@common/service/constants";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import {
  IonCol,
  IonRow,
  useIonActionSheet,
} from "@ionic/react";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import {
  RangeMinMax,
  RangeMinMaxDefault,
} from "@common/model/Range";
import {
  Trans,
  t,
} from "@lingui/macro";
import { confirmDelete } from "@feature/confirm/service/delete";
import { deviceBleSetRange } from "@feature/device/slice/deviceSlice";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { usePowerReset } from "@feature/power/hook/usePowerReset";
import { useRangeReset } from "@feature/range/hook/useRangeReset";

type FormData = {
  min?: string;
  max?: string;
}

type Pros = {
  athleteUuid: string;
  exerciseUuid: string;
  loadWeight: number;
  range?: RangeMinMax;
}

export const RangeFormComponent = (props: Pros) => {
  const dispatch = useAppDispatch();
  const settingState = useAppSelector(selectSettingState);

  const [ present ] = useIonActionSheet();
  const { resetPower } = usePowerReset();
  const { resetRange } = useRangeReset();

  const {
    reset,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>({
    defaultValues: {
      min: props.range ? props.range.min.toString() : RangeMinMaxDefault.min.toString(),
      max: props.range ? props.range.max.toString() : RangeMinMaxDefault.max.toString(),
    },
  });

  const onSubmit = async(formData: FormData) => {
    try {
      const min = parseInt(formData.min, 10);
      const max = parseInt(formData.max, 10);

      if (min > DEVICE_RANGE_MIN &&
        min < DEVICE_RANGE_MAX &&
        max > DEVICE_RANGE_MIN &&
        max < DEVICE_RANGE_MAX &&
        max > min
      ) {
        const rangeMinMax: RangeMinMax = {
          min: min,
          max: max,
        };
        dispatch(deviceBleSetRange(rangeMinMax));

        resetRange({
          athleteUuid: props.athleteUuid,
          exerciseUuid: props.exerciseUuid,
          rangeMinMax: rangeMinMax,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resetRangeSubmit = () => {
    confirmDelete({
      header: t`Do you want to reset the range?`,
      subHeader: t`This action will reset the range to the default value.`,
      present: present,
      action: async() => {
        dispatch(deviceBleSetRange(RangeMinMaxDefault));
        resetPower({
          athleteUuid: props.athleteUuid,
          exerciseUuid: props.exerciseUuid,
          loadWeight: props.loadWeight,
        });
        resetRange({
          athleteUuid: props.athleteUuid,
          exerciseUuid: props.exerciseUuid,
          rangeMinMax: null,
        });
      },
    });
  };

  useEffect(() => {
    // Questo effetto si attiva ogni volta che cambia `props.range`
    reset({
      min: props.range ? props.range.min.toString() : RangeMinMaxDefault.min.toString(),
      max: props.range ? props.range.max.toString() : RangeMinMaxDefault.max.toString(),
    });
  }, [
    props.range,
    reset,
  ]);

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <>
        <IonRow>
          <h3>
            <Trans>Range</Trans>
          </h3>
        </IonRow>
        <IonRow>
          <IonCol>
            <InputUi
              type="number"
              autoComplete={"off"}
              minValue={DEVICE_RANGE_MIN}
              maxValue={DEVICE_RANGE_MAX}
              isValid={!Boolean(errors.min)}
              id="min"
              placeholder={t`Min`}
              {...register("min", {
                required: true,
              })}
            />
          </IonCol>

          <IonCol>
            <InputUi
              type="number"
              autoComplete={"off"}
              minValue={DEVICE_RANGE_MIN}
              maxValue={DEVICE_RANGE_MAX}
              isValid={!Boolean(errors.max)}
              id="max"
              placeholder={t`Max`}
              {...register("max", {
                required: true,
              })}
            />
          </IonCol>
          <IonCol>
            {isSubmitting && <PrimaryPositiveButtonUi isLoading />}
            {!isSubmitting && <PrimaryPositiveButtonUi label={t`Set Range`} />}
          </IonCol>
        </IonRow>
      </>

      {
        Boolean(props.range) &&
        !isSubmitting &&
        <PrimaryDestructiveButtonUi
          label={t`Reset Range`}
          onClick={resetRangeSubmit}
        />
      }
    </FormUi>
  </>;
};

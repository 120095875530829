import { IonicSafeString } from "@ionic/react";

export const djb2Hash = (str: string | IonicSafeString): string => {
  let hash = 5381;
  const value = str.toString();
  for (let i = 0; i < value.length; i++) {
    const char = value.charCodeAt(i);
    hash = ((hash << 5) + hash) + char;
  }
  return `0x${ (hash >>> 0).toString(16) }`;
};

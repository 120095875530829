import { ApiData } from "@feature/api/component/ApiData";
import {
  Athlete,
  Configuration,
  Exercise,
  Profile,
  Protocol,
  Relation,
} from "@common/type-graphql/generated";
import {
  CONFIGURATION_CODE_LOAD_WEIGHT_USED,
  CONFIGURATION_CODE_POWER_REFERENCE_USED,
  CONFIGURATION_CODE_RANGE_MAX_USED,
  CONFIGURATION_CODE_RANGE_MIN_USED,
} from "@common/model/Configuration";
import { ConfigurationValue } from "@common/model/ConfigurationValue";
import { EXERCISE_CODE_BENCH_PRESS } from "@common/model/Exercise";
import { PROTOCOL_CODE_FMAX } from "@common/model/Protocol";
import { Serie } from "@common/model/Serie";
import { SerieComponent } from "@feature/serie/component/serieComponent";
import {
  misto1Mock,
  misto1MockTitle,
  misto1Range,
} from "../../../mock/misto1.mock";
import { runPageStyle } from "@feature/run/page/RunPageStyle";
import { useAthleteListQuery } from "@feature/athlete/api/athleteApi";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import {
  useEffect,
  useState,
} from "react";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";
import { useProfileListQuery } from "@feature/profile/api/profileApi";
import { useProtocolListQuery } from "@feature/protocol/api/protocolApi";
import { useRelationListQuery } from "@feature/relation/api/relationApi";
import { useSerieCalculate } from "@feature/serie/hook/useSerieCalculate";

export const DebugSerie1 = () => {
  const relationListApi = useRelationListQuery();
  const protocolListApi = useProtocolListQuery();
  const exerciseListApi = useExerciseListQuery();
  const configurationListApi = useConfigurationListQuery();
  const athleteListApi = useAthleteListQuery();
  const profileListApi = useProfileListQuery({ where: { uuid: athleteListApi.data ? athleteListApi.data[0].uuid : "" } }, { skip: !athleteListApi.isSuccess || !athleteListApi.data });

  return <>
    <ApiData endpoint={[
      relationListApi,
      protocolListApi,
      exerciseListApi,
      configurationListApi,
      athleteListApi,
      profileListApi,
    ]}>
      <SerieChildComponent
        relationList={relationListApi.data}
        protocolList={protocolListApi.data}
        exerciseList={exerciseListApi.data}
        configurationList={configurationListApi.data}
        athleteList={athleteListApi.data}
        profileList={profileListApi.data}
      />
    </ApiData>
  </>;
};

type Props = {
  relationList: Relation[];
  protocolList: Protocol[];
  exerciseList: Exercise[];
  configurationList: Configuration[];
  athleteList: Athlete[];
  profileList: Profile[];
};
const SerieChildComponent = (props: Props) => {
  const { classes } = runPageStyle();

  const [
    serie,
    setSerie,
  ] = useState<Serie>(new Serie());

  const {
    calculateSerie,
  } = useSerieCalculate();

  const protocolUuid = props.protocolList.find(protocol => protocol.code === PROTOCOL_CODE_FMAX)?.uuid;
  const exerciseUuid = props.exerciseList.find(exercise => exercise.code === EXERCISE_CODE_BENCH_PRESS)?.uuid;
  const profileUuid = props.profileList[0].uuid;

  const trainingConfigurationValues: ConfigurationValue[] = [];

  const serieConfigurationValues: ConfigurationValue[] = [
    {
      code: CONFIGURATION_CODE_LOAD_WEIGHT_USED,
      value: 50,
    },
    {
      code: CONFIGURATION_CODE_POWER_REFERENCE_USED,
      value: 150,
    },
    {
      code: CONFIGURATION_CODE_RANGE_MIN_USED,
      value: misto1Range.min,
    },
    {
      code: CONFIGURATION_CODE_RANGE_MAX_USED,
      value: misto1Range.min,
    },
  ];

  // Calculate the algorithm
  useEffect(() => {
    const serie = calculateSerie({
      points: misto1Mock,
      protocolUuid: protocolUuid,
      exerciseUuid: exerciseUuid,
      trainingConfigurationValues: trainingConfigurationValues,
      serieConfigurationValues: serieConfigurationValues,
    });

    if (serie) {
      setSerie(serie);
    }
    // eslint-disable-next-line
  }, []);

  return <>
    <div className={classes.historyPage}>
      <h1>{ misto1MockTitle }</h1>
      <SerieComponent
        serie={serie}
        protocolUuid={protocolUuid}
        exerciseUuid={exerciseUuid}
        profileUuid={profileUuid}
        workoutConfigurationValues={trainingConfigurationValues}
        serieConfigurationValues={serieConfigurationValues}
        isLive={false}
      />
    </div>
  </>;
};

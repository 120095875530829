import { FooterComponent } from "@feature/layout/component/FooterComponent";
import {
  LINK_PRIVACY_POLICY,
  LINK_TERMS_AND_CONDITIONS,
} from "@feature/account/accountConstants";
import { Trans } from "@lingui/macro";
import { useStyles } from "@feature/account/style";

export const DisclaimerComponent = () => {
  const { classes } = useStyles();

  return <>
    <div className={classes.disclaimerContainer}>
      <div className={classes.disclaimerText}>
        <span>
          <Trans>By continuing forward, you agree to Bodygon's <br /><a
            target={"_blank"}
            href={LINK_PRIVACY_POLICY}
            rel="noreferrer">Privacy Policy</a> and <a
            target={"_blank"}
            href={LINK_TERMS_AND_CONDITIONS}
            rel="noreferrer">Terms & Conditions</a>
          </Trans>
        </span>
        <FooterComponent />
      </div>
    </div>
  </>;
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<{ children }>()(
  (theme, props, classes) => ({
    sectionRoot: {
      marginTop: "10px",
      zIndex: 1,
      marginLeft: 12,
      marginRight: 12,
      "& > button": {
        marginLeft: 2,
      },
    },
    rounded: {
      "& > div": {
        "&:first-of-type": {
          borderTopLeftRadius: 32,
          borderTopRightRadius: 32,
        },
        "&:last-of-type": {
          borderBottomLeftRadius: 32,
          borderBottomRightRadius: 32,
          "& .separator": {
            display: "none",
            visibility: "hidden",
          },
        },
      },
    },
    sectionTitle: {
      margin: "16px 12px",
      color: theme.colors.pureWhite,
      ...theme.typography.RB24,
    },
    hasAddButton: { paddingBottom: "0px" },
  })
);

/**
 * Password pattern
 * ^                         Start anchor
 * (?=.*[A-Z])               Ensure string has at least one uppercase letters.
 * (?=.*[a-z])               Ensure string has at least one lowercase letters.
 * (?=.*\d)                  Ensure string has at least one digits.
 * (?=.*[!"£$%&/()=@*#?_|])  Ensure string has at least one special case letter.
 * .{8,128}                  Ensure string length is between 8 and 128.
 * $                         End anchor.
 */
export const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"£$%&/()=@*#?_|]).{8,128}$/;

/**
 * Email pattern
 */
export const emailPattern = /^[a-zA-Z\d._%+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/;

/**
 * Url with Uuid pattern like /run/0c76bd10-0d5f-4e12-8e63-000000000000
 */
export const urlWithUuidPattern = /\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

/**
 * Url with Uuid pattern like /run/0c76bd10-0d5f-4e12-8e63-000000000000
 */
export const urlWithParamsPattern = /\/:([a-zA-Z0-9]+)\??/;

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    onlyOneAthlete: { display: "none" },
    onlyOneProfile: { display: "none" },
    onlyOneAthleteAndOneProfile: {
      borderTopLeftRadius: 32,
      borderTopRightRadius: 32,
    },
  })
);

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    footerRoot: { marginTop: 8 },
    footerParsedHTML: {
      color: theme.colors.grey,
      ...theme.typography.RR14,
    },
  })
);

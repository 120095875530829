import { KeepAwake } from "@capacitor-community/keep-awake";

const isSupported = async(): Promise<boolean> => {
  const result = await KeepAwake.isSupported();
  return result.isSupported;
};
const isKeptAwake = async(): Promise<boolean> => {
  const result = await KeepAwake.isKeptAwake();
  return result.isKeptAwake;
};

export const suspensionKeepAwake = async(): Promise<void> => {
  if (!(await isSupported())) {
    return;
  }

  if (await isKeptAwake()) {
    return;
  }

  await KeepAwake.keepAwake();
};

export const suspensionAllowSleep = async(): Promise<void> => {
  if (!(await isSupported())) {
    return;
  }

  if (!(await isKeptAwake())) {
    return;
  }

  await KeepAwake.allowSleep();
};

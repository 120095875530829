import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    tabBarRoot: {
      left: 0,
      bottom: 0,
      zIndex: 10,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "0px 16px env(safe-area-inset-bottom, 0)",
      position: "fixed",
      backgroundColor: theme.colors.black,
      "& button": { margin: "4px 0px 0px" },
    },
  })
);

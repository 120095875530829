import { makeStyles } from "@theme/makeStyles";

interface Props {
  headerTextColor: string;
}

export const useStyles = makeStyles<Props>()(
  (theme, props, classes) => ({
    timerRoot: {
      minWidth: 155,
      marginRight: 8,
    },
    time: {
      overflow: "hidden",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      maxHeight: 57,
      transform: "traslateY(-7px)",
      color: theme.colors[props.headerTextColor],
      ...theme.typography.OIB64,
    },
  })
);

// To keep sync with Exercise table
export const EXERCISE_CODE_OVERHEAD_SQUAT = "OVERHEAD_SQUAT";
export const EXERCISE_CODE_LUNGES = "LUNGES";
export const EXERCISE_CODE_FRONT_SQUAT = "FRONT_SQUAT";
export const EXERCISE_CODE_CLIMB_ON_THE_STAIR = "CLIMB_ON_THE_STAIR";
export const EXERCISE_CODE_FORWARD_SHOULDER_PRESS = "FORWARD_SHOULDER_PRESS";
export const EXERCISE_CODE_BACKWARD_SHOULDER_PRESS = "BACKWARD_SHOULDER_PRESS";
export const EXERCISE_CODE_SQUAT_JUMP = "SQUAT_JUMP";
export const EXERCISE_CODE_COUNTER_MOVEMENT_JUMP = "COUNTER_MOVEMENT_JUMP";
export const EXERCISE_CODE_DROP_JUMP = "DROP_JUMP";
export const EXERCISE_CODE_REBOUND = "REBOUND";
export const EXERCISE_CODE_SQUAT = "SQUAT";
export const EXERCISE_CODE_LEG_PRESS = "LEG_PRESS";
export const EXERCISE_CODE_LEG_CURL = "LEG_CURL";
export const EXERCISE_CODE_LEG_EXTENSION = "LEG_EXTENSION";
export const EXERCISE_CODE_BENT_OVER_ROW = "BENT_OVER_ROW";
export const EXERCISE_CODE_DECLINE_BENCH = "DECLINE_BENCH";
export const EXERCISE_CODE_INCLINE_BENCH = "INCLINE_BENCH";
export const EXERCISE_CODE_BENCH_PRESS = "BENCH_PRESS";
export const EXERCISE_CODE_STEP_UP = "STEP_UP";
export const EXERCISE_CODE_HIP_THRUST = "HIP_THRUST";
export const EXERCISE_CODE_LAT_PULLDOWN = "LAT_PULLDOWN";
export const EXERCISE_CODE_ROW = "ROW";
export const EXERCISE_CODE_BARBELL_ROW = "BARBELL_ROW";
export const EXERCISE_CODE_BARBELL_CURL = "BARBELL_CURL";
export const EXERCISE_CODE_FRENCH_PRESS = "FRENCH_PRESS";
export const EXERCISE_CODE_PUSH_DOWN = "PUSH_DOWN";
export const EXERCISE_CODE_MILITARY_PRESS = "MILITARY_PRESS";
export const EXERCISE_CODE_CHEST_ROW = "CHEST_ROW";
export const EXERCISE_CODE_CLEAN = "CLEAN";
export const EXERCISE_CODE_DEADLIFT = "DEADLIFT";
export const EXERCISE_CODE_SNATCH = "SNATCH";

export type ExerciseCode =
    | typeof EXERCISE_CODE_OVERHEAD_SQUAT
    | typeof EXERCISE_CODE_LUNGES
    | typeof EXERCISE_CODE_FRONT_SQUAT
    | typeof EXERCISE_CODE_CLIMB_ON_THE_STAIR
    | typeof EXERCISE_CODE_FORWARD_SHOULDER_PRESS
    | typeof EXERCISE_CODE_BACKWARD_SHOULDER_PRESS
    | typeof EXERCISE_CODE_SQUAT_JUMP
    | typeof EXERCISE_CODE_COUNTER_MOVEMENT_JUMP
    | typeof EXERCISE_CODE_DROP_JUMP
    | typeof EXERCISE_CODE_REBOUND
    | typeof EXERCISE_CODE_SQUAT
    | typeof EXERCISE_CODE_LEG_PRESS
    | typeof EXERCISE_CODE_LEG_CURL
    | typeof EXERCISE_CODE_LEG_EXTENSION
    | typeof EXERCISE_CODE_BENT_OVER_ROW
    | typeof EXERCISE_CODE_DECLINE_BENCH
    | typeof EXERCISE_CODE_INCLINE_BENCH
    | typeof EXERCISE_CODE_BENCH_PRESS
    | typeof EXERCISE_CODE_STEP_UP
    | typeof EXERCISE_CODE_HIP_THRUST
    | typeof EXERCISE_CODE_LAT_PULLDOWN
    | typeof EXERCISE_CODE_ROW
    | typeof EXERCISE_CODE_BARBELL_ROW
    | typeof EXERCISE_CODE_BARBELL_CURL
    | typeof EXERCISE_CODE_FRENCH_PRESS
    | typeof EXERCISE_CODE_PUSH_DOWN
    | typeof EXERCISE_CODE_MILITARY_PRESS
    | typeof EXERCISE_CODE_CHEST_ROW
    | typeof EXERCISE_CODE_CLEAN
    | typeof EXERCISE_CODE_DEADLIFT
    | typeof EXERCISE_CODE_SNATCH;

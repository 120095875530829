import { useState } from "react";

interface Props {
  onSearchChange?: (value: string) => void;
}

export const useLogic = (props: Props) => {
  const [
    isSearchBarVisible,
    setSearchBarVisible,
  ] = useState<boolean>(false);

  const handleSearchButtonClick = () => {
    setSearchBarVisible(!isSearchBarVisible);
    if (isSearchBarVisible) {
      setSearchValue("");
      props.onSearchChange("");
    }
  };

  const [
    searchValue,
    setSearchValue,
  ] = useState<string>();

  const handleOnSearchChange = (value: string) => {
    props.onSearchChange(value);
    setSearchValue(value);
  };

  return {
    isSearchBarVisible: isSearchBarVisible,
    handleSearchButtonClick: handleSearchButtonClick,
    handleOnSearchChange: handleOnSearchChange,
    searchValue: searchValue,
    setSearchValue: setSearchValue,
  };
};

import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "@core/redux/store";
import { STORE_TABS_KEY } from "@feature/tab/tabsConstants";

type InitialStateModelInterface = {
  isVisible: boolean;
  activeRoute?: string;
}

const initialState: InitialStateModelInterface = {
  isVisible: false,
  activeRoute: null,
};

export const tabsSlice = createSlice({
  name: STORE_TABS_KEY,
  initialState: initialState,
  reducers: {
    setIsVisible: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    setActiveRoute: (state, action: PayloadAction<string>) => {
      state.activeRoute = action.payload;
    },
  },
});

export const {
  setActiveRoute,
  setIsVisible,
} = tabsSlice.actions;

export const selectTabs = (state: RootState) => state[STORE_TABS_KEY];

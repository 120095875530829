/*import Filters from "@ui/Filters";*/
import {
  ButtonClick,
  MultipleChildrens,
} from "@types-custom";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { ModalContentUi } from "@ui/ModalContentUi/ModalContentUi";
import { ModalUi } from "@ui/ModalUi/ModalUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { t } from "@lingui/macro";
import { useLogic } from "@ui/ModalExercisesUi/useLogic";
import { useStyles } from "./useStyles";

interface Props {
  title?: string;
  isOpen: boolean;
  children: MultipleChildrens;
  onClose?: ButtonClick;
  closeButtonLabel?: string;
  onActionButtonClick?: ButtonClick;
  actionButtonLabel?: string;
  closeOnClickOutside?: boolean;
}

export const ModalListUi = ({
  title,
  isOpen,
  children,
  onClose,
  closeButtonLabel = t`close`,
  onActionButtonClick,
  actionButtonLabel = "Done",
  closeOnClickOutside = false,
}: Props) => {
  const { $modalContent } = useLogic({ isOpen: isOpen });
  const { classes } = useStyles();
  return <>
    <ModalUi
      isOpen={isOpen}
      onClose={onClose}
      closeOnClickOutside={closeOnClickOutside}
      className={classes.modalListRoot}>
      <ModalContentUi
        isOpen={isOpen}
        className={classes.modalList}
        animation="slide-from-bottom"
      >
        <div className={classes.modalListHeader}>
          <span className={classes.modalListHeaderTitle}>{title}</span>
          <ButtonUi
            label={closeButtonLabel}
            width="60px"
            type="secondary"
            labelColor="pureWhite"
            backgroundColor="black"
            icon=""
            iconColor="grey"
            iconPosition="left"
            fontStyle="RB12"
            appearance="rounded"
            hasToggle={false}
            hasPressed={true}
            onClick={onClose}
            isActive={false}
          />
        </div>
        <div className={classes.modalListContentContainer}>
          <SectionUi rounded>
            {children}
          </SectionUi>
        </div>
        {
          onActionButtonClick &&
          <div className={classes.modalListCTA}>
            <ButtonUi
              label={actionButtonLabel}
              width="100%"
              type="primary"
              labelColor="pureWhite"
              backgroundColor="black"
              icon=""
              iconColor="grey"
              iconPosition="left"
              fontStyle="RR16"
              appearance="rounded"
              hasToggle={false}
              hasPressed={true}
              onClick={onActionButtonClick}
              isActive={false}
            />
          </div>
        }
      </ModalContentUi>
    </ModalUi>
  </>;
};

import React, {
  useEffect,
  useMemo,
} from "react";
import useFormPersist from "react-hook-form-persist";
import {
  AthleteCreateInput,
  AthleteUpdateInput,
} from "@common/type-graphql/generated";
import { FormErrorUi } from "@ui/FormErrorUi/FormErrorUi";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { STORE_FORM_ATHLETE } from "@feature/athlete/athleteConstants";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import { emailPattern } from "@common/patterns";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import {
  historyController,
  useAppDispatch,
} from "@core/redux/store";
import { toast } from "@feature/toast/slice/toastSlice";
import {
  useAthleteCreateMutation,
  useAthleteEditMutation,
  useAthleteGetQuery,
} from "@feature/athlete/api/athleteApi";
import { useForm } from "react-hook-form";
import { wrapSetInput } from "@util/wrapSetInput";

type Props = {
  uuid?: string;
}

type FormData = {
  name?: string;
  email?: string;
  bodyHeight?: number;
  bodyWeight?: number;
}

export const AthleteForm = (props: Props) => {
  const dispatch = useAppDispatch();

  const [
    create,
    { error },
  ] = useAthleteCreateMutation();

  const [ edit ] = useAthleteEditMutation();

  const { data } = useAthleteGetQuery({ where: { uuid: props.uuid } }, { skip: !props.uuid });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: useMemo(() => {
      return data;
    }, [ data ]),
  });

  useEffect(() => {
    reset(data);
    if (!data) {
      setTimeout(() => {
        setFocus("name");
      }, 100);
    }
  }, [
    data,
    reset,
    setFocus,
  ]);

  useFormPersist(`${ STORE_FORM_ATHLETE }.uuid:${ props.uuid ?? "crete" }`, {
    watch: watch,
    setValue: setValue,
  });

  const onSubmit = async(formData: FormData) => {
    try {
      if (data) {
        if ("uuid" in formData) {
          delete formData.uuid;
        }

        if (formData.bodyHeight) {
          formData.bodyHeight = parseInt(String(formData.bodyHeight), 10);
        }

        await edit({
          data: wrapSetInput<FormData, AthleteUpdateInput>(formData),
          where: { uuid: props.uuid },
        }).unwrap();
        dispatch(toast({ message: t`Athlete edited successfully.` }));
        historyController.replace(routes.athleteView.routePath.replace(getRouteUuidPlaceholder(), props.uuid));
      } else {
        await create({ data: formData as AthleteCreateInput }).unwrap();
        dispatch(toast({ message: t`Athlete created successfully.` }));
        reset();
        historyController.replace(routes.athleteList.redirectPath);
      }
    } catch (err) {
      console.error(err, error);
      setError("root", err);
    }
  };

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <SectionUi title={t`Informations`} rounded>
        <ListItemUi title={t`Name`} isRequired={true}>
          <InputUi
            type="text"
            id="athlete-edit-name"
            autoComplete="name"
            isValid={!Boolean(errors.name)}
            {...register("name", { required: true })}
          />
          <FormErrorUi error={errors.name} />
        </ListItemUi>

        <ListItemUi
          title={t`Email`}
        >
          <InputUi
            type="email"
            id="athlete-edit-email"
            autoComplete="email"
            isValid={!Boolean(errors.email)}
            {...register("email", {
              pattern: emailPattern,
            })}
          />
          <FormErrorUi error={errors.email} />
          <small><Trans>No emails will be sent to the specified email address.</Trans></small>
        </ListItemUi>

        <ListItemUi title={t`Height (cm)`} isRequired={true}>
          <InputUi
            id="athlete-edit-bodyHeight"
            type="number"
            inputMode="numeric"
            minValue={1}
            maxValue={300}
            autoComplete="off"
            isValid={!Boolean(errors.bodyHeight)}
            {...register("bodyHeight", {
              required: true,
              valueAsNumber: true,
              validate: {
                positive: value => value > 0 || t`Height must be greater than zero`,
                maxLimit: value => value < 300 || t`Height must be less than 300 cm`,
              },
            })}
          />
          <FormErrorUi error={errors.bodyHeight} />
        </ListItemUi>

        <ListItemUi title={t`Weight (kg)`} isRequired={true}>
          <InputUi
            id="athlete-edit-bodyWeight"
            type="number"
            inputMode="numeric"
            minValue={25}
            maxValue={500}
            autoComplete="off"
            isValid={!Boolean(errors.bodyWeight)}
            {...register("bodyWeight", {
              required: true,
              valueAsNumber: true,
              validate: {
                positive: value => value > 25 || t`Body weight must be greater than 25 kg`,
                maxLimit: value => value < 500 || t`Body weight must be less than 500 kg`,
              },
            })}
          />
          <FormErrorUi error={errors.bodyWeight} />
        </ListItemUi>

        {
          errors.root &&
          <ListItemUi title={t`Errors`}>
	          <FormErrorUi error={errors.root.message} />
          </ListItemUi>
        }

        <ListItemUi>
          <small style={{ padding: 10 }}><Trans>* indicates mandatory fields.</Trans></small>
          <PrimaryPositiveButtonUi
            label={ !props.uuid ? t`Add athlete` : t`Save` }
          />
        </ListItemUi>
      </SectionUi>
    </FormUi>
  </>;
};

import {
  ChipProps,
  ChipUi,
} from "@ui/ChipUi/ChipUi";
import { ReactNode } from "react";
import { getUniqueId } from "@util/getUniqueId";
import { useStyles } from "./useStyles";

interface Props {
  chips?: ChipProps[];
  children?: ReactNode[];
}

export const ChipMultipleUi = (props: Props) => {
  const styles = useStyles();

  return <>
    <div className={styles.cx({ [styles.classes.root]: true })}>
      {
        props.chips &&
        props.chips.map(chipProp => <ChipUi key={getUniqueId()} {...chipProp} />)
      }
      {
        props.children
      }
    </div>
  </>;
};

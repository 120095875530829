import { AccountChangePasswordPage } from "@feature/account/page/accountChangePasswordPage";
import { AccountForgotPasswordPage } from "@feature/account/page/accountForgotPasswordPage";
import { AccountLoginOrBuyPage } from "@feature/account/page/accountLoginOrBuyPage";
import { AccountLoginPage } from "@feature/account/page/accountLoginPage";
import { AccountLogoutPage } from "@feature/account/page/accountLogoutPage";
import { AccountSignupPage } from "@feature/account/page/accountSignupPage";
import { AccountViewPage } from "@feature/account/page/accountViewPage";
import { AthleteConnectPage } from "@feature/athlete/page/athleteConnectPage";
import { AthleteCreatePage } from "@feature/athlete/page/athleteCreatePage";
import { AthleteEditPage } from "@feature/athlete/page/athleteEditPage";
import { AthleteListPage } from "@feature/athlete/page/athleteListPage";
import { AthleteViewPage } from "@feature/athlete/page/athleteViewPage";
import { DashboardPage } from "@feature/dashboard/page/dashboardPage";
import { DevelopmentPage } from "@feature/development/page/developmentPage";
import { DeviceListPage } from "@feature/device/page/deviceListPage";
import { HistoryListPage } from "@feature/history/page/historyListPage";
import { HistoryViewChartPage } from "@feature/history/page/historyViewChartPage";
import { HistoryViewPage } from "@feature/history/page/historyViewPage";
import { InfoPowerReferencePage } from "@feature/info/page/InfoPowerReferencePage";
import { InfoRangePage } from "@feature/info/page/InfoRangePage";
import { IonRouterOutlet } from "@ionic/react";
import { MaintenancePage } from "@feature/maintenance/page/MaintenancePage";
import { ProfileCreatePage } from "@feature/profile/page/profileCreatePage";
import { ProfileEditPage } from "@feature/profile/page/profileEditPage";
import { ProfileListPage } from "@feature/profile/page/profileListPage";
import { QuickEditPage } from "@feature/training/page/quickEditPage";
import {
  Redirect,
  Route,
} from "react-router-dom";
import { RouteNotFoundComponent } from "@feature/layout/component/RouteNotFoundComponent";
import { RunPage } from "@feature/run/page/RunPage";
import { SettingsPage } from "@feature/setting/page/settingsPage";
import { SheetCreatePage } from "@feature/sheet/page/sheetCreatePage";
import { SheetEditPage } from "@feature/sheet/page/sheetEditPage";
import { SheetListPage } from "@feature/sheet/page/sheetListPage";
import { TrainingCreatePage } from "@feature/training/page/trainingCreatePage";
import { TrainingEditPage } from "@feature/training/page/trainingEditPage";
import { VersionMandatoryPage } from "@feature/maintenance/page/VersionMandatoryPage";
import { WelcomePage } from "@feature/welcome/page/welcomePage";
import { memo } from "react";
import { routes } from "@core/route";

export const RouterOutlet = () => {
  return <>
    <IonRouterOutlet>
      <RouterList />
    </IonRouterOutlet>
  </>;
};

const RouterList = memo(() => {
  return <>
    <RouteNotFoundComponent />
    <Route exact path={routes.root.routePath}>
      <Redirect to={routes.welcome.routePath} />
    </Route>
    <Route path={routes.maintenance.routePath}>
      <MaintenancePage />
    </Route>
    <Route path={routes.maintenanceVersion.routePath}>
      <VersionMandatoryPage />
    </Route>
    <Route path={routes.development.routePath}>
      <DevelopmentPage />
    </Route>
    <Route path={routes.dashboard.routePath}>
      <DashboardPage />
    </Route>
    <Route path={routes.welcome.routePath}>
      <WelcomePage />
    </Route>
    <Route path={routes.sheetList.routePath}>
      <SheetListPage />
    </Route>
    <Route path={routes.historyList.routePath}>
      <HistoryListPage />
    </Route>
    <Route path={routes.historyView.routePath}>
      <HistoryViewPage />
    </Route>
    <Route path={routes.historyViewChart.routePath}>
      <HistoryViewChartPage />
    </Route>
    <Route path={routes.sheetEdit.routePath}>
      <SheetEditPage />
    </Route>
    <Route path={routes.sheetCreate.routePath}>
      <SheetCreatePage />
    </Route>
    <Route path={routes.settings.routePath}>
      <SettingsPage />
    </Route>
    <Route path={routes.accountLoginOrBuy.routePath}>
      <AccountLoginOrBuyPage />
    </Route>
    <Route path={routes.accountSignup.routePath}>
      <AccountSignupPage />
    </Route>
    <Route path={routes.accountForgotPassword.routePath}>
      <AccountForgotPasswordPage />
    </Route>
    <Route path={routes.accountChangePassword.routePath}>
      <AccountChangePasswordPage />
    </Route>
    <Route path={routes.accountLogin.routePath}>
      <AccountLoginPage />
    </Route>
    <Route path={routes.accountLogout.routePath}>
      <AccountLogoutPage />
    </Route>
    <Route path={routes.accountView.routePath}>
      <AccountViewPage />
    </Route>
    <Route path={routes.athleteList.routePath}>
      <AthleteListPage />
    </Route>
    <Route path={routes.athleteView.routePath}>
      <AthleteViewPage />
    </Route>
    <Route path={routes.athleteCreate.routePath}>
      <AthleteCreatePage />
    </Route>
    <Route path={routes.athleteEdit.routePath}>
      <AthleteEditPage />
    </Route>
    <Route path={routes.athleteConnect.routePath}>
      <AthleteConnectPage />
    </Route>
    <Route path={routes.profileList.routePath}>
      <ProfileListPage />
    </Route>
    <Route path={routes.profileCreate.routePath}>
      <ProfileCreatePage />
    </Route>
    <Route path={routes.profileEdit.routePath}>
      <ProfileEditPage />
    </Route>
    <Route path={routes.quickEdit.routePath}>
      <QuickEditPage />
    </Route>
    <Route path={routes.trainingEdit.routePath}>
      <TrainingEditPage />
    </Route>
    <Route path={routes.trainingCreate.routePath}>
      <TrainingCreatePage />
    </Route>
    <Route path={routes.run.routePath}>
      <RunPage />
    </Route>
    <Route path={routes.deviceList.routePath}>
      <DeviceListPage />
    </Route>
    <Route path={routes.infoRange.routePath}>
      <InfoRangePage />
    </Route>
    <Route path={routes.infoPowerReference.routePath}>
      <InfoPowerReferencePage />
    </Route>
  </>;
});

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    mainTitleBarRoot: {
      backgroundColor: theme.colors.black,
      padding: "15px 24px 8px 24px",
      borderRadius: "0 0 32px 32px",
      display: "flex",
      alignItems: "center",
      marginTop: -1,
    },
    title: {
      color: theme.colors.pureWhite,
      ...theme.typography.RB32,
      display: "block",
      flexGrow: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "auto",
      span: {
        display: "inline-block",
        animation: "none",
        "&.animated": { animation: "scrollContent 5s linear infinite" },
      },
    },
  })
);

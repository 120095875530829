import ReactDOM from "react-dom";
import {
  ButtonClick,
  MultipleChildrens,
} from "@types-custom";
import { useLogic } from "./useLogic";
import { useStyles } from "./useStyles";

interface Props {
  isOpen?: boolean;
  children?: MultipleChildrens;
  onClose?: ButtonClick;
  className?: string;
  closeOnClickOutside?: boolean;
}

export const ModalUi = ({
  isOpen = false, children, onClose, className, closeOnClickOutside,
}: Props) => {
  const { classes } = useStyles();
  const {
    $root, $innerModal,
  } = useLogic({
    isOpen: isOpen,
    onClose: onClose,
    closeOnClickOutside: closeOnClickOutside,
  });

  return ReactDOM.createPortal(
    <div ref={$root} className={className}>
      <div ref={$innerModal}>
        {children}
      </div>
    </div>,
    document.getElementById("root")
  );
};

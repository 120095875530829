import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    spinnerRoot: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingLabel: {
      ...theme.typography.RB12,
      lineHeight: "20px",
      padding: 0,
      margin: 0,
    },
    svg: {
      width: 50,
      height: 50,

      /*zIndex: 1,
      position: "absolute",*/
    },
    polyline1: {},
  })
);

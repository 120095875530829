import { GraphQLError } from "graphql";
import { i18n } from "@lingui/core";

export const ERROR_FORM_TYPE = "FORM";

export type GraphQLErrorFormField = {
  name: string;
  message: string;
}

export class GraphQLErrorForm extends GraphQLError {
  constructor(
    message: string,
    fields: GraphQLErrorFormField[]
  ) {
    super(
      i18n._(message),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      {
        code: ERROR_FORM_TYPE,
        fields: fields,
      }
    );
  }
}

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    trainingInfoRoot: {
      "& > div": {
        display: "flex",
        flex: "1 0 50%;",
        height: 25,
        "& > p": {
          ...theme.typography.RR14,
          lineHeight: "22px",
          margin: 3,
          gap: 3,
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        },
      },
    },
  })
);

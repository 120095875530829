import React, { ReactNode } from "react";
import { BackButtonUi } from "@ui/BackButtonUi/BackButtonUi";
import {
  ButtonClick,
  Color,
  HeaderBackground,
} from "@types-custom";
import { IonHeader } from "@ionic/react";
import { MainNavigationBarUi } from "@ui/MainNavigationBarUi/MainNavigationBarUi";
import { MainTitleBarUi } from "@ui/MainTitleBarUi/MainTitleBarUi";
import { Route } from "@core/route";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useStyles } from "./useStyles";

interface Props {
  pageTitle?: string;
  backHistory?: boolean;
  backToRoute?: Route;
  backToTitle?: string;
  backToPath?: string;
  background?: HeaderBackground;
  avatarColor?: Color;
  onNotificationButtonClick?: ButtonClick;
  rightChildren?: ReactNode;
  navigatorHide?: boolean;
}

export const HeaderUi = ({
  pageTitle,
  backHistory,
  backToRoute,
  backToTitle,
  backToPath,
  background = "black",
  avatarColor = null,
  rightChildren = <></>,
  navigatorHide = false,
}: Props) => {
  const {
    classes, cx,
  } = useStyles({
    hasTitle: Boolean(pageTitle),
    background: background,
  });

  const getChildrenLeft = () => {
    if (backToPath && backToTitle) {
      return <>
        <BackButtonUi href={backToPath} label={i18n._(backToTitle)} />
      </>;
    }
    if (backToPath) {
      return <>
        <BackButtonUi href={backToPath} />
      </>;
    }
    if (backToRoute) {
      return <>
        <BackButtonUi href={backToRoute.redirectPath} label={i18n._(backToRoute.title)} />
      </>;
    }
    if (backHistory) {
      return <>
        <BackButtonUi label={t`Back`} />
      </>;
    }
    return <></>;
  };

  const getChildrenRight = () => {
    return <></>;
    // <Button
    //   label=""
    //   width=""
    //   type="primary"
    //   labelColor="neon"
    //   backgroundColor="transparent"
    //   icon="bell"
    //   iconColor="grey"
    //   iconPosition="top"
    //   fontStyle="RB12"
    //   appearance="rounded"
    //   hasToggle={false}
    //   hasPressed={false}
    //   // onClick={onNotificationButtonClick}
    //   isActive={false}
    //   hasBadge={true}
    //   badgeBackground={background}
    // />
  };
  return <>
    <IonHeader className={classes.headerRoot}>
      <div className={classes.iosHeaderPlaceholder} />
      <MainNavigationBarUi
        background={background}
        childrenLeft={getChildrenLeft()}
        childrenRight={rightChildren}
        navigatorBarHide={navigatorHide}
      />
      {
        pageTitle &&
        <MainTitleBarUi title={i18n._(pageTitle)} avatarColor={avatarColor} />
      }
    </IonHeader>
  </>;
};

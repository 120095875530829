import { t } from "@lingui/macro";

type Props = {
  header?: string;
  subHeader?: string;
  present: ({
    header,
    subHeader,
    buttons,
    onDidDismiss,
  }) => void;
  action: () => void;
}

export const confirmDelete = (props: Props) => {
  props.present({
    header: props.header ?? t`Delete`,
    subHeader: props.subHeader ?? t`Are you sure?`,
    buttons: [
      {
        text: t`Delete`,
        role: "destructive",
        data: { action: "delete" },
      },
      {
        text: t`Do not delete`,
        role: "cancel",
        data: { action: "cancel" },
      },
    ],
    onDidDismiss: async({ detail }) => {
      if (detail.data && detail.data.action === "delete") {
        props.action();
      }
    },
  });
};

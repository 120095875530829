import React from "react";
import { ApiData } from "@feature/api/component/ApiData";
import { AthleteChipComponent } from "@feature/athlete/component/athleteChipComponent";
import { ChipMultipleUi } from "@ui/ChipMultipleUi/ChipMultipleUi";
import { ExerciseChipComponent } from "@feature/exercise/component/exerciseChipComponent";
import {
  HistorySerie,
  HistorySerieOrderByWithRelationInput,
  HistoryWorkout,
} from "@common/type-graphql/generated";
import { HistorySerieItemComponent } from "@feature/history/component/historySerieItemComponent";
import { ProfileChipComponent } from "@feature/profile/component/profileChipComponent";
import { ProtocolChipComponent } from "@feature/protocol/component/protocolChipComponent";
import { getUniqueId } from "@util/getUniqueId";
import {
  useHistorySerieListQuery,
  useHistoryWorkoutGetQuery,
} from "@feature/history/api/historyApi";

type Props = {
  historyWorkoutUuid: string;
}

export const HistorySerieListComponent = (props: Props) => {
  const orderBy: [HistorySerieOrderByWithRelationInput] = [ { dateTime: "asc" } ];
  const historyWorkoutApi = useHistoryWorkoutGetQuery({ where: { uuid: props.historyWorkoutUuid } });
  const historySerieListApi = useHistorySerieListQuery({
    where: { historyWorkoutUuid: { equals: props.historyWorkoutUuid } },
    orderBy: orderBy,
  });

  return <>
    <ApiData
      endpoint={[
        historySerieListApi,
        historyWorkoutApi,
      ]}
    >
      <HistorySerieListComponentChild
        historyWorkout={historyWorkoutApi.data}
        historySeries={historySerieListApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  historyWorkout: HistoryWorkout;
  historySeries: HistorySerie[];
}

const HistorySerieListComponentChild = (props: PropsChild) => {
  return <>
    <>
      <ChipMultipleUi>
        <AthleteChipComponent profileUuid={props.historyWorkout.profileUuid} />
        <ProfileChipComponent profileUuid={props.historyWorkout.profileUuid} />
      </ChipMultipleUi>
      <ChipMultipleUi>
        <ProtocolChipComponent protocolUuid={props.historyWorkout.protocolUuid} />
        <ExerciseChipComponent exerciseUuid={props.historyWorkout.exerciseUuid} />
      </ChipMultipleUi>
    </>
    {
      props.historySeries.map((historySerie, index) =>
        <HistorySerieItemComponent
          key={getUniqueId()}
          uuid={historySerie.uuid}
          n={index + 1}
        />
      )
    }
  </>;
};

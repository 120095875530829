import moment from "moment/moment";
import {
  Locale,
  i18n,
} from "@lingui/core";
import { REHYDRATE } from "redux-persist";
import { STORE_SETTING_KEY } from "@feature/setting/settingConstants";

export const i18nRehydrateMiddleware = store => next => action => {
  if (action.type === REHYDRATE) {
    const { payload } = action;
    if (
      payload &&
      payload[STORE_SETTING_KEY] &&
      payload[STORE_SETTING_KEY].language
    ) {
      const language: Locale = payload[STORE_SETTING_KEY].language;
      i18n.activate(language);
      moment.locale(language);
    }
  }
  return next(action);
};

import { ButtonClick } from "@types-custom";
import {
  IonChip,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { useStyles } from "./useStyles";

export interface ChipProps {
  icon: string;
  title: string;
  size?: "small" | "large";
  onClick?: ButtonClick;
}

export const ChipUi = (props: ChipProps) => {
  const styles = useStyles();

  return <>
    <IonChip
      color="primary"
      onClick={() => {
        if (props.onClick) {
          props.onClick(null);
        }
      }}
      className={styles.cx({ [styles.classes.root]: true })}>
      <IonIcon
        icon={props.icon}
        size={props.size ?? "small"} />
      <div className={styles.classes.labelContainer}>
        <IonLabel>
          {props.title}
        </IonLabel>
      </div>
    </IonChip>
  </>;
};

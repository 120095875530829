import { AthleteForm } from "@feature/athlete/component/form/athleteForm";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { i18n } from "@lingui/core";
import { useParams } from "react-router-dom";

type Params = {
  uuid?: string;
}

export const AthleteEditPage = () => {
  const params = useParams<Params>();

  return <>
    <IonPage>
      {
        params.uuid ?
          <HeaderUi
            pageTitle={i18n._(routes.athleteEdit.title)}
            backToPath={routes.athleteView.routePath.replace(getRouteUuidPlaceholder(), params.uuid)}
            backToTitle={i18n._(routes.athleteView.title)} /> :
		      <HeaderUi
            pageTitle={i18n._(routes.athleteEdit.title)}
            backToRoute={routes.athleteList} />
      }
      <IonContent>
        <AthleteForm uuid={params.uuid} />
      </IonContent>
    </IonPage>
  </>;
};

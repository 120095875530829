import { SingleChildren } from "@types-custom";
import { useLogic } from "./useLogic";

interface Props {
  className: string;
  isOpen: boolean;
  animation: "scale-from-center" | "slide-from-bottom";
  children: SingleChildren;
}

export const ModalContentUi = ({
  className, isOpen, animation, children,
}: Props) => {
  const { $root } = useLogic({
    isOpen: isOpen,
    animation: animation,
  });
  return <>
    <div ref={$root} className={className}>
      {children}
    </div>
  </>;
};

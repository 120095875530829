import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    searchBarRoot: {
      width: "100%",
      padding: "7px 0",
      "& div": {
        maxHeight: 35,
        "& input": {
          minHeight: 35,
          borderRadius: 8,
          padding: "7px 16px",
        },
      },

    },
  })
);

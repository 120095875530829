import { theme } from "@theme/index";

export const globals = {
  html: {
    display: "flex",
    justifyContent: "center",
  },
  button: { "&[disabled]": { cursor: "not-allowed" } },
  "ion-header ~ ion-content": {
    "--padding-top": "38px",
    "--padding-bottom": `${ theme.footerHeight + theme.defaultPadding + theme.defaultPadding }px`,
  },
  h2: {
    "ion-button": {
      margin: "0 0 0 15px",
      verticalAlign: "sub",
    },
  },
  "ion-action-sheet": {
    "& .action-sheet-title.action-sheet-has-sub-title": {
      fontSize: "1.2rem",
      color: "white",
      "& div.action-sheet-sub-title": {
        fontSize: "1rem",
      },
    },
  },
};

import { DebugSerie1 } from "@feature/development/component/debugSerie1";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";

export const DevelopmentPage = () => {
  return <>
    <IonPage>
      <HeaderUi pageTitle={i18n._(routes.development.title)} />
      <IonContent>
        {/*<DebugAudio />*/}
        <DebugSerie1 />
      </IonContent>
    </IonPage>
  </>;
};

import { ButtonClick } from "@types-custom";
import { useStyles } from "./useStyles";

type Items = {
  id: number;
  image: string;
  title: string;
  text: string;
}

interface DotButtonProps {
  selected: boolean;
  onClick: ButtonClick;
}

export const WelcomeCarouselDotButtonUi = ({
  selected, onClick,
}: DotButtonProps) => {
  const {
    classes, cx,
  } = useStyles();

  return <>
    <div className={classes.buttonRoot}>
      <button
        className={cx({
          "embla__dot": true,
          "is-selected": selected,
        })}
        type="button"
        onClick={onClick}
      >
        <span />
      </button>
    </div>
  </>;
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    listActionBarWrapper: {
      position: "relative",
      zIndex: 1,
      height: "fit-content",
    },
    listActionBarRoot: {
      display: "flex",
      width: "100%",
      backgroundColor: theme.colors.pureBlack,
      padding: 8,
    },
    firstMask: {
      top: 0,
      left: 0,
      position: "absolute",
      transform: "scaleY(-1)",
      transformOrigin: "0% 0%",
    },
    lastMask: {
      position: "absolute",
      top: "100%",
    },
    childrenWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      "& button": { margin: 0 },
    },
    childrenRight: {
      width: "fit-content",
      justifyContent: "flex-end",
    },
  })
);

import { BluetoothDevice } from "@feature/device/slice/deviceSlice";
import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import {
  STORE_DEVICE_AUTO_CONNECT_KEY,
} from "@feature/device/deviceConstants";

type InitialStateModelInterface = {
  autoConnectDevice: BluetoothDevice | null;
}

const initialState: InitialStateModelInterface = { autoConnectDevice: null };

export const deviceAutoConnectSlice = createSlice({
  name: STORE_DEVICE_AUTO_CONNECT_KEY,
  initialState: initialState,
  reducers: {
    setDeviceAutoConnected: (state, payload: PayloadAction<BluetoothDevice>) => {
      state.autoConnectDevice = payload.payload;
    },
    unsetDeviceAutoConnected: state => {
      state.autoConnectDevice = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});

export const {
  setDeviceAutoConnected,
  unsetDeviceAutoConnected,
} = deviceAutoConnectSlice.actions;

export const selectDeviceAutoConnectState = (state: RootState) => state[STORE_DEVICE_AUTO_CONNECT_KEY];

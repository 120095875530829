import { InputUi } from "@ui/InputUi/InputUi";
import { t } from "@lingui/macro";
import { useStyles } from "./useStyles";

interface Props {
  value: string;
  onSearchChange: (value: string) => void;
}

export const SearchBarUi = (props: Props) => {
  const {
    classes, cx,
  } = useStyles();

  return <>
    <div className={classes.searchBarRoot}>
      <InputUi
        autoFocus
        id="searchbar"
        type="text"
        placeholder={t`Type here...`}
        autoComplete="off"
        hasViewPassword={false}
        hasTooltip={false}
        value={props.value}
        onChange={event => {
          const val = event.currentTarget.value;
          props.onSearchChange(val);
        }}
      />
    </div>
  </>;
};

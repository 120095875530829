/* eslint-disable no-process-env */

import { IConfigApp } from "./IConfigApp";
import { configLocal } from "./local";
import { configProduction } from "./production";
import { configStaging } from "./staging";

const getConfig = (): IConfigApp => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "local":
      return configLocal;
    case "staging":
      return configStaging;
    case "production":
      return configProduction;
    default:
      throw new Error("Invalid environment");
  }
};

export const config: IConfigApp = getConfig();

import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import { HistorySerieSwiperViewComponent } from "@feature/history/component/historySerieSwiperViewComponent";
import {
  IonContent,
  IonPage,
} from "@ionic/react";

import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { i18n } from "@lingui/core";
import { runPageStyle } from "@feature/run/page/RunPageStyle";
import { useParams } from "react-router-dom";

type Params = {
  historySerieUuid: string;
}

export const HistoryViewChartPage = () => {
  const params = useParams<Params>();

  const { classes } = runPageStyle();

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.historyViewChart.title)}
        backToTitle={i18n._("Back")}
        backToPath={routes.historyView.routePath.replace(getRouteUuidPlaceholder("historySerie"), params.historySerieUuid)}
      />
      <IonContent>
        <div className={classes.historyPage}>
          <HistorySerieSwiperViewComponent
            showChips={true}
          />
        </div>
      </IonContent>
    </IonPage>
  </>;
};

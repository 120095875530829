import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    emptyListMessageRoot: {
      width: "100%",
      display: "flex",
      placeContent: "center",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px 32px 32px",
    },
    title: {
      maxWidth: 256,
      ...theme.typography.RM18,
      color: theme.colors.pureWhite,
      textAlign: "center",
    },
    text: {
      maxWidth: 256,
      ...theme.typography.RR14,
      color: theme.colors.grey,
      marginTop: 8,
      marginBottom: 16,
      textAlign: "center",
    },
    link: {
      ...theme.typography.RR14,
      color: theme.colors.neon,
      marginTop: 8,
      marginBottom: 16,
      textAlign: "center",
    },
    fullScreen: { height: "50%" },
  })
);

import { MotionRangeMissingCueComponent } from "@feature/run/component/MotionRangeMissingCueComponent";
import {
  RUN_STATUS_RUNNING,
  selectRunState,
} from "@feature/run/slice/runSlice";
import { ReferencePowerMissingCueComponent } from "@feature/run/component/ReferencePowerMissingCueComponent";
import { selectDeviceState } from "@feature/device/slice/deviceSlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAppSelector } from "@core/redux/store";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useRunRange } from "@feature/run/hook/useRunRange";

export const CueComponent = () => {
  const runState = useAppSelector(selectRunState);
  const deviceState = useAppSelector(selectDeviceState);

  const {
    endpoints: rangeEndpoints,
    runRangeMinMax,
  } = useRunRange();

  const {
    endpoints: runConfigurationEndpoints,
    getRunConfigurationUsePower,
    getRunConfigurationPowerReferenceCurrent,
  } = useConfiguration();

  const { isApiDataReady } = useApiDataLogic([
    ...rangeEndpoints,
    ...runConfigurationEndpoints,
  ]);

  if (
    runState.runStatus === RUN_STATUS_RUNNING ||
    !deviceState.connectedDevice ||
    !isApiDataReady
  ) {
    return null;
  }

  if (runRangeMinMax === null) {
    return <MotionRangeMissingCueComponent />;
  }

  if (
    getRunConfigurationUsePower() &&
    getRunConfigurationPowerReferenceCurrent() === null
  ) {
    return <ReferencePowerMissingCueComponent />;
  }

  return null;
};

import { ApiData } from "@feature/api/component/ApiData";
import { HistorySerieListComponent } from "@feature/history/component/historySerieListComponent";
import { HistoryWorkout } from "@common/type-graphql/generated";
import { HistoryWorkoutUi } from "@ui/HistoryWorkoutUi/HistoryWorkoutUi";
import { useHistoryWorkoutGetQuery } from "@feature/history/api/historyApi";

type Props = {
  uuid: string;
}

export const HistoryWorkoutItemComponent = (props: Props) => {
  const historyWorkoutApi = useHistoryWorkoutGetQuery({ where: { uuid: props.uuid } });

  return <>
    <ApiData endpoint={historyWorkoutApi}>
      <HistoryWorkoutItemComponentChild
        historyWorkout={historyWorkoutApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  historyWorkout: HistoryWorkout;
}

const HistoryWorkoutItemComponentChild = (props: PropsChild) => {
  return <>
    <HistoryWorkoutUi>
      <HistorySerieListComponent
        historyWorkoutUuid={props.historyWorkout.uuid}
      />
    </HistoryWorkoutUi>
  </>;
};

import { colors } from "@theme/colors";
import { paddings } from "@theme/paddings";
import { typography } from "@theme/typography";

export const theme = {
  isDarkMode: true,
  colors: colors,
  typography: typography,
  paddings: paddings,
  defaultPadding: 20,
  titleBarHeight: 32,
  runHeaderHeight: 70,
  runFooterHeight: 140,
  footerHeight: 54,
};

import {
  BLUETOOTH_DEVICE_STATUS_CONNECTED,
  DEVICE_FAKE_ID,
  DEVICE_FAKE_NAME,
} from "@feature/device/deviceConstants";
import {
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
} from "@ionic/react";
import {
  bluetooth,
  bluetoothOutline,
  bugOutline,
} from "ionicons/icons";
import {
  deviceConnect,
  deviceDisconnectAll,
  selectDeviceState,
} from "@feature/device/slice/deviceSlice";
import {
  selectDeviceAutoConnectState,
  setDeviceAutoConnected,
  unsetDeviceAutoConnected,
} from "@feature/device/slice/deviceAutoConnectSlice";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";

export const DeviceDevelpomentFabComponent = () => {
  const dispatch = useAppDispatch();

  const settingState = useAppSelector(selectSettingState);
  const deviceState = useAppSelector(selectDeviceState);
  const bluetoothAutoConnectState = useAppSelector(selectDeviceAutoConnectState);

  const singleConnect = () => {
    dispatch(deviceConnect({
      name: DEVICE_FAKE_NAME,
      id: DEVICE_FAKE_ID,
      rssi: 0,
      status: BLUETOOTH_DEVICE_STATUS_CONNECTED,
    }));
  };

  const disconnect = () => {
    dispatch(unsetDeviceAutoConnected());
    dispatch(deviceDisconnectAll());
  };

  const autoConnect = () => {
    dispatch(setDeviceAutoConnected({
      name: DEVICE_FAKE_NAME,
      id: DEVICE_FAKE_ID,
      rssi: 0,
      status: BLUETOOTH_DEVICE_STATUS_CONNECTED,
    }));
    singleConnect();
  };

  const isFakeDeviceAutoConnected = () => {
    return bluetoothAutoConnectState.autoConnectDevice?.name === DEVICE_FAKE_NAME;
  };

  if (!settingState.isDebug) {
    return <></>;
  }

  return <>
    <IonFab>
      <IonFabButton
        color="primary"
        size="small">
        <IonIcon
          icon={bugOutline}
          size="small" />
      </IonFabButton>
      <IonFabList side="bottom">
        {
          !deviceState.connectedDevice &&
          <IonFabButton onClick={singleConnect} color={"tertiary"}>
            <IonIcon icon={bluetoothOutline}></IonIcon>
            <small>BT</small>
          </IonFabButton>
        }
        {
          deviceState.connectedDevice &&
          <IonFabButton onClick={disconnect} color={"danger"}>
            <IonIcon icon={bluetoothOutline}></IonIcon>
            <small>Off</small>
          </IonFabButton>
        }
        {
          deviceState.connectedDevice &&
          !isFakeDeviceAutoConnected() &&
          <IonFabButton onClick={autoConnect} color={"secondary"}>
            <IonIcon icon={bluetooth}></IonIcon>
            <small>Auto</small>
          </IonFabButton>
        }
        {
          deviceState.connectedDevice &&
          isFakeDeviceAutoConnected() &&
          <IonFabButton onClick={disconnect} color={"tertiary"}>
            <IonIcon icon={bluetooth}></IonIcon>
            <small>OFF</small>
          </IonFabButton>
        }
      </IonFabList>
    </IonFab>
  </>;
};

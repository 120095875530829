import {
  Account,
} from "@common/type-graphql/generated";
import { ApiData } from "@feature/api/component/ApiData";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import {
  CUSTOMER_SERVICE_EMAIL,
  CUSTOMER_SERVICE_EMAIL_URL,
  CUSTOMER_SERVICE_PHONE_NUMBER,
  CUSTOMER_SERVICE_WHATSAPP_URL,
} from "@common/commonConstants";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import { confirmDestructive } from "@feature/confirm/service/confirmDestructive";
import {
  historyController,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";
import { toast } from "@feature/toast/slice/toastSlice";
import { typography } from "@theme/typography";
import {
  useAccountDestroyMutation,
  useAccountGetQuery,
} from "@feature/account/api/accountApi";
import { useIonActionSheet } from "@ionic/react";

export const AccountViewComponent = () => {
  const accountApi = useAccountGetQuery();

  return <>
    <ApiData endpoint={[ accountApi ]}>
      <ChildComponent
        account={accountApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  account: Account;
}
const ChildComponent = (props: PropsChild) => {
  const dispatch = useAppDispatch();

  const [ present ] = useIonActionSheet();
  const [ destroy ] = useAccountDestroyMutation();

  const goToChangePassword = () => {
    historyController.replace(routes.accountChangePassword.redirectPath);
  };

  const destroyAccount = () => {
    confirmDestructive({
      header: t`Delete your account`,
      subHeader: t`By deleting your account you will no longer be able to access the application and all your data will be deleted.`,
      present: present,
      action: async() => {
        destroy({
          data: {
            reason: "User requested",
          },
        });
        historyController.replace(routes.accountLogout.redirectPath);
        dispatch(toast({ message: t`Account deleted successfully` }));
      },
    });
  };

  return <>
    <SectionUi rounded>
      <ListItemUi
        title={t`Firstname`}
        description={props.account.firstname}
        titleTypography={typography.RB24}
        textElement={true}
      />
      <ListItemUi
        title={t`Lastname`}
        description={props.account.lastname}
        titleTypography={typography.RB24}
        textElement={true}
      />

      {
        props.account.email &&
		    <ListItemUi
			    title={t`Email`}
			    description={`${ props.account.email }  (${ props.account.emailVerifiedAt ? t`Verified` : t`Not verified` })` }
			    titleTypography={typography.RB24}
			    textElement={true}
		    />
      }

      {
        props.account.phoneNumber &&
		    <ListItemUi
			    title={t`Phone number`}
			    description={`${ props.account.phoneNumber }  (${ props.account.phoneNumberVerifiedAt ? t`Verified` : t`Not verified` })` }
			    titleTypography={typography.RB24}
			    textElement={true}
		    />
      }

      <ListItemUi
        title={t`Marketing`}
        description={`${ props.account.marketingAcceptedAt ? t`Accepted` : t`Not Accepted` }` }
        titleTypography={typography.RB24}
        textElement={true}
      />

      {
        props.account.exhibition &&
	      <ListItemUi
		      title={props.account.exhibition}
		      description={`${ props.account.exhibition } (${ props.account.exhibitionAcceptedAt ? t`Accepted` : t`Not accepted` })` }
		      titleTypography={typography.RB24}
		      textElement={true}
	      />
      }

      <ListItemUi>
        <p>
          <Trans>
            To edit your informations please contact the customer care via email at <a
              target={"_blank"}
              href={ CUSTOMER_SERVICE_EMAIL_URL }
              rel="noreferrer">{ CUSTOMER_SERVICE_EMAIL }</a> or via whatsapp at <a
              target={"_blank"}
              href={CUSTOMER_SERVICE_WHATSAPP_URL}
              rel="noreferrer">{ CUSTOMER_SERVICE_PHONE_NUMBER }</a>.
          </Trans>
        </p>
      </ListItemUi>
    </SectionUi>

    <SectionUi title={t`Dangerous zone`} rounded={true}>
      <ListItemUi title={t`Password`}>
        <PrimaryButtonUi
          onClick={goToChangePassword}
          label={t`Change Password`}
        />
      </ListItemUi>

      <ListItemUi
        title={t`Delete your account`}
      >
        <p>
          <Trans>By deleting your account you will no longer be able to access the application and all your data will be deleted.</Trans>
        </p>
        <ButtonUi
          onClick={destroyAccount}
          type="primary"
          label={t`Delete your account`}
          backgroundColor="black"
          appearance="rounded"
          fontStyle="RR16"
          width="100%"
          iconPosition="left"
          labelColor="red"
        />
      </ListItemUi>
    </SectionUi>
  </>;
};

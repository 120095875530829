import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<{ iconColor }, "isToggled" | "branded" | "iconLeft">()(
  (theme, props, classes) => ({
    // Base styles
    iconRoot: {
      position: "relative",
      zIndex: 1,
      width: 24,
      height: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [`& .${ classes.iconLeft }`]: {},
    },
    hidden: { visibility: "hidden" },
    iconLeft: {
      width: 50,
      height: 50,
      borderRadius: 16,
    },
    // isActive ButtonUi is Toggled
    isToggled: {},
    // is branded
    branded: {},
    // Placeholder
    icon: {
      display: "block",
      maxWidth: 24,
      maxHeight: 24,
      fill: props.iconColor !== "" ? theme.colors[props.iconColor] : theme.colors.grey,
      // background: theme.colors.grey,
      [`&.${ classes.isToggled }`]: { fill: theme.colors.neon },
      [`&.${ classes.branded }`]: { fill: theme.colors.neon },
    },

  })
);

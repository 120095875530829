import { IonToggle } from "@ionic/react";
import { useLogic } from "./useLogic";
import { useStyles } from "./useStyles";

interface Props {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
}

export const SwitchUi = ({
  className, checked, disabled, onBlur, onChange, onFocus,
}: Props) => {
  const {
    classes, cx,
  } = useStyles();

  const {
    isActive,
    handleToggle,
  } = useLogic();

  return <>
    <IonToggle
      className={cx({
        [classes.switchRoot]: true,
        [className]: className !== "",
      })}
      checked={checked}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
  </>;
};

import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import {
  IonCol,
  IonRow,
} from "@ionic/react";
import {
  POWER_FORM_MAX_VALUE,
  POWER_FORM_MIN_VALUE,
} from "@feature/power/powerConstants";
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  usePowerCreateMutation,
} from "@feature/power/api/powerApi";
import { usePowerReset } from "@feature/power/hook/usePowerReset";

type FormData = {
  value?: string;
}

type Pros = {
  athleteUuid: string;
  exerciseUuid: string;
  loadWeight: number;
  power?: PowerReferenceValue;
}

export const PowerForm = (props: Pros) => {
  const [ powerCreate ] = usePowerCreateMutation();
  const { resetPower } = usePowerReset();

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>({
    defaultValues: {
      value: props.power ? props.power.toString() : "",
    },
  });

  const onSubmit = async(formData: FormData) => {
    try {
      const value = parseInt(formData.value, 10);

      if (value > POWER_FORM_MIN_VALUE && value < POWER_FORM_MAX_VALUE) {
        const power: PowerReferenceValue = value;
        powerCreate({
          data: {
            Athlete: { connect: { uuid: props.athleteUuid } },
            Exercise: { connect: { uuid: props.exerciseUuid } },
            loadWeight: props.loadWeight,
            powerReference: power,
            isDefault: true,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resetPowerSubmit = () => {
    reset({ value: "0" });
    resetPower({
      athleteUuid: props.athleteUuid,
      exerciseUuid: props.exerciseUuid,
      loadWeight: props.loadWeight,
    });
  };

  useEffect(() => {
    reset({
      value: props.power ? props.power.toString() : "",
    });
  }, [
    props.power,
    reset,
  ]);

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <h3>
          <Trans>Power</Trans>
        </h3>
      </IonRow>
      <IonRow>
        <IonCol>
          <InputUi
            type="number"
            autoComplete={"off"}
            minValue={POWER_FORM_MIN_VALUE}
            maxValue={POWER_FORM_MAX_VALUE}
            isValid={!Boolean(errors.value)}
            id="value"
            placeholder={t`Value`}
            {...register("value", {
              required: true,
            })}
          />
        </IonCol>
        <IonCol>
          {isSubmitting && <PrimaryPositiveButtonUi isLoading />}
          {!isSubmitting && <PrimaryPositiveButtonUi label={t`Set Power`} />}
        </IonCol>
        <IonCol>
          {!isSubmitting && <PrimaryDestructiveButtonUi label={t`Reset Power`} onClick={resetPowerSubmit} />}
        </IonCol>
      </IonRow>
    </FormUi>
  </>;
};

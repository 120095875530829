import { ApiData } from "@feature/api/component/ApiData";
import { ListButtonUi } from "@ui/ListButtonUi/ListButtonUi";
import { Profile } from "@common/type-graphql/generated";
import { useProfileGetQuery } from "@feature/profile/api/profileApi";

type Props = {
  uuid?: string;
  onClick: () => void;
}

export const ProfileListElementComponent = (props: Props) => {
  const profileApi = useProfileGetQuery({ where: { uuid: props.uuid } });

  return <>
    <ApiData endpoint={profileApi}>
      <ProfileListElementComponentChild
        {...props}
        profile={profileApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  profile: Profile;
}

const ProfileListElementComponentChild = (props: PropsChild) => {
  return <>
    <ListButtonUi
      title={props.profile.name}
      // avatarSrc={profile.avatar}
      onClick={props.onClick}
    />
  </>;
};

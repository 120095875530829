import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    notConnectedControls: {
      width: "100%",
      display: "flex",
      gap: 8,
      "& button": { margin: 0 },
    },
  })
);

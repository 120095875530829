import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (
    theme,
    props,
    classes
  ) => ({
    avatarWrapper: {
      height: 50,
      width: 50,
      overflow: "hidden",
      backgroundColor: theme.colors.black,
      borderRadius: "50%",
      marginRight: 16,
    },
    sizeBig: {
      width: 100,
      height: 100,
    },
    sizeSmall: {
      width: 30,
      height: 30,
    },
    avatarImage: {
      display: "block",
      width: "100%",
      objectFit: "cover",
    },
  })
);

import { ButtonClick } from "@types-custom";
import {
  HistorySerieDetailsProps,
  HistorySerieDetailsUi,
} from "@ui/HistorySerieDetailsUi/HistorySerieDetailsUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { getUniqueId } from "@util/getUniqueId";
import { t } from "@lingui/macro";
import { useStyles } from "@ui/HistorySerieUi/useStyles";

export interface Props extends HistorySerieDetailsProps {
  historySerieUuid: string;
  n: number;
  onClick: ButtonClick;
  commentPreview?: string;
}

export const HistorySerieUi = (props: Props) => {
  const styles = useStyles();

  return <>
    <div className={styles.cx({ [styles.classes.historySerieRoot]: true })}>
      <ListItemUi
        key={getUniqueId()}
        title={t`Serie: ` + props.n}
        onClick={props.onClick}
      >
        <HistorySerieDetailsUi {...props} />
      </ListItemUi>
    </div>
  </>;
};

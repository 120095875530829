import {
  ButtonUi,
  IconButtonProps,
} from "@ui/ButtonUi/ButtonUi";
import {
  historyController,
} from "@core/redux/store";

export const BackButtonUi = ({
  label,
  href,
}: IconButtonProps) => {
  const action = () => {
    if (href) {
      historyController.replace(href);
    } else {
      historyController.goBack();
    }
  };

  return <>
    <ButtonUi
      appearance="rounded"
      backgroundColor="transparent"
      fontStyle="RR16"
      hasPressed
      icon="expand-left"
      iconColor="neon"
      iconPosition="left"
      label={label}
      labelColor="pureWhite"
      onClick={action}
      type="primary"
      width="fit-content"
      isActive={false}
    />
  </>;
};

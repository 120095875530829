import { DisclaimerComponent } from "@feature/account/component/disclaimerComponent";
import { ForgotPasswordForm } from "@feature/account/component/form/forgotPasswordForm";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { IonRow } from "@ionic/react";
import { LogoUi } from "@ui/LogoUi/LogoUi";
import { routes } from "@core/route";
import { t } from "@lingui/macro";
import { useStyles } from "../style";

export const AccountForgotPasswordPage = () => {
  const { classes } = useStyles();

  return <>
    <IonPage>
      <IonContent>
        <HeaderUi
          background={"realBlack"}
          backToPath={routes.accountLoginOrBuy.routePath}
          backToTitle={""} />
        <div className={classes.mainContainerWithForm}>
          <div className={classes.headerContainer}>
            <IonRow className={classes.logoFixed}>
              <LogoUi full />
            </IonRow>
          </div>
          <div>
            <div className={classes.forgotPasswordFormContainer}>
              <ForgotPasswordForm />
              <div className={classes.formInfo}>
                <span>{t`We'll send you an email with a link to reset your password.`}</span>
              </div>
            </div>
            <DisclaimerComponent />
          </div>
        </div>
      </IonContent>
    </IonPage>
  </>;
};

import {
  HeaderBackground,
  MultipleChildrens,
  SingleChildren,
} from "@types-custom";
import { useStyles } from "./useStyles";

interface MainNavigationBarProps {
  className?: string;
  childrenLeft?: SingleChildren;
  childrenRight?: MultipleChildrens;
  background?: HeaderBackground;
  navigatorBarHide?: boolean;
}

export const MainNavigationBarUi = ({
  childrenLeft,
  childrenRight,
  background,
  navigatorBarHide = false,
}: MainNavigationBarProps) => {
  // Style
  const {
    classes, cx,
  } = useStyles({
    background: background,
    navigatorBarHide: navigatorBarHide,
  });

  return <>
    <div
      className={cx({
        [classes.navigationBarRoot]: true,
        [classes.coloredBackground]: (background !== "black" && background !== "realBlack"),
      })}
    >
      <div className={classes.navigationBarLeft}>
        {childrenLeft}
      </div>
      <div className={classes.navigationBarRight}>
        {childrenRight}
      </div>
    </div>
  </>;
};

import { ApiData } from "@feature/api/component/ApiData";
import { Athlete } from "@common/type-graphql/generated";
import { ListButtonUi } from "@ui/ListButtonUi/ListButtonUi";
import { useAthleteGetQuery } from "@feature/athlete/api/athleteApi";

type Props = {
  uuid?: string;
  onClick: () => void;
}

export const AthleteListElementComponent = (props: Props) => {
  const athleteApi = useAthleteGetQuery({ where: { uuid: props.uuid } });

  return <>
    <ApiData endpoint={athleteApi}>
      <AthleteListElementComponentChild
        athlete={athleteApi.data}
        onClick={props.onClick}
      />
    </ApiData>
  </>;
};

type PropChild = {
  athlete: Athlete;
  onClick: () => void;
}

const AthleteListElementComponentChild = (props: PropChild) => {
  return <>
    <ListButtonUi
      title={props.athlete.name}
      // avatarSrc={athlete.avatar}
      onClick={props.onClick}
    />
  </>;
};

import moment from "moment/moment";
import { DEFAULT_LANGUAGE } from "@feature/setting/settingConstants";
import { IOptions } from "@ui/SelectUi/SelectUi";
import { i18n } from "@lingui/core";

export const getLanguageOptions = (): Array<IOptions> => {
  return [
    {
      label: "Italiano",
      value: "it",
    },
    {
      label: "English",
      value: "en",
    },
  ];
};

export const setSystemLanguage = (localeCode?: string) => {
  const locale = localeCode ?? getSystemLanguage();
  i18n.activate(locale);
  moment.locale(locale);
  document.documentElement.lang = locale;
};

export const getSystemLanguage = (): string => (navigator.language.startsWith("en") ? "en" : DEFAULT_LANGUAGE);

import ChangePasswordForm from "@feature/account/component/form/changePasswordForm";
import {
  IonContent,
  IonPage,
} from "@ionic/react";

export const AccountChangePasswordPage = () => {
  return <>
    <IonPage>
      <IonContent>
        <div className="logo">
          <img src="/assets/img/appicon.svg" alt="Ionic logo" />
        </div>
        <ChangePasswordForm />
      </IonContent>
    </IonPage>
  </>;
};

import { AvatarColorUi } from "@ui/AvatarColorUi/AvatarColorUi";
import { Color } from "@types-custom";
import {
  useEffect,
  useRef,
  useState,
} from "react";
import { useStyles } from "./useStyles";

interface Props {
  title: string;
  avatarColor?: Color;
}

export const MainTitleBarUi = ({
  title, avatarColor,
}: Props) => {
  const {
    classes, cx,
  } = useStyles();

  const containerRef = useRef(null);
  const textRef = useRef(null);

  const [
    shouldAnimate,
    setShouldAnimate,
  ] = useState(false);

  useEffect(() => {
    const containerWidth = containerRef.current.offsetWidth;
    const textWidth = textRef.current.scrollWidth;

    if (textWidth > containerWidth) {
      setShouldAnimate(true);
    }
  }, []);

  return <>
    <div className={classes.mainTitleBarRoot}>
      {
        avatarColor &&
		    <AvatarColorUi color={avatarColor} size={"small"} />
      }
      <span className={classes.title} ref={containerRef}>
        <span className={shouldAnimate ? "animated" : ""} ref={textRef}>{title}</span>
      </span>
    </div>
  </>;
};

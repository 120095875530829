import {
  ALGORITHM_CODE_SLOW_SERIE,
  ALGORITHM_CODE_SOLO_CONCENTRIC,
  ALGORITHM_CODE_SOLO_CONCENTRIC_FULL,
  AlgorithmCode,
} from "@common/model/Algorithm";

import { ConfigurationValue } from "@common/model/ConfigurationValue";
import { Serie } from "../model/Serie";
import {
  calculatePhases,
  calculatePoints,
} from "@common/service/serieService";
import {
  getConfigurationValueFormattedLoadWeightUsed,
  getConfigurationValueFormattedMaxDistanceTooFarFromMedianSpikes,
  getConfigurationValueFormattedMergeAfterPercent,
  getConfigurationValueFormattedMergeStallTimeUnder,
  getConfigurationValueFormattedRangeMaxReductionMm,
  getConfigurationValueFormattedRangeMaxReductionPercent,
  getConfigurationValueFormattedRangeMinReductionMm,
  getConfigurationValueFormattedRangeMinReductionPercent,
  getConfigurationValueFormattedReadsPerSeconds,
  getConfigurationValueFormattedSpeedThresholdConcentric,
  getConfigurationValueFormattedSpeedThresholdEccentric,
  getRangeByConfigurations,
  getRangeMinMaxReduced,
} from "@common/service/configurationService";

interface Props {
  isLive: boolean;
  isDebug: boolean;
  algorithmCode: AlgorithmCode;
  serie: Serie;
  relationConfigurationValues: ConfigurationValue[];
  trainingOrHistoryWorkoutConfigurationValues: ConfigurationValue[];
  serieConfigurationValues: ConfigurationValue[];
}

export const calculateAlgorithm = (props: Props): boolean => {
  switch (props.algorithmCode) {
    case ALGORITHM_CODE_SOLO_CONCENTRIC:
    case ALGORITHM_CODE_SLOW_SERIE:
    case ALGORITHM_CODE_SOLO_CONCENTRIC_FULL:
      break;
    default:
      console.log("Algorithm not implemented yet", props.algorithmCode);
      return false;
  }

  const maxDistanceTooFarFromMedianSpikes = getConfigurationValueFormattedMaxDistanceTooFarFromMedianSpikes(props.relationConfigurationValues);
  const readsPerSeconds = getConfigurationValueFormattedReadsPerSeconds(props.relationConfigurationValues);
  const speedThresholdUp = getConfigurationValueFormattedSpeedThresholdConcentric(props.relationConfigurationValues);
  const speedThresholdDown = getConfigurationValueFormattedSpeedThresholdEccentric(props.relationConfigurationValues);

  if (
    !maxDistanceTooFarFromMedianSpikes ||
    !readsPerSeconds ||
    !speedThresholdUp ||
    !speedThresholdDown
  ) {
    return false;
  }

  // Range
  const rangeFull = getRangeByConfigurations(props.serieConfigurationValues);
  const minReductionMmPercent = getConfigurationValueFormattedRangeMinReductionPercent(props.relationConfigurationValues);
  const maxReductionMmPercent = getConfigurationValueFormattedRangeMaxReductionPercent(props.relationConfigurationValues);
  const minReductionMm = getConfigurationValueFormattedRangeMinReductionMm(props.relationConfigurationValues);
  const maxReductionMm = getConfigurationValueFormattedRangeMaxReductionMm(props.relationConfigurationValues);
  const rangeReduced = getRangeMinMaxReduced({
    rangeMinMax: rangeFull,
    minReductionMmPercent: minReductionMmPercent,
    maxReductionMmPercent: maxReductionMmPercent,
    minReductionMm: minReductionMm,
    maxReductionMm: maxReductionMm,
  });

  if (!calculatePoints({
    serie: props.serie,
    maxDistanceTooFarFromMedianSpikes: maxDistanceTooFarFromMedianSpikes,
    maxReadsPerSecond: readsPerSeconds,
    speedThresholdUp: speedThresholdUp,
    speedThresholdDown: speedThresholdDown,
    range: rangeReduced,
  })) {
    return false;
  }

  const mergeStallTimeUnder = getConfigurationValueFormattedMergeStallTimeUnder(props.relationConfigurationValues);
  const mergeAfterPercent = getConfigurationValueFormattedMergeAfterPercent(props.relationConfigurationValues);
  const loadWeight = getConfigurationValueFormattedLoadWeightUsed(props.serieConfigurationValues);

  const isCalculated = calculatePhases({
    serie: props.serie,
    isLive: props.isLive,
    mergeStallTimeUnder: mergeStallTimeUnder,
    mergeAfterPercent: mergeAfterPercent,
    loadWeight: loadWeight,
    rangeMinMax: rangeReduced,
  });

  if (props.isDebug) {
    console.log(JSON.parse(JSON.stringify(props.serie)));
  }

  return isCalculated;
};

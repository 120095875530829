import { Props } from "@ui/AvatarColorUi/AvatarColorUi";
import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<Props>()(
  (
    theme,
    props,
    classes
  ) => ({
    avatarWrapper: {
      height: 50,
      width: 50,
      backgroundColor: props.color !== null && props.color !== "" ? theme.colors[props.color] : theme.colors.black,
      overflow: "hidden",
      borderRadius: "50%",
      marginRight: 16,
    },
    sizeBig: {
      width: 100,
      height: 100,
    },
    sizeSmall: {
      width: 26,
      height: 23,
    },
    avatarImage: {
      display: "block",
      width: "100%",
      objectFit: "cover",
    },
  })
);

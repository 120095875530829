import { Callback } from "@types-custom";
import { DIRECTION_ASCENDING } from "@common/model/Direction";
import {
  RUN_STATUS_RUNNING,
  selectRunState,
} from "@feature/run/slice/runSlice";
import { Serie } from "@common/model/Serie";
import { isInStall } from "@common/service/pointService";
import { useAppSelector } from "@core/redux/store";

type Props = {
  serie: Serie;
  seconds: number;
  stop: (elapsedSeconds: number) => Callback;
}

type Output = {
  runAutoStop: (props: Props) => void;
};

export const useRunAutoStop = (): Output => {
  const runState = useAppSelector(selectRunState);

  const runAutoStop = (props: Props) => {
    if (runState.runStatus !== RUN_STATUS_RUNNING) {
      return;
    }

    const eccentricPhases = props.serie.finalPhases.filter(p => p.direction === DIRECTION_ASCENDING);
    if (!eccentricPhases.length) {
      return;
    }
    const lastEccentricPhase = eccentricPhases[eccentricPhases.length - 1];
    const lastToMs = lastEccentricPhase.toMs;

    const originalPoints = props.serie.originalPoints;
    const lastOriginalPoint = originalPoints[originalPoints.length - 1];

    const limitMs = props.seconds * 1000;

    if ((lastOriginalPoint.x - lastToMs) < limitMs) {
      return;
    }

    if (!isInStall(props.serie.median, lastToMs)) {
      return;
    }

    props.stop(props.seconds)();
  };

  return {
    runAutoStop: runAutoStop,
  };
};

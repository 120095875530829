import moment from "moment";
import { capitalizeFirstLetter } from "@util/capitalizeFirstLetter";
import "moment/locale/it";

const DATE_FORMAT_DATE_AND_TIME_HUMAN = "dddd, MMMM Do YYYY, h:mm:ss a";
const DATE_FORMAT_DATE_AND_TIME = "YYYY-MM-DD HH:mm:ss";
const DATE_FORMAT_ONLY_TIME = "HH:mm:ss";

export const formatDateTimeCompleteHuman = (value: Date): string => {
  return moment(value).format(DATE_FORMAT_DATE_AND_TIME_HUMAN);
};

export const formatDateTime = (value: Date): string => {
  return moment(value).format(DATE_FORMAT_DATE_AND_TIME);
};

export const formatOnlyTime = (value: Date): string => {
  return moment(value).format(DATE_FORMAT_ONLY_TIME);
};

export const formatItalianDateTime = (value: Date): string => {
  const dayOfWeek = capitalizeFirstLetter(moment(value).format("dddd"));
  const day = moment(value).format("D");
  const month = capitalizeFirstLetter(moment(value).format("MMMM"));
  const time = moment(value).format("HH:mm");

  return `${ dayOfWeek } ${ day } ${ month }, ${ time }`;
};

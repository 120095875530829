import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100% - 35px)",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    mainContainerWithForm: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    headerContainer: {
      minHeight: "140px",
      position: "relative",
      zIndex: 1,
    },
    contentContainer: {
      textAlign: "center",
      zIndex: 2,
      backgroundColor: theme.colors.pureBlack,
    },
    logoFixed: {
      position: "fixed",
      top: "calc(40px + var(--ion-safe-area-top))",
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    iconButton: {
      marginLeft: "4px",
      marginRight: "4px",
    },
  }));

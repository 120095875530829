import gsap from "gsap";
import { GSAP_CUSTOM_EASE_INOUTQUINT } from "@feature/gsap/gsapConstants";
import {
  useEffect,
  useMemo,
  useState,
} from "react";

export const useLogic = (props: any) => {
  const [
    $bottomPath,
    setBottomPath,
  ] = useState<any>();

  const [
    $topPath,
    setTopPath,
  ] = useState<any>();

  const [
    $head,
    setHead,
  ] = useState<any>();

  const bottomPath = "189.33402 127.256949 192.614164 112.563879 205.998396 105.973894 219.432584 112.563879 222.826239 127.256949";

  /*const topPath = "189.33402 106.256949 192.614164 91.5638785 205.998396 84.973894 219.432584 91.5638785 222.826239 106.256949";*/

  const timeline = useMemo(() => {
    if (!$head || !$topPath || !$bottomPath) {
      return undefined;
    }

    const points = [
      { value: 106 },
      { value: 84 },
    ];

    return (
      gsap.timeline({
        repeat: -1,
        yoyo: true,
        paused: true,
        onUpdate: () => {
          if ($bottomPath) {
            $bottomPath.setAttribute("points",
              `230 127.283055 233.280144 112.589985 246.664377 ${ points[0].value } 260.098565 112.589985 263.492219 127.283055`);
          }
        },
      })
        .to($head, {
          y: 10,
          scale: 1.4,
          transformOrigin: "50% 50%",
          duration: 0.5,
          ease: GSAP_CUSTOM_EASE_INOUTQUINT,
        }, 0)
        .to($topPath, {
          y: 30,
          duration: 0.4,
          ease: GSAP_CUSTOM_EASE_INOUTQUINT,
        }, 0)
        .to(points[0], {
          value: 118,
          duration: 0.4,
          ease: GSAP_CUSTOM_EASE_INOUTQUINT,
        }, 0));
  }, [
    $head,
    $topPath,
    $bottomPath,
  ]);

  useEffect(() => {
    if ((timeline) !== undefined) {
      gsap.to($head, {
        rotation: 360,
        repeat: -1,
        duration: 2.5,
        ease: "none",
      });
      timeline.duration(props.duration);
      timeline.play();

      return () => {
        timeline.kill();
      };
    }

    return () => {
      return;
    };
  }, [
    timeline,
    props.duration,
    $head,
  ]);

  return {
    setBottomPath: setBottomPath,
    setTopPath: setTopPath,
    setHead: setHead,
  };
};

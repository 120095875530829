import { ButtonClick } from "@types-custom";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { ListActionBarMaskUi } from "@ui/ListActionBarMaskUi/ListActionBarMaskUi";
import { SearchBarUi } from "@ui/SearchBarUi/SearchBarUi";
import { t } from "@lingui/macro";
import { useLogic } from "@ui/ListActionBarUi/useLogic";
import { useStyles } from "./useStyles";

interface Props {
  hasFilters?: boolean;
  addButtonLabel?: string;
  onAddButtonClick?: ButtonClick;
  onFilterButtonClick?: ButtonClick;
  onSearchChange?: (value: string) => void;
}

export const ListActionBarUi = ({
  hasFilters = false,
  addButtonLabel = t`Add`,
  onAddButtonClick,
  onSearchChange,
  onFilterButtonClick,
}: Props) => {
  const {
    classes, cx,
  } = useStyles();

  const {
    isSearchBarVisible,
    handleSearchButtonClick,
    handleOnSearchChange,
    searchValue,
  } = useLogic({ onSearchChange: onSearchChange });

  return <>
    <div className={classes.listActionBarWrapper}>
      <ListActionBarMaskUi className={classes.firstMask} />
      <div className={classes.listActionBarRoot}>
        <div className={classes.childrenWrapper}>
          {
            !isSearchBarVisible &&
            onAddButtonClick &&
            <ButtonUi
              label={addButtonLabel}
              width="fit-content"
              type="primary"
              labelColor="grey"
              backgroundColor="pureBlack"
              icon="add-round"
              iconColor="grey"
              iconPosition="left"
              fontStyle="RR16"
              appearance="rounded"
              hasToggle={false}
              hasPressed={true}
              onClick={onAddButtonClick}
              isActive={false}
            />
          }
          {
            onSearchChange &&
            isSearchBarVisible &&
            <SearchBarUi
              onSearchChange={handleOnSearchChange}
              value={searchValue}
            />
          }
        </div>
        <div className={cx({
          [classes.childrenWrapper]: true,
          [classes.childrenRight]: true,
        })}>
          {
            onSearchChange &&
            <ButtonUi
              label={t`Search`}
              width=""
              type="primary"
              labelColor="neon"
              backgroundColor="pureBlack"
              icon="search"
              iconColor="grey"
              iconPosition="top"
              fontStyle="RM10"
              appearance="rounded"
              hasToggle={true}
              hasPressed={true}
              onClick={handleSearchButtonClick}
              isActive={false}
            />
          }
          {
            hasFilters &&
            <ButtonUi
              label={t`Filter`}
              width=""
              type="primary"
              labelColor="neon"
              backgroundColor="pureBlack"
              icon="filter-alt"
              iconColor="grey"
              iconPosition="top"
              fontStyle="RM10"
              appearance="rounded"
              hasToggle={true}
              hasPressed={true}
              onClick={onFilterButtonClick}
              isActive={false}
            />
          }
        </div>
      </div>
      <ListActionBarMaskUi className={classes.lastMask} />
    </div>
  </>;
};

import { Algorithm } from "@common/type-graphql/generated";
import { useAlgorithmListQuery } from "@feature/algorithm/api/algorithmApi";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useRelation } from "@feature/relation/hook/useRelation";

export const useAlgorithm = () => {
  const algorithmListApi = useAlgorithmListQuery();

  const {
    getReleationByProtocolAndExercise,
    endpoints: relationEndpoints,
  } = useRelation();

  const {
    isApiDataReady,
    endpoints,
  } = useApiDataLogic([
    ...relationEndpoints,
    algorithmListApi,
  ]);

  const getAlgorithmByProtocolAndExercise = (protocol: string, exercise: string): Algorithm | null => {
    if (!isApiDataReady) {
      return null;
    }

    const relation = getReleationByProtocolAndExercise(protocol, exercise);
    if (!relation) {
      return null;
    }

    return algorithmListApi.data.find(algorithm => algorithm.uuid === relation.algorithmUuid);
  };

  return {
    endpoints: endpoints,
    getAlgorithmByProtocolAndExercise: getAlgorithmByProtocolAndExercise,
  };
};

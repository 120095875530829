import parse from "html-react-parser";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { WelcomeCarouselDotButtonUi } from "@ui/WelcomeCarouselDotButtonUi/WelcomeCarouselDotButtonUi";
import { doubleDigits } from "@util/doubleDigits";
import { getUniqueId } from "@util/getUniqueId";
import { useLogic } from "./useLogic";
import { useStyles } from "@ui/WelcomeCarouselUi/useStyles";

export type Slide = {
  id: number;
  image: string;
  title: string;
  text: string;
}

interface Props {
  slides: Slide[];
  nextButtonLabel: string;
  onStartApp?: () => void;
}

export const WelcomeCarouselUi = (props: Props) => {
  const {
    width,
    ref,
    embla,
    selectedIndex,
    scrollProgress,
    scrollTo,
    scrollNext,
  } = useLogic({
    draggable: false,
    swipeable: false,
  });

  const {
    classes, cx, theme,
  } = useStyles();

  return <>
    <div
      className={classes.welcomeCarouselRoot}
    >
      <div
        className="embla"
        ref={ref}
      >
        <div className="embla__container">
          {
            props.slides.map(slide => (
              <div
                className="embla__slide"
                key={getUniqueId()}>
                <div className="embla__slide__inner innerImage">
                  <img
                    className="embla__slide__img"
                    src={slide.image}
                    alt={"slider"} />
                </div>
              </div>
            ))
          }
        </div>

        {/*Fixed elements here*/}
        {/* Indexes Component */}
        <div className={classes.indexes}>
          <div
            className={classes.indexesInner}
            style={{ transform: `translateX(${ (-(props.slides.length - 1) * scrollProgress) * width }px)` }}
          >
            {
              props.slides.map((slide, index) => (
                <div
                  className={classes.index}
                  key={getUniqueId()}>
                  {doubleDigits(index + 1)}
                </div>
              ))
            }
          </div>
        </div>

        {/* Text Component */}
        <div className={classes.slideTextContainer}>
          <div
            className={classes.slideTextInnerContainer}
            style={{ transform: `translateX(${ (-(props.slides.length - 1) * scrollProgress) * width }px)` }}
          >
            {
              props.slides.map(slide => (
                <div
                  className={classes.slideText}
                  key={getUniqueId()}>
                  <h2>
                    {parse(slide.title)}
                  </h2>
                  <p>
                    {parse(slide.text)}
                  </p>
                </div>
              ))
            }
          </div>
        </div>

        {/* Triangle used to mask / hide a portion of images */}
        <svg
          className={classes.imageHider}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 414 268"
          preserveAspectRatio="none"
        >
          <polygon
            points="0 272 414 540 0 540"
            transform="translate(0 -272)"
          />
        </svg>

        {/* Triangle used to mask indexes numbers */}
        <svg
          className={classes.imageHider}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 414 268"
          preserveAspectRatio="none"
        >
          <clipPath id="indexClipPath" clipPathUnits="objectBoundingBox">
            <polygon
              points={"0 272 270 540 0 540"}
              transform="translate(0 -272)"
            />
          </clipPath>
        </svg>
      </div>

      <div
        className={cx({
          "embla__dots": true,
          [classes.carouselDots]: true,
        })}
      >
        <div className={classes.dots}>
          {
            props.slides.map((slide, index) => (
              <WelcomeCarouselDotButtonUi
                key={getUniqueId()}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))
          }
        </div>

        <ButtonUi
          label={props.nextButtonLabel}
          width="fit-content"
          type="primary"
          labelColor="black"
          backgroundColor="black"
          icon="expand-right"
          iconColor="neon"
          iconPosition="left"
          fontStyle="OIB18"
          appearance="eptagon"
          hasToggle={false}
          hasPressed={false}
          onClick={() => {
            if (selectedIndex === props.slides.length - 1) {
              props.onStartApp();
            }
            scrollNext();
          }}
          isActive={false}
        />
      </div>
    </div>
  </>;
};

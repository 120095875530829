import { RangeMinMax } from "@common/model/Range";
import { confirm } from "@feature/confirm/service/confirm";
import { t } from "@lingui/macro";
import { useIonActionSheet } from "@ionic/react";
import { useRangeSetMutation } from "@feature/range/api/rangeApi";

type Props = {
  athleteUuid: string;
  exerciseUuid: string;
  rangeMinMax: RangeMinMax | null;
}

export const useRangeReset = () => {
  const [ present ] = useIonActionSheet();
  const [ create ] = useRangeSetMutation();

  const resetRange = (props: Props) => {
    create({
      data: {
        data: JSON.stringify(props.rangeMinMax),
        Athlete: { connect: { uuid: props.athleteUuid } },
        Exercise: { connect: { uuid: props.exerciseUuid } },
      },
    });
  };

  const resetRangeConfirm = (props: Props) => {
    return () => {
      if (
        !props.athleteUuid ||
        !props.exerciseUuid
      ) {
        return;
      }

      confirm({
        header: t`Do you want to reset the range?`,
        subHeader: t`This action will reset the range to the default value.`,
        present: present,
        action: () => resetRange(props),
      });
    };
  };

  return {
    resetRange: resetRange,
    resetRangeConfirm: resetRangeConfirm,
  };
};

import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import { STORE_OFFLINE_KEY } from "@feature/offline/offlineConstants";
import { convertDatesToTimestamps } from "@util/convertDatesToTimestamps";
import { hashObject } from "@util/hash";

type InitialStateModelInterface = {
  payloads: any[];
}

const initialState: InitialStateModelInterface = { payloads: [] };

export const offlineSlice = createSlice({
  name: STORE_OFFLINE_KEY,
  initialState: initialState,
  reducers: {
    addOfflineRequest: (state, action: PayloadAction) => {
      state.payloads.push(convertDatesToTimestamps(action.payload));
    },
    removeOfflineRequest: (state, action: PayloadAction) => {
      state.payloads = state.payloads.filter(req => hashObject(req) !== hashObject(action.payload));
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});

export const {
  addOfflineRequest,
  removeOfflineRequest,
} = offlineSlice.actions;

export const selectOfflineState = (state: RootState) => state[STORE_OFFLINE_KEY];

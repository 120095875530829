import { Network } from "@capacitor/network";
import { onlineMiddlewares } from "@core/api";
import {
  removeOfflineRequest,
  selectOfflineState,
} from "@feature/offline/slice/offlineSlice";
import {
  selectOnlineState,
  setIsOnline,
} from "@feature/offline/slice/onlineSlice";
import {
  store,
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useEffect } from "react";

export const OfflineProvider = () => {
  const dispatch = useAppDispatch();

  const offlineState = useAppSelector(selectOfflineState);
  const onLineState = useAppSelector(selectOnlineState);

  useEffect(() => {
    Network.getStatus().then(networkStatus => {
      const isNetworkStatusConnected = networkStatus.connected;
      if (onLineState.isOnline !== isNetworkStatusConnected) {
        dispatch(setIsOnline(isNetworkStatusConnected));
      }
    });

    Network.addListener(
      "networkStatusChange",
      status => {
        dispatch(setIsOnline(status.connected));

        if (status.connected) {
          const payloads: any = offlineState.payloads;
          payloads.forEach((action: any) => {
            for (const onlineMiddleware of onlineMiddlewares) {
              onlineMiddleware(store, action);
            }
            dispatch(removeOfflineRequest(action));
          });
        }
      }
    );

    return () => {
      Network.removeAllListeners();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onLineState ]);

  return null;
};

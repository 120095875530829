import { ButtonInputNumberUi } from "@ui/ButtonInputNumberUi/ButtonInputNumberUi";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { TrainingConfigurationCreateManyTrainingInput } from "@common/type-graphql/generated";
import { UseFormReturn } from "react-hook-form";
import { t } from "@lingui/macro";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { useStyles } from "./useStyles";

type Props = {
  configurationUuid: string;
  form: UseFormReturn;
}

export const MaximalFormElement = (props: Props) => {
  const {
    classes,
  } = useStyles();

  const configurationListApi = useConfigurationListQuery();
  if (!configurationListApi.isSuccess) {
    return <></>;
  }
  const configuration = configurationListApi.data.find(configuration => configuration.uuid === props.configurationUuid);

  const getConfigurationValue = () => {
    return props.form.getValues("TrainingConfiguration")?.find(
      trainingConfiguration =>
        trainingConfiguration.configurationUuid === props.configurationUuid
    )?.value || "0";
  };

  const update = (amount: number, absolute = false): void => {
    let val = Number(getConfigurationValue());
    val = val + amount;
    if (val < 0) {
      val = 0;
    }
    if (val > 999) {
      val = 999;
    }

    if (absolute) {
      val = amount;
    }

    updateForm(val);
  };

  const updateForm = (val: number) => {
    const trainingConfigurations = props.form.getValues("TrainingConfiguration") ?? [];
    let created = false;

    trainingConfigurations.forEach(trainingConfiguration => {
      if (trainingConfiguration.configurationUuid === props.configurationUuid) {
        trainingConfiguration.value = val.toString();
        created = true;
      }
    });
    if (!created) {
      const newConfiguration : TrainingConfigurationCreateManyTrainingInput = {
        value: val.toString(),
        configurationUuid: props.configurationUuid,
      };
      trainingConfigurations.push(newConfiguration);
      props.form.setValue("TrainingConfiguration", trainingConfigurations);
    }
    props.form.setValue(`configurations.${ configuration.code }`, val.toString());
  };

  return <>
    <ListItemUi
      title={`${ t`Maximal` } (${ configuration.unit })` }
      className={classes.root}>
      <div className={classes.controls}>
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"- 10"}
          onClick={() => update(-10)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconPosition="left"
          hasPressed
          label={"- 1"}
          onClick={() => update(-1)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <input
          type="hidden"
          id={configuration.code}
          {...props.form.register(`configurations.${ configuration.code }`, {
            required: true,
            min: 1,
            max: 999,
            value: getConfigurationValue(),
          })} />
        <ButtonInputNumberUi
          type={"primary"}
          appearance={"rounded"}
          min={1}
          max={999}
          step={0.5}
          backgroundColor={"black"}
          labelColor={"pureWhite"}
          initialValue={getConfigurationValue()}
          fontStyle={"RM16"}
          onChange={(value: number) => {
            update(value, true);
          }}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"+ 1"}
          onClick={() => update(1)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"+ 10"}
          onClick={() => update(10)}
          type="primary"
          width="50px"
          isActive={false}
        />
      </div>
    </ListItemUi>
  </>;
};

import { FilledIcons } from "@types-custom";

export const isFilled = (value: string): value is FilledIcons => [
  "close-round-fill",
  "filter-alt-fill",
  "home-fill",
  "img-box-fill",
  "info-fill",
  "on-button-fill",
  "search-fill",
  "settings-fill",
  "upload-fill",
  "user-fill",
  "view-fill",
  "view-hide-fill",
].includes(value as FilledIcons);

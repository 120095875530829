/* eslint-disable no-magic-numbers,max-len,@typescript-eslint/indent */
import {
  DIRECTION_ASCENDING,
  DIRECTION_DESCENDING,
  DIRECTION_END,
  DIRECTION_STABLE,
  Direction,
} from "@common/model/Direction";
import { KalmanFilter } from "@common/service/kalmanFilter";
import { LoadWeightValue } from "@common/model/LoadWeight";
import {
  MS_AHEAD_TO_CALCULATE_DENSITY,
  MS_AROUND_TO_CALCULATE_MAX,
  MS_AROUND_TO_CALCULATE_MEDIAN,
  MS_AROUND_TO_CALCULATE_SPEED,
  MS_BEHIND_TO_CALCULATE_DENSITY,
  PHASE_INCOMPLETE_ASCENDING_FALLING_DISPLACEMENT,
  PHASE_INCOMPLETE_DESCENDING_RISING_DISPLACEMENT,
  RANGE_CALCULATE_TOLLERANCE_BETWEEN_EACH_REPETITION_MM,
  RANGE_MARGIN_HIGH_DISPLACEMENT_MARGIN,
  RANGE_MARGIN_LOWER_THRESHOLD,
  RANGE_MARGIN_LOW_DISPLACEMENT_MARGIN,
  RANGE_MARGIN_UPPER_THRESHOLD,
} from "@common/service/constants";
import {
  PHASE_IGNORE_REASON_OVER_TOP,
  PHASE_IGNORE_REASON_SHORT,
  PHASE_IGNORE_REASON_TOO_SHORT,
  PHASE_IGNORE_REASON_UNDER_BOTTOM,
  Phase,
  PhaseIgnoreReason,
  PhasePhysics,
  PhasePositions,
} from "@common/model/Phase";
import { Point } from "@common/model/Point";
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import { RangeMinMax } from "@common/model/Range";
import { Serie } from "@common/model/Serie";
import {
  calculateDensityPoint,
  calculateDirectionBySpeedPoints,
  calculateMedianPoint,
  calculateSimplified,
  calculateSpeedPoint,
  findIndexBackwardHigherTillMm,
  findIndexBackwardLowerTillMm,
  findIndexForwardHigherTillMm,
  findIndexForwardLowerTillMm,
  fixSpikesPoints,
  getFromAndToIndexByIndexes,
  getPower,
  getRangeMargins,
  getRefiningPoints,
  interpolateX,
  interpolateY,
  invalidateOutOfDensityDirections,
  thereAreEnoughPoints,
} from "@common/service/pointService";

import { roundToDecimals } from "@util/roundToDecimals";

type CalculatePointsProps = {
  serie: Serie;
  maxDistanceTooFarFromMedianSpikes: number;
  maxReadsPerSecond: number;
  speedThresholdUp: number;
  speedThresholdDown?: number;
  range?: RangeMinMax | null;
}

export const calculatePoints = (props: CalculatePointsProps): boolean => {
  // Check if is already calculating
  if (props.serie.live.isCalculating) {
    return false;
  }
  props.serie.live.isCalculating = true;

  // Check if there are enough points
  if (!thereAreEnoughPoints(props.serie.originalPoints, props.serie.live.originalPointsLastCalculatedIndex)) {
    props.serie.live.isCalculating = false;
    return false;
  }

  // Prepare new refining points
  props.serie.refiningPoints = getRefiningPoints(props.serie.originalPoints, props.serie.refined, props.serie.live.originalPointsLastCalculatedIndex);

  // Prepare indexes to work on
  let {
    // eslint-disable-next-line prefer-const
    fromIndex,
    toIndex,
  } = getFromAndToIndexByIndexes(props.serie.originalPoints, props.serie.live.originalPointsLastCalculatedIndex, MS_AROUND_TO_CALCULATE_MAX);
  if (fromIndex === toIndex) {
    props.serie.live.isCalculating = false;
    return false;
  }
  for (let currentIndex = fromIndex; currentIndex <= toIndex; currentIndex++) {
    // Calculate median
    const medianPoint = calculateMedianPoint(props.serie.refiningPoints, currentIndex, MS_AROUND_TO_CALCULATE_MEDIAN);
    props.serie.median.push(medianPoint);
  }

  // Fix big spikes
  fixSpikesPoints(
    props.serie.refiningPoints,
    props.serie.median,
    fromIndex,
    toIndex,
    props.maxDistanceTooFarFromMedianSpikes
  );

  // Calculate kalmanPoints and refinedPoints
  for (let currentIndex = fromIndex; currentIndex <= toIndex; currentIndex++) {
    const kalmanPoint = calculateKalmanPoint(props.serie, currentIndex);
    props.serie.refined.push(kalmanPoint);
  }

  // If range exists generate virtual point that match the range margins
  // This step is done here because the range is used to calculate it
  if (props.range) {
    const generatedQuantity = generateVirtualPoints(props.serie, props.range);
    toIndex = toIndex + generatedQuantity;
  }

  // Calculate density
  for (let currentIndex = fromIndex; currentIndex <= toIndex; currentIndex++) {
    const densityPoint = calculateDensityPoint(props.serie.originalPoints, currentIndex, props.maxReadsPerSecond, MS_BEHIND_TO_CALCULATE_DENSITY, MS_AHEAD_TO_CALCULATE_DENSITY);
    props.serie.density.push(densityPoint);
  }

  // Update lastCalculatedIndex
  props.serie.live.originalPointsLastCalculatedIndex = toIndex;

  // Calculate speed
  const {
    fromIndex: fromIndexSpeed,
    toIndex: toIndexSpeed,
  } = getFromAndToIndexByIndexes(props.serie.originalPoints, props.serie.live.originalPointsLastCalculatedSpeedIndex, MS_AROUND_TO_CALCULATE_MAX + MS_AROUND_TO_CALCULATE_SPEED);
  if (fromIndexSpeed < 0 || toIndexSpeed < 0) {
    props.serie.refiningPoints = [];
    props.serie.live.isCalculating = false;
    return false;
  }
  for (let currentIndex = fromIndexSpeed; currentIndex <= toIndexSpeed; currentIndex++) {
    const speedPoint = calculateSpeedPoint(props.serie.originalPoints, props.serie.refined, currentIndex, MS_AROUND_TO_CALCULATE_SPEED);
    props.serie.speed.push(speedPoint);
  }

  // Calculate kalmanSpeedPoints and refinedSpeedPoints
  for (let currentIndex = fromIndexSpeed; currentIndex <= toIndexSpeed; currentIndex++) {
    const kalmanSpeedPoint = calculateKalmanSpeedPoint(props.serie, currentIndex);
    props.serie.refinedSpeed.push(kalmanSpeedPoint);
  }

  // Calculate directions
  for (let currentIndex = fromIndexSpeed; currentIndex <= toIndexSpeed; currentIndex++) {
    const direction = calculateDirectionBySpeedPoints(
      props.serie.speed,
      currentIndex,
      props.speedThresholdUp,
      props.speedThresholdDown
    );
    props.serie.directions.push(direction);
  }

  // Invalidate out of density directions
  invalidateOutOfDensityDirections(
    props.serie.directions,
    props.serie.density,
    fromIndexSpeed,
    props.maxReadsPerSecond
  );

  // Simplified
  props.serie.simplifiedPoints = calculateSimplified(
    props.serie.originalPoints,
    props.serie.simplifiedPoints,
    props.serie.directions,
    props.serie.refined,
    fromIndexSpeed,
    toIndexSpeed,
    props.range
  );

  // Update lastCalculatedSpeedIndex
  props.serie.live.originalPointsLastCalculatedSpeedIndex = toIndexSpeed;

  props.serie.refiningPoints = [];
  props.serie.live.isCalculating = false;

  return true;
};

/**
 * This method calculates the phases of a serie.
 * It have to be idempotent. @todo maybe not because of calculation performances
 */
export const calculatePhases = (props: {
    serie: Serie;
    isLive: boolean;
    rangeMinMax: RangeMinMax | null;
    mergeStallTimeUnder: number | null;
    mergeAfterPercent: number | null;
    loadWeight: number | null;
  }): boolean => {
  // Check if is already calculating
  if (props.serie.live.isCalculating) {
    return false;
  }

  // Set the is calculating flag to true
  props.serie.live.isCalculating = true;

  // Calculate the original phases
  calculateOriginalPhases(props.serie, props.isLive, props.rangeMinMax);

  // Calculate the merged phases
  calculateMergedPhases(props.serie, props.isLive, props.rangeMinMax, props.mergeStallTimeUnder, props.mergeAfterPercent);

  // Calculate the final phases
  calculateFinalPhases(props.serie, props.isLive, props.rangeMinMax, props.loadWeight);

  // Set the is calculating flag to false
  props.serie.live.isCalculating = false;
  return true;
};

const generateOriginalEquiparablePhases = (
  serie: Serie,
  currentOriginalPhase: Phase,
  rangeMinMax: RangeMinMax,
  currentIndex: number
) => {
  const {
    previousPhase,
    centralPhase,
    nextPhase,
  } = calculateEquiparablePhases(serie, currentOriginalPhase, rangeMinMax);

  // First of all replace the current phase with the central one
  if (centralPhase) {
    serie.originalPhases.splice(currentIndex, 1, centralPhase);
  }

  if (
    previousPhase === null &&
        nextPhase === null
  ) {
    return;
  }

  // Then push the next phase if it exists
  if (nextPhase) {
    serie.originalPhases.splice(currentIndex + 1, 0, nextPhase);
  }

  // And finally push the previous phase if it exists
  if (previousPhase) {
    serie.originalPhases.splice(currentIndex, 0, previousPhase);
  }
};

export const calculateOriginalPhases = (
  serie: Serie,
  isLive: boolean,
  rangeMinMax?: RangeMinMax | null
) => {
  const originalPoints = serie.originalPoints;
  const refined = serie.refined;
  const directions: Direction[] = serie.directions;

  // If it is not live, and then it is the last calculation, then force set the last direction to stable
  if (!isLive) {
    serie.directions[serie.directions.length - 1] = DIRECTION_STABLE;
  }

  const currentOriginalPhase = serie.originalPhases[serie.originalPhases.length - 1];

  // Remove the last phase if it is live, beacuse it is temporary
  if (
    isLive &&
        currentOriginalPhase &&
        currentOriginalPhase.direction === DIRECTION_END
  ) {
    serie.originalPhases.pop();
  }

  // Calculate originalPhases
  const startingIndex = serie.live.originalPhasesLastCalculatedIndex + 1;
  for (
    let currentIndex = startingIndex;
    currentIndex < serie.directions.length;
    currentIndex++
  ) {
    // Ignore first step because we need at least two points to calculate
    if (currentIndex === 0) {
      continue;
    }

    const currentDirection: Direction = directions[currentIndex];
    const previousDirection: Direction = directions[currentIndex - 1];

    // If the direction is the same, check for range margins and continue
    if (currentDirection === previousDirection) {
      const previousRefined = serie.refined[currentIndex - 1];
      const currentRefined = serie.refined[currentIndex];
      if (
        rangeMinMax && (
          (
            currentDirection === DIRECTION_ASCENDING &&
            (
              (
                previousRefined < rangeMinMax.min &&
                    currentRefined >= rangeMinMax.min
              ) ||
                (
                  previousRefined < rangeMinMax.max &&
                    currentRefined >= rangeMinMax.max
                )
            )
          ) || (
            currentDirection === DIRECTION_DESCENDING &&
            (
              (
                previousRefined > rangeMinMax.max &&
                    currentRefined <= rangeMinMax.max
              ) || (
                previousRefined > rangeMinMax.min &&
                    currentRefined <= rangeMinMax.min
              )
            )
          )
        )
      ) {
        const lastOriginalPhase = serie.originalPhases[serie.originalPhases.length - 1];
        const newOriginalPhase: Phase = {
          direction: currentDirection,
          fromMs: lastOriginalPhase.toMs,
          toMs: serie.originalPoints[currentIndex].x,
          fromIndex: lastOriginalPhase.toIndex,
          toIndex: currentIndex,
          fromMm: lastOriginalPhase.toMm,
          toMm: refined[currentIndex],
        };
        serie.originalPhases.push(newOriginalPhase);
        serie.live.originalPhasesLastCalculatedIndex = currentIndex;
      }

      continue;
    }

    // Handle first phase, always stable
    if (
      serie.originalPhases.length === 0 &&
            currentDirection !== DIRECTION_STABLE
    ) {
      const newOriginalPhase: Phase = {
        direction: DIRECTION_STABLE,
        fromMs: 0,
        toMs: originalPoints[currentIndex].x,
        fromIndex: 0,
        toIndex: currentIndex,
        fromMm: refined[0],
        toMm: refined[currentIndex],
      };
      serie.originalPhases.push(newOriginalPhase);
      serie.live.originalPhasesLastCalculatedIndex = currentIndex;
      continue;
    }

    const fromMm = refined[serie.live.originalPhasesLastCalculatedIndex];
    const toMm = refined[currentIndex];

    const newOriginalPhase: Phase = {
      direction: previousDirection,
      fromMs: originalPoints[serie.live.originalPhasesLastCalculatedIndex].x,
      toMs: originalPoints[currentIndex].x,
      fromIndex: serie.live.originalPhasesLastCalculatedIndex,
      toIndex: currentIndex,
      fromMm: fromMm,
      toMm: toMm,
    };
    serie.originalPhases.push(newOriginalPhase);
    serie.live.originalPhasesLastCalculatedIndex = currentIndex;
  }

  // Fill with the temporary DIRECTION_END phase
  if (serie.originalPhases.length === 0) {
    return;
  }

  const lastOriginalPointsIndex = serie.originalPoints.length - 1;
  const lastOriginalPhaseIndex = serie.originalPhases.length - 1;
  const lastOriginalPhase = serie.originalPhases[lastOriginalPhaseIndex];
  const endOriginalPhase: Phase = {
    direction: DIRECTION_END,
    fromMs: lastOriginalPhase.toMs,
    toMs: originalPoints[lastOriginalPointsIndex].x,
    fromIndex: lastOriginalPhase.toIndex,
    toIndex: lastOriginalPointsIndex,
    fromMm: lastOriginalPhase.toMm,
    toMm: originalPoints[lastOriginalPointsIndex].y,
  };

  serie.originalPhases.push(endOriginalPhase);

  calculatePhasesPhysicsAndPositions(serie.originalPhases, rangeMinMax);
};

export const calculateMergedPhases = (
  serie: Serie,
  isLive: boolean,
  rangeMinMax: RangeMinMax | null,
  stallDurationThreshold: number | undefined | null,
  mergeAfterPercent?: number | undefined | null
) => {
  let originalPhasesLength = serie.originalPhases.length;
  if (!stallDurationThreshold || originalPhasesLength < 2) {
    return;
  }

  // Push the first originalPhase always as DIRECTION_STABLE
  if (serie.mergedPhases.length === 0) {
    serie.mergedPhases.push({
      ...JSON.parse(JSON.stringify(serie.originalPhases[0])),
      direction: DIRECTION_STABLE,
    });
    serie.mergedPhases.push(JSON.parse(JSON.stringify(serie.originalPhases[1])));
    serie.live.mergedPhasesLastCalculatedIndex = 1;
  }

  // Remove the last phase if it is live, beacuse it is temporary
  const endMergedPhase = serie.mergedPhases[serie.mergedPhases.length - 1];
  if (
    isLive &&
    endMergedPhase &&
    endMergedPhase.direction === DIRECTION_END
  ) {
    serie.mergedPhases.pop();
  }

  // Cycle through the original phases
  const startingIndex = serie.live.mergedPhasesLastCalculatedIndex + 1;
  for (
    let currentIndex = startingIndex;
    currentIndex < originalPhasesLength - 1;
    currentIndex++
  ) {
    const mergedPhasesLength = serie.mergedPhases.length;
    const secondLastMergedPhase = serie.mergedPhases[mergedPhasesLength - 2];
    const lastMergedPhase = serie.mergedPhases[mergedPhasesLength - 1];
    const newOriginalPhase = serie.originalPhases[currentIndex];

    /**
     * This merges the phases that are
     * 1) ascending/descending + stable + ascending/descending(equal)
     */
    if (
    // 1) ascending/descending + stable + ascending/descending(equal)
      (
      // If the last merged phase direction is ascending or descending
        secondLastMergedPhase &&
        (
          secondLastMergedPhase.direction === DIRECTION_ASCENDING ||
          secondLastMergedPhase.direction === DIRECTION_DESCENDING
        ) &&

        // If the middle phase is stable
        lastMergedPhase.direction === DIRECTION_STABLE &&

        // and if the next phase is equal to the last merged phase
        newOriginalPhase.direction === secondLastMergedPhase.direction &&

        // and if the stall duration is less than the threshold
        lastMergedPhase.physics &&
        lastMergedPhase.physics.duration < stallDurationThreshold &&

        // and if all happens in the range
        secondLastMergedPhase.positions &&
        secondLastMergedPhase.positions.isAllInRange &&
        newOriginalPhase.positions &&
        newOriginalPhase.positions.isAllInRange
      ) && (
        !rangeMinMax ||
        !mergeAfterPercent ||
        (
          newOriginalPhase.fromMm >= ((((rangeMinMax.max - rangeMinMax.min) / 100) * mergeAfterPercent) + rangeMinMax.min)
        )
      )
    ) {
      // Replace the last phase with the merged one
      const newMergedPhase = mergeTwoPhases(secondLastMergedPhase, newOriginalPhase, rangeMinMax);
      const newMergedPhaseIndex = mergedPhasesLength - 2;
      serie.mergedPhases.splice(newMergedPhaseIndex, 2, newMergedPhase);
    } else {
      serie.mergedPhases.push(JSON.parse(JSON.stringify(newOriginalPhase)));
    }
    serie.live.mergedPhasesLastCalculatedIndex = currentIndex;
  }

  // Push the last originalPhase as DIRECTION_END starting from the lastMergedPhase
  originalPhasesLength = serie.originalPhases.length;
  const lastOriginalPhase = serie.originalPhases[originalPhasesLength - 1];
  const mergedPhasesLength = serie.mergedPhases.length;
  const lastMergedPhase = serie.mergedPhases[mergedPhasesLength - 1];
  serie.mergedPhases.push({
    ...JSON.parse(JSON.stringify(lastOriginalPhase)),
    fromMs: lastMergedPhase.toMs,
    fromIndex: lastMergedPhase.toIndex,
    fromMm: lastMergedPhase.toMm,
  });

  // Calculate all the mergedPhases physics
  calculatePhasesPhysicsAndPositions(serie.mergedPhases, rangeMinMax);
};

/**
 * This method just add the ignoreReson on the phases
 * @param serie
 * @param isLive
 * @param rangeMinMax
 * @param loadWeight
 */
const calculateFinalPhases = (
  serie: Serie,
  isLive: boolean,
  rangeMinMax: RangeMinMax | null,
  loadWeight: LoadWeightValue | null
) => {
  // If the load weight or the range are not defined or there are no enough phases, then return
  let mergedPhasesLength = serie.mergedPhases.length;
  if (
    !rangeMinMax ||
    !loadWeight ||
    serie.mergedPhases.length < 2
  ) {
    return;
  }

  // Push the first phase
  if (serie.live.finalPhasesLastCalculatedIndex === -1) {
    serie.live.finalPhasesLastCalculatedIndex = 0;
    serie.finalPhases.push(JSON.parse(JSON.stringify(serie.mergedPhases[0])));
  }

  // Remove the last phase if it is live, beacuse it is temporary
  const endFinalPhase = serie.finalPhases[serie.finalPhases.length - 1];
  if (
    isLive &&
    endFinalPhase &&
    endFinalPhase.direction === DIRECTION_END
  ) {
    serie.finalPhases.pop();
  }

  // Cycle through the merged phases
  const startingIndex = serie.live.finalPhasesLastCalculatedIndex + 1;
  for (
    let currentIndex = startingIndex;
    currentIndex < mergedPhasesLength - 1;
    currentIndex++
  ) {
    const currentMergedPhase = serie.mergedPhases[currentIndex];
    const nextMergedPhase = serie.mergedPhases[currentIndex + 1];
    const secondNextMergedPhase = serie.mergedPhases[currentIndex + 2];

    const newFinalPhase: Phase = JSON.parse(JSON.stringify(currentMergedPhase));
    let newFinalPhaseFounded = false;

    // Ascending under bottom
    if (
      currentMergedPhase.direction === DIRECTION_ASCENDING &&
      currentMergedPhase.positions &&
      currentMergedPhase.positions.endsInMinRange
    ) {
      newFinalPhase.ignoreReason = PHASE_IGNORE_REASON_UNDER_BOTTOM;
      newFinalPhaseFounded = true;
    } else

    // Descending over top
    if (
      currentMergedPhase.direction === DIRECTION_DESCENDING &&
      currentMergedPhase.positions &&
      currentMergedPhase.positions.endsInMaxRange
    ) {
      newFinalPhase.ignoreReason = PHASE_IGNORE_REASON_OVER_TOP;
      newFinalPhaseFounded = true;
    } else

    // Good ascending/descending phase wich fulls the range limit
    if (
      (
        currentMergedPhase.direction === DIRECTION_ASCENDING ||
        currentMergedPhase.direction === DIRECTION_DESCENDING
      ) &&
      currentMergedPhase.positions &&
      currentMergedPhase.positions.isAllInRange &&
      currentMergedPhase.positions.reachedBottomRange &&
      currentMergedPhase.positions.reachedTopRange
    ) {
      newFinalPhaseFounded = true;
    } else

    // Good ascending phase wich reach the top range limit
    if (
      currentMergedPhase.direction === DIRECTION_ASCENDING &&
      currentMergedPhase.positions &&
      currentMergedPhase.positions.isAllInRange &&
      currentMergedPhase.positions.reachedTopRange
    ) {
      newFinalPhaseFounded = true;
    } else

    // Good descending phase wich reach the bottom range limit
    if (
      currentMergedPhase.direction === DIRECTION_DESCENDING &&
      currentMergedPhase.positions &&
      currentMergedPhase.positions.isAllInRange &&
      currentMergedPhase.positions.reachedBottomRange
    ) {
      newFinalPhaseFounded = true;
    } else

    // During an ascending the next fall down before touch the range limit
    if (
      nextMergedPhase &&
      currentMergedPhase.direction === DIRECTION_ASCENDING &&
        currentMergedPhase.positions &&
      currentMergedPhase.positions.isAllInRange &&
      currentMergedPhase.toMm > nextMergedPhase.toMm &&
      (currentMergedPhase.toMm - nextMergedPhase.toMm) >= PHASE_INCOMPLETE_ASCENDING_FALLING_DISPLACEMENT
    ) {
      newFinalPhaseFounded = true;
    } else

    // During an ascending the second next fall down before touch the range limit
    if (
      secondNextMergedPhase &&
      currentMergedPhase.direction === DIRECTION_ASCENDING &&
        currentMergedPhase.positions &&
      currentMergedPhase.positions.isAllInRange &&
      currentMergedPhase.toMm > secondNextMergedPhase.toMm &&
      (currentMergedPhase.toMm - secondNextMergedPhase.toMm) >= PHASE_INCOMPLETE_ASCENDING_FALLING_DISPLACEMENT
    ) {
      newFinalPhaseFounded = true;
    } else

    // During an ascending the next reach the range limit and the secondNext exists
    if (
      nextMergedPhase &&
      nextMergedPhase.direction === DIRECTION_STABLE &&
      secondNextMergedPhase &&
      secondNextMergedPhase.direction !== DIRECTION_ASCENDING &&
      nextMergedPhase.positions &&
      nextMergedPhase.positions.reachedBottomRange
    ) {
      newFinalPhaseFounded = true;
    } else

    // During a descending the next rise up before touch the range limit
    if (
      nextMergedPhase &&
      currentMergedPhase.direction === DIRECTION_DESCENDING &&
        currentMergedPhase.positions &&
      currentMergedPhase.positions.isAllInRange &&
      nextMergedPhase.toMm > currentMergedPhase.toMm &&
      (nextMergedPhase.toMm - currentMergedPhase.toMm) >= PHASE_INCOMPLETE_DESCENDING_RISING_DISPLACEMENT
    ) {
      newFinalPhaseFounded = true;
    } else

    // During a descending the second next rise up before touch the range limit
    if (
      secondNextMergedPhase &&
      currentMergedPhase.direction === DIRECTION_DESCENDING &&
        currentMergedPhase.positions &&
      currentMergedPhase.positions.isAllInRange &&
      secondNextMergedPhase.toMm > currentMergedPhase.toMm &&
      (secondNextMergedPhase.toMm - currentMergedPhase.toMm) >= PHASE_INCOMPLETE_DESCENDING_RISING_DISPLACEMENT
    ) {
      newFinalPhaseFounded = true;
    } else

    // During a descending the next reach the range limit and the secondNext exists
    if (
      nextMergedPhase &&
      nextMergedPhase.direction === DIRECTION_STABLE &&
      secondNextMergedPhase &&
      currentMergedPhase.direction === DIRECTION_DESCENDING &&
      nextMergedPhase.positions &&
      nextMergedPhase.positions.reachedBottomRange
    ) {
      newFinalPhaseFounded = true;
    } else

    // Ascending over top
    if (
      currentMergedPhase.direction === DIRECTION_ASCENDING &&
      currentMergedPhase.positions &&
      currentMergedPhase.positions.startsFromMaxRange
    ) {
      newFinalPhase.ignoreReason = PHASE_IGNORE_REASON_OVER_TOP;
      newFinalPhaseFounded = true;
    } else

    // Descending under bottom
    if (
      currentMergedPhase.direction === DIRECTION_DESCENDING &&
      currentMergedPhase.positions &&
      currentMergedPhase.positions.startsFromMinRange
    ) {
      newFinalPhase.ignoreReason = PHASE_IGNORE_REASON_UNDER_BOTTOM;
      newFinalPhaseFounded = true;
    }

    // Recover the incompleted phases happened without reach the range limit
    if (
      newFinalPhaseFounded ||
      !isLive
    ) {
      // Search for previous good but incompleted phases
      const infraPhases = serie.mergedPhases.slice(serie.live.finalPhasesLastCalculatedIndex + 1, currentIndex);
      for (const infraPhase of infraPhases) {
        if (
          (
            infraPhase.direction === DIRECTION_ASCENDING ||
            infraPhase.direction === DIRECTION_DESCENDING
          ) &&
          infraPhase.positions &&
          infraPhase.positions.isAllInRange
        ) {
          serie.finalPhases.push(infraPhase);
          serie.live.finalPhasesLastCalculatedIndex = serie.mergedPhases.indexOf(infraPhase);
        }
      }
    }

    // If the newFinalPhase is founded, then push it
    if (
      newFinalPhaseFounded
    ) {
      serie.finalPhases.push(newFinalPhase);
      serie.live.finalPhasesLastCalculatedIndex = currentIndex;
    }
  }

  // Push the last mergedPhase as DIRECTION_END starting from the lastFinalPhase
  mergedPhasesLength = serie.mergedPhases.length;
  const lastMergedPhase = serie.mergedPhases[mergedPhasesLength - 1];
  const finalPhasesLength = serie.finalPhases.length;
  const lastFinalPhase = serie.finalPhases[finalPhasesLength - 1];
  serie.finalPhases.push({
    ...JSON.parse(JSON.stringify(lastMergedPhase)),
    fromMs: lastFinalPhase.toMs,
    fromIndex: lastFinalPhase.toIndex,
    fromMm: lastFinalPhase.toMm,
  });

  // Calculate all the finalPhases physics including power
  calculatePhasesPhysicsAndPositions(serie.finalPhases, rangeMinMax, loadWeight);

  // Ignore short concentric phases on calculated phase
  ignoreShortConcentricPhases(serie.finalPhases, rangeMinMax);
};

/**
 * This method generates virtual points to make the phases equiparable.
 * It generate new points in at the range margins
 * Return the quantity of added points
 */
export const generateVirtualPoints = (
  serie: Serie,
  rangeMinMax: RangeMinMax
): number => {
  let generatedQuantity = 0;
  let i = 1;

  if (serie.refined.length <= 1) {
    return generatedQuantity;
  }

  const rangeTopMarginValue = rangeMinMax.max;
  const rangeBottomMarginValue = rangeMinMax.min;
  while (i < serie.refined.length) {
    const previousOriginalPoint = serie.originalPoints[i - 1];
    const currentOriginalPoint = serie.originalPoints[i];
    const previousRefiningPoint = serie.refiningPoints[i - 1];
    const currentRefiningPoint = serie.refiningPoints[i];
    const previousMedian = serie.median[i - 1];
    const currentMedian = serie.median[i];
    const previousRefined = serie.refined[i - 1];
    const currentRefined = serie.refined[i];
    let newRefined = 0;
    if (
      previousRefined < rangeTopMarginValue &&
            currentRefined > rangeTopMarginValue
    ) {
      newRefined = rangeTopMarginValue;
    } else if (
      previousRefined > rangeTopMarginValue &&
            currentRefined < rangeTopMarginValue
    ) {
      newRefined = rangeTopMarginValue;
    } else if (
      previousRefined > rangeBottomMarginValue &&
            currentRefined < rangeBottomMarginValue
    ) {
      newRefined = rangeBottomMarginValue;
    } else if (
      previousRefined < rangeBottomMarginValue &&
            currentRefined > rangeBottomMarginValue
    ) {
      newRefined = rangeBottomMarginValue;
    }
    if (newRefined !== 0) {
      const newOriginalPointX = interpolateX(previousOriginalPoint.x, previousRefined, currentOriginalPoint.x, currentRefined, newRefined);
      const newOriginalPointY = interpolateY(previousOriginalPoint.x, previousOriginalPoint.y, currentOriginalPoint.x, currentOriginalPoint.y, newOriginalPointX);
      const newOriginalPoint = {
        x: newOriginalPointX,
        y: newOriginalPointY,
      };
      serie.originalPoints.splice(i, 0, newOriginalPoint);

      const newRefiningPointX = interpolateX(previousOriginalPoint.x, previousRefined, currentOriginalPoint.x, currentRefined, newRefined);
      const newRefiningPointY = interpolateY(previousOriginalPoint.x, previousRefiningPoint.y, currentOriginalPoint.x, currentRefiningPoint.y, newRefiningPointX);
      const newRefiningPoint = {
        x: newRefiningPointX,
        y: newRefiningPointY,
      };
      serie.refiningPoints.splice(i, 0, newRefiningPoint);

      const newMedianPointX = interpolateX(previousOriginalPoint.x, previousRefined, currentOriginalPoint.x, currentRefined, newRefined);
      const newMedian = interpolateY(previousOriginalPoint.x, previousMedian, currentOriginalPoint.x, currentMedian, newMedianPointX);
      serie.median.splice(i, 0, newMedian);

      serie.refined.splice(i, 0, newRefined);
      generatedQuantity++;
      i++;
    }
    i++;
  }

  return generatedQuantity;
};

const mergeTwoPhases = (
  phase1: Phase,
  phase2: Phase,
  rangeMinMax: RangeMinMax | null
): Phase => {
  const phase = {
    direction: phase1.direction,
    fromMs: phase1.fromMs,
    toMs: phase2.toMs,
    fromIndex: phase1.fromIndex,
    toIndex: phase2.toIndex,
    fromMm: phase1.fromMm,
    toMm: phase2.toMm,
  };
  calculatePhasesPhysicsAndPositions([ phase ], rangeMinMax);
  return phase;
};

const calculateKalmanPoint = (serie: Serie, currentIndex: number): number => {
  if (!serie.live.kalman) {
    const firstPoint = serie.originalPoints[0];
    serie.live.kalman = new KalmanFilter(1, 7, firstPoint.y);
  }
  return Math.round(serie.live.kalman.filter(serie.refiningPoints[currentIndex].y));
};

const calculateKalmanSpeedPoint = (serie: Serie, currentIndex: number): number => {
  if (!serie.live.kalmanSpeed) {
    const firstSpeed = serie.speed[0];
    serie.live.kalmanSpeed = new KalmanFilter(1, 50, firstSpeed);
  }
  return roundToDecimals(serie.live.kalmanSpeed.filter(serie.speed[currentIndex]));
};

const closestNumber = (numbers: number[], threshold: number): number | null => {
  // Calculate the average of the array
  const average = numbers.reduce((acc, curr) => acc + curr, 0) / numbers.length;

  // Filter the array to exclude values that exceed the threshold from the average
  const validNumbers = numbers.filter(num => Math.abs(num - average) <= threshold);

  if (validNumbers.length === 0) {
    return null; // All numbers were outliers
  }

  // Find the number that has the smallest average distance to the other valid numbers

  // Get the lowest number in the array validNumbers
  let bestNumber: number = validNumbers.reduce((acc, curr) => Math.min(acc, curr), Infinity);

  let smallestAverageDistance = Infinity;

  for (const num of validNumbers) {
    const averageDistance = validNumbers.reduce((acc, curr) => acc + Math.abs(curr - num), 0) / validNumbers.length;
    if (averageDistance < smallestAverageDistance) {
      smallestAverageDistance = averageDistance;
      bestNumber = num;
    }
  }

  return bestNumber;
};

const countCloseValues = (value: number, numbers: number[], threshold: number): number | null => {
  let count = 0;
  for (const num of numbers) {
    if (Math.abs(num - value) <= threshold) {
      count++;
    }
  }
  return count;
};

export const calculateRange = (phases: Phase[]): RangeMinMax | null => {
  const {
    mins,
    maxs,
  } = phases.reduce((value, p) => {
    if (p.direction === DIRECTION_ASCENDING && !p.ignoreReason) {
      value.mins.push(p.fromMm);
      value.maxs.push(p.toMm);
    }
    return value;
  }, {
    mins: [] as number[],
    maxs: [] as number[],
  });

  const min = closestNumber(mins, RANGE_CALCULATE_TOLLERANCE_BETWEEN_EACH_REPETITION_MM);
  const max = closestNumber(maxs, RANGE_CALCULATE_TOLLERANCE_BETWEEN_EACH_REPETITION_MM);

  if (
    !min ||
    !max ||
    min >= max
  ) {
    return null;
  }

  const minCount = countCloseValues(min, mins, RANGE_CALCULATE_TOLLERANCE_BETWEEN_EACH_REPETITION_MM);
  const maxCount = countCloseValues(max, maxs, RANGE_CALCULATE_TOLLERANCE_BETWEEN_EACH_REPETITION_MM);

  if (
    !minCount ||
    !maxCount ||
    minCount < 3 ||
    maxCount < 3
  ) {
    return null;
  }

  return {
    min: min,
    max: max,
  };
};

export const calculateMaxPower = (serie: Serie): PowerReferenceValue | null => {
  if (!serie.finalPhases.length) {
    return null;
  }

  const maxPower = serie.finalPhases.reduce((value, phase) => {
    if (phase.direction !== DIRECTION_ASCENDING || phase.ignoreReason) {
      return value;
    }
    if (!phase.physics || !phase.physics.power) {
      return value;
    }
    return Math.max(value, phase.physics.power);
  }, 0);

  if (maxPower === 0) {
    return null;
  }

  return maxPower;
};

type EquiparablePhasesOutput = {
  previousPhase: Phase | null;
  centralPhase: Phase;
  nextPhase: Phase | null;
}
export const calculateEquiparablePhases = (
  serie: Serie,
  incomingPhase: Phase,
  rangeMinMax: RangeMinMax
): EquiparablePhasesOutput => {
  const refined = serie.refined;

  let previousPhase: Phase | null = null;
  let nextPhase: Phase | null = null;

  const positions = calculatePhasePositions(incomingPhase, rangeMinMax);

  if (positions.isAllOverMaxRange || positions.isAllUnderMinRange) {
    return {
      previousPhase: previousPhase,
      centralPhase: incomingPhase,
      nextPhase: nextPhase,
    };
  }

  if (incomingPhase.direction === DIRECTION_ASCENDING) {
    if (incomingPhase.toMm > rangeMinMax.max) {
      const newTopIndex = findIndexBackwardLowerTillMm(refined, rangeMinMax.max, incomingPhase.toIndex, incomingPhase.fromIndex);
      // const newTopIndex = findIndexForwardHigherTillMm(refined, rangeMinMax.max, incomingPhase.fromIndex, incomingPhase.fromIndex);
      nextPhase = {
        direction: DIRECTION_ASCENDING,
        ignoreReason: PHASE_IGNORE_REASON_OVER_TOP,
        fromMs: serie.originalPoints[newTopIndex].x,
        toMs: incomingPhase.toMs,
        fromIndex: newTopIndex,
        toIndex: incomingPhase.toIndex,
        fromMm: serie.refined[newTopIndex],
        toMm: incomingPhase.toMm,
      };
    }

    if (incomingPhase.fromMm < rangeMinMax.min) {
      const newBottomIndex = findIndexForwardHigherTillMm(refined, rangeMinMax.min, incomingPhase.fromIndex, incomingPhase.toIndex);

      previousPhase = {
        direction: DIRECTION_ASCENDING,
        ignoreReason: PHASE_IGNORE_REASON_UNDER_BOTTOM,
        fromMs: incomingPhase.fromMs,
        toMs: serie.originalPoints[newBottomIndex].x,
        fromIndex: incomingPhase.fromIndex,
        toIndex: newBottomIndex,
        fromMm: incomingPhase.fromMm,
        toMm: serie.refined[newBottomIndex],
      };
    }
  } else if (incomingPhase.direction === DIRECTION_DESCENDING) {
    if (incomingPhase.fromMm > rangeMinMax.max) {
      const newTopIndex = findIndexForwardLowerTillMm(refined, rangeMinMax.max, incomingPhase.fromIndex, incomingPhase.toIndex);

      previousPhase = {
        direction: DIRECTION_DESCENDING,
        ignoreReason: PHASE_IGNORE_REASON_OVER_TOP,
        fromMs: incomingPhase.fromMs,
        toMs: serie.originalPoints[newTopIndex].x,
        fromIndex: incomingPhase.fromIndex,
        toIndex: newTopIndex,
        fromMm: incomingPhase.fromMm,
        toMm: serie.refined[newTopIndex],
      };
    }

    if (incomingPhase.toMm < rangeMinMax.min) {
      const newBottomIndex = findIndexBackwardHigherTillMm(refined, rangeMinMax.min, incomingPhase.toIndex, incomingPhase.fromIndex);
      // const newBottomIndex = findIndexForwardLowerTillMm(refined, rangeMinMax.min, incomingPhase.fromIndex, incomingPhase.fromIndex);

      nextPhase = {
        direction: DIRECTION_DESCENDING,
        ignoreReason: PHASE_IGNORE_REASON_UNDER_BOTTOM,
        fromMs: serie.originalPoints[newBottomIndex].x,
        toMs: incomingPhase.toMs,
        fromIndex: newBottomIndex,
        toIndex: incomingPhase.toIndex,
        fromMm: serie.refined[newBottomIndex],
        toMm: incomingPhase.toMm,
      };
    }
  }

  const centralPhase: Phase = {
    direction: incomingPhase.direction,
    fromMs: previousPhase ? previousPhase.toMs : incomingPhase.fromMs,
    toMs: nextPhase ? nextPhase.fromMs : incomingPhase.toMs,
    fromIndex: previousPhase ? previousPhase.toIndex : incomingPhase.fromIndex,
    toIndex: nextPhase ? nextPhase.fromIndex : incomingPhase.toIndex,
    fromMm: previousPhase ? previousPhase.toMm : incomingPhase.fromMm,
    toMm: nextPhase ? nextPhase.fromMm : incomingPhase.toMm,
  };

  return {
    previousPhase: previousPhase,
    centralPhase: centralPhase,
    nextPhase: nextPhase,
  };
};

export const calculatePhasesPhysicsAndPositions = (
  phases: Phase[],
  rangeMinMax?: RangeMinMax | null,
  loadWeight?: number | null
) => {
  for (let i = 0; i < phases.length; i++) {
    const phase = phases[i];
    phases[i].physics = calculatePhasePhysics(phase, loadWeight);
    if (rangeMinMax) {
      phases[i].positions = calculatePhasePositions(phase, rangeMinMax);
    }
  }
};

const calculatePhasePhysics = (
  phase: Phase,
  loadWeight?: number | null
): PhasePhysics => {
  const duration = phase.toMs - phase.fromMs;
  const physics: PhasePhysics = { duration: duration };

  if (phase.direction === DIRECTION_ASCENDING) {
    physics.displacement = phase.toMm - phase.fromMm;
  } else if (phase.direction === DIRECTION_DESCENDING) {
    physics.displacement = phase.fromMm - phase.toMm;
  }

  if (
    physics.displacement &&
        (
          phase.direction === DIRECTION_ASCENDING ||
            phase.direction === DIRECTION_DESCENDING
        )
  ) {
    physics.speed = roundToDecimals((physics.displacement / physics.duration));

    if (loadWeight) {
      physics.power = getPower(physics.displacement, duration, loadWeight);
    }
  }

  return physics;
};

const calculatePhasePositions = (
  phase: Phase,
  rangeMinMax: RangeMinMax
): PhasePositions => {
  const [
    rangeTopMarginValue,
    rangeBottomMarginValue,
  ] = getRangeMargins(
    rangeMinMax,
    RANGE_MARGIN_LOW_DISPLACEMENT_MARGIN,
    RANGE_MARGIN_HIGH_DISPLACEMENT_MARGIN,
    RANGE_MARGIN_LOWER_THRESHOLD,
    RANGE_MARGIN_UPPER_THRESHOLD
  );
  const direction = phase.direction;
  let isAllInRange = false;
  let overcomeMaxRange = false;
  let overcomeMinRange = false;
  let reachedTopRange = false;
  let reachedBottomRange = false;
  let reachedTopMargin = false;
  let reachedBottomMargin = false;
  let isAllOverMaxRange = false;
  let isAllUnderMinRange = false;
  const startsFromMinRange = phase.fromMm === rangeMinMax.min;
  const startsFromMaxRange = phase.fromMm === rangeMinMax.max;
  const endsInMinRange = phase.toMm === rangeMinMax.min;
  const endsInMaxRange = phase.toMm === rangeMinMax.max;

  if (direction === DIRECTION_ASCENDING) {
    isAllInRange = phase.fromMm >= rangeMinMax.min && phase.toMm <= rangeMinMax.max;
    overcomeMaxRange = phase.toMm > rangeMinMax.max;
    overcomeMinRange = phase.fromMm < rangeMinMax.min;
    reachedTopMargin = phase.toMm >= rangeTopMarginValue;
    reachedTopRange = phase.toMm >= rangeMinMax.max;
    reachedBottomRange = phase.fromMm <= rangeMinMax.min;
    reachedBottomMargin = phase.fromMm <= rangeBottomMarginValue;
    isAllOverMaxRange = phase.fromMm >= rangeMinMax.max && phase.toMm > rangeMinMax.max;
    isAllUnderMinRange = phase.fromMm < rangeMinMax.min && phase.toMm <= rangeMinMax.min;
  } else if (direction === DIRECTION_DESCENDING) {
    isAllInRange = phase.fromMm <= rangeMinMax.max && phase.toMm >= rangeMinMax.min;
    overcomeMaxRange = phase.fromMm > rangeMinMax.max;
    overcomeMinRange = phase.toMm < rangeMinMax.min;
    reachedTopRange = phase.fromMm >= rangeMinMax.max;
    reachedBottomRange = phase.toMm <= rangeMinMax.min;
    reachedTopMargin = phase.fromMm >= rangeTopMarginValue;
    reachedBottomMargin = phase.toMm <= rangeBottomMarginValue;
    isAllOverMaxRange = phase.fromMm > rangeMinMax.max && phase.toMm >= rangeMinMax.max;
    isAllUnderMinRange = phase.fromMm <= rangeMinMax.min && phase.toMm < rangeMinMax.min;
  } else {
    isAllInRange =
            (phase.fromMm >= rangeMinMax.min && phase.toMm <= rangeMinMax.max) ||
            (phase.fromMm <= rangeMinMax.max && phase.toMm >= rangeMinMax.min);
    overcomeMaxRange =
            phase.fromMm > rangeMinMax.max ||
            phase.toMm > rangeMinMax.max;
    overcomeMinRange =
            phase.fromMm < rangeMinMax.min ||
            phase.toMm < rangeMinMax.min;
    reachedTopRange =
            phase.fromMm >= rangeMinMax.max ||
            phase.toMm >= rangeMinMax.max;
    reachedBottomRange =
            phase.fromMm <= rangeMinMax.min ||
            phase.toMm <= rangeMinMax.min;
    reachedTopMargin =
            phase.fromMm >= rangeTopMarginValue ||
            phase.toMm >= rangeTopMarginValue;
    reachedBottomMargin =
            phase.fromMm <= rangeBottomMarginValue ||
            phase.toMm <= rangeBottomMarginValue;
    isAllOverMaxRange =
            (phase.fromMm >= rangeMinMax.max && phase.toMm > rangeMinMax.max) ||
            (phase.fromMm > rangeMinMax.max && phase.toMm >= rangeMinMax.max);
    isAllUnderMinRange =
            (phase.fromMm < rangeMinMax.min && phase.toMm <= rangeMinMax.min) ||
            (phase.fromMm <= rangeMinMax.min && phase.toMm < rangeMinMax.min);
  }

  return {
    isAllInRange: isAllInRange,
    isAllOverMaxRange: isAllOverMaxRange,
    isAllUnderMinRange: isAllUnderMinRange,
    overcomeMaxRange: overcomeMaxRange,
    overcomeMinRange: overcomeMinRange,
    reachedTopRange: reachedTopRange,
    reachedBottomRange: reachedBottomRange,
    reachedTopMargin: reachedTopMargin,
    reachedBottomMargin: reachedBottomMargin,
    startsFromMinRange: startsFromMinRange,
    startsFromMaxRange: startsFromMaxRange,
    endsInMinRange: endsInMinRange,
    endsInMaxRange: endsInMaxRange,
  };
};

/**
 * Ignore short concentric phases on calculated phase
 *
 * @param incomingPhases
 * @param rangeMinMax
 */
export const ignoreShortConcentricPhases = (
  incomingPhases: Phase[],
  rangeMinMax: RangeMinMax
) => {
  const [
    rangeTopMarginValue,
    rangeBottomMarginValue,
  ] = getRangeMargins(
    rangeMinMax,
    RANGE_MARGIN_LOW_DISPLACEMENT_MARGIN,
    RANGE_MARGIN_HIGH_DISPLACEMENT_MARGIN,
    RANGE_MARGIN_LOWER_THRESHOLD,
    RANGE_MARGIN_UPPER_THRESHOLD
  );

  const shortDisplacement = rangeTopMarginValue - rangeBottomMarginValue;
  const tooShortDisplacement = shortDisplacement * 0.7;
  ignoreShorterThanConcentricPhases(incomingPhases, tooShortDisplacement, PHASE_IGNORE_REASON_TOO_SHORT);
  ignoreShorterThanConcentricPhases(incomingPhases, shortDisplacement, PHASE_IGNORE_REASON_SHORT);
};

/**
 * Ignore concentric phases shorter than displacement
 *
 * @param incomingPhases
 * @param displacement
 * @param phaseIgnoreReason
 */
export const ignoreShorterThanConcentricPhases = (
  incomingPhases: Phase[],
  displacement: number,
  phaseIgnoreReason: PhaseIgnoreReason
) => {
  for (const phase of incomingPhases) {
    if (
      (
        phase.direction === DIRECTION_ASCENDING ||
        phase.direction === DIRECTION_DESCENDING
      ) &&
        phase.ignoreReason === undefined
    ) {
      if (
        phase.physics &&
        phase.physics.displacement !== undefined &&
        phase.physics.displacement !== null &&
        phase.physics.displacement < displacement
      ) {
        phase.ignoreReason = phaseIgnoreReason;
      }
    }
  }
};

export const addPoints = (serie: Serie, points: Point[]) => {
  let hasError = false;
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    const lastPoint = serie.originalPoints[serie.originalPoints.length - 1];
    if (!lastPoint || lastPoint.x < point.x) {
      serie.originalPoints.push({
        x: point.x,
        y: point.y,
      });
    } else {
      hasError = true;
      console.log("The point is not in the right order at position", i, point);
    }
  }
  if (hasError) {
    console.log("Some points were not in the right order", points);
  }
};

type SpeedSuggestedOutput = {
  speed: number;
  min: number;
  max: number;
}
export const getSpeedSuggested = (rangeMinMax: RangeMinMax): SpeedSuggestedOutput => {
  let rom = rangeMinMax.max - rangeMinMax.min;
  if (rom > 1120) {
    rom = 1120;
  }
  if (rom < 350) {
    rom = 350;
  }
  const speedSuggested = rom / (((rom - 350) * 0.026) + 50);
  return {
    speed: speedSuggested,
    min: Math.floor(speedSuggested),
    max: Math.ceil(speedSuggested),
  };
};

export const getPhasesAscendingCountable = (phases: Phase[]): number => {
  return phases.filter(p =>
    p.direction === DIRECTION_ASCENDING &&
    (
      !p.ignoreReason ||
      p.ignoreReason === PHASE_IGNORE_REASON_SHORT
    )
  ).length;
};

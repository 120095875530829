import { ApiDataList } from "@feature/api/component/ApiDataList";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { SheetListComponent } from "@feature/sheet/component/sheetsListComponent";
import { clearSheetFormPreferences } from "@feature/sheet/service/sheetService";
import { historyController } from "@core/redux/store";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";
import { useSheetListQuery } from "@feature/sheet/api/sheetApi";

export const SheetListPage = () => {
  const sheetListApi = useSheetListQuery();

  const goToSheetCreate = async() => {
    await clearSheetFormPreferences();
    historyController.replace(routes.sheetCreate.redirectPath);
  };

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.sheetList.title)}
        backToRoute={routes.dashboard} />
      <IonContent>
        <ApiDataList
          endpoint={sheetListApi}
          useRefresher={true}
          onButtonClick={goToSheetCreate}
        >
          <SheetListComponent
            sheetList={sheetListApi.data}
          />
        </ApiDataList>
      </IonContent>
    </IonPage>
  </>;
};

import {
  Route,
  routes,
} from "@core/route";
import { TabBarUi } from "@ui/TabBarUi/TabBarUi";
import {
  historyController,
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import {
  matchPath,
  useLocation,
} from "react-router";
import { selectRunState } from "@feature/run/slice/runSlice";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import {
  selectTabs,
  setActiveRoute,
  setIsVisible,
} from "@feature/tab/slice/tabsSlice";
import { useEffect } from "react";

export const TabsOutlet = () => {
  const dispatch = useAppDispatch();

  const tabsState = useAppSelector(selectTabs);
  const runState = useAppSelector(selectRunState);
  const settingState = useAppSelector(selectSettingState);
  const location = useLocation();

  const allTabs: Route[] = [
    routes.dashboard,
    routes.development,
    routes.historyList,
    routes.sheetList,
    routes.run,
    routes.resume,
    routes.deviceList,
    routes.settings,
  ];

  const goTo = (route: Route) => {
    dispatch(setActiveRoute(route.code));
    historyController.replace(route.redirectPath);
  };

  const getTabs = () => {
    let tabs = allTabs.filter(t => (
      runState.hasSheetUuid ?
        t.code !== routes.sheetList.code :
        t.code !== routes.run.code
    ));

    if (!settingState.isDebug) {
      tabs = tabs.filter(t => t.code !== "development");
    }

    if (runState.hasSheetUuid) {
      tabs = tabs.filter(t => t.code !== "run");
    } else {
      tabs = tabs.filter(t => t.code !== "resume");
    }

    return tabs;
  };

  // This effect is used to redirect the user to the login page if he is not logged in and the route is not public
  useEffect(() => {
    Object.keys(routes).forEach(key => {
      const route: Route = routes[key];
      const match = matchPath(location.pathname, { path: route.routePath });
      if (
        !match ||
        !match.isExact
      ) {
        return;
      }

      // set active route
      dispatch(setActiveRoute(route.activeTab || null));

      // set tabs visibility
      if (!route.isTabsVisible && tabsState.isVisible) {
        dispatch(setIsVisible(false));
      } else if (route.isTabsVisible && !tabsState.isVisible) {
        dispatch(setIsVisible(true));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ location.pathname ]);

  if (!tabsState.isVisible) {
    return <></>;
  }

  return <>
    <TabBarUi
      items={getTabs()}
      goTo={goTo}
      activeRoute={tabsState.activeRoute}
    />
  </>;
};

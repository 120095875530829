import { ApiData } from "@feature/api/component/ApiData";
import {
  ButtonClick,
  Callback,
} from "@types-custom";
import { ModalListUi } from "@ui/ModalListUi/ModalListUi";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { Profile } from "@common/type-graphql/generated";
import { ProfileListComponent } from "@feature/profile/component/profileListComponent";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { getUniqueId } from "@util/getUniqueId";
import { t } from "@lingui/macro";
import { useProfileListQuery } from "@feature/profile/api/profileApi";

type Props = {
  athleteUuid: string;
  isOpen: boolean;
  onClose: ButtonClick;
  onClick: ((profileUuid: string) => void);
}

export const ProfileChooseModal = (props: Props) => {
  const profileListApi = useProfileListQuery({ where: { uuid: props.athleteUuid } });

  return <>
    <ApiData endpoint={profileListApi}>
      <ProfileChooseModalChild
        profileList={profileListApi.data}
        refetch={profileListApi.refetch}
        {...props}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  profileList: Profile[];
  refetch: Callback;
}

const ProfileChooseModalChild = (props: PropsChild) => {
  return <>
    <ModalListUi
      title={t`Choose the Profile`}
      isOpen={props.isOpen}
      onClose={props.onClose}>
      <ProfileListComponent
        athleteUuid={props.athleteUuid}
        data={props.profileList}
        onClick={props.onClick}
        hideAddButtonClick={true}
        key={getUniqueId()}
      />
      <SeparatorUi marginTop={20} />
      <PrimaryButtonUi
        onClick={() => props.refetch()}
        label={t`Reload`}
      />
    </ModalListUi>
  </>;
};

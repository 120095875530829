import { API_CACHE_TAG_RELATION } from "@feature/relation/relationConstants";
import { Relation } from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  relationsQuery,
  relationsTransformResponse,
} from "@common/type-graphql/relation/gql/relation-list.g";

export const relationApi = api.injectEndpoints({
  endpoints: builder => ({
    relationList: builder.query<Relation[], void>({
      query: () => ({ document: relationsQuery }),
      transformResponse: relationsTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_RELATION, result),
    }),
  }),
  overrideExisting: false,
});

export const { useRelationListQuery } = relationApi;

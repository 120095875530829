import { ApiData } from "@feature/api/component/ApiData";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  Sheet,
  Training,
} from "@common/type-graphql/generated";
import { SystemErrorMessageComponent } from "@feature/layout/component/SystemErrorMessageComponent";
import { TrainingForm } from "@feature/training/component/form/trainingForm";
import { t } from "@lingui/macro";
import {
  useSheetListQuery,
  useSheetResetQuickMutation,
} from "@feature/sheet/api/sheetApi";
import { useTrainingListQuery } from "@feature/training/api/trainingApi";

export const QuickEditComponent = () => {
  const sheetListApi = useSheetListQuery();
  const quickSheet: Sheet = sheetListApi.isSuccess && sheetListApi.data ? sheetListApi.data.find(sheet => sheet.isQuick === true) : null;
  const quickTrainingApi = useTrainingListQuery({ where: { uuid: quickSheet?.uuid } }, { skip: !quickSheet });

  return <>
    <ApiData endpoint={[
      sheetListApi,
      quickTrainingApi,
    ]}>
      <QuickEditComponentChild
        quickSheetList={sheetListApi.data}
        trainingList={quickTrainingApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  quickSheetList: Sheet[];
  trainingList: Training[];
}

const QuickEditComponentChild = (props: PropsChild) => {
  const quickSheet: Sheet = props.quickSheetList.length ? props.quickSheetList.find(sheet => sheet.isQuick === true) : null;
  const quickTraining = props.trainingList.length ? props.trainingList[0] : null;
  const [ reset ] = useSheetResetQuickMutation();

  const resetQuickSheet = () => {
    reset();
  };

  if (
    !quickSheet ||
    !quickTraining
  ) {
    return <>
      <SystemErrorMessageComponent />
      <SectionUi>
        <PrimaryPositiveButtonUi
          label={t`Reset quick sheet`}
          onClick={resetQuickSheet}
        />
      </SectionUi>
    </>;
  }

  return <>
    <TrainingForm
      sheetUuid={quickSheet.uuid}
      trainingUuid={quickTraining.uuid} />
  </>;
};

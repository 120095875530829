/**
 * Il filtro di Kalman è un algoritmo di stima ottimale che fornisce una soluzione al problema di stima lineare quadratico.
 * È particolarmente efficace nel filtrare il rumore da serie temporali, prevedere stati futuri e ottimizzare le
 * prestazioni basate su tali previsioni. Viene spesso utilizzato in sistemi di controllo, robotica, computer vision e in
 * molte altre applicazioni dove la stima precisa di variabili in presenza di rumore è fondamentale.
 * Ecco una spiegazione semplificata del filtro di Kalman:
 * Inizializzazione: Il filtro inizia con una stima iniziale dello stato del sistema e una stima della covarianza dello stato.
 * Il filtro ha due fasi principali: Predizione e Aggiornamento.
 * a. Predizione: Il filtro predice il prossimo stato e la prossima covarianza in base al modello del sistema.
 * b. Aggiornamento: Quando viene ottenuta una nuova misurazione, il filtro la utilizza per aggiornare la stima dello
 * stato e la sua covarianza. L'essenza dell'aggiornamento è combinare la stima corrente (dalla fase di previsione) con la
 * nuova misurazione, ponderando le informazioni in base alle loro stime di precisione.
 * Le variabili chiave del filtro di Kalman sono:
 * Q: rumore del processo. Indica quanto pensi che il tuo modello sia accurato.
 * R: rumore di misura. Indica quanto fidarti della misurazione.
 * A, B, C: Matrici che definiscono le relazioni nel modello del sistema. Nel nostro caso, abbiamo semplificato le cose
 * usando un filtro di Kalman 1D, quindi queste sono solo variabili scalari. In applicazioni più complesse, queste
 * potrebbero essere matrici.
 * K: guadagno di Kalman. Determina quanto fidarsi della misurazione rispetto alla previsione.
 * x: la stima corrente dello stato.
 * cov: la stima corrente della covarianza dello stato.
 * In sostanza, il filtro di Kalman stima continuamente lo stato di un sistema (in questo caso, la posizione y del bilanciere)
 * e la certezza di tale stima. Quando arrivano nuove misurazioni, il filtro utilizza queste informazioni per correggere o
 * affinare le sue stime, bilanciando l'informazione proveniente dalla previsione del modello e quella proveniente dalla
 * nuova misurazione.
 * Il bello del filtro di Kalman è che, pur essendo relativamente semplice, è ottimale per sistemi lineari con rumore
 * gaussiano, il che lo rende molto efficace in molte applicazioni reali.
 */
export class KalmanFilter {
  private readonly Q: number; // process noise covariance
  private readonly R: number; // measurement noise covariance
  private readonly A: number; // state vector
  private readonly B: number; // control vector
  private readonly C: number; // measurement vector

  private cov: number;
  private x: number;

  constructor(Q = 1, R = 0.01, startX = 0, startCov = 0) {
    this.Q = Q;
    this.R = R;
    this.A = 1;
    this.B = 0;
    this.C = 1;

    this.x = startX;
    this.cov = startCov;
  }

  filter(z: number): number {
    // Prediction step
    const predX = this.predict();
    const predCov = this.uncertainty();

    // Update step
    const K = predCov * this.C / (this.C * predCov * this.C + this.R);
    this.x = predX + K * (z - this.C * predX);
    this.cov = (1 - K * this.C) * predCov;

    return this.x;
  }

  private predict(): number {
    return this.A * this.x + this.B;
  }

  private uncertainty(): number {
    return this.A * this.cov * this.A + this.Q;
  }
}

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    formErrorRoot: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "5px",
      marginTop: "15px",
      color: theme.colors.red,
    },
  })
);

import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { useId } from "react-aria";
import { useStyles } from "./useStyles";

type CardBackground = "neon" | "yellow" | "orange" | "purple" | "darkPurple" | "cyan"

export interface Props {
  title: string;
  description: string;
  imageSrc: string;
  background: CardBackground;
  imagePosition?: "left" | "right";
  onButtonClick?: () => void;
}

export const CardUi = ({
  title, description, imageSrc, background, imagePosition = "right", onButtonClick,
}: Props) => {
  const uid = useId();

  const {
    classes, cx,
  } = useStyles({
    uid: uid,
    background: background,
  });

  return <>
    <div
      className={cx({
        [classes.cardRoot]: true,
        [classes.cardRootImagePositionLeft]: imagePosition === "left",
      })}
      onClick={onButtonClick}
    >
      <div className={cx({
        [classes.infoWrapper]: true,
        [classes.infoWrapperImagePositionLeft]: imagePosition === "left",
        [classes.infoTextWhite]: background === "darkPurple" || background === "purple",
      })}>
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <ButtonUi
          label=""
          width="fit-content"
          type="primary"
          labelColor="pureWhite"
          backgroundColor="black"
          icon="expand-right"
          iconColor="neon"
          iconPosition="left"
          fontStyle="OIB18"
          appearance="eptagon"
          hasToggle={false}
          hasPressed={false}
          onClick={onButtonClick}
          isActive={false}
        />
      </div>
      <div className={cx({
        [classes.imageWrapper]: true,
        [classes.imagePositionRight]: imagePosition === "right",
        [classes.imagePositionLeft]: imagePosition === "left",
      })}>
        <svg viewBox="0 0 97.49 100" width="240" height="240">
          <defs>
            <clipPath id={uid}>
              <path d="M97.49 50 78.18 90.1 34.79 100 0 72.25v-44.5L34.79 0l43.39 9.9L97.49 50z" />
            </clipPath>
          </defs>
          <image
            preserveAspectRatio="xMidYMid slice"
            className={classes.cardImage}
            xlinkHref={imageSrc}
          ></image>
        </svg>
      </div>
    </div>
  </>;
};

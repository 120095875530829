import { Color } from "@types-custom";
import { useStyles } from "./useStyles";

interface Props {
  label?: string | undefined;
  background?: Color;
  className?: string;
  padding?: number;
  marginTop?: number | string;
  marginBottom?: number | string;
}

export const SeparatorUi = ({
  label, background = "", className, padding, marginTop = "12px", marginBottom,
}: Props) => {
  // Style
  const {
    classes, cx,
  } = useStyles();

  return <>
    <div
      className={cx({ [classes.separatorRoot]: true })}
      style={{
        paddingLeft: padding,
        paddingRight: padding,
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
    >
      <div
        style={{ width: `calc(100% - ${ padding !== 0 ? padding : 0 * 2 }px)` }}
        className={cx({
          [classes.separator]: true,
          [classes.transparentBackground]: background === "",
          [classes.darkBackground]: background === "darkTint",
          [classes.coloredBackground]: background === "white",
        })}
      />
      {label && (
        <span className={cx({
          [classes.separatorLabel]: true,
          [classes.darkBackgroundLabel]: background === "darkTint",
          [classes.coloredBackgroundLabel]: background === "white",
        })}>{label}</span>
      )}
    </div>
  </>;
};

import { makeStyles } from "@theme/makeStyles";

export const runPageStyle = makeStyles()(
  (theme, props, classes) => ({
    runPage: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: theme.runHeaderHeight,
      paddingBottom: theme.runFooterHeight + 50,
    },
    historyPage: {
      paddingTop: 10,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 93,
    },
  })
);

export const DIRECTION_UNKNOWN = "UNKNOWN";
export const DIRECTION_END = "END";
export const DIRECTION_STABLE = "STABLE";
export const DIRECTION_ASCENDING = "ASCENDING";
export const DIRECTION_DESCENDING = "DESCENDING";
export const DIRECTION_INVALID = "INVALID";

export type Direction =
  | typeof DIRECTION_UNKNOWN
  | typeof DIRECTION_END
  | typeof DIRECTION_STABLE
  | typeof DIRECTION_ASCENDING
  | typeof DIRECTION_DESCENDING
  | typeof DIRECTION_INVALID

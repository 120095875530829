import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    separatorRoot: {
      height: "fit-content",
      zIndex: 1,
      display: "flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
    },
    separatorLabel: {
      zIndex: 2,
      display: "flex",
      padding: "0px 16px",
      alignItems: "center",
      justifyContent: "center",
      ...theme.typography.RM14,
      textTransform: "uppercase",
    },
    separator: {
      width: "100%",
      height: 1,
      zIndex: 1,
      position: "absolute",
    },
    transparentBackground: { backgroundColor: theme.colors.transparent },
    darkBackground: { backgroundColor: theme.colors.separatorDarkBackground },
    coloredBackground: { backgroundColor: theme.colors.separatorColoredBackground },
    darkBackgroundLabel: {
      color: theme.colors.pureWhite,
      backgroundColor: theme.colors.darkTint,
    },
    coloredBackgroundLabel: {
      color: theme.colors.pureBlack,
      backgroundColor: theme.colors.neon,
    },
  })
);

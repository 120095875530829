import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    buttonRoot: {
      minHeight: 50,
      display: "flex",
      alignItems: "center",
      "& .embla__dots": {
        position: "relative",
        display: "flex",
        listStyle: "none",
        justifyContent: "center",
        paddingTop: "10px",
      },
      "& .embla__dot": {
        backgroundColor: "transparent",
        cursor: "pointer",
        position: "relative",
        padding: 0,
        outline: 0,
        border: 0,
        width: "30px",
        height: "30px",
        marginRight: 4,
        marginLeft: 4,
        display: "flex",
        alignItems: "center",
      },
      "& .embla__dot:after": {
        backgroundColor: theme.colors.black,
        opacity: 0.16,
        width: "100%",
        height: "4px",
        borderRadius: 0,
        // eslint-disable-next-line
        content: '""',
      },
      "& .embla__dot.is-selected:after": {
        backgroundColor: theme.colors.black,
        opacity: 1,
      },
    },
  })
);

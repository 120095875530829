import { makeStyles } from "@theme/makeStyles";

const FADEIN_TIME = "0.5s";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    splashScreen :{
      backgroundColor: theme.colors.neon,
      zIndex: 100,
      padding: 40,
    },
    splashScreenContent: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      opacity: 0,
    },
    splashScreenLogo: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    splashScreenFooter: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      "a" : {
        color: theme.colors.grey,
      },
    },
  }));

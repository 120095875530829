import {
  api,
  getTags,
} from "@core/api";

import {
  API_CACHE_TAG_SHEET,
  API_CACHE_TAG_SHEET_QUICK_VALUE,
} from "@feature/sheet/sheetConstants";
import { API_CACHE_TAG_TRAINING } from "@feature/training/trainingConstants";
import {
  CreateOneTrainingArgs,
  FindUniqueSheetArgs,
  FindUniqueTrainingArgs,
  Training,
  UpdateOneAthleteArgs,
  UpdateOneTrainingArgs,
} from "@common/type-graphql/generated";
import {
  createOneTrainingQuery,
  createOneTrainingTransformResponse,
} from "@common/type-graphql/training/gql/training-create.g";
import {
  duplicateTrainingQuery,
  duplicateTrainingTransformResponse,
} from "@common/type-graphql/training/gql/training-duplicate.g";
import {
  trainingListQuery,
  trainingListTransformResponse,
} from "@common/type-graphql/training/gql/training-list.g";
import {
  trainingQuery,
  trainingTransformResponse,
} from "@common/type-graphql/training/gql/training-get.g";
import {
  updateOneTrainingQuery,
  updateOneTrainingTransformResponse,
} from "@common/type-graphql/training/gql/training-update.g";

export const trainingApi = api.injectEndpoints({
  endpoints: builder => ({
    trainingList: builder.query<Training[], FindUniqueSheetArgs>({
      query: variables => ({
        document: trainingListQuery,
        variables: variables,
      }),
      transformResponse: trainingListTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_TRAINING, result),
    }),
    trainingGet: builder.query<Training, FindUniqueTrainingArgs>({
      query: variables => ({
        document: trainingQuery,
        variables: variables,
      }),
      transformResponse: trainingTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_TRAINING,
          id: result.uuid,
        },
        {
          type: API_CACHE_TAG_SHEET,
          id: API_CACHE_TAG_SHEET_QUICK_VALUE,
        },
      ],
    }),
    trainingCreate: builder.mutation<Training, CreateOneTrainingArgs>({
      query: variables => ({
        document: createOneTrainingQuery,
        variables: variables,
      }),
      transformResponse: createOneTrainingTransformResponse,
      invalidatesTags: () => getTags(API_CACHE_TAG_TRAINING),
    }),
    trainingDuplicate: builder.mutation<Training, FindUniqueTrainingArgs>({
      query: variables => ({
        document: duplicateTrainingQuery,
        variables: variables,
      }),
      transformResponse: duplicateTrainingTransformResponse,
      invalidatesTags: () => getTags(API_CACHE_TAG_TRAINING),
    }),
    trainingEdit: builder.mutation<Training, UpdateOneAthleteArgs>({
      query: variables => ({
        document: updateOneTrainingQuery,
        variables: variables,
      }),
      transformResponse: updateOneTrainingTransformResponse,
      invalidatesTags: (result, error, input) => getTags(API_CACHE_TAG_TRAINING, result),
    }),
    trainingUpdate: builder.mutation<Training, UpdateOneTrainingArgs>({
      query: variables => ({
        document: updateOneTrainingQuery,
        variables: variables,
      }),
      transformResponse: updateOneTrainingTransformResponse,
      invalidatesTags: (result, error, input) => getTags(API_CACHE_TAG_TRAINING, result),
    }),
  }),
  overrideExisting: false,
});

export const {
  useTrainingListQuery,
  useTrainingGetQuery,
  useLazyTrainingGetQuery,
  useTrainingCreateMutation,
  useTrainingDuplicateMutation,
  useTrainingEditMutation,
  useTrainingUpdateMutation,
} = trainingApi;

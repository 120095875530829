import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import {
  IonCol,
  IonRow,
} from "@ionic/react";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { deviceBleSetTemperature } from "@feature/device/slice/deviceSlice";
import { t } from "@lingui/macro";
import { useAppDispatch } from "@core/redux/store";
import { useForm } from "react-hook-form";

type FormData = {
  temperature?: string;
}

export const TemperatureFormComponent = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>();

  const onSubmit = async(formData: FormData) => {
    try {
      const value = parseInt(formData.temperature, 10);

      if (value > 0 &&
        value < 50) {
        dispatch(deviceBleSetTemperature({ temperature: value }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <IonCol>
          <InputUi
            type="number"
            autoComplete={"off"}
            minValue={0}
            maxValue={50}
            isValid={!Boolean(errors.temperature)}
            id="temperature"
            placeholder={t`Temperature`}
            {...register("temperature", {
              required: true,
              value: "0",
            })}
          />
        </IonCol>

        <IonCol>
          {isSubmitting && <PrimaryPositiveButtonUi isLoading />}
          {!isSubmitting && <PrimaryPositiveButtonUi label={t`Set Temperature`} />}
        </IonCol>
      </IonRow>
    </FormUi>
  </>;
};

import { Callback } from "@types-custom";
import {
  useEffect,
  useRef,
  useState,
} from "react";

interface Props {
  targetTime: number;
  isRecoverPhase: boolean;
  isAutoStartEnabled: boolean;
  recoverAmount?: number;
  callback?: Callback;
}

export const useLogic = (props: Props) => {
  const [
    seconds,
    setSeconds,
  ] = useState<number>(0);

  const [
    isFuture,
    setIsFuture,
  ] = useState<boolean>(true);

  const timerIdRef = useRef(null);

  useEffect(() => {
    const update = () => {
      const currentDate = Math.ceil(Date.now() / 1000);
      const targetDate = Math.ceil(props.targetTime / 1000);
      const timeDiff = targetDate - currentDate;

      if (!targetDate) {
        return;
      }

      if (currentDate > targetDate) {
        setIsFuture(false);
        if (props.isRecoverPhase && props.recoverAmount) {
          setSeconds(currentDate - targetDate + props.recoverAmount);
        } else {
          setSeconds(currentDate - targetDate);
        }
      } else {
        setIsFuture(true);
        setSeconds(targetDate - currentDate);
      }

      if (
        props.isAutoStartEnabled &&
        props.isRecoverPhase &&
        props.callback &&
        timeDiff === 10
      ) {
        props.callback();
      } else {
        timerIdRef.current = setTimeout(update, 1000);
      }
    };

    update();

    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.callback,
    props.targetTime,
  ]);

  return {
    seconds: seconds,
    isFuture: isFuture,
  };
};

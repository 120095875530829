import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<{ uid; background }, "cardImage">()(
  (theme, props, classes) => ({
    // Base styles
    cardRoot: {
      minHeight: 240,
      display: "flex",
      borderRadius: 32,
      overflow: "hidden",
      margin: 12,
      position: "relative",
      zIndex: 1,
      backgroundColor: theme.colors[props.background],
    },
    cardRootImagePositionLeft: { flexDirection: "row-reverse" },
    infoWrapper: {
      width: "50%",
      color: theme.colors.black,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: 12,
      "& h2": {
        ...theme.typography.OIB32,
        margin: 0,
        marginBottom: 16,
        textTransform: "uppercase",
      },
      "& p": {
        ...theme.typography.RM14,
        margin: 0,
        paddingBottom: 10,
      },
    },
    infoTextWhite: { color: theme.colors.pureWhite },
    infoWrapperImagePositionLeft: {
      alignItems: "flex-end",
    },
    imageWrapper: {
      position: "relative",
      zIndex: 1,
      width: "50%",
      "& svg": {
        display: "block",
        width: "auto",
        position: "absolute",
        top: "50%",
        zIndex: 1,
      },
    },
    imagePositionRight: {
      "& svg": {
        right: 0,
        transform: "translateX(35%) translateY(-50%)",
        "& clipPath": {
          transform: "rotate(180deg)",
          transformOrigin: "center",
        },
      },
    },
    imagePositionLeft: {
      "& svg": {
        left: 0,
        transform: "translateX(-35%) translateY(-50%)",
        "& clipPath": {
          transform: "rotate(0deg)",
          transformOrigin: "center",
        },
      },
    },
    cardImage: {
      height: "100%",
      width: "100%",
      display: "block",
      objectFit: "cover",
      objectPosition: "center",
      clipPath: `url(#${ props.uid })`,
    },
  })
);

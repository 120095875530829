import { ApiData } from "@feature/api/component/ApiData";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { UseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { formatItalianDateTime } from "@util/dateFormat";
import { getUniqueId } from "@util/getUniqueId";

type Props = {
  apiData: UseQueryHookResult<any>;
}

export const CommentsComponent = (props: Props) => {
  return <>
    <ApiData endpoint={props.apiData}>
      <CommentsListComponent comments={props.apiData.data as CommentDataProps[]} />
    </ApiData>
  </>;
};

type CommentsListProps = {
  comments: CommentDataProps[];
}

const CommentsListComponent = (props: CommentsListProps) => {
  return <>
    <SectionUi rounded>
      { props.comments.slice(0).reverse().map((c: CommentDataProps) =>
        <ListItemUi key={getUniqueId()}>
          <CommentComponent
            comment={c}
            key={getUniqueId()} />
        </ListItemUi>
      )}
    </SectionUi>
  </>;
};

type CommentProps = {
  comment: CommentDataProps;
}

type CommentDataProps = {
  comment: string;
  dateTime: Date;
}

const CommentComponent = (props: CommentProps) => {
  return <>
    <b>{ formatItalianDateTime(props.comment.dateTime) }</b>
    <p>{ props.comment.comment }</p>
  </>;
};

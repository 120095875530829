import { API_CACHE_TAG_CHART } from "@feature/chart/chartConstants";
import { Chart } from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  chartsQuery,
  chartsTransformResponse,
} from "@common/type-graphql/chart/gql/chart-list.g";

export const chartApi = api.injectEndpoints({
  endpoints: builder => ({
    chartList: builder.query<Chart[], void>({
      query: () => ({ document: chartsQuery }),
      transformResponse: chartsTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_CHART, result),
    }),
  }),
  overrideExisting: false,
});

export const { useChartListQuery } = chartApi;

import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import { STORE_ONLINE_KEY } from "@feature/offline/offlineConstants";

type InitialStateModelInterface = {
  isOnline: boolean;
}

const initialState: InitialStateModelInterface = { isOnline: true };

export const onlineSlice = createSlice({
  name: STORE_ONLINE_KEY,
  initialState: initialState,
  reducers: {
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});

export const { setIsOnline } = onlineSlice.actions;

export const selectOnlineState = (state: RootState) => state[STORE_ONLINE_KEY];

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    welcomeCarouselRoot: {
      height: "100%",
      "& .embla": {
        height: "100%",
        position: "relative",
        backgroundColor: theme.colors.neon,
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 1,
      },
      "& .embla__viewport": {
        overflow: "hidden",
        width: "100%",
      },
      "& .embla__container": {
        display: "flex",
        userSelect: "none",
        "WebkitTouchCallout": "none",
        "WebkitTapHighlightColor": "transparent",
        marginLeft: 0,
      },
      "& .embla__slide": {
        height: "100%",
        position: "relative",
        minWidth: "100%",
        paddingLeft: 0,
      },
      "& .embla__slide__inner": {
        position: "relative",
        overflow: "hidden",
        height: "50vh",
        backgroundColor: theme.colors.realBlack,
        display: "flex",
        justifyContent: "center",
      },
      "& .embla__slide__img": {
        width: "auto",
        height: "50vh",
        maxWidth: "none",
      },
      "& .innerText": {
        height: "50vh",
        top: "50vh",
        left: 0,
        position: "absolute",
        zIndex: 4,
        backgroundColor: theme.colors.neon,
      },
    },

    imageHider: {
      position: "fixed",
      top: "calc(25vh + 1px)",
      left: 0,
      zIndex: 2,
      width: "100%",
      height: "25vh",
      pointerEvents: "none",
      display: "flex",
      userSelect: "none",
      "WebkitTouchCallout": "none",
      "WebkitTapHighlightColor": "transparent",
      marginLeft: 0,
      fill: theme.colors.neon,
      filter: "drop-shadow(0px -9px 4px rgba(50, 50, 50, 0.25))",
    },

    carouselDots: {
      position: "fixed",
      bottom: 30,
      left: 0,
      zIndex: 10,
      width: "100%",
      padding: "0px 24px",
      display: "flex",
      justifyContent: "space-between",
      "@supports (padding-bottom: env(safe-area-inset-bottom))": { bottom: "calc(16px + env(safe-area-inset-bottom, 0))" },
    },
    dots: {
      display: "flex",
      justifyContent: "flex-start",
    },
    indexes: {
      position: "fixed",
      top: "25vh",
      left: 0,
      zIndex: 3,
      width: "100%",
      height: "25vh",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      margin: 0,
      paddingLeft: 24,
      clipPath: "url(#indexClipPath)",
    },
    indexesInner: {
      width: "fit-content",
      display: "flex",
    },
    index: {
      display: "block",
      width: "100vw",
      color: theme.colors.pureBlack,
      opacity: 0.16,
      ...theme.typography.OIB64,
      fontSize: 56,
    },

    slideTextContainer: {
      position: "fixed",
      top: "50vh",
      left: 0,
      zIndex: 3,
      width: "100%",
      height: "fit-content",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    slideTextInnerContainer: { display: "flex" },
    slideText: {
      width: "100vw",
      padding: "0px 24px",
      "& h2": {
        margin: 0,
        padding: 0,
        ...theme.typography.OIB48,
        lineHeight: "52px",
        textTransform: "uppercase",
        color: theme.colors.pureBlack,
      },
      "& p": {
        margin: 0,
        marginTop: 24,
        padding: 0,
        ...theme.typography.RM16,
        color: theme.colors.black,
      },
    },
  })
);

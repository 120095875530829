import {
  IonButton,
  IonIcon,
} from "@ionic/react";
import {
  addOutline,
  move,
  removeOutline,
} from "ionicons/icons";

type Props = {
  isEnabled: boolean;
  isEnabledHandler: (value: boolean) => void;
  height: number;
  heightHandler: (value: number) => void;
}

export const ChartControllerComponenet = (props: Props) => {
  const isEnabledToggle = () => {
    props.isEnabledHandler(!props.isEnabled);
  };

  const heightIncrease = () => {
    if (props.height > 1000) {
      return;
    }
    props.heightHandler(props.height + 50);
  };

  const heightDecrease = () => {
    if (props.height < 300) {
      return;
    }
    props.heightHandler(props.height - 50);
  };

  return <>
    <IonButton
      size={"small"}
      color={props.isEnabled ? "primary" : "secondary"}
      onClick={isEnabledToggle}>
      <IonIcon
        slot={"icon-only"}
        icon={move}
        size="small" />
    </IonButton>

    {
      props.isEnabled &&
      <IonButton
        size={"small"}
        color={"medium"}
        onClick={heightIncrease}>
        <IonIcon
          icon={addOutline}
          size="small" />
      </IonButton>
    }

    {
      props.isEnabled &&
      <IonButton
        size={"small"}
        color={"medium"}
        onClick={heightDecrease}>
        <IonIcon
          icon={removeOutline}
          size="small" />
      </IonButton>
    }
  </>;
};

import gsap from "gsap";
import { GSAP_CUSTOM_EASE_OUTQUINT } from "@feature/gsap/gsapConstants";
import {
  useEffect,
  useRef,
} from "react";

export const useLogic = (props: any) => {
  const $root = useRef(null);

  useEffect(() => {
    if (props.animation === "scale-from-center") {
      $root.current && gsap.to($root.current, {
        scale: props.isOpen ? 1 : 0.9,
        opacity: props.isOpen ? 1 : 0,
        duration: 0.4,
        ease: GSAP_CUSTOM_EASE_OUTQUINT,
      });
    }

    if (props.animation === "slide-from-bottom") {
      $root.current && gsap.to($root.current, {
        y: props.isOpen ? 0 : "100%",
        opacity: props.isOpen ? 1 : 0,
        duration: 0.4,
        ease: GSAP_CUSTOM_EASE_OUTQUINT,
      });
    }
  }, [
    props.isOpen,
    props.animation,
  ]);

  return { $root: $root };
};

import { GraphQLError } from "graphql";
import { i18n } from "@lingui/core";

export const ERROR_WARNING_TYPE = "WARNING";

export class GraphQLErrorWarning extends GraphQLError {
  constructor(
    message: string
  ) {
    super(
      i18n._(message),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      {
        code: ERROR_WARNING_TYPE,
      }
    );
  }
}

import React, { useState } from "react";
import {
  CHART_ZOOM_MIN_X,
  CHART_ZOOM_MIN_Y,
} from "@feature/chart/chartConstants";
import { ChartControllerComponenet } from "@feature/chart/component/ChartControllerComponent";
import { ChartOptions } from "chart.js";
import {
  DIRECTION_ASCENDING,
  DIRECTION_DESCENDING,
  DIRECTION_STABLE,
} from "@common/model/Direction";
import { RangeMinMax } from "@common/model/Range";
import { Scatter } from "react-chartjs-2";
import { Serie } from "@common/model/Serie";
import {
  convertMergedPhasesToPoints,
  convertOriginalPhasesToPoints,
  convertPhasesToPoints,
  getMaxYByPoints,
  getMinYByPoints,
  getTotalMaxXByPoints,
  roundAxisNumber,
} from "@feature/chart/service/chartSerivice";
import { useStyles } from "@theme/makeStyles";

type Props = {
  serie: Serie;
  rangeFull?: RangeMinMax;
  range?: RangeMinMax;
  minPower?: number;
  maxPower?: number;
}

export const ChartDebugComponent = (props: Props) => {
  const {
    cx,
    theme,
  } = useStyles();

  const originalPoints = props.serie.originalPoints;
  const refiningPoints = props.serie.refiningPoints;

  const refinedPoints = props.serie.refined.map((value, i) => {
    const point = props.serie.originalPoints[i];
    return {
      x: point.x,
      y: value,
    };
  });

  const refinedSpeedPoints = props.serie.refinedSpeed.map((value, i) => {
    const point = props.serie.originalPoints[i];
    return {
      x: point.x,
      y: value,
    };
  });

  const median = props.serie.median.map((median, i) => {
    const point = props.serie.originalPoints[i];
    return {
      x: point.x,
      y: median,
    };
  });

  const density = props.serie.density.map((density, i) => {
    const point = props.serie.originalPoints[i];
    return {
      x: point.x,
      y: density + 600,
    };
  });

  const speed = props.serie.speed.map((speed, i) => {
    const point = props.serie.originalPoints[i];
    return {
      x: point.x,
      y: (speed * 50) + 400,
    };
  });

  const direction = props.serie.directions.map((d, i) => {
    let y = 0; // 0 for DIRECTION_INVALID
    switch (d) {
      case DIRECTION_ASCENDING:
        y = 1000;
        break;
      case DIRECTION_STABLE:
        y = 750;
        break;
      case DIRECTION_DESCENDING:
        y = 400;
        break;
    }
    return {
      x: props.serie.originalPoints[i].x,
      y: y,
    };
  });

  const simplified = props.serie.simplifiedPoints;
  const originalPhases = props.serie.originalPhases;
  const mergedPhases = props.serie.mergedPhases;
  const phases = props.serie.finalPhases;

  const maxTotalX = getTotalMaxXByPoints(originalPoints);
  const minY = getMinYByPoints(originalPoints);
  const maxY = getMaxYByPoints(originalPoints);

  const [
    isAnalyzing,
    setIsAnalyzing,
  ] = useState(false);

  const [
    height,
    setHeight,
  ] = useState(500);

  const options: ChartOptions<"scatter"> = {
    scales: {
      x: { ticks: { callback: roundAxisNumber } },
      y: { ticks: { callback: roundAxisNumber } },
    },
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: { enabled: true },
      zoom: {
        zoom: {
          wheel: { enabled: isAnalyzing },
          pinch: { enabled: isAnalyzing },
          mode: "xy",
        },
        pan: {
          enabled: isAnalyzing,
          mode: "xy",
        },
        limits: {
          x: {
            min: 0,
            max: maxTotalX,
            minRange: CHART_ZOOM_MIN_X,
          },
          y: {
            min: minY,
            max: maxY,
            minRange: CHART_ZOOM_MIN_Y,
          },
        },
      },
      annotation: {
        annotations: {
          rangeMin: {
            display: props.range && props.range.min > 0,
            type: "line",
            yMin: props.range?.min,
            yMax: props.range?.min,
            borderColor: theme.colors.orange,
            borderWidth: 2,
            borderDash: [
              5,
              5,
            ],
          },
          rangeMax: {
            display: props.range && props.range.max > 0,
            type: "line",
            yMin: props.range?.max,
            yMax: props.range?.max,
            borderColor: theme.colors.orange,
            borderWidth: 2,
            borderDash: [
              5,
              5,
            ],
          },
        },
      },
    },
  };

  const data = {
    datasets: [
      ...convertPhasesToPoints(originalPoints, simplified, phases, true),
      {
        label: "Original Points",
        pointRadius: 2,
        backgroundColor: theme.colors.orange,
        borderColor: theme.colors.orange,
        lineTension: 0,
        showLine: false,
        hidden: false,
        data: originalPoints,
      },
      {
        label: "Median",
        pointRadius: 2,
        backgroundColor: theme.colors.cyan,
        borderColor: theme.colors.cyan,
        lineTension: 0,
        showLine: true,
        hidden: true,
        data: median,
      },
      {
        label: "Refining",
        pointRadius: 1,
        backgroundColor: theme.colors.redPressed,
        borderColor: theme.colors.redPressed,
        lineTension: 0.2,
        showLine: true,
        hidden: true,
        data: refiningPoints,
      },
      {
        label: "Density",
        pointRadius: 2,
        backgroundColor: theme.colors.greyLight,
        borderColor: theme.colors.greyLight,
        lineTension: 0,
        showLine: true,
        hidden: true,
        data: density,
      },
      {
        label: "Refined",
        pointRadius: 2,
        backgroundColor: theme.colors.yellow,
        borderColor: theme.colors.yellow,
        lineTension: 0,
        showLine: false,
        hidden: false,
        data: refinedPoints,
      },
      {
        label: "RefinedSpeed",
        pointRadius: 2,
        backgroundColor: theme.colors.redPressed,
        borderColor: theme.colors.redPressed,
        lineTension: 0,
        showLine: true,
        hidden: true,
        data: refinedSpeedPoints,
      },
      {
        label: "Speed",
        pointRadius: 2,
        backgroundColor: theme.colors.redPressed,
        borderColor: theme.colors.redPressed,
        lineTension: 0,
        showLine: true,
        hidden: true,
        data: speed,
      },
      {
        label: "Direction",
        pointRadius: 2,
        backgroundColor: theme.colors.darkPurple,
        borderColor: theme.colors.darkPurple,
        lineTension: 0,
        showLine: true,
        hidden: false,
        data: direction,
      },
      {
        label: "Simplified",
        pointRadius: 2,
        fill: false,
        backgroundColor: theme.colors.neon,
        borderColor: theme.colors.neon,
        lineTension: 0.1,
        showLine: false,
        hidden: true,
        data: simplified,
      },
      ...convertOriginalPhasesToPoints(originalPoints, simplified, originalPhases),
      ...convertMergedPhasesToPoints(originalPoints, simplified, mergedPhases),
    ],
  };

  return <>
    {/*<ReadsPerSecondComponent points={originalPoints} />*/}

    <ChartControllerComponenet
      isEnabled={isAnalyzing}
      isEnabledHandler={setIsAnalyzing}
      height={height}
      heightHandler={setHeight}
    />

    <div style={{ height: `${ height }px` }}>
      <Scatter
        redraw={false}
        data={data}
        options={options} />
    </div>
  </>;
};

import { useState } from "react";

export type ColorPickerColors = "neon" | "yellow" | "orange" | "purple" | "darkPurple" | "cyan";

export const useLogic = (currentColor : ColorPickerColors) => {
  const [
    currentOption,
    setCurrentOption,
  ] = useState(currentColor);
  const [
    dropdownVisible,
    setDropdownVisible,
  ] = useState(false);

  return {
    currentOption: currentOption,
    setCurrentOption: setCurrentOption,
    dropdownVisible: dropdownVisible,
    setDropdownVisible: setDropdownVisible,
  };
};

import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { QuickEditComponent } from "@feature/training/component/quickEditComponent";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";

export const QuickEditPage = () => {
  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.quickEdit.title)}
        backToPath={routes.dashboard.routePath}
        backToTitle={i18n._(routes.dashboard.title)} />
      <IonContent>
        <QuickEditComponent />
      </IonContent>
    </IonPage>
  </>;
};

import {
  Exercise,
  Relation,
} from "@common/type-graphql/generated";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";
import { useRelationListQuery } from "@feature/relation/api/relationApi";

export const useRelation = () => {
  const exerciseListApi = useExerciseListQuery();
  const relationListApi = useRelationListQuery();

  const {
    isApiDataReady,
    endpoints,
  } = useApiDataLogic([
    exerciseListApi,
    relationListApi,
  ]);

  const getExercisesFromRelationsByProtocol = (
    protocolUuid: string
  ): Exercise[] => {
    if (!isApiDataReady) {
      return [];
    }

    const exercises: Exercise[] = [];
    relationListApi.data
      .filter(relation => relation.protocolUuid === protocolUuid)
      .forEach(relation => relation.RelationExercise.forEach(relationExercise => {
        if (exercises.filter(e => e.uuid === relationExercise.exerciseUuid).length === 0) {
          const exercise = exerciseListApi.data.find(exercise => exercise.uuid === relationExercise.exerciseUuid);
          exercises.push(exercise);
        }
      }));
    return exercises;
  };

  const getReleationByProtocolAndExercise = (
    protocolUuid: string,
    exerciseUuid: string
  ): Relation | null => {
    if (!isApiDataReady) {
      return null;
    }

    return relationListApi.data.find(relation =>
      relation.protocolUuid === protocolUuid &&
      Boolean(relation.RelationExercise.find(relationExercise => relationExercise.exerciseUuid === exerciseUuid))
    );
  };

  return {
    endpoints: endpoints,
    getExercisesFromRelationsByProtocol: getExercisesFromRelationsByProtocol,
    getReleationByProtocolAndExercise: getReleationByProtocolAndExercise,
  };
};

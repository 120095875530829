import { CustomerServiceContactComponent } from "@feature/layout/component/CustomerServiceContactComponent";
import { FooterUi } from "@ui/FooterUi/FooterUi";
import { Trans } from "@lingui/macro";
import { getAppVersion } from "@feature/api/service/apiService";
import { makeStyles } from "@theme/makeStyles";

export const FooterComponent = () => {
  const { classes } = styles();

  const appVersion = getAppVersion();

  return <>
    <div className={classes.footerRoot}>
      <FooterUi>
        <CustomerServiceContactComponent />
        <div className={classes.text}>
          <span>© 2024 Bodygon - <Trans>Version</Trans> {appVersion}</span>
        </div>
      </FooterUi>
    </div>
  </>;
};

const styles = makeStyles()(
  theme => (
    {
      footerRoot: {
        width: "100%",
        textAlign: "center",
        paddingBottom: "calc(env(safe-area-inset-bottom, 0)",
      },
      text: {
        marginTop: 8,
        opacity: 1,
        color: theme.colors.grey,
        fontSize: "12px",
        fontWeight: "400",
        fontStyle: "normal",
        letterSpacing: "0px",
        lineHeight: "18px",
        a: {
          color: theme.colors.grey,
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    }
  )
);

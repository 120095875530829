import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import { HistorySerieSwiperViewComponent } from "@feature/history/component/historySerieSwiperViewComponent";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";
import { runPageStyle } from "@feature/run/page/RunPageStyle";

export const HistoryViewPage = () => {
  const { classes } = runPageStyle();

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.historyView.title)}
        backToRoute={routes.historyList} />
      <IonContent>
        <div className={classes.historyPage}>
          <HistorySerieSwiperViewComponent
            showChips={true}
          />
        </div>
      </IonContent>
    </IonPage>
  </>;
};

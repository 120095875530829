import {
  CommentForm,
  CommentFormData,
} from "@feature/comments/component/Form/commentForm";
import { CommentsComponent } from "@feature/comments/component/commentsComponent";
import {
  useHistorySerieCommentCreateMutation,
  useHistorySerieCommentListQuery,
} from "@feature/history/api/historyApi";

type Props = {
  uuid: string;
}

export const HistorySerieCommentComponent = (props: Props) => {
  const historySerieCommentsApi = useHistorySerieCommentListQuery({
    where: { historySerieUuid: { equals: props.uuid } },
    orderBy: [ { dateTime: "asc" } ],
  });

  const [ create ] = useHistorySerieCommentCreateMutation();

  const onSubmit = (formData: CommentFormData) => {
    try {
      const comment = formData.comment;

      create({
        data: {
          comment: comment,
          dateTime: new Date(),
          HistorySerie: { connect: { uuid: props.uuid } },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return <>
    <CommentsComponent apiData={historySerieCommentsApi} />
    <CommentForm onSubmit={onSubmit} />
  </>;
};

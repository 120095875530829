import QRCode from "react-qr-code";
import { APP_BASE_URL } from "@common/commonConstants";
import {
  Account,
  Athlete,
} from "@common/type-graphql/generated";
import { AccountListLinkedToAthleteOutput } from "@common/type-graphql/athlete/output-type/account-list-linked-to-athlete-output";
import { ApiData } from "@feature/api/component/ApiData";
import { Clipboard } from "@capacitor/clipboard";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { Share } from "@capacitor/share";
import {
  Trans,
  t,
} from "@lingui/macro";
import { confirmDestructive } from "@feature/confirm/service/confirmDestructive";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { getUniqueId } from "@util/getUniqueId";
import { toast } from "@feature/toast/slice/toastSlice";
import { typography } from "@theme/typography";
import { useAccountGetQuery } from "@feature/account/api/accountApi";
import {
  useAccountListLinkedToAthleteQuery,
  useAthleteGetQuery,
  useAthleteToggleConnectCodeMutation,
  useAthleteUnshareMutation,
} from "@feature/athlete/api/athleteApi";
import { useAppDispatch } from "@core/redux/store";
import { useIonActionSheet } from "@ionic/react";

type Props = {
  athleteUuid?: string;
}

export const AccountListLinkedToAthleteComponent = (props: Props) => {
  const accountGetApi = useAccountGetQuery();
  const athleteApi = useAthleteGetQuery({ where: { uuid: props.athleteUuid } });
  const accountListLinkedToAthleteApi = useAccountListLinkedToAthleteQuery({ data: { athleteUuid: props.athleteUuid } });

  return <>
    <ApiData
      endpoint={[
        accountGetApi,
        athleteApi,
        accountListLinkedToAthleteApi,
      ]}
    >
      <ChildComponent
        account={accountGetApi.data}
        athlete={athleteApi.data}
        accountListLinkedToAthlete={accountListLinkedToAthleteApi.data}
        {...props}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  account: Account;
  athlete: Athlete;
  accountListLinkedToAthlete: AccountListLinkedToAthleteOutput[];
}
const ChildComponent = (props: PropsChild) => {
  const dispatch = useAppDispatch();
  const [ toggle ] = useAthleteToggleConnectCodeMutation();
  const [ unshare ] = useAthleteUnshareMutation();
  const [ present ] = useIonActionSheet();

  if (props.athlete.ownerUuid !== props.account.uuid) {
    return null;
  }

  const toggleSharing = async() => {
    await toggle({
      data: {
        athleteUuid: props.athleteUuid,
      },
    }).unwrap();
  };

  const getDeepLink = () => {
    return `${ APP_BASE_URL }${ routes.athleteConnect.routePath.replace(getRouteUuidPlaceholder("athlete"), props.athlete.uuid) }`;
  };

  const isShareEnabled = Boolean(props.athlete.connectCode);

  const share = async() => {
    try {
      await Share.share({
        title: t`Bodygon - Athlete share`,
        text: t`${ props.account.firstname } wants to share the Athlete: ${ props.athlete.name } progress with you. Click the link to connect.`,
        url: getDeepLink(),
        dialogTitle: t`Share the Athlete: ${ props.athlete.name }`,
      });
    } catch (e) {
      await Clipboard.write({
        string: getDeepLink(),
      });
      dispatch(toast({
        message: "Share link copied to clipboard",
        type: "success",
      }));
    }
  };

  const unlink = (accountUuid: string) => async() => {
    confirmDestructive({
      header: t`Unlink this Account?`,
      subHeader: t`By unlink this Account can't access anymore the Athlete's progress.`,
      present: present,
      action: async() => {
        await unshare({
          data: {
            athleteUuid: props.athleteUuid,
            externalAccountUuid: accountUuid,
          },
        });
      },
    });
  };

  const accountListLinkedToAthlete = props.accountListLinkedToAthlete.filter(account => account.accountUuid !== props.account.uuid);

  return <>
    <SectionUi rounded>
      <ListItemUi
        title={t`Share this Athlete`}
        description={t`Let your Personal Trainer track your progress`}
        titleTypography={typography.RB24}
        textElement={true}
      />
      <ListItemUi
        title={t`Allow new share`}
        description={t`When enabled, new accounts can be linked`}
        itemOrder={60}
        hasSwitch={true}
        checked={isShareEnabled}
        onClick={toggleSharing}
        hasSeparator={true}
      />
      {
        isShareEnabled &&
      <ListItemUi>
	        <>
	          <SeparatorUi />
		        <div onClick={share}>
			        <h6>
				        <Trans>Connect code:</Trans> <strong>{props.athlete.connectCode}</strong>
			        </h6>
			        <div style={{
	              height: "auto",
	              margin: "0 auto",
	              maxWidth: 200,
	              width: "100%",
	            }}>
				        <QRCode
					        size={256}
					        style={{
	                  height: "auto",
	                  maxWidth: "100%",
	                  width: "100%",
	                }}
					        value={getDeepLink()}
					        viewBox={"0 0 256 256"}
				        />
			        </div>
			        <p>
				        <Trans>Click on the QR code to share the link.</Trans>
			        </p>
		        </div>
	        </>
      </ListItemUi>
      }
      {
        accountListLinkedToAthlete.length > 0 &&
	      <ListItemUi
		      title={t`Shared with the following accounts`}
		      itemOrder={60}
		      hasSeparator={true}
	      >
	        {
	          accountListLinkedToAthlete.map(account =>
	            <ListItemUi
	              key={getUniqueId()}
	              title={`${ account.firstname } ${ account.lastname }`}
	              hasSwitch={true}
	              checked={true}
	              onClick={unlink(account.accountUuid)}
	              hasSeparator={true}
	            />
	          )
	        }
	      </ListItemUi>
      }
    </SectionUi>
  </>;
};

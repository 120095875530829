import { HeaderBackground } from "@types-custom";
import { RunStatus } from "@feature/run/slice/runSlice";
import { useStyles } from "./useStyles";

interface Props {
  feedback: string;
  background: HeaderBackground;
  runStatus: RunStatus;
  isRecoverPhase: boolean;
  feedbackTextColor: string;
  headerTextColor: string;
}

export const FeedbackBoxUi = (props: Props) => {
  const { classes } = useStyles({
    color: props.feedbackTextColor,
    borderColor: props.headerTextColor,
  });

  return <>
    <div className={classes.feedbackBoxRoot}>
      <span className={classes.feedbackSpan}>
        { props.feedback }
      </span>
    </div>
  </>;
};

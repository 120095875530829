import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    formRoot: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
  })
);

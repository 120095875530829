import { t } from "@lingui/macro";

type Props = {
  header?: string;
  subHeader?: string;
  present: ({
    header,
    subHeader,
    buttons,
    onDidDismiss,
  }) => void;
  action?: () => void;
}

export const notify = (props: Props) => {
  props.present({
    header: props.header,
    subHeader: props.subHeader ?? null,
    buttons: [
      {
        text: t`Close`,
        data: { action: "close" },
      },
    ],
    onDidDismiss: async() => {
      if (props.action) {
        props.action();
      }
    },
  });
};

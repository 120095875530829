import React from "react";
import { APP_BASE_URL } from "@common/commonConstants";
import { Capacitor } from "@capacitor/core";
import { FooterComponent } from "@feature/layout/component/FooterComponent";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonButton,
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import { LogoUi } from "@ui/LogoUi/LogoUi";
import { Trans } from "@lingui/macro";
import { historyController } from "@core/redux/store";
import { routes } from "@core/route";
import { useStyles } from "../style";

export const MaintenancePage = () => {
  const { classes } = useStyles();

  const platform = Capacitor.getPlatform();

  const handleRedirect = () => {
    if (platform === "ios") {
      historyController.replace(routes.dashboard.redirectPath);
    } else if (platform === "android") {
      historyController.replace(routes.dashboard.redirectPath);
    } else {
      window.location.href = `${ APP_BASE_URL }?timestamp=${ new Date().getTime() }`;
    }
  };

  return <>
    <IonPage>
      <IonContent>
        <HeaderUi background={"realBlack"} />
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <IonRow className={classes.logoFixed}>
              <LogoUi full />
            </IonRow>
          </div>
          <div className={classes.contentContainer}>
            <h1 style={{ textAlign: "center" }}>
              <Trans>We are currently enhancing the app for a better user experience. Please check back soon.</Trans>
            </h1>
            <br />
            <IonButton onClick={handleRedirect}>
              <Trans>Click here to update the app</Trans>
            </IonButton>
            <FooterComponent />
          </div>
        </div>
      </IonContent>
    </IonPage>
  </>
  ;
};

import gsap from "gsap";
import { GSAP_CUSTOM_EASE_OUTQUINT } from "@feature/gsap/gsapConstants";
import { useClickAway } from "@hook/useClickAway";
import {
  useEffect,
  useRef,
} from "react";

export const useLogic = (props: any) => {
  const $root = useRef(document.createElement("div"));
  const $innerModal = useRef<HTMLDivElement | null>(null);

  // Set initial opacity to ZERO
  useEffect(() => {
    $root.current && gsap.set($root.current, { opacity: 0 });
  }, []);

  useEffect(() => {
    $root.current && gsap.to($root.current, {
      opacity: props.isOpen ? 1 : 0,
      duration: 0.4,
      ease: GSAP_CUSTOM_EASE_OUTQUINT,
      onComplete: () => {
        $root.current && gsap.to($root.current, { pointerEvents: props.isOpen ? "all" : "none" });
      },
    });
    if (!props.isOpen && props.onClose !== undefined) {
      props.onClose();
    }
  }, [ props ]);

  useClickAway($innerModal, () => {
    if (props.closeOnClickOutside) {
      props.onClose();
    }
  });

  return {
    $root: $root,
    $innerModal: $innerModal,
  };
};

import { HeaderBackground } from "@types-custom";
import { makeStyles } from "@theme/makeStyles";

interface Props {
  background: HeaderBackground;
  isAutoStartEnabled: boolean;
  headerTextColor: string;
}

export const useStyles = makeStyles<Props>()(
  (theme, props, classes) => ({
    headerRunPlaceholder: { width: "100%" },
    actionHeaderRoot: {
      zIndex: 100,
      maxHeight: 116,
      marginTop: 40,
      position: "fixed",
      top: "var(--ion-safe-area-top)",
      left: 0,
      right: 0,
    },
    autoButton: {
      color: props.isAutoStartEnabled ? theme.colors.neon : theme.colors.pureWhite,
      ...theme.typography.RB12,
    },
    actionHeader: {
      display: "flex",
      position: "relative",
      zIndex: 2,
      backgroundColor: theme.colors[props.background],
      paddingLeft: 16,
      paddingBottom: 16,
      borderBottomRightRadius: 32,
      borderBottomLeftRadius: 32,
      transition: "background-color 0.3s",
    },
    infoWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "calc(100% - 16px)",
    },
    timerAndFeedback: { display: "flex" },
    valueLabelText: {
      display: "flex",
      flexDirection: "column",
      marginRight: 16,
      color: theme.colors[props.headerTextColor],
      "& p": {
        margin: 0,
        padding: 0,
        textTransform: "uppercase",
        ...theme.typography.RR10,
      },
      "& span": {
        textTransform: "uppercase",
        ...theme.typography.RB12,
      },
    },
    controlsWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 8px",
      width: "100%",
      maxWidth: 66,
      "& button": {
        margin: 0,
        padding: 0,
      },
    },

    actionHeaderMenuRoot: {
      top: 0,
      left: 0,
      zIndex: 1,
      "--y": "116px",
      "--yPercent": "-100%",
      transform: "translateY(calc(var(--yPercent) + var(--y)))",
      width: "100%",
      height: "fit-content",
      position: "fixed",
      overflow: "hidden",
      paddingTop: 116 + 50,
      backgroundColor: theme.colors.darkTint,
      borderBottomLeftRadius: 32,
      borderBottomRightRadius: 32,
      maxHeight: "calc(100vh - (env(safe-area-inset-bottom, 0) + 100px))",
      overflowY: "scroll",
    },
  })
);

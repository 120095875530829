import {
  Callback,
  HeaderBackground,
} from "@types-custom";
import { RunStatus } from "@feature/run/slice/runSlice";
import { parseSeconds } from "@util/parseSeconds";
import { useLogic } from "./useLogic";
import { useStyles } from "./useStyles";

interface Props {
  timerDate: number;
  background: HeaderBackground;
  runStatus: RunStatus;
  isRecoverPhase: boolean;
  isAutoStartEnabled: boolean;
  callback?: Callback;
  recoverAmount?: number;
  headerTextColor: string;
}

export const TimerUi = (props: Props) => {
  const { classes } = useStyles({
    headerTextColor: props.headerTextColor,
  });

  const { seconds } = useLogic({
    targetTime: props.timerDate,
    recoverAmount: props.recoverAmount,
    isRecoverPhase: props.isRecoverPhase,
    isAutoStartEnabled: props.isAutoStartEnabled,
    callback: props.callback,
  });

  return <>
    <div className={classes.timerRoot}>
      <span className={classes.time}>
        {parseSeconds(seconds)}
      </span>
    </div>
  </>;
};

import { ApiData } from "@feature/api/component/ApiData";
import { Athlete } from "@common/type-graphql/generated";
import { AthleteListComponent } from "@feature/athlete/component/athleteListComponent";
import {
  ButtonClick,
  Callback,
} from "@types-custom";
import { ModalListUi } from "@ui/ModalListUi/ModalListUi";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { getUniqueId } from "@util/getUniqueId";
import { t } from "@lingui/macro";
import { useAthleteListQuery } from "@feature/athlete/api/athleteApi";

type Props = {
  isOpen: boolean;
  onClose: ButtonClick;
  onClick: ((athleteUuid: string) => void);
}

export const AthleteChooseModal = (props: Props) => {
  const athleteListApi = useAthleteListQuery();

  return <>
    <ApiData endpoint={athleteListApi}>
      <AthleteChooseModalChild
        athleteList={athleteListApi.data}
        refetch={athleteListApi.refetch}
        {...props}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  athleteList: Athlete[];
  refetch: Callback;
}

const AthleteChooseModalChild = (props: PropsChild) => {
  return <>
    <ModalListUi
      title={t`Choose Athlete`}
      isOpen={props.isOpen}
      onClose={props.onClose}>
      <AthleteListComponent
        data={props.athleteList}
        onClick={props.onClick}
        hideAddButtonClick={true}
        key={getUniqueId()}
      />
      <SeparatorUi marginTop={20} />
      <PrimaryButtonUi
        onClick={() => props.refetch()}
        label={t`Reload`}
      />
    </ModalListUi>
  </>;
};

import {
  ButtonUi,
  PrimaryButtonProps,
} from "@ui/ButtonUi/ButtonUi";

export const PrimaryButtonUi = ({
  label,
  onClick,
  href,
  isLoading = false,
  labelColor = "pureWhite",
  ...props
}: PrimaryButtonProps) => {
  return <>
    <ButtonUi
      disabled={props.disabled}
      href={href}
      appearance="rounded"
      backgroundColor="black"
      fontStyle="RR16"
      hasPressed
      icon=""
      iconColor="grey"
      iconPosition="left"
      label={label}
      labelColor={labelColor}
      onClick={onClick}
      type="primary"
      width="100%"
      isActive={false}
      isLoading={isLoading}
    />
  </>;
};

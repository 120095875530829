// How to use:
// const formData = filterObjectByClass(rawFormData, new FormData());

const filterObjectByClass = <T extends object>(obj: any, classInstance: T): T => {
  const result: T = {} as T;
  for (const key in classInstance) {
    if (key in obj) {
      result[key] = obj[key];
    }
  }
  return result;
};

export default filterObjectByClass;

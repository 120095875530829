import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import { STORE_TIMER_KEY } from "@feature/run/runConstants";
import {
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import {
  runPrepare,
  runUnsetSheetUuid,
} from "@feature/run/slice/runSlice";

type TrainingTimer = {
  training_uuid: string;
  timerDate: number;
}

type InitialStateModelInterface = {
  timerDate: number;
  timersDate: TrainingTimer[];
}

const initialState: InitialStateModelInterface = {
  timerDate: 0,
  timersDate: [],
};

export const timerSlice = createSlice({
  name: STORE_TIMER_KEY,
  initialState: initialState,
  reducers: {
    timerStart: state => {
      state.timerDate = (new Date()).valueOf();
    },
    timerStop: state => {
      state.timerDate = 0;
    },
    timerSetTime: (state, action) => {
      const dateTime = new Date();
      dateTime.setSeconds(dateTime.getSeconds() + action.payload);
      state.timerDate = dateTime.valueOf();
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
    builder
      .addMatcher(
        isAnyOf(
          runUnsetSheetUuid
        ), state => {
          state.timerDate = null;
          state.timersDate = [];
        })
      .addMatcher(
        isAnyOf(
          runPrepare
        ), (state, action) => {
          const dateTime = new Date();
          dateTime.setSeconds(dateTime.getSeconds() + action.payload);
          state.timerDate = dateTime.valueOf();
        });
  },
});

export const {
  timerStart,
  timerStop,
  timerSetTime,
} = timerSlice.actions;

export const selectTimerState = (state: RootState) => state[STORE_TIMER_KEY];

import React, { useEffect } from "react";
import moment from "moment";
import { ButtonInputNumberUi } from "@ui/ButtonInputNumberUi/ButtonInputNumberUi";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import {
  CONFIGURATION_CODE_RECOVERY_TIME_INITIAL,
  CONFIGURATION_CODE_RECOVERY_TIME_MAX,
  CONFIGURATION_CODE_RECOVERY_TIME_MIN,
} from "@common/model/Configuration";
import {
  Configuration,
  RelationConfiguration,
  TrainingConfiguration,
  TrainingConfigurationCreateManyTrainingInput,
} from "@common/type-graphql/generated";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import { UseFormReturn } from "react-hook-form";
import {
  getConfigurationUuidByCode,
  getValueByRelationConfigurations,
} from "@common/service/configurationService";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { useStyles } from "./useStyles";

type Props = {
  configurations: Configuration[];
  configurationUuid: string;
  form: UseFormReturn;
  relationConfigurations: RelationConfiguration[];
}

export const RecoveryTimeFormElement = (props: Props) => {
  const {
    classes,
    theme,
  } = useStyles();

  const recoveryTimeMinConfiguratioUuid = getConfigurationUuidByCode(props.configurations, CONFIGURATION_CODE_RECOVERY_TIME_MIN);
  const recoveryTimeMinValue = parseInt(getValueByRelationConfigurations(props.relationConfigurations, recoveryTimeMinConfiguratioUuid), 10);

  const recoveryTimeMaxConfiguratioUuid = getConfigurationUuidByCode(props.configurations, CONFIGURATION_CODE_RECOVERY_TIME_MAX);
  const recoveryTimeMaxValue = parseInt(getValueByRelationConfigurations(props.relationConfigurations, recoveryTimeMaxConfiguratioUuid), 10);

  const recoveryTimeInitialConfiguratioUuid = getConfigurationUuidByCode(props.configurations, CONFIGURATION_CODE_RECOVERY_TIME_INITIAL);
  const recoveryTimeInitialValue = parseInt(getValueByRelationConfigurations(props.relationConfigurations, recoveryTimeInitialConfiguratioUuid), 10) || 0;

  useEffect(() => {
    if (!getConfigurationFormValue()) {
      updateForm(recoveryTimeInitialValue);
    }
    // eslint-disable-next-line
  }, []);

  const configurationListApi = useConfigurationListQuery();
  if (!configurationListApi.isSuccess) {
    return <></>;
  }
  const configuration = configurationListApi.data.find(configuration => configuration.uuid === props.configurationUuid);

  const getConfigurationFormValue = () => {
    const val = props.form.getValues("TrainingConfiguration")?.find(
      (trainingConfiguration: TrainingConfiguration) =>
        trainingConfiguration.configurationUuid === props.configurationUuid
    )?.value;

    if (!val) {
      return 0;
    }

    return parseInt(val, 10);
  };

  const update = (amount: number, absolute = false): void => {
    let val = Number(getConfigurationFormValue());
    val = val + amount;
    if (val < 0) {
      val = 0;
    }
    if (val > 600) {
      val = 600;
    }

    if (absolute) {
      val = amount;
    }

    updateForm(val);
  };

  const updateForm = (val: number) => {
    const trainingConfigurations = props.form.getValues("TrainingConfiguration") ?? [];
    let created = false;

    trainingConfigurations.forEach(trainingConfiguration => {
      if (trainingConfiguration.configurationUuid === props.configurationUuid) {
        trainingConfiguration.value = val.toString();
        created = true;
      }
    });
    if (!created) {
      const newConfiguration : TrainingConfigurationCreateManyTrainingInput = {
        value: val.toString(),
        configurationUuid: props.configurationUuid,
      };
      trainingConfigurations.push(newConfiguration);
      props.form.setValue("TrainingConfiguration", trainingConfigurations);
    }
    props.form.setValue(`configurations.${ configuration.code }`, val.toString());
  };

  const getHumanTime = (s: string | number) => {
    const duration = moment.duration(s, "seconds");
    const seconds = typeof s === "string" ? parseInt(s, 10) : s;
    return seconds === 0 ?
      "No recover" :
      seconds >= 120 ?
        duration.seconds() === 0 ?
          `${ duration.minutes() } ${ t`minutes` }` :
          `${ duration.minutes() } ${ t`minutes and ` } ${ duration.seconds() } ${ t`seconds` }` :
        seconds >= 60 ?
          duration.seconds() === 0 ?
            `${ duration.minutes() } ${ t`minutes` }` :
            `${ duration.minutes() } ${ t`minutes and ` } ${ duration.seconds() } ${ t`seconds` }` :
          `${ duration.seconds() } ${ t`seconds` }`;
  };

  const isOutOfRange = () => {
    const recoverTime = getConfigurationFormValue();
    return (recoverTime < recoveryTimeMinValue) || (recoverTime > recoveryTimeMaxValue);
  };

  return <>
    <ListItemUi
      title={`${ t`Recovery time` } (${ configuration.unit })` }
      className={classes.root}>
      <div className={classes.controls}>
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"- 30"}
          onClick={() => update(-30)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconPosition="left"
          hasPressed
          label={"- 10"}
          onClick={() => update(-10)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <input
          type="hidden"
          id={configuration.code}
          {...props.form.register(`configurations.${ configuration.code }`, {
            required: true,
            min: 1,
            max: 600,
            value: getConfigurationFormValue(),
          })} />
        <ButtonInputNumberUi
          type={"primary"}
          appearance={"rounded"}
          min={1}
          max={999}
          step={1}
          backgroundColor={"black"}
          labelColor={"pureWhite"}
          initialValue={getConfigurationFormValue()}
          fontStyle={"RM16"}
          onChange={(value: number) => {
            update(value, true);
          }}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"+ 10"}
          onClick={() => update(10)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"+ 30"}
          onClick={() => update(30)}
          type="primary"
          width="50px"
          isActive={false}
        />
      </div>
      <div className={classes.details}>
        <p>
          <Trans>Minimum recovery suggested:</Trans> { getHumanTime(recoveryTimeMinValue) }
        </p>
        <p>
          <Trans>Maximum recovery suggested:</Trans> { getHumanTime(recoveryTimeMaxValue) }
        </p>
        <p>
          <Trans>Current value:</Trans> { getHumanTime(getConfigurationFormValue()) }
        </p>
        {
          isOutOfRange() &&
	        <span style={{ color: theme.colors.orange }}>
	          <Trans>Your recovery time is out of suggested range</Trans>
	        </span>
        }
        {
          !isOutOfRange() &&
		      <span style={{ color: theme.colors.neonPressed }}>
		        <Trans>Your recovery time is on line with suggested range</Trans>
		      </span>
        }
      </div>
    </ListItemUi>
  </>;
};

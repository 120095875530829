import { Storage } from "@ionic/storage";

let store: Storage | null = null;

const getStorage = async(): Promise<Storage> => {
  if (store === null) {
    store = new Storage();
    await store.create();
  }
  return store;
};

export const storageSet = async <T = string>(key: string, value: T): Promise<void> => {
  const storage = await getStorage();
  const valueToStore = typeof value === "string" || typeof value === "number" ? value : JSON.stringify(value);
  await storage.set(key, valueToStore);
};

export const storageGet = async <T = string>(key: string): Promise<T | null> => {
  const storage = await getStorage();
  const value = await storage.get(key);
  if (value === null) {
    return null;
  }
  try {
    if (typeof value === "string" && !((/^\d+$/).test(value))) {
      return JSON.parse(value) as T;
    }
  } catch (error) {
    console.error("Failed to parse the stored value", error);
  }
  return value as T;
};

import hexToRgba from "hex-to-rgba";
import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    modalListRoot: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: "fixed",
      zIndex: 888,
      background: `${ hexToRgba(theme.colors.darkTint, 0.75) }`,
      pointerEvents: "all",
      backdropFilter: "blur(10px)",
    },
    modalList: {
      flex: 1,
      height: "100%",
      bottom: 0,
      left: 0,
      width: "100%",
      position: "absolute",
      zIndex: 2,
      background: theme.colors.pureBlack,
      overflow: "hidden",
    },

    // Content
    modalListContentContainer: {
      width: "100%",
      height: "100%",
      overflow: "auto",
      paddingTop: "calc(50px + var(--ion-safe-area-top))",
      paddingBottom: "var(--ion-safe-area-top)",
    },

    modalListHeader: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: "calc(50px + var(--ion-safe-area-top))",
      zIndex: 99,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "var(--ion-safe-area-top) 24px 0",
      width: "100%",
      background: theme.colors.black,
      userSelect: "none",
      "& button": { margin: 0 },
      borderBottomRightRadius: 32,
      borderBottomLeftRadius: 32,
    },
    modalListHeaderTitle: {
      padding: 0,
      margin: 0,
      ...theme.typography.RB32,
      color: theme.colors.pureWhite,
    },
    modalListCTA: {
      padding: 24,
      width: "100%",
      position: "fixed",
      zIndex: 2,
      bottom: 0,
      left: 0,
      background: theme.colors.darkTint,
      userSelect: "none",
      "& button": { margin: 0 },
      "@supports (padding-bottom: env(safe-area-inset-bottom))": { paddingBottom: "calc(16px + env(safe-area-inset-bottom, 0))" },
    },

  })
);

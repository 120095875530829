import { AthleteForm } from "@feature/athlete/component/form/athleteForm";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";

export const AthleteCreatePage = () => {
  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.athleteCreate.title)}
        backToRoute={routes.athleteList} />
      <IonContent>
        <AthleteForm />
      </IonContent>
    </IonPage>
  </>;
};

import moment from "moment";
import {
  BLUETOOTH_DEVICE_STATUS_CONNECTED,
  BLUETOOTH_DEVICE_STATUS_DISCONNECTED,
  BLUETOOTH_STATUS_IDLE,
  BLUETOOTH_STATUS_SCANNING,
  DEVICE_BODYGON_FLOW_CHARACTERISTIC,
  DEVICE_BODYGON_GET_BATTERY_LEVEL_CHARACTERISTIC,
  DEVICE_BODYGON_SEND_CHARACTERISTIC,
  DEVICE_BODYGON_SERVICE,
  DEVICE_BODYGON_SET_NEW_NAME_CHARACTERISTIC,
  DEVICE_BODYGON_SET_RANGE_CHARACTERISTIC,
  DEVICE_BODYGON_SET_TEMPERATURE_CHARACTERISTIC,
  DEVICE_FAKE_ID,
  DEVICE_LAST_RECEIVED_THRESHOLD,
  DEVICE_NAME_PREFIX,
  DEVICE_NAME_PREFIX_CORE,
  DEVICE_NAME_PREFIX_PT,
  DEVICE_SCAN_TIMEOUT,
  STORE_DEVICE_KEY,
} from "@feature/device/deviceConstants";
import {
  BleClient,
  dataViewToNumbers,
  numbersToDataView,
  textToDataView,
} from "@capacitor-community/bluetooth-le";
import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
  RANGE_EXTRA_TOLLERANCE_FOR_VISIBILITY_MM,
} from "@common/service/constants";
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { Point } from "@common/model/Point";
import { RESET_APP_STATE } from "@core/api";
import { RangeMinMax } from "@common/model/Range";
import {
  RootState,
  store,
} from "@core/redux/store";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { deviceReadsService } from "@feature/device/service/deviceReadsService";
import { deviceStubService } from "@feature/device/service/deviceStubService";

export type BluetoothDeviceStatus = typeof BLUETOOTH_DEVICE_STATUS_CONNECTED | typeof BLUETOOTH_DEVICE_STATUS_DISCONNECTED;
export type BluetoothStatus = typeof BLUETOOTH_STATUS_IDLE | typeof BLUETOOTH_STATUS_SCANNING;

export type BluetoothDevice = {
  id: string;
  name: string;
  rssi: number;
  status: BluetoothDeviceStatus;
  batteryLevel?: number;
}

type InitialStateModelInterface = {
  bluetoothStatus: BluetoothStatus;
  isConnecting: boolean;
  connectedDevice: BluetoothDevice | null;
  availableDevices: BluetoothDevice[];
  lastReceived: number;
}

const initialState: InitialStateModelInterface = {
  bluetoothStatus: BLUETOOTH_STATUS_IDLE,
  isConnecting: false,
  connectedDevice: null,
  availableDevices: [],
  lastReceived: 0,
};

let deviceScanTimeoutId: TimeoutId = null;
const deviceScanTimeout = (ms: number) => {
  return new Promise(resolve => {
    deviceScanTimeoutId = setTimeout(resolve, ms);
  });
};
const deviceScanSleep = async(ms: number, callback: any, ...args: any[]) => {
  await deviceScanTimeout(ms);
  return callback(...args);
};

export const deviceBleScanStart = createAsyncThunk(
  "device/bleScanStart",
  async(arg, getThunkAPI) : Promise<void> => {
    try {
      await BleClient.initialize({ androidNeverForLocation: true });
    } catch (error) {
      console.error("Error during BLE initialization:", error);
    }

    try {
      await BleClient.requestLEScan({ allowDuplicates: true }, scanResult => {
        if (
          scanResult.device.name &&
          (
            scanResult.device.name.includes(DEVICE_NAME_PREFIX_PT) ||
            scanResult.device.name.includes(DEVICE_NAME_PREFIX_CORE) ||
            scanResult.device.name.includes(DEVICE_NAME_PREFIX)
          )
        ) {
          const device: BluetoothDevice = {
            name: scanResult.localName,
            id: scanResult.device.deviceId,
            rssi: scanResult.rssi,
            status: BLUETOOTH_DEVICE_STATUS_DISCONNECTED,
          };
          store.dispatch({
            type: deviceSlice.actions.addBleDevice.type,
            payload: device,
          });
        }
      });
    } catch (error) {
      console.error("Error during BLE scan:", error);
    }

    if (deviceScanTimeoutId) {
      clearTimeout(deviceScanTimeoutId);
    }

    await deviceScanSleep(DEVICE_SCAN_TIMEOUT, () => {
      getThunkAPI.dispatch(deviceBleScanStop());
      deviceScanTimeoutId = null;
    }, []);
  }
);

export const deviceBleScanStop = createAsyncThunk(
  "device/bleScanStop",
  async() => {
    try {
      await BleClient.stopLEScan();
    } catch (error) {
      console.error("Error during BLE stop scan:", error);
    }
  }
);

export const deviceBleConnectByBrowser = createAsyncThunk(
  "device/bleConnectDeviceByBrowser",
  async() => {
    try {
      await BleClient.initialize({ androidNeverForLocation: true });
    } catch (error) {
      console.error("Error during BLE initialization:", error);
    }
    try {
      const bleDevice = await BleClient.requestDevice({
        namePrefix: DEVICE_NAME_PREFIX,
        optionalServices: [ DEVICE_BODYGON_SERVICE ],
      });

      const device: BluetoothDevice = {
        id: bleDevice.deviceId,
        name: bleDevice.name,
        rssi: 0,
        status: BLUETOOTH_DEVICE_STATUS_CONNECTED,
      };

      await BleClient.connect(bleDevice.deviceId, deviceId => deviceOnDisconnect(device));

      return device;
    } catch (error) {
      console.error("Error during BLE request device:", error);
    }

    return null;
  }
);

export const deviceBleConnect = async(device: BluetoothDevice): Promise<boolean> => {
  try {
    const result = await BleClient.connect(device.id, () => deviceOnDisconnect(device));
    store.dispatch({
      type: deviceSlice.actions.deviceConnect.type,
      payload: device,
    });
  } catch (error) {
    console.error("Error during BLE connect:", error);
    return false;
  }
  return true;
};

export const deviceBleSendStart = createAsyncThunk(
  "device/bleSendStart",
  async(arg, getThunkAPI) => {
    const state: any = getThunkAPI.getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      deviceStubService.send(1);
      return;
    }

    try {
      await BleClient.startNotifications(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_FLOW_CHARACTERISTIC,
        dataView => {
          const dataArray = new Uint32Array(dataView.buffer);

          for (let i = 0; i < dataArray.length; i++) {
            const timeMs: number = dataArray[i] >>> 12;
            const distanceMm: number = dataArray[i] & 0xFFF;
            const payload: Point = {
              x: timeMs,
              y: distanceMm,
            };
              // console.info(`Incoming time_ms: ${ timeMs } - distance_mm: ${ distanceMm }`);

            if (timeMs !== 0) {
              deviceReadsService.add(payload);
            }
          }

          const now = Date.now();
          const state: any = getThunkAPI.getState();
          if (moment(now) > moment(state.device.lastReceived).add(DEVICE_LAST_RECEIVED_THRESHOLD, "milliseconds")) {
            store.dispatch(deviceReadsReceived());
          }
        }
      );
    } catch (error) {
      console.error("Error during startNotifications flow BLE:", error);
    }
    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SEND_CHARACTERISTIC,
        numbersToDataView([ 1 ])
      );
    } catch (error) {
      console.error("Error during write send start BLE:", error);
    }
  }
);

export const deviceBleSendStop = createAsyncThunk(
  "device/bleSendStop",
  async(arg, getThunkAPI) => {
    const state: any = getThunkAPI.getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      deviceStubService.send(0);
      return;
    }

    try {
      await BleClient.stopNotifications(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_FLOW_CHARACTERISTIC
      );
    } catch (error) {
      console.error("Error during stopNotifications flow BLE:", error);
    }

    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SEND_CHARACTERISTIC,
        numbersToDataView([ 0 ])
      );
    } catch (error) {
      console.error("Error during write send stop BLE:", error);
    }
  }
);

export const deviceBleSetRange = createAsyncThunk(
  "device/bleSetRange",
  async(
    arg: RangeMinMax,
    getThunkAPI
  ) => {
    const min = Math.max(arg.min - RANGE_EXTRA_TOLLERANCE_FOR_VISIBILITY_MM, DEVICE_RANGE_MIN);
    const max = Math.min(arg.max + RANGE_EXTRA_TOLLERANCE_FOR_VISIBILITY_MM, DEVICE_RANGE_MAX);

    const byte1 = Math.floor(min < 256 ? 0 : min / 256);
    const byte2 = Math.floor((min < 256 ? min : min - (256 * byte1)));

    const byte3 = Math.floor((max < 256 ? 0 : max / 256));
    const byte4 = Math.floor((max < 256 ? max : max - (256 * byte3)));

    const state: any = getThunkAPI.getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      return;
    }

    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SET_RANGE_CHARACTERISTIC,
        numbersToDataView([
          byte1,
          byte2,
          byte3,
          byte4,
        ])
      );
    } catch (error) {
      console.error("Error during write range BLE:", error);
    }
  }
);

export const deviceBleSetTemperature = createAsyncThunk(
  "device/bleSetTemperature",
  async(
    arg: {temperature: number},
    getThunkAPI
  ) => {
    const state: any = getThunkAPI.getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      return;
    }

    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SET_TEMPERATURE_CHARACTERISTIC,
        numbersToDataView([ arg.temperature ])
      );
    } catch (error) {
      console.error("Error during write temperature BLE:", error);
    }
  }
);

export const deviceBleSetBatteryLevel = createAsyncThunk(
  "device/bleSetBatteryLevel",
  async(
    arg: void,
    getThunkAPI
  ) => {
    const state: any = getThunkAPI.getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      return 0;
    }

    try {
      const batteryLevelData = await BleClient.read(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_GET_BATTERY_LEVEL_CHARACTERISTIC
      );
      return dataViewToNumbers(batteryLevelData)[0];
    } catch (error) {
      console.error("Error during read battery level BLE:", error);
    }
    return 0;
  }
);

export const deviceBleSetNewName = createAsyncThunk(
  "device/bleSetNewName",
  async(
    arg: {
      deviceId: string;
      newName: string;
    },
    getThunkAPI
  ) => {
    const state: any = getThunkAPI.getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id !== arg.deviceId) {
      return;
    }

    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SET_NEW_NAME_CHARACTERISTIC,
        textToDataView(arg.newName)
      );
    } catch (error) {
      console.error("Error during write new name BLE:", error);
    }
  }
);

const deviceOnDisconnect = (device: BluetoothDevice): void => {
  store.dispatch({
    type: deviceSlice.actions.deviceDisconnect.type,
    payload: device,
  });
};

export const deviceSlice = createSlice({
  name: STORE_DEVICE_KEY,
  initialState: initialState,
  reducers: {
    resetAvailableDevices: state => {
      state.availableDevices = [];
    },
    addBleDevice: (state, action: PayloadAction<BluetoothDevice>) => {
      if (!state.availableDevices.find(d => d.id === action.payload.id)) {
        state.availableDevices.push(action.payload);
      }
    },
    setIsConnecting: (state, action: PayloadAction<boolean>) => {
      state.isConnecting = action.payload;
    },
    deviceConnect: (state, action: PayloadAction<BluetoothDevice>) => {
      const bleDevice = JSON.parse(JSON.stringify(action.payload));
      if (
        !state.connectedDevice ||
        state.connectedDevice.id !== bleDevice.id
      ) {
        bleDevice.status = BLUETOOTH_DEVICE_STATUS_CONNECTED;
        state.connectedDevice = bleDevice;
      }
      state.availableDevices = [];
    },
    deviceDisconnect: (state, action: PayloadAction<BluetoothDevice>) => {
      const device = action.payload;
      state.connectedDevice = null;

      if (device.id === DEVICE_FAKE_ID) {
        return;
      }
      try {
        BleClient.disconnect(device.id);
      } catch (error) {
        console.error("Error during disconnect BLE:", error);
      }
    },
    deviceDisconnectAll: state => {
      const connectedDevice = state.connectedDevice;
      const deviceId = connectedDevice.id;
      state.connectedDevice = null;
      if (deviceId === DEVICE_FAKE_ID) {
        return;
      }
      try {
        BleClient.disconnect(connectedDevice.id);
      } catch (error) {
        console.error("Error during disconnect BLE:", error);
      }
    },
    deviceReadsReceived: state => {
      state.lastReceived = Date.now();
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
    builder
      .addCase(deviceBleConnectByBrowser.pending, state => {
        state.bluetoothStatus = BLUETOOTH_STATUS_SCANNING;
      })
      .addCase(deviceBleConnectByBrowser.fulfilled, (state, action) => {
        const device = JSON.parse(JSON.stringify(action.payload)) as BluetoothDevice;
        if (!device) {
          state.bluetoothStatus = BLUETOOTH_STATUS_IDLE;
          return;
        }
        device.status = BLUETOOTH_DEVICE_STATUS_CONNECTED;
        state.bluetoothStatus = BLUETOOTH_STATUS_IDLE;
        state.connectedDevice = device;
      })
      .addCase(deviceBleConnectByBrowser.rejected, (state, action) => {
        state.bluetoothStatus = BLUETOOTH_STATUS_IDLE;
      })
      .addMatcher(
        isAnyOf(
          deviceBleScanStop.fulfilled,
          deviceBleScanStart.rejected
        ), (state, action) => {
          clearTimeout(deviceScanTimeoutId);
          state.bluetoothStatus = BLUETOOTH_STATUS_IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          deviceBleScanStart.pending
        ), (state, action) => {
          state.bluetoothStatus = BLUETOOTH_STATUS_SCANNING;
        }
      )
      .addMatcher(
        isAnyOf(
          deviceBleSetNewName.fulfilled
        ), (state, action) => {
          state.connectedDevice = null;
        }
      )
      .addMatcher(
        isAnyOf(
          deviceBleSetBatteryLevel.fulfilled
        ), (state, action) => {
          const connectedDevice = state.connectedDevice;
          if (connectedDevice) {
            connectedDevice.batteryLevel = action.payload;
          }
        }
      );
  },
});

export const {
  resetAvailableDevices,
  setIsConnecting,
  deviceConnect,
  deviceDisconnect,
  deviceDisconnectAll,
  deviceReadsReceived,
} = deviceSlice.actions;

export const selectDeviceState = (state: RootState) => state[STORE_DEVICE_KEY];

import { ToastComponent } from "@feature/toast/component/toastComponent";
import { getToastState } from "../slice/toastSlice";
import { getUniqueId } from "@util/getUniqueId";
import { useAppSelector } from "@core/redux/store";
import { useEffect } from "react";

export const ToastOutlet = () => {
  const toastState = useAppSelector(getToastState);

  useEffect(() => {
    // Update toast pool
  }, [ toastState.pool ]);

  return <>
    {
      toastState.pool.map(toast =>
        <ToastComponent
          key={getUniqueId()}
          toast={toast} />
      )}
  </>;
};

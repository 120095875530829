import { API_CACHE_TAG_PROTOCOL } from "@feature/protocol/protocolConstants";
import { Protocol } from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  protocolListQuery,
  protocolListTransformResponse,
} from "@common/type-graphql/protocol/gql/protocol-list.g";

export const protocolApi = api.injectEndpoints({
  endpoints: builder => ({
    protocolList: builder.query<Protocol[], void>({
      query: () => ({ document: protocolListQuery }),
      transformResponse: protocolListTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_PROTOCOL, result),
    }),
  }),
  overrideExisting: false,
});

export const { useProtocolListQuery } = protocolApi;

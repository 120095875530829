import {
  AutoComplete,
  MultipleChildrens,
} from "@types-custom";
import { FormEventHandler } from "react";
import { useStyles } from "./useStyles";

interface Props {
  children: MultipleChildrens;
  onSubmit: FormEventHandler<HTMLFormElement>;
  autoComplete?: AutoComplete;
}

export const FormUi = ({
  children, onSubmit, autoComplete = "off",
}: Props) => {
  const { classes } = useStyles();
  return <>
    <form
      className={classes.formRoot}
      onSubmit={onSubmit}
      autoComplete={autoComplete}
    >
      {children}
    </form>
  </>;
};

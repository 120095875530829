// FontName + FontWeight + FontSize
// Ordered by size
const ROBOTO = "'Roboto', sans-serif";
const OSWALD_ITALIC = "Oswald Italic";
const LINE_HEIGHT = "1.2em";

export const typography = {
  // Roboto styles
  RR10: {
    fontFamily: ROBOTO,
    fontWeight: 400,
    fontSize: 10,
  },
  RM10: {
    fontFamily: ROBOTO,
    fontWeight: 500,
    fontSize: 10,
  },
  RB12: {
    fontFamily: ROBOTO,
    fontWeight: 700,
    fontSize: 12,
  },
  RM12: {
    fontFamily: ROBOTO,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "1em",
  },
  RM14: {
    fontFamily: ROBOTO,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "1em",
  },
  RR12: {
    fontFamily: ROBOTO,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: LINE_HEIGHT,
  },
  RR14: {
    fontFamily: ROBOTO,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: LINE_HEIGHT,
  },
  RR16: {
    fontFamily: ROBOTO,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: LINE_HEIGHT,
  },
  RM16: {
    fontFamily: ROBOTO,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: LINE_HEIGHT,
  },
  RM18: {
    fontFamily: ROBOTO,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: LINE_HEIGHT,
  },
  RB24: {
    fontFamily: ROBOTO,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: LINE_HEIGHT,
  },
  RB32: {
    fontFamily: ROBOTO,
    fontWeight: 700,
    fontSize: 32,
    lineHeight: LINE_HEIGHT,
  },

  // Oswald Style
  OIB18: {
    fontFamily: OSWALD_ITALIC,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: LINE_HEIGHT,
  },
  OIB20: {
    fontFamily: OSWALD_ITALIC,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: LINE_HEIGHT,
  },
  OIB24: {
    fontFamily: OSWALD_ITALIC,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: LINE_HEIGHT,
  },
  OIB32: {
    fontFamily: OSWALD_ITALIC,
    fontWeight: 700,
    fontSize: 32,
    lineHeight: "32px",
  },
  OIB48: {
    fontFamily: OSWALD_ITALIC,
    fontWeight: 700,
    fontSize: 48,
    lineHeight: "55px",
  },
  OIB64: {
    fontFamily: OSWALD_ITALIC,
    fontWeight: 700,
    fontSize: 64,
    lineHeight: "54px",
  },
};

import useRafState from "./useRafState";
import { isBrowser } from "@util/isBrowser";
import { off } from "@util/offEvent";
import { on } from "@util/onEvent";
import { useEffect } from "react";

const useWindowSize = (initialWidth = Infinity, initialHeight = Infinity) => {
  const [
    state,
    setState,
  ] = useRafState<{ width: number; height: number }>({
    width: isBrowser ? window.innerWidth : initialWidth,
    height: isBrowser ? window.innerHeight : initialHeight,
  });

  // eslint-disable-next-line consistent-return
  useEffect((): (() => void) | void => {
    if (isBrowser) {
      const handler = () => {
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      on(window, "resize", handler);

      return () => {
        off(window, "resize", handler);
      };
    }
  }, [ setState ]);

  return state;
};

export default useWindowSize;

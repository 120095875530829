import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    textAreaRoot: {
      width: "100%",
      padding: 16,
      borderRadius: 16,
      border: "none",
      backgroundColor: theme.colors.input,
      "&::placeholder": {
        ...theme.typography.RR14,
        color: theme.colors.grey,
      },
      "&:focus": { outline: "none" },
      minHeight: 100,
      resize: "none",
      overflow: "auto",
      ...theme.typography.RR14,
      marginTop: 8,
      marginBottom: 8,
      color: theme.colors.pureWhite,
    },
    isInvalid: { outline: `1px solid ${ theme.colors.red }` },
  })
);

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    maskRoot: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    maskCorner: {
      fill: theme.colors.pureBlack,
      display: "block",
      width: 32,
      height: 32,
    },
    rightCorner: { transform: "scaleX(-1)" },
  })
);

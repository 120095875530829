import React, {
  LegacyRef,
  forwardRef,
} from "react";
import { useStyles } from "./useStyles";

interface Props {
  infoTopLeft: string;
  infoTopRight: string;
  infoBottomLeft: string;
  infoBottomRight: string;
}

export const TrainingInfoUi = forwardRef((
  props: Props,
  ref
) => {
  const {
    classes, cx,
  } = useStyles();

  return <>
    <div
      ref={ref as LegacyRef<HTMLDivElement>}
      className={cx({ [classes.trainingInfoRoot]: true })}
    >
      <div>
        <p>
          {props.infoTopLeft} - {props.infoTopRight}
        </p>
      </div>
      <div>
        <p>
          {props.infoBottomLeft} - {props.infoBottomRight}
        </p>
      </div>
    </div>
  </>;
});

import { ActionBarComponent } from "@feature/run/component/ActionBarComponent";
import { ApiData } from "@feature/api/component/ApiData";
import {
  Configuration,
  Power,
  Range,
  Relation,
  Sheet,
  Training,
} from "@common/type-graphql/generated";
import { currentSerie } from "@feature/run/data/currentSerie";
import {
  runRecover,
  selectRunState,
} from "@feature/run/slice/runSlice";
import { selectHistoryState } from "@feature/history/slice/historySlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { usePowerListQuery } from "@feature/power/api/powerApi";
import { useRangeListQuery } from "@feature/range/api/rangeApi";
import { useRelationListQuery } from "@feature/relation/api/relationApi";
import { useRunAutoStop } from "@feature/run/hook/useRunAutoStop";
import { useRunSave } from "@feature/run/hook/useRunSave";
import { useRunStart } from "@feature/run/hook/useRunStart";
import { useRunStop } from "@feature/run/hook/useRunStop";
import { useRunTraining } from "@feature/run/hook/useRunTraining";
import { useSheetGetQuery } from "@feature/sheet/api/sheetApi";
import { useTrainingGetQuery } from "@feature/training/api/trainingApi";

export const RunBottomComponent = () => {
  const runState = useAppSelector(selectRunState);

  const configurationListApi = useConfigurationListQuery();
  const relationListApi = useRelationListQuery();
  const trainingApi = useTrainingGetQuery({ where: { uuid: runState.trainingUuid } }, { skip: !runState.trainingUuid });

  const { runTraining } = useRunTraining();
  const sheetApi = useSheetGetQuery({ where: { uuid: runTraining?.sheetUuid } }, { skip: !runTraining || !runState.trainingUuid || !trainingApi.data || !trainingApi.data.sheetUuid });
  const rangeListApi = useRangeListQuery({ where: { uuid: trainingApi.data?.athleteUuid } }, { skip: !trainingApi.data || !trainingApi.data.athleteUuid });
  const powerListApi = usePowerListQuery({
    where: {
      athleteUuid: trainingApi.data?.athleteUuid,
      exerciseUuid: trainingApi.data?.exerciseUuid,
    },
  }, { skip: !trainingApi.data || !trainingApi.data.athleteUuid || !trainingApi.data.exerciseUuid });

  return <>
    <ApiData endpoint={[
      configurationListApi,
      relationListApi,
      trainingApi,
      sheetApi,
      rangeListApi,
      powerListApi,
    ]}>
      <ActionBar
        configurationList={configurationListApi.data}
        relationList={relationListApi.data}
        training={trainingApi.data}
        sheet={sheetApi.data}
        rangeList={rangeListApi.data}
        powerList={powerListApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  configurationList: Configuration[];
  relationList: Relation[];
  training: Training;
  sheet: Sheet;
  rangeList: Range[];
  powerList: Power[];
}

const ActionBar = (props: PropsChild) => {
  const dispatch = useAppDispatch();

  const runState = useAppSelector(selectRunState);
  const historyState = useAppSelector(selectHistoryState);

  const {
    endpoints: configurationEndpoints,
    getRunConfigurationUsePower,
    getRunConfigurationAutoStopTime,
    getRunConfigurationRecoveryTimeInitial,
    getRunConfigurationLoadWeightCurrent,
    getRunConfigurationPowerReferenceCurrent,
  } = useConfiguration();

  const {
    isApiDataReady,
  } = useApiDataLogic([ ...configurationEndpoints ]);

  const runLoadWeight = getRunConfigurationLoadWeightCurrent();

  const { runStart } = useRunStart();

  const { runStop } = useRunStop();

  const { runSave } = useRunSave();

  const recoverAmount = getRunConfigurationRecoveryTimeInitial();

  const onStart = (wait: number) => () => {
    runStart({
      rangeList: props.rangeList,
      training: props.training,
      wait: wait,
    });
  };

  const stopCallback = (elapsedSeconds?: number) => async() => {
    runStop(Number(recoverAmount - Number(elapsedSeconds)));
    await runSave({
      historyState: historyState,
      configurationList: props.configurationList,
      rangeList: props.rangeList,
      powerList: props.powerList,
      sheet: props.sheet,
      training: props.training,
      loadWeights: runState.loadWeights,
    });
    dispatch(runRecover());
  };

  const { runAutoStop } = useRunAutoStop();
  const autoStopTime = getRunConfigurationAutoStopTime();
  runAutoStop({
    serie: currentSerie,
    seconds: autoStopTime,
    stop: stopCallback,
  });

  if (!isApiDataReady) {
    return null;
  }

  return <>
    <ActionBarComponent
      onStop={stopCallback}
      onStart={onStart}
      athleteUuid={props.training.athleteUuid}
      exerciseUuid={props.training.exerciseUuid}
      loadWeight={runLoadWeight}
      powerReference={getRunConfigurationUsePower() ? getRunConfigurationPowerReferenceCurrent() : undefined}
    />
  </>;
};

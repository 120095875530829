import React, { useEffect } from "react";
import { BLUETOOTH_STATUS_SCANNING } from "@feature/device/deviceConstants";
import {
  BluetoothDevice,
  deviceBleConnect,
  deviceBleConnectByBrowser,
  deviceBleScanStart,
  deviceBleScanStop,
  deviceBleSetBatteryLevel,
  deviceDisconnect,
  resetAvailableDevices,
  selectDeviceState,
  setIsConnecting,
} from "@feature/device/slice/deviceSlice";
import { Capacitor } from "@capacitor/core";
import {
  IonCol,
  IonIcon,
  IonRow,
  IonSpinner,
  useIonActionSheet,
} from "@ionic/react";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import {
  batteryDead,
  batteryFull,
  radioButtonOff,
  radioButtonOn,
} from "ionicons/icons";
import { confirm } from "@feature/confirm/service/confirm";
import { getUniqueId } from "@util/getUniqueId";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";

export const DeviceListComponent = () => {
  const dispatch = useAppDispatch();

  const deviceState = useAppSelector(selectDeviceState);
  const [ present ] = useIonActionSheet();

  const scanStart = async() => {
    if (deviceState.bluetoothStatus === BLUETOOTH_STATUS_SCANNING) {
      return;
    }

    dispatch(resetAvailableDevices());
    switch (Capacitor.getPlatform()) {
      case "web":
        if (deviceState.isConnecting) {
          return;
        }
        dispatch(setIsConnecting(true));
        await dispatch(deviceBleConnectByBrowser());
        dispatch(setIsConnecting(false));
        dispatch(deviceBleSetBatteryLevel());
        break;
      default:
        await dispatch(deviceBleScanStart());
        break;
    }
  };

  const scanStop = () => {
    dispatch(deviceBleScanStop());
  };

  const clickDisconnect = (device: BluetoothDevice) => () => {
    confirm({
      header: t`Do you want to disconnect the device?`,
      present: present,
      action: async() => {
        dispatch(deviceDisconnect(device));
      },
    });
  };

  const clickConnect = (device: BluetoothDevice) => async() => {
    if (deviceState.isConnecting) {
      return;
    }
    scanStop();
    dispatch(setIsConnecting(true));
    const isConected = await deviceBleConnect(device);
    dispatch(resetAvailableDevices());
    dispatch(setIsConnecting(false));
    if (isConected) {
      dispatch(deviceBleSetBatteryLevel());
    }
  };

  // Auto start scan
  useEffect(() => {
    if (
      !deviceState.isConnecting &&
      !deviceState.connectedDevice
    ) {
      scanStart();
    }
    // eslint-disable-next-line
  }, [ ]);

  useEffect(() => {
    // Redraw the component when the connected device changes and the available devices change
  }, [
    deviceState.availableDevices,
    deviceState.connectedDevice,
  ]);

  const connectedDevice = deviceState.connectedDevice;

  return <>
    {
      deviceState.availableDevices &&
      <p style={{ margin: 20 }}>
        <Trans>Open the device and check that the transmitter is flashing blue, a sign that it is ready to connect.</Trans>
      </p>
    }

    {
      connectedDevice &&
      <div style={{ margin: 20 }}>
        <IonRow>
          <IonCol size="8">
	          <h3>
              {connectedDevice.name} <small>#{connectedDevice.id.slice(-4)}</small>
	          </h3>
          </IonCol>
	        <IonCol size="4" class="ion-align-items-end ion-justify-content-center ion-text-right ion-padding-vertical">
            {
              connectedDevice.batteryLevel &&
			        <span>
			          { connectedDevice.batteryLevel }%
			          {
			            connectedDevice.batteryLevel >= 40 &&
                  <IonIcon icon={batteryFull} slot="end" />
			          }
			          {
			            connectedDevice.batteryLevel < 40 &&
                  <IonIcon icon={batteryDead} slot="end" />
			          }
			        </span>
            }
          </IonCol>
        </IonRow>
        {/*<DeviceRenameForm deviceId={connectedDevice.id} />*/}

        <PrimaryDestructiveButtonUi
          onClick={clickDisconnect(connectedDevice)}
          label={t`Disconnect`}
        />
      </div>
    }
    {
      deviceState.availableDevices.length > 0 &&
      <>
        {
          deviceState.availableDevices.map(availableDevice => (
            <div style={{ margin: 20 }} key={getUniqueId()}>
              <IonRow>
                <IonCol size="8">
                  <h3>
                    {availableDevice.name} <small>#{availableDevice.id.slice(-4)}</small>
                  </h3>
                </IonCol>
                <IonCol
                  size="4"
                  class="ion-align-items-end ion-justify-content-center ion-text-right ion-padding-vertical">
                  <span>
                    {
                      availableDevice.rssi > -60 &&
	                    <span>
	                      <IonIcon icon={radioButtonOn} slot="end" />
	                      <IonIcon icon={radioButtonOn} slot="end" />
	                      <IonIcon icon={radioButtonOn} slot="end" />
	                    </span>
                    }
                    {
                      availableDevice.rssi > -70 &&
                      availableDevice.rssi <= -60 &&
		                  <span>
	                      <IonIcon icon={radioButtonOn} slot="end" />
	                      <IonIcon icon={radioButtonOn} slot="end" />
	                      <IonIcon icon={radioButtonOff} slot="end" />
	                    </span>
                    }
                    {
                      availableDevice.rssi < -70 &&
		                  <span>
		                    <IonIcon icon={radioButtonOn} slot="end" />
		                    <IonIcon icon={radioButtonOff} slot="end" />
		                    <IonIcon icon={radioButtonOff} slot="end" />
		                  </span>
                    }
                  </span>
                </IonCol>
              </IonRow>

              {
                deviceState.isConnecting &&
	              <PrimaryPositiveButtonUi
		              disabled={true}
		              label={t`Connecting...`}
	              />
              }

              {
                !deviceState.isConnecting &&
                <PrimaryPositiveButtonUi
	                onClick={clickConnect(availableDevice)}
                  label={t`Connect`}
                />
              }
            </div>
          ))
        }
      </>
    }

    {
      deviceState.bluetoothStatus === BLUETOOTH_STATUS_SCANNING &&
		  <div style={{
		    textAlign: "center",
		    marginTop: 20,
		  }}>
			  <IonSpinner onClick={scanStop} />
		  </div>
    }

    {
      !deviceState.connectedDevice &&
      deviceState.bluetoothStatus !== BLUETOOTH_STATUS_SCANNING &&
      !deviceState.isConnecting &&
		  <div style={{ margin: 20 }}>
		    {
		      deviceState.availableDevices.length === 0 &&
				  <p>
					  <Trans>No devices found.</Trans>
				  </p>
		    }
			  <PrimaryButtonUi
				  label={t`Start scan`}
				  onClick={scanStart}
				  disabled={deviceState.isConnecting}
			  />
		  </div>
    }
  </>;
};

import {
  deviceConnect,
  selectDeviceState,
} from "@feature/device/slice/deviceSlice";
import { selectDeviceAutoConnectState } from "@feature/device/slice/deviceAutoConnectSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useEffect } from "react";

export const DeviceAutoConnectOutlet = () => {
  const dispatch = useAppDispatch();

  const bluetoothAutoConnectState = useAppSelector(selectDeviceAutoConnectState);
  const deviceState = useAppSelector(selectDeviceState);

  useEffect(() => {
    if (deviceState.connectedDevice) {
      return;
    }

    const autoConnectDevice = bluetoothAutoConnectState.autoConnectDevice;

    if (!autoConnectDevice) {
      return;
    }

    dispatch(deviceConnect(autoConnectDevice));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  return null;
};

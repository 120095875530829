import { API_CACHE_TAG_PROFILE } from "@feature/profile/profileConstants";
import {
  CreateOneProfileArgs,
  FindUniqueAthleteArgs,
  FindUniqueProfileArgs,
  Profile,
  UpdateOneAthleteArgs,
} from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  createOneProfileQuery,
  createOneProfileTransformResponse,
} from "@common/type-graphql/profile/gql/profile-create.g";
import {
  profileListQuery,
  profileListTransformResponse,
} from "@common/type-graphql/profile/gql/profile-list.g";
import {
  profileQuery,
  profileTransformResponse,
} from "@common/type-graphql/profile/gql/profile-get.g";
import {
  updateOneProfileQuery,
  updateOneProfileTransformResponse,
} from "@common/type-graphql/profile/gql/profile-update.g";

export const profileApi = api.injectEndpoints({
  endpoints: builder => ({
    profileList: builder.query<Profile[], FindUniqueAthleteArgs>({
      query: variables => ({
        document: profileListQuery,
        variables: variables,
      }),
      transformResponse: profileListTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_PROFILE, result),
    }),
    profileGet: builder.query<Profile, FindUniqueProfileArgs>({
      query: variables => ({
        document: profileQuery,
        variables: variables,
      }),
      transformResponse: profileTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_PROFILE, result),
    }),
    profileCreate: builder.mutation<Profile, CreateOneProfileArgs>({
      query: variables => ({
        document: createOneProfileQuery,
        variables: variables,
      }),
      transformResponse: createOneProfileTransformResponse,
      invalidatesTags: () => getTags(API_CACHE_TAG_PROFILE),
    }),
    profileEdit: builder.mutation<Profile, UpdateOneAthleteArgs>({
      query: variables => ({
        document: updateOneProfileQuery,
        variables: variables,
      }),
      transformResponse: updateOneProfileTransformResponse,
      invalidatesTags: (result, error, input) => getTags(API_CACHE_TAG_PROFILE, result),
    }),
  }),
  overrideExisting: false,
});

export const {
  useProfileListQuery,
  useProfileGetQuery,
  useProfileCreateMutation,
  useProfileEditMutation,
} = profileApi;

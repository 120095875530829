import { CardUi } from "@ui/CardUi/CardUi";
import { FooterComponent } from "@feature/layout/component/FooterComponent";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { LINK_SHOP } from "@feature/account/accountConstants";
import { PrewarmComponent } from "@feature/prewarm/component/prewarmComponent";
import { historyController } from "@core/redux/store";
import { routes } from "@core/route";
import { t } from "@lingui/macro";

export const DashboardPage = () => {
  return <>
    <IonPage>
      <HeaderUi pageTitle={t`Bodygon`} navigatorHide={true} />
      <IonContent>
        <PrewarmComponent />
        <CardUi
          background={"darkPurple"}
          description={t`Do not lose your time, start your workout now!`}
          imageSrc={"/assets/dashboard/dashboard-3.webp"}
          title={t`Quick start`}
          onButtonClick={() => {
            historyController.replace(routes.quickEdit.redirectPath);
          }}
        />
        <CardUi
          background={"cyan"}
          description={t`Manage your training sheets`}
          imageSrc={"/assets/dashboard/dashboard-1.webp"}
          imagePosition="left"
          title={t`Sheets`}
          onButtonClick={() => {
            historyController.replace(routes.sheetList.redirectPath);
          }}
        />
        <CardUi
          background={"orange"}
          description={t`Manage your athletes`}
          imageSrc={"/assets/dashboard/dashboard-2.webp"}
          title={t`Athletes`}
          onButtonClick={() => historyController.replace(routes.athleteList.redirectPath)}
        />
        <CardUi
          background={"yellow"}
          description={t`Checkout your training histories`}
          imagePosition="left"
          imageSrc={"/assets/dashboard/dashboard-graph.webp"}
          title={t`History`}
          onButtonClick={() => historyController.replace(routes.historyList.redirectPath)}
        />
        <CardUi
          background={"neon"}
          description={t`Buy now the Bodygon device!`}
          imageSrc={"/assets/dashboard/buy_now.webp"}
          imagePosition={"right"}
          title={t`Bodygon`}
          onButtonClick={() => {
            window.open(LINK_SHOP);
          }}
        />
        {/*<SectionUi title="Quick Links" rounded>*/}
        {/*  <ListItemUi title={t`Account`} href={ROUTES.settings.PATH} />*/}
        {/*  <ListItemUi title={t`Help`} href={ROUTES.settings.PATH} />*/}
        {/*</SectionUi>*/}
        <FooterComponent />
      </IonContent>
    </IonPage>
  </>;
};

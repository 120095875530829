import { ConfigurationForm } from "@feature/training/component/form/configurationForm";
import { UseFormReturn } from "react-hook-form";
import { getUniqueId } from "@util/getUniqueId";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useRelation } from "@feature/relation/hook/useRelation";

type Props = {
  protocolUuid: string;
  exerciseUuid: string;
  form: UseFormReturn;
}

export const ConfigurationsForm = (props: Props) => {
  const {
    endpoints: relationsEndpoints,
    getReleationByProtocolAndExercise,
  } = useRelation();

  const {
    isApiDataReady,
  } = useApiDataLogic([ ...relationsEndpoints ]);

  if (!isApiDataReady) {
    return null;
  }

  const relation = getReleationByProtocolAndExercise(props.protocolUuid, props.exerciseUuid);

  const configurations = relation.RelationConfiguration.slice().sort(
    (c1, c2) => (c1.position ?? 0) - (c2.position ?? 0)
  );

  return <>
    {
      configurations.map(configuration =>
        <ConfigurationForm
          key={getUniqueId()}
          configurationUuid={configuration.configurationUuid}
          configurations={configurations}
          protocolUuid={props.protocolUuid}
          exerciseUuid={props.exerciseUuid}
          form={props.form} />
      )}
  </>;
};

import React, {
  useEffect,
  useRef,
} from "react";
import gsap from "gsap";
import {
  ButtonClick,
  Callback,
} from "@types-custom";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { CueComponent } from "@feature/run/component/CueComponent";
import { GSAP_CUSTOM_EASE_OUTQUINT } from "@feature/gsap/gsapConstants";
import { NotConnectedControlsUi } from "@ui/NotConnectedControlsUi/NotConnectedControlsUi";
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import {
  RUN_STATUS_PREPARING,
  RUN_STATUS_READY,
  RUN_STATUS_RESTING,
  RUN_STATUS_RUNNING,
  RunStatus,
} from "@feature/run/slice/runSlice";
import { ReferencePowerComponent } from "@feature/power/components/ReferencePowerComponent";
import { RunLoadWeightComponent } from "@feature/run/component/RunLoadWeightComponent";
import { TrainingSummaryComponent } from "@feature/training/component/trainingSummaryComponent";
import { makeStyles } from "@theme/makeStyles";
import { t } from "@lingui/macro";
import { useAppDispatch } from "@core/redux/store";
import { usePrevious } from "@hook/usePrevious";

type Props = {
  phase: RunStatus;
  isConnected: boolean;
  onStartClick?: ButtonClick;
  onPlusFiveClick?: Callback;
  onPlusTenClick?: Callback;
  onStopClick?: ButtonClick;
  onPrevClick?: ButtonClick;
  onNextClick?: ButtonClick;
  onConnectClick?: ButtonClick;
  athleteUuid: string;
  exerciseUuid: string;
  loadWeight: number;
  powerReference?: PowerReferenceValue;
}

export const RunActionsBarComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const { classes } = style();

  const $notConnectedControls = useRef<HTMLDivElement | null>(null);
  const $beforeStartControls = useRef<HTMLDivElement | null>(null);
  const $recoverControls = useRef<HTMLDivElement | null>(null);
  const $startControls = useRef<HTMLDivElement | null>(null);

  type Controller =
    | typeof $notConnectedControls.current
    | typeof $beforeStartControls.current
    | typeof $recoverControls.current
    | typeof $startControls.current

  const previousPhase = usePrevious<typeof props.phase>(props.phase === undefined ? null : props.phase as RunStatus);

  // Animate controllers based on current WorkutPhase
  useEffect(() => {
    const animateController = <T extends RunStatus>(
      currentPhase: T,
      controller: Controller,
      phase: T
    ) => {
      if (!controller) {
        return;
      }
      if (previousPhase === undefined) {
        return;
      }

      // Constants
      const delay = previousPhase !== phase ? 0.2 : 0;
      const ease = GSAP_CUSTOM_EASE_OUTQUINT;
      const duration = 0.4;

      if (currentPhase === phase) {
        gsap.to(controller, {
          y: 0,
          delay: delay,
          duration: duration,
          ease: ease,
        });
      } else if (previousPhase === phase) {
        gsap.to(controller, {
          y: -50,
          delay: delay,
          duration: duration,
          ease: ease,
          onComplete: () => {
            if (controller) {
              gsap.set(controller, { y: 50 });
            }
          },
        });
      }
    };

    let currentPhase: RunStatus = props.phase;
    if (
      previousPhase === RUN_STATUS_READY &&
      currentPhase === RUN_STATUS_PREPARING
    ) {
      currentPhase = RUN_STATUS_RUNNING;
    }

    // Animated Before Start Controls
    animateController(currentPhase, $notConnectedControls.current, null);
    animateController(currentPhase, $beforeStartControls.current, RUN_STATUS_READY);
    animateController(currentPhase, $startControls.current, RUN_STATUS_RUNNING);
    animateController(currentPhase, $recoverControls.current, RUN_STATUS_RESTING);
  }, [
    previousPhase,
    props.phase,
  ]);

  return <>
    <CueComponent />

    <div className={classes.workoutActionBarRoot}>
      <div className={classes.topControlsWrapper}>
        {
          (
            Boolean(props.onPrevClick) ||
            Boolean(props.onNextClick)
          ) &&
				  <ButtonUi
					  className={classes.prev}
					  width="40px"
					  type="link"
					  labelColor="black"
					  backgroundColor="black"
					  icon="expand-left-double"
					  iconColor="orange"
					  iconPosition="left"
					  fontStyle="OIB24"
					  appearance="rounded"
					  hasToggle={false}
					  hasPressed={true}
					  onClick={props.onPrevClick}
					  formType={"button"}
					  isLoading={false}
					  disabled={props.phase === RUN_STATUS_RUNNING || !Boolean(props.onPrevClick)}
				  />
        }
        <div className={classes.info}>
          <TrainingSummaryComponent />
        </div>
        {
          (
            Boolean(props.onPrevClick) ||
            Boolean(props.onNextClick)
          ) &&
				  <ButtonUi
					  className={classes.next}
					  width="40px"
					  type="link"
					  labelColor="black"
					  backgroundColor="black"
					  icon="expand-right-double"
					  iconColor="orange"
					  iconPosition="left"
					  fontStyle="OIB24"
					  appearance="rounded"
					  hasToggle={false}
					  hasPressed={true}
					  onClick={props.onNextClick}
					  formType={"button"}
					  isLoading={false}
					  disabled={props.phase === RUN_STATUS_RUNNING || props.phase === RUN_STATUS_PREPARING || !Boolean(props.onNextClick)}
				  />
        }
      </div>

      <div className={classes.bottomControlsWrapper}>
        {
          props.isConnected &&
				  <div>
				    {
				      props.powerReference !== undefined &&
						  <ReferencePowerComponent
							  athleteUuid={props.athleteUuid}
							  exerciseUuid={props.exerciseUuid}
							  powerReference={props.powerReference}
							  loadWeight={props.loadWeight}
							  isEnabled={props.phase !== RUN_STATUS_RUNNING && props.phase !== RUN_STATUS_PREPARING}
						  />
				    }
				    {
				      props.powerReference === undefined &&
						  <ButtonUi
							  label="5 sec"
							  width="70px"
							  type="primary"
							  labelColor="neon"
							  backgroundColor="black"
							  iconPosition="left"
							  fontStyle="OIB20"
							  appearance="rounded"
							  hasToggle={false}
							  hasPressed={true}
							  onClick={props.onPlusFiveClick}
							  formType={"button"}
							  isLoading={false}
							  disabled={props.phase === RUN_STATUS_RUNNING || props.phase === RUN_STATUS_PREPARING}
						  />
				    }
					  <ButtonUi
						  label={t`START`}
						  width="100%"
						  type="primary"
						  labelColor="black"
						  backgroundColor="neon"
						  iconPosition="left"
						  fontStyle="OIB20"
						  appearance="rounded"
						  hasToggle={false}
						  hasPressed={true}
						  onClick={props.onStartClick}
						  onLongPress={props.onPlusTenClick}
						  formType={"button"}
						  isLoading={false}
						  disabled={props.phase === RUN_STATUS_RUNNING || props.phase === RUN_STATUS_PREPARING}
					  />
					  <ButtonUi
						  label={t`STOP`}
						  width="100%"
						  type="primary"
						  labelColor="black"
						  backgroundColor="red"
						  iconPosition="left"
						  fontStyle="OIB20"
						  appearance="rounded"
						  hasToggle={false}
						  hasPressed={true}
						  onClick={props.onStopClick}
						  formType={"button"}
						  isLoading={false}
						  disabled={props.phase !== RUN_STATUS_RUNNING && props.phase !== RUN_STATUS_PREPARING}
					  />
					  <RunLoadWeightComponent />
				  </div>
        }
        {
          !props.isConnected &&
				  <NotConnectedControlsUi
					  className={classes.notConnectedControls}
					  onConnectClick={props.onConnectClick}
				    // ref={$notConnectedControls}
				  />
        }
      </div>
    </div>
  </>;
};

const style = makeStyles()(
  (theme, props, classes) => ({
    workoutActionBarRoot: {
      height: `calc(var(--ion-safe-area-bottom) + ${ theme.runFooterHeight }px)`,
      left: 0,
      bottom: 0,
      zIndex: 100,
      padding: 24,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      position: "fixed",
      backgroundColor: theme.colors.darkTint,
      borderTopLeftRadius: 32,
      borderTopRightRadius: 32,
      "@supports (padding-bottom: env(safe-area-inset-bottom))": { padding: "16px 24px calc(16px + env(safe-area-inset-bottom, 0)) 24px" },
      gap: 8,
      "& button": { margin: 0 },
    },
    developmentButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 24,
    },
    bottomControlsWrapper: {
      height: 50,
      width: "100%",
      overflow: "hidden",
      position: "relative",
      zIndex: 5,
      display: "flex",
      "& > div": {
        width: "100%",
        display: "flex",
        gap: 8,
        "& button": { margin: 0 },
      },
    },
    topControlsWrapper: {
      height: 50,
      width: "100%",
      maxWidth: "100%",
      overflow: "hidden",
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      zIndex: 9,
    },
    prev: { display: "flex" },
    next: { display: "flex" },
    info: {
      display: "flex",
      flexGrow: 1,
      flex: "flex: 1 0 50%;",
      justifyContent: "space-between",
      flexDirection: "column",
      overflow: "hidden",
    },
    notConnectedControls: {
      zIndex: 40,
      backgroundColor: theme.colors.darkTint,
    },
    beforeStartControls: {
      zIndex: 30,
      // transform: "translateY(50px)",
      backgroundColor: theme.colors.darkTint,
    },
    onStartControls: {
      zIndex: 20,
      // transform: "translateY(50px)",
      backgroundColor: theme.colors.darkTint,
    },
    onRecoverControls: {
      zIndex: 10,
      // transform: "translateY(50px)",
      backgroundColor: theme.colors.darkTint,
    },
  })
);

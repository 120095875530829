import {
  PayloadAction,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import {
  STORE_HISTORY_KEY,
  UUID_NAME_HISTORY_TRAINING_SESSION,
  UUID_NAME_HISTORY_WORKOUT,
} from "@feature/history/historyConstants";
import { generateUuid } from "@util/generateUuid";
import {
  runSetSheetUuid,
  runUnsetSheetUuid,
} from "@feature/run/slice/runSlice";

export interface HistoryWorkoutData {
  historyTrainingSessionUuid: string;
  profileUuid: string;
  protocolUuid: string;
  exerciseUuid: string;
}

export type HistoryStateModel = {
  historyTrainingSessionListPage: string | undefined;
  historyTrainingSessionListOpened: string[];
  historyTrainingSessionUuid: string;
  historyWorkoutUuid: string;
  historySerieUuid: string;
  historyLastWorkoutData: HistoryWorkoutData;
}

const initialState: HistoryStateModel = {
  historyTrainingSessionListPage: undefined,
  historyTrainingSessionListOpened: [],
  historyTrainingSessionUuid: null,
  historyWorkoutUuid: null,
  historySerieUuid: null,
  historyLastWorkoutData: null,
};

export const historySlice = createSlice({
  name: STORE_HISTORY_KEY,
  initialState: initialState,
  reducers: {
    toggleHistoryTrainingSessionListOpened: (state, action: PayloadAction<string | undefined>) => {
      const opened = state.historyTrainingSessionListOpened || [];
      const index = opened.indexOf(action.payload);
      if (index !== -1) {
        opened.splice(index, 1);
      } else {
        opened.push(action.payload);
      }
      state.historyTrainingSessionListOpened = opened;
    },
    setHistoryTrainingSessionListPage: (state, action: PayloadAction<string | undefined>) => {
      state.historyTrainingSessionListPage = action.payload;
    },
    setHistoryTrainingSessionUuid: (state, action: PayloadAction<string>) => {
      state.historyTrainingSessionUuid = action.payload;
      state.historyWorkoutUuid = null;
      state.historySerieUuid = null;
    },
    setHistoryWorkoutUuid: (state, action: PayloadAction<string>) => {
      state.historyWorkoutUuid = action.payload;
      state.historySerieUuid = null;
    },
    setHistorySerieUuid: (state, action: PayloadAction<string>) => {
      state.historySerieUuid = action.payload;
    },
    setHistoryLastWorkoutData: (state, action: PayloadAction<HistoryWorkoutData>) => {
      state.historyLastWorkoutData = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
    builder
      .addMatcher(
        isAnyOf(
          runSetSheetUuid
        ), state => {
          const historyTrainingSessionUuid = generateUuid(UUID_NAME_HISTORY_TRAINING_SESSION, true);
          const historyWorkoutUuid = generateUuid(UUID_NAME_HISTORY_WORKOUT, true);
          state.historyTrainingSessionUuid = historyTrainingSessionUuid;
          state.historyWorkoutUuid = historyWorkoutUuid;
          state.historySerieUuid = null;
        })
      .addMatcher(
        isAnyOf(
          runUnsetSheetUuid
        ), (state, action) => {
          state.historyTrainingSessionUuid = null;
          state.historyWorkoutUuid = null;
          state.historySerieUuid = null;
        });
  },
});

export const {
  toggleHistoryTrainingSessionListOpened,
  setHistoryTrainingSessionListPage,
  setHistoryTrainingSessionUuid,
  setHistoryWorkoutUuid,
  setHistorySerieUuid,
  setHistoryLastWorkoutData,
} = historySlice.actions;

export const selectHistoryState = (state: RootState) => state[STORE_HISTORY_KEY];

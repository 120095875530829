import { ApiData } from "@feature/api/component/ApiData";
import { ButtonInputNumberUi } from "@ui/ButtonInputNumberUi/ButtonInputNumberUi";
import {
  RUN_STATUS_READY,
  RUN_STATUS_RESTING,
  runSetLoadWeight,
  selectRunState,
} from "@feature/run/slice/runSlice";
import { Training } from "@common/type-graphql/generated";
import { makeStyles } from "@theme/makeStyles";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useTrainingGetQuery } from "@feature/training/api/trainingApi";

export const RunLoadWeightComponent = () => {
  const runState = useAppSelector(selectRunState);

  const trainingApi = useTrainingGetQuery({ where: { uuid: runState.trainingUuid } }, { skip: !runState.trainingUuid });

  return <>
    <ApiData endpoint={trainingApi}>
      <LoadWeightComponentChild
        training={trainingApi.data}
        enabled={runState.runStatus === RUN_STATUS_RESTING || runState.runStatus === RUN_STATUS_READY}
      />
    </ApiData>
  </>;
};

type ChildProps = {
  training: Training;
  enabled: boolean;
}

const LoadWeightComponentChild = (props: ChildProps) => {
  const dispatch = useAppDispatch();

  const { classes } = runBottomComponentStyle();

  const {
    endpoints: configurationEndpoints,
    getRunConfigurationLoadWeightCurrent,
  } = useConfiguration();

  const {
    isApiDataReady,
  } = useApiDataLogic([ ...configurationEndpoints ]);

  const runLoadWeight = getRunConfigurationLoadWeightCurrent();

  const setNewLoadWeight = (value: any) => {
    dispatch(runSetLoadWeight({
      trainingUuid: props.training.uuid,
      value: parseFloat(value),
    }));
  };

  if (!isApiDataReady) {
    return <></>;
  }

  return <>
    <div className={classes.loadWeightInputContainer}>
      <ButtonInputNumberUi
        type={"primary"}
        appearance={"rounded"}
        disabled={!props.enabled}
        min={1}
        max={999}
        step={0.5}
        backgroundColor={"black"}
        labelColor={"neon"}
        initialValue={runLoadWeight}
        fontStyle={"OIB18"}
        onChange={setNewLoadWeight}
        labelPost={" Kg"}
      />
    </div>
  </>;
};

const runBottomComponentStyle = makeStyles()(
  (theme, props, classes) => ({
    loadWeightInputContainer: {
      borderRadius: 16,
      flex: "1 0 75px",
      "& > button": { width: "100%" },
      "& input": {
        backgroundColor: theme.colors.black,
        borderRadius: 16,
        color: theme.colors.neon,
        ...theme.typography.OIB18,
      },
    },
  })
);

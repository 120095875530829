import { GraphQLError } from "graphql";
import { i18n } from "@lingui/core";

export const ERROR_UNAUTHORIZED_TYPE = "UNAUTHORIZED";

export class GraphQLErrorUnauthorized extends GraphQLError {
  constructor(
    message?: string
  ) {
    super(
      message ? i18n._(message) : "Unauthorized",
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      {
        code: ERROR_UNAUTHORIZED_TYPE,
      }
    );
  }
}

import {
  AccountToken,
} from "@common/type-graphql/generated";
import {
  AnyAction,
  PayloadAction,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { HttpStatusCode } from "@common/HttpStatusCode";
import { RESET_APP_STATE } from "@core/api";
import {
  RootState,
} from "@core/redux/store";
import { STORE_ACCOUNT_KEY } from "../accountConstants";
import { accountApi } from "../api/accountApi";

export type AccountState = {
  isWelcomeDone: boolean;
  isInitialized: boolean;
  isInitializing: boolean;
  token: string | null;
  refreshToken: string | null;
  expiresAt: string | null;
  isLoggedIn: boolean;
  tokenExpired: boolean;
  error: string | null | undefined | unknown;
}

const initialState: AccountState = {
  isWelcomeDone: false,
  isInitialized: false,
  isInitializing: false,
  token: null,
  refreshToken: null,
  expiresAt: null,
  isLoggedIn: false,
  tokenExpired: false,
  error: null,
};

const isError401 = (
  action: AnyAction
): action is PayloadAction<number> => {
  return action.payload?.status === HttpStatusCode.UNAUTHORIZED;
};

export const accountSlice = createSlice({
  name: STORE_ACCOUNT_KEY,
  initialState: initialState,
  reducers: {
    logout: state => {
      state.token = null;
      state.refreshToken = null;
      state.expiresAt = null;
      state.isLoggedIn = false;
      state.tokenExpired = false;
      state.error = null;
    },
    setIsWelcomeDone: state => {
      state.isWelcomeDone = true;
    },
    setAsInitialized: state => {
      state.isInitialized = true;
      state.isInitializing = false;
    },
    setAsInitializing: state => {
      state.isInitialized = false;
      state.isInitializing = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
    builder.addMatcher(accountApi.endpoints.refreshToken.matchFulfilled,
      (state, action: PayloadAction<{accountGetRefreshToken: AccountToken}>) => {
        const {
          token,
          refreshToken,
          expiresAt,
        } = action.payload.accountGetRefreshToken;
        state.isWelcomeDone = true;
        state.token = token;
        state.refreshToken = refreshToken;
        state.expiresAt = expiresAt.toString();
        state.isLoggedIn = true;
        state.tokenExpired = false;
        state.error = null;
      });
    builder.addMatcher(accountApi.endpoints.login.matchFulfilled,
      (state, action: PayloadAction<{accountLogin: AccountToken}>) => {
        const {
          token,
          refreshToken,
          expiresAt,
        } = action.payload.accountLogin;
        state.isWelcomeDone = true;
        state.token = token;
        state.refreshToken = refreshToken;
        state.expiresAt = expiresAt.toString();
        state.isLoggedIn = true;
        state.tokenExpired = false;
        state.error = null;
      });
    builder.addMatcher(accountApi.endpoints.loginOtp.matchFulfilled,
      (
        state,
        action: PayloadAction<{accountLoginOtp: AccountToken}>
      ) => {
        const {
          token,
          refreshToken,
          expiresAt,
        } = action.payload.accountLoginOtp;
        state.isWelcomeDone = true;
        state.token = token;
        state.refreshToken = refreshToken;
        state.expiresAt = expiresAt.toString();
        state.isLoggedIn = true;
        state.tokenExpired = false;
        state.error = null;
      });
    builder.addMatcher(accountApi.endpoints.loginGoogle.matchFulfilled,
      (state, action: PayloadAction<{accountLoginGoogle: AccountToken}>) => {
        const {
          token,
          refreshToken,
          expiresAt,
        } = action.payload.accountLoginGoogle;
        state.isWelcomeDone = true;
        state.token = token;
        state.refreshToken = refreshToken;
        state.expiresAt = expiresAt.toString();
        state.isLoggedIn = true;
        state.tokenExpired = false;
        state.error = null;
      });
    builder.addMatcher(accountApi.endpoints.loginFacebook.matchFulfilled,
      (state, action: PayloadAction<{accountLoginFacebook: AccountToken}>) => {
        const {
          token,
          refreshToken,
          expiresAt,
        } = action.payload.accountLoginFacebook;
        state.isWelcomeDone = true;
        state.token = token;
        state.refreshToken = refreshToken;
        state.expiresAt = expiresAt.toString();
        state.isLoggedIn = true;
        state.tokenExpired = false;
        state.error = null;
      });
    builder.addMatcher(accountApi.endpoints.loginApple.matchFulfilled,
      (state, action: PayloadAction<{accountLoginApple: AccountToken}>) => {
        const {
          token,
          refreshToken,
          expiresAt,
        } = action.payload.accountLoginApple;
        state.isWelcomeDone = true;
        state.token = token;
        state.refreshToken = refreshToken;
        state.expiresAt = expiresAt.toString();
        state.isLoggedIn = true;
        state.tokenExpired = false;
        state.error = null;
      });
    builder.addMatcher(
      isAnyOf(
        accountApi.endpoints.login.matchRejected,
        accountApi.endpoints.loginGoogle.matchRejected,
        accountApi.endpoints.loginFacebook.matchRejected,
        accountApi.endpoints.loginApple.matchRejected
      ),
      (state, action) => {
        state.token = null;
        state.refreshToken = null;
        state.expiresAt = null;
        state.isLoggedIn = false;
        state.tokenExpired = false;
        state.error = action.error;
      });
    builder.addMatcher(accountApi.endpoints.createAccountGuestWithSampleData.matchFulfilled,
      (state, action: PayloadAction<{createAccountGuestWithSampleData: AccountToken}>) => {
        const {
          token,
          refreshToken,
          expiresAt,
        } = action.payload.createAccountGuestWithSampleData;
        state.isWelcomeDone = true;
        state.token = token;
        state.refreshToken = refreshToken;
        state.expiresAt = expiresAt.toString();
        state.isLoggedIn = true;
        state.tokenExpired = false;
        state.error = null;
      });
    builder.addMatcher(accountApi.endpoints.createAccountGuestWithSampleData.matchRejected,
      (state, action) => {
        state.token = null;
        state.refreshToken = null;
        state.expiresAt = null;
        state.isLoggedIn = false;
        state.tokenExpired = false;
        state.error = action.error;
      });
    builder.addMatcher(isError401, (state, action) => {
      state.tokenExpired = true;
      state.error = "Token is expired";
    });
  },
});

export const {
  setIsWelcomeDone,
  setAsInitialized,
  setAsInitializing,
  logout,
} = accountSlice.actions;

export const selectAccountState = (state: RootState) => state[STORE_ACCOUNT_KEY];

import React from "react";
import { CueMessageUi } from "@ui/CueMessageUi/CueMessageUi";
import { Trans } from "@lingui/macro";
import { rocketOutline } from "ionicons/icons";

export const ReferencePowerMissingCueComponent = () => {
  return <>
    <CueMessageUi
      icon={rocketOutline}
      title={<Trans>Power reference missing!</Trans>}
      text={<Trans>Perform one or two repetitions at maximum power</Trans>}
    />
  </>;
};

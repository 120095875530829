import gsap from "gsap";
import { GSAP_CUSTOM_EASE_OUTQUINT } from "@feature/gsap/gsapConstants";
import {
  useEffect,
  useRef,
} from "react";

export const useLogic = (props: any) => {
  const $icon = useRef();

  useEffect(() => {
    if (props.isAccordion) {
      $icon.current && gsap.to($icon.current, {
        rotate: props.isAccordionOpen === true ? -180 : 0,
        duration: 0.4,
        ease: GSAP_CUSTOM_EASE_OUTQUINT,
      });
    }
  }, [
    props.isAccordion,
    props.isAccordionOpen,
  ]);
  return { $icon: $icon };
};

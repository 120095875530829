import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import { STORE_LOADING_KEY } from "@feature/loading/loadingConstants";

type InitialStateModelInterface = {
  isLoading: boolean;
}

const initialState: InitialStateModelInterface = { isLoading: false };

export const loadingSlice = createSlice({
  name: STORE_LOADING_KEY,
  initialState: initialState,
  reducers: {
    showLoading: (state, action: PayloadAction) => {
      state.isLoading = true;
    },
    hideLoading: (state, action: PayloadAction) => {
      state.isLoading = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});

export const {
  showLoading,
  hideLoading,
} = loadingSlice.actions;

export const getLoadingState = (state: RootState) => state[STORE_LOADING_KEY];

import { AvatarSize } from "@types-custom";
import { useStyles } from "./useStyles";

interface Props {
  src: string;
  size?: AvatarSize;
}

export const AvatarSrcUi = (props: Props) => {
  const {
    classes,
    cx,
  } = useStyles();
  return <>
    <div className={cx({
      [classes.avatarWrapper]: true,
      [classes.sizeBig]: props.size === "big",
      [classes.sizeSmall]: props.size === "small",
    })}>
      <img
        className={classes.avatarImage}
        src={props.src}
        alt=""
      />
    </div>
  </>;
};

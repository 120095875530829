import { useLogic } from "./useLogic";
import { useStyles } from "./useStyles";

interface Props {
  duration: number;
  color: "black" | "neon";
}

export const SpinnerUi = ({
  duration, color,
}: Props) => {
  const {
    classes,
    theme,
  } = useStyles();

  const {
    setBottomPath,
    setTopPath,
    setHead,
  } = useLogic({ duration: duration });

  return <>
    <div className={classes.spinnerRoot}>
      <svg
        className={classes.svg}
        id="svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 38 45">
        <g fill="none" fillRule="evenodd" transform="translate(-228 -83)">
          <polyline
            ref={setBottomPath}
            id="path1"
            stroke={theme.colors[color]}
            strokeWidth="4"
            points="230 127.283055 233.280144 112.589985 246.664377 106 260.098565 112.589985 263.492219 127.283055" />
          <polyline
            ref={setTopPath}
            id="path2"
            stroke={theme.colors[color]}
            strokeWidth="4"
            points="230 105.283055 233.280144 90.5899846 246.664377 84 260.098565 90.5899846 263.492219 105.283055"
            transform="matrix(1 0 0 -1 0 189.283)" />
          <polygon
            ref={setHead}
            id="head"
            fill={theme.colors[color]}
            points="242.128176 92.8214398 241 87.9248664 244.160988 84 249.23254 84 252.393528 87.9248664 251.265352 92.8214398 246.696764 95" />
        </g>
      </svg>
    </div>
  </>;
};

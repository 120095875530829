import { ChipMultipleUi } from "@ui/ChipMultipleUi/ChipMultipleUi";
import { ChipProps } from "@ui/ChipUi/ChipUi";
import { MultipleChildrens } from "@types-custom";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { useStyles } from "./useStyles";

export interface HistoryWorkoutProps {
  accountProfileChips?: ChipProps[];
  exerciseProtocolChips?: ChipProps[];
  children: MultipleChildrens;
}

export const HistoryWorkoutUi = (props: HistoryWorkoutProps) => {
  const styles = useStyles({
    hasAccountProfileChips: props.accountProfileChips !== null,
    hasExerciseProtocolChips: props.exerciseProtocolChips !== null,
  });

  return <>
    <div className={styles.cx({ [styles.classes.historyWorkoutRoot]: true })}>
      {
        props.accountProfileChips &&
				<ChipMultipleUi chips={props.accountProfileChips} />
      }
      {
        props.exerciseProtocolChips &&
				<ChipMultipleUi chips={props.exerciseProtocolChips} />
      }
      <SectionUi>
        {
          props.children
        }
      </SectionUi>
    </div>
  </>;
};

import React, { useEffect } from "react";
import gsap from "gsap";

const FADEIN_TIME = 1; // s
const AFTER_SPLASH_PAUSE = 500; // milliseconds

export const useLogic = (ref: React.RefObject<HTMLDivElement>, onSplashScreenTimeout?: () => void) => {
  useEffect(() => {
    const timeoutId = null;
    if (ref && ref.current) {
      ref.current && gsap.to(ref.current, {
        opacity: 1,
        duration: FADEIN_TIME,
        ease: "ease-out",
      }).then(() => {
        if (onSplashScreenTimeout) {
          setTimeout(onSplashScreenTimeout, AFTER_SPLASH_PAUSE);
        }
      });
      return () => clearTimeout(timeoutId);
    }

    return () => {
      return;
    };
  }, [
    ref,
    onSplashScreenTimeout,
  ]);
};

import { AthleteViewComponent } from "@feature/athlete/component/athleteViewComponent";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";
import { useParams } from "react-router-dom";

type Params = {
  uuid?: string;
}

export const AthleteViewPage = () => {
  const params = useParams<Params>();

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.athleteView.title)}
        backToRoute={routes.athleteList} />
      <IonContent>
        <AthleteViewComponent uuid={params.uuid} />
      </IonContent>
    </IonPage>
  </>;
};

import { GraphQLError } from "graphql";
import { i18n } from "@lingui/core";

export const ERROR_SILENT_TYPE = "SILENT";

export class GraphQLErrorSilent extends GraphQLError {
  constructor(
    message: string
  ) {
    super(
      i18n._(message),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      {
        code: ERROR_SILENT_TYPE,
      }
    );
  }
}

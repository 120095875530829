import { RangeMinMax } from "@common/model/Range";

export const misto1MockTitle = "1 completa + 1 short top + 1 slow down + 1 short bottom + 1 completa";
export const misto1Range : RangeMinMax = {
  min: 500,
  max: 1100,
};
export const misto1Mock = [
  {
    "x": 0,
    "y": 1042,
  },
  {
    "x": 10,
    "y": 1041,
  },
  {
    "x": 20,
    "y": 1041,
  },
  {
    "x": 30,
    "y": 1040,
  },
  {
    "x": 40,
    "y": 1053,
  },
  {
    "x": 50,
    "y": 1041,
  },
  {
    "x": 60,
    "y": 1041,
  },
  {
    "x": 70,
    "y": 1039,
  },
  {
    "x": 80,
    "y": 1040,
  },
  {
    "x": 90,
    "y": 1041,
  },
  {
    "x": 100,
    "y": 1040,
  },
  {
    "x": 110,
    "y": 1039,
  },
  {
    "x": 120,
    "y": 1039,
  },
  {
    "x": 130,
    "y": 1040,
  },
  {
    "x": 140,
    "y": 1038,
  },
  {
    "x": 150,
    "y": 1037,
  },
  {
    "x": 160,
    "y": 1037,
  },
  {
    "x": 170,
    "y": 1036,
  },
  {
    "x": 180,
    "y": 1034,
  },
  {
    "x": 190,
    "y": 1033,
  },
  {
    "x": 200,
    "y": 1032,
  },
  {
    "x": 210,
    "y": 1031,
  },
  {
    "x": 220,
    "y": 1032,
  },
  {
    "x": 230,
    "y": 1032,
  },
  {
    "x": 240,
    "y": 1031,
  },
  {
    "x": 250,
    "y": 1031,
  },
  {
    "x": 260,
    "y": 1030,
  },
  {
    "x": 270,
    "y": 1030,
  },
  {
    "x": 280,
    "y": 1028,
  },
  {
    "x": 290,
    "y": 1030,
  },
  {
    "x": 300,
    "y": 1029,
  },
  {
    "x": 310,
    "y": 1028,
  },
  {
    "x": 320,
    "y": 1027,
  },
  {
    "x": 330,
    "y": 1027,
  },
  {
    "x": 340,
    "y": 1027,
  },
  {
    "x": 350,
    "y": 1027,
  },
  {
    "x": 360,
    "y": 1027,
  },
  {
    "x": 370,
    "y": 1026,
  },
  {
    "x": 380,
    "y": 1025,
  },
  {
    "x": 390,
    "y": 1025,
  },
  {
    "x": 400,
    "y": 1024,
  },
  {
    "x": 410,
    "y": 1024,
  },
  {
    "x": 420,
    "y": 1024,
  },
  {
    "x": 430,
    "y": 1024,
  },
  {
    "x": 440,
    "y": 1024,
  },
  {
    "x": 450,
    "y": 1025,
  },
  {
    "x": 460,
    "y": 1025,
  },
  {
    "x": 470,
    "y": 1025,
  },
  {
    "x": 480,
    "y": 1025,
  },
  {
    "x": 490,
    "y": 1025,
  },
  {
    "x": 500,
    "y": 1024,
  },
  {
    "x": 510,
    "y": 1023,
  },
  {
    "x": 520,
    "y": 1023,
  },
  {
    "x": 530,
    "y": 1022,
  },
  {
    "x": 540,
    "y": 1022,
  },
  {
    "x": 550,
    "y": 1022,
  },
  {
    "x": 560,
    "y": 1022,
  },
  {
    "x": 570,
    "y": 1021,
  },
  {
    "x": 580,
    "y": 1020,
  },
  {
    "x": 590,
    "y": 1019,
  },
  {
    "x": 600,
    "y": 1018,
  },
  {
    "x": 610,
    "y": 1018,
  },
  {
    "x": 620,
    "y": 1017,
  },
  {
    "x": 630,
    "y": 1018,
  },
  {
    "x": 640,
    "y": 1018,
  },
  {
    "x": 650,
    "y": 1018,
  },
  {
    "x": 660,
    "y": 1017,
  },
  {
    "x": 670,
    "y": 1035,
  },
  {
    "x": 680,
    "y": 1016,
  },
  {
    "x": 690,
    "y": 1017,
  },
  {
    "x": 700,
    "y": 1016,
  },
  {
    "x": 710,
    "y": 1016,
  },
  {
    "x": 720,
    "y": 1016,
  },
  {
    "x": 730,
    "y": 1015,
  },
  {
    "x": 740,
    "y": 1015,
  },
  {
    "x": 750,
    "y": 1015,
  },
  {
    "x": 760,
    "y": 1015,
  },
  {
    "x": 770,
    "y": 1015,
  },
  {
    "x": 780,
    "y": 1015,
  },
  {
    "x": 790,
    "y": 1015,
  },
  {
    "x": 800,
    "y": 1014,
  },
  {
    "x": 810,
    "y": 1014,
  },
  {
    "x": 820,
    "y": 1014,
  },
  {
    "x": 830,
    "y": 1013,
  },
  {
    "x": 840,
    "y": 1014,
  },
  {
    "x": 850,
    "y": 1022,
  },
  {
    "x": 860,
    "y": 1015,
  },
  {
    "x": 870,
    "y": 1014,
  },
  {
    "x": 880,
    "y": 1015,
  },
  {
    "x": 890,
    "y": 1015,
  },
  {
    "x": 900,
    "y": 1014,
  },
  {
    "x": 910,
    "y": 1015,
  },
  {
    "x": 920,
    "y": 1016,
  },
  {
    "x": 930,
    "y": 1015,
  },
  {
    "x": 940,
    "y": 1015,
  },
  {
    "x": 950,
    "y": 1015,
  },
  {
    "x": 960,
    "y": 1015,
  },
  {
    "x": 970,
    "y": 1014,
  },
  {
    "x": 980,
    "y": 1015,
  },
  {
    "x": 990,
    "y": 1016,
  },
  {
    "x": 1000,
    "y": 1016,
  },
  {
    "x": 1010,
    "y": 1017,
  },
  {
    "x": 1020,
    "y": 1018,
  },
  {
    "x": 1030,
    "y": 1018,
  },
  {
    "x": 1040,
    "y": 1017,
  },
  {
    "x": 1050,
    "y": 1017,
  },
  {
    "x": 1060,
    "y": 1018,
  },
  {
    "x": 1070,
    "y": 1020,
  },
  {
    "x": 1080,
    "y": 1022,
  },
  {
    "x": 1090,
    "y": 1024,
  },
  {
    "x": 1100,
    "y": 1025,
  },
  {
    "x": 1110,
    "y": 1029,
  },
  {
    "x": 1120,
    "y": 1061,
  },
  {
    "x": 1130,
    "y": 1038,
  },
  {
    "x": 1140,
    "y": 1040,
  },
  {
    "x": 1150,
    "y": 1047,
  },
  {
    "x": 1160,
    "y": 1048,
  },
  {
    "x": 1170,
    "y": 1053,
  },
  {
    "x": 1180,
    "y": 1055,
  },
  {
    "x": 1190,
    "y": 1060,
  },
  {
    "x": 1200,
    "y": 1063,
  },
  {
    "x": 1210,
    "y": 1065,
  },
  {
    "x": 1220,
    "y": 1068,
  },
  {
    "x": 1230,
    "y": 1073,
  },
  {
    "x": 1240,
    "y": 1077,
  },
  {
    "x": 1250,
    "y": 1077,
  },
  {
    "x": 1260,
    "y": 1084,
  },
  {
    "x": 1270,
    "y": 1084,
  },
  {
    "x": 1280,
    "y": 1089,
  },
  {
    "x": 1290,
    "y": 1090,
  },
  {
    "x": 1300,
    "y": 1095,
  },
  {
    "x": 1310,
    "y": 1100,
  },
  {
    "x": 1320,
    "y": 1101,
  },
  {
    "x": 1330,
    "y": 1104,
  },
  {
    "x": 1338,
    "y": 1107,
  },
  {
    "x": 1340,
    "y": 1108,
  },
  {
    "x": 1350,
    "y": 1109,
  },
  {
    "x": 1360,
    "y": 1110,
  },
  {
    "x": 1370,
    "y": 1114,
  },
  {
    "x": 1380,
    "y": 1116,
  },
  {
    "x": 1390,
    "y": 1119,
  },
  {
    "x": 1400,
    "y": 1120,
  },
  {
    "x": 1410,
    "y": 1125,
  },
  {
    "x": 1420,
    "y": 1127,
  },
  {
    "x": 1430,
    "y": 1129,
  },
  {
    "x": 1440,
    "y": 1130,
  },
  {
    "x": 1450,
    "y": 1132,
  },
  {
    "x": 1460,
    "y": 1133,
  },
  {
    "x": 1470,
    "y": 1134,
  },
  {
    "x": 1480,
    "y": 1134,
  },
  {
    "x": 1490,
    "y": 1134,
  },
  {
    "x": 1500,
    "y": 1134,
  },
  {
    "x": 1510,
    "y": 1134,
  },
  {
    "x": 1520,
    "y": 1134,
  },
  {
    "x": 1530,
    "y": 1135,
  },
  {
    "x": 1540,
    "y": 1136,
  },
  {
    "x": 1550,
    "y": 1135,
  },
  {
    "x": 1560,
    "y": 1136,
  },
  {
    "x": 1570,
    "y": 1136,
  },
  {
    "x": 1580,
    "y": 1136,
  },
  {
    "x": 1590,
    "y": 1134,
  },
  {
    "x": 1600,
    "y": 1134,
  },
  {
    "x": 1610,
    "y": 1134,
  },
  {
    "x": 1620,
    "y": 1134,
  },
  {
    "x": 1630,
    "y": 1134,
  },
  {
    "x": 1640,
    "y": 1134,
  },
  {
    "x": 1650,
    "y": 1134,
  },
  {
    "x": 1660,
    "y": 1135,
  },
  {
    "x": 1670,
    "y": 1135,
  },
  {
    "x": 1680,
    "y": 1136,
  },
  {
    "x": 1690,
    "y": 1136,
  },
  {
    "x": 1700,
    "y": 1136,
  },
  {
    "x": 1710,
    "y": 1136,
  },
  {
    "x": 1720,
    "y": 1136,
  },
  {
    "x": 1730,
    "y": 1137,
  },
  {
    "x": 1740,
    "y": 1137,
  },
  {
    "x": 1750,
    "y": 1137,
  },
  {
    "x": 1760,
    "y": 1137,
  },
  {
    "x": 1770,
    "y": 1137,
  },
  {
    "x": 1780,
    "y": 1136,
  },
  {
    "x": 1790,
    "y": 1138,
  },
  {
    "x": 1800,
    "y": 1138,
  },
  {
    "x": 1810,
    "y": 1138,
  },
  {
    "x": 1820,
    "y": 1138,
  },
  {
    "x": 1830,
    "y": 1138,
  },
  {
    "x": 1840,
    "y": 1138,
  },
  {
    "x": 1850,
    "y": 1138,
  },
  {
    "x": 1860,
    "y": 1138,
  },
  {
    "x": 1870,
    "y": 1138,
  },
  {
    "x": 1880,
    "y": 1138,
  },
  {
    "x": 1890,
    "y": 1139,
  },
  {
    "x": 1900,
    "y": 1139,
  },
  {
    "x": 1910,
    "y": 1139,
  },
  {
    "x": 1920,
    "y": 1140,
  },
  {
    "x": 1930,
    "y": 1139,
  },
  {
    "x": 1940,
    "y": 1139,
  },
  {
    "x": 1950,
    "y": 1140,
  },
  {
    "x": 1960,
    "y": 1140,
  },
  {
    "x": 1970,
    "y": 1139,
  },
  {
    "x": 1980,
    "y": 1140,
  },
  {
    "x": 1990,
    "y": 1140,
  },
  {
    "x": 2000,
    "y": 1141,
  },
  {
    "x": 2010,
    "y": 1141,
  },
  {
    "x": 2020,
    "y": 1141,
  },
  {
    "x": 2030,
    "y": 1141,
  },
  {
    "x": 2040,
    "y": 1141,
  },
  {
    "x": 2050,
    "y": 1141,
  },
  {
    "x": 2060,
    "y": 1142,
  },
  {
    "x": 2070,
    "y": 1143,
  },
  {
    "x": 2080,
    "y": 1143,
  },
  {
    "x": 2090,
    "y": 1142,
  },
  {
    "x": 2100,
    "y": 1143,
  },
  {
    "x": 2110,
    "y": 1144,
  },
  {
    "x": 2120,
    "y": 1144,
  },
  {
    "x": 2130,
    "y": 1145,
  },
  {
    "x": 2140,
    "y": 1145,
  },
  {
    "x": 2150,
    "y": 1145,
  },
  {
    "x": 2160,
    "y": 1147,
  },
  {
    "x": 2170,
    "y": 1147,
  },
  {
    "x": 2180,
    "y": 1149,
  },
  {
    "x": 2190,
    "y": 1149,
  },
  {
    "x": 2200,
    "y": 1150,
  },
  {
    "x": 2210,
    "y": 1150,
  },
  {
    "x": 2220,
    "y": 1150,
  },
  {
    "x": 2230,
    "y": 1150,
  },
  {
    "x": 2240,
    "y": 1150,
  },
  {
    "x": 2250,
    "y": 1151,
  },
  {
    "x": 2260,
    "y": 1150,
  },
  {
    "x": 2270,
    "y": 1150,
  },
  {
    "x": 2280,
    "y": 1150,
  },
  {
    "x": 2290,
    "y": 1149,
  },
  {
    "x": 2300,
    "y": 1146,
  },
  {
    "x": 2310,
    "y": 1144,
  },
  {
    "x": 2320,
    "y": 1143,
  },
  {
    "x": 2330,
    "y": 1141,
  },
  {
    "x": 2340,
    "y": 1139,
  },
  {
    "x": 2350,
    "y": 1136,
  },
  {
    "x": 2360,
    "y": 1131,
  },
  {
    "x": 2370,
    "y": 1127,
  },
  {
    "x": 2380,
    "y": 1127,
  },
  {
    "x": 2390,
    "y": 1122,
  },
  {
    "x": 2400,
    "y": 1120,
  },
  {
    "x": 2410,
    "y": 1118,
  },
  {
    "x": 2420,
    "y": 1111,
  },
  {
    "x": 2430,
    "y": 1109,
  },
  {
    "x": 2440,
    "y": 1105,
  },
  {
    "x": 2450,
    "y": 1100,
  },
  {
    "x": 2460,
    "y": 1093,
  },
  {
    "x": 2466,
    "y": 1088,
  },
  {
    "x": 2467,
    "y": 1087,
  },
  {
    "x": 2470,
    "y": 1085,
  },
  {
    "x": 2480,
    "y": 1082,
  },
  {
    "x": 2490,
    "y": 1074,
  },
  {
    "x": 2500,
    "y": 1072,
  },
  {
    "x": 2510,
    "y": 1065,
  },
  {
    "x": 2520,
    "y": 1055,
  },
  {
    "x": 2530,
    "y": 1051,
  },
  {
    "x": 2540,
    "y": 1040,
  },
  {
    "x": 2550,
    "y": 1027,
  },
  {
    "x": 2560,
    "y": 1021,
  },
  {
    "x": 2570,
    "y": 1014,
  },
  {
    "x": 2580,
    "y": 1007,
  },
  {
    "x": 2590,
    "y": 999,
  },
  {
    "x": 2600,
    "y": 993,
  },
  {
    "x": 2610,
    "y": 984,
  },
  {
    "x": 2620,
    "y": 972,
  },
  {
    "x": 2630,
    "y": 967,
  },
  {
    "x": 2640,
    "y": 958,
  },
  {
    "x": 2650,
    "y": 966,
  },
  {
    "x": 2660,
    "y": 935,
  },
  {
    "x": 2670,
    "y": 927,
  },
  {
    "x": 2680,
    "y": 913,
  },
  {
    "x": 2690,
    "y": 904,
  },
  {
    "x": 2700,
    "y": 893,
  },
  {
    "x": 2710,
    "y": 884,
  },
  {
    "x": 2720,
    "y": 878,
  },
  {
    "x": 2730,
    "y": 869,
  },
  {
    "x": 2740,
    "y": 858,
  },
  {
    "x": 2749,
    "y": 848,
  },
  {
    "x": 2759,
    "y": 843,
  },
  {
    "x": 2769,
    "y": 832,
  },
  {
    "x": 2779,
    "y": 822,
  },
  {
    "x": 2789,
    "y": 813,
  },
  {
    "x": 2799,
    "y": 805,
  },
  {
    "x": 2809,
    "y": 792,
  },
  {
    "x": 2819,
    "y": 784,
  },
  {
    "x": 2829,
    "y": 779,
  },
  {
    "x": 2839,
    "y": 767,
  },
  {
    "x": 2849,
    "y": 763,
  },
  {
    "x": 2859,
    "y": 756,
  },
  {
    "x": 2869,
    "y": 744,
  },
  {
    "x": 2879,
    "y": 735,
  },
  {
    "x": 2889,
    "y": 722,
  },
  {
    "x": 2899,
    "y": 715,
  },
  {
    "x": 2909,
    "y": 712,
  },
  {
    "x": 2919,
    "y": 702,
  },
  {
    "x": 2929,
    "y": 695,
  },
  {
    "x": 2939,
    "y": 692,
  },
  {
    "x": 2949,
    "y": 684,
  },
  {
    "x": 2959,
    "y": 672,
  },
  {
    "x": 2969,
    "y": 668,
  },
  {
    "x": 2979,
    "y": 660,
  },
  {
    "x": 2989,
    "y": 653,
  },
  {
    "x": 2999,
    "y": 644,
  },
  {
    "x": 3009,
    "y": 639,
  },
  {
    "x": 3019,
    "y": 628,
  },
  {
    "x": 3029,
    "y": 616,
  },
  {
    "x": 3039,
    "y": 610,
  },
  {
    "x": 3049,
    "y": 608,
  },
  {
    "x": 3059,
    "y": 602,
  },
  {
    "x": 3069,
    "y": 598,
  },
  {
    "x": 3079,
    "y": 592,
  },
  {
    "x": 3089,
    "y": 586,
  },
  {
    "x": 3099,
    "y": 576,
  },
  {
    "x": 3109,
    "y": 570,
  },
  {
    "x": 3119,
    "y": 565,
  },
  {
    "x": 3129,
    "y": 560,
  },
  {
    "x": 3139,
    "y": 552,
  },
  {
    "x": 3149,
    "y": 543,
  },
  {
    "x": 3159,
    "y": 539,
  },
  {
    "x": 3169,
    "y": 533,
  },
  {
    "x": 3179,
    "y": 528,
  },
  {
    "x": 3189,
    "y": 524,
  },
  {
    "x": 3199,
    "y": 519,
  },
  {
    "x": 3209,
    "y": 517,
  },
  {
    "x": 3219,
    "y": 509,
  },
  {
    "x": 3229,
    "y": 504,
  },
  {
    "x": 3239,
    "y": 500,
  },
  {
    "x": 3249,
    "y": 494,
  },
  {
    "x": 3259,
    "y": 491,
  },
  {
    "x": 3260,
    "y": 491,
  },
  {
    "x": 3261,
    "y": 490,
  },
  {
    "x": 3269,
    "y": 485,
  },
  {
    "x": 3279,
    "y": 480,
  },
  {
    "x": 3288,
    "y": 474,
  },
  {
    "x": 3298,
    "y": 467,
  },
  {
    "x": 3308,
    "y": 465,
  },
  {
    "x": 3318,
    "y": 461,
  },
  {
    "x": 3328,
    "y": 460,
  },
  {
    "x": 3338,
    "y": 456,
  },
  {
    "x": 3348,
    "y": 453,
  },
  {
    "x": 3358,
    "y": 450,
  },
  {
    "x": 3368,
    "y": 449,
  },
  {
    "x": 3378,
    "y": 447,
  },
  {
    "x": 3388,
    "y": 444,
  },
  {
    "x": 3398,
    "y": 444,
  },
  {
    "x": 3408,
    "y": 444,
  },
  {
    "x": 3418,
    "y": 445,
  },
  {
    "x": 3428,
    "y": 444,
  },
  {
    "x": 3438,
    "y": 445,
  },
  {
    "x": 3448,
    "y": 445,
  },
  {
    "x": 3458,
    "y": 447,
  },
  {
    "x": 3468,
    "y": 445,
  },
  {
    "x": 3478,
    "y": 445,
  },
  {
    "x": 3488,
    "y": 446,
  },
  {
    "x": 3498,
    "y": 450,
  },
  {
    "x": 3508,
    "y": 452,
  },
  {
    "x": 3518,
    "y": 453,
  },
  {
    "x": 3528,
    "y": 458,
  },
  {
    "x": 3539,
    "y": 461,
  },
  {
    "x": 3549,
    "y": 462,
  },
  {
    "x": 3559,
    "y": 466,
  },
  {
    "x": 3569,
    "y": 467,
  },
  {
    "x": 3579,
    "y": 474,
  },
  {
    "x": 3589,
    "y": 478,
  },
  {
    "x": 3599,
    "y": 479,
  },
  {
    "x": 3609,
    "y": 482,
  },
  {
    "x": 3619,
    "y": 486,
  },
  {
    "x": 3629,
    "y": 490,
  },
  {
    "x": 3639,
    "y": 500,
  },
  {
    "x": 3649,
    "y": 507,
  },
  {
    "x": 3659,
    "y": 510,
  },
  {
    "x": 3659,
    "y": 510,
  },
  {
    "x": 3660,
    "y": 511,
  },
  {
    "x": 3669,
    "y": 521,
  },
  {
    "x": 3679,
    "y": 524,
  },
  {
    "x": 3689,
    "y": 527,
  },
  {
    "x": 3699,
    "y": 536,
  },
  {
    "x": 3709,
    "y": 540,
  },
  {
    "x": 3719,
    "y": 550,
  },
  {
    "x": 3729,
    "y": 553,
  },
  {
    "x": 3739,
    "y": 559,
  },
  {
    "x": 3749,
    "y": 566,
  },
  {
    "x": 3759,
    "y": 577,
  },
  {
    "x": 3769,
    "y": 583,
  },
  {
    "x": 3779,
    "y": 590,
  },
  {
    "x": 3789,
    "y": 601,
  },
  {
    "x": 3799,
    "y": 606,
  },
  {
    "x": 3809,
    "y": 608,
  },
  {
    "x": 3819,
    "y": 613,
  },
  {
    "x": 3829,
    "y": 620,
  },
  {
    "x": 3839,
    "y": 622,
  },
  {
    "x": 3849,
    "y": 633,
  },
  {
    "x": 3859,
    "y": 640,
  },
  {
    "x": 3869,
    "y": 652,
  },
  {
    "x": 3879,
    "y": 660,
  },
  {
    "x": 3889,
    "y": 666,
  },
  {
    "x": 3899,
    "y": 672,
  },
  {
    "x": 3909,
    "y": 680,
  },
  {
    "x": 3919,
    "y": 683,
  },
  {
    "x": 3929,
    "y": 688,
  },
  {
    "x": 3939,
    "y": 695,
  },
  {
    "x": 3949,
    "y": 702,
  },
  {
    "x": 3959,
    "y": 711,
  },
  {
    "x": 3969,
    "y": 721,
  },
  {
    "x": 3979,
    "y": 727,
  },
  {
    "x": 3989,
    "y": 738,
  },
  {
    "x": 3999,
    "y": 744,
  },
  {
    "x": 4009,
    "y": 749,
  },
  {
    "x": 4019,
    "y": 753,
  },
  {
    "x": 4029,
    "y": 761,
  },
  {
    "x": 4040,
    "y": 769,
  },
  {
    "x": 4050,
    "y": 777,
  },
  {
    "x": 4060,
    "y": 780,
  },
  {
    "x": 4070,
    "y": 794,
  },
  {
    "x": 4080,
    "y": 800,
  },
  {
    "x": 4090,
    "y": 807,
  },
  {
    "x": 4100,
    "y": 815,
  },
  {
    "x": 4110,
    "y": 819,
  },
  {
    "x": 4120,
    "y": 824,
  },
  {
    "x": 4130,
    "y": 831,
  },
  {
    "x": 4140,
    "y": 838,
  },
  {
    "x": 4150,
    "y": 848,
  },
  {
    "x": 4160,
    "y": 855,
  },
  {
    "x": 4170,
    "y": 860,
  },
  {
    "x": 4180,
    "y": 871,
  },
  {
    "x": 4190,
    "y": 869,
  },
  {
    "x": 4200,
    "y": 877,
  },
  {
    "x": 4210,
    "y": 886,
  },
  {
    "x": 4220,
    "y": 895,
  },
  {
    "x": 4230,
    "y": 901,
  },
  {
    "x": 4240,
    "y": 907,
  },
  {
    "x": 4250,
    "y": 915,
  },
  {
    "x": 4260,
    "y": 917,
  },
  {
    "x": 4270,
    "y": 925,
  },
  {
    "x": 4280,
    "y": 928,
  },
  {
    "x": 4290,
    "y": 935,
  },
  {
    "x": 4300,
    "y": 935,
  },
  {
    "x": 4310,
    "y": 940,
  },
  {
    "x": 4320,
    "y": 954,
  },
  {
    "x": 4330,
    "y": 956,
  },
  {
    "x": 4340,
    "y": 962,
  },
  {
    "x": 4350,
    "y": 965,
  },
  {
    "x": 4360,
    "y": 970,
  },
  {
    "x": 4370,
    "y": 975,
  },
  {
    "x": 4380,
    "y": 983,
  },
  {
    "x": 4390,
    "y": 987,
  },
  {
    "x": 4400,
    "y": 990,
  },
  {
    "x": 4410,
    "y": 998,
  },
  {
    "x": 4420,
    "y": 1004,
  },
  {
    "x": 4430,
    "y": 1008,
  },
  {
    "x": 4440,
    "y": 1013,
  },
  {
    "x": 4450,
    "y": 1017,
  },
  {
    "x": 4460,
    "y": 1025,
  },
  {
    "x": 4470,
    "y": 1027,
  },
  {
    "x": 4480,
    "y": 1031,
  },
  {
    "x": 4490,
    "y": 1036,
  },
  {
    "x": 4500,
    "y": 1042,
  },
  {
    "x": 4510,
    "y": 1043,
  },
  {
    "x": 4520,
    "y": 1046,
  },
  {
    "x": 4530,
    "y": 1050,
  },
  {
    "x": 4540,
    "y": 1052,
  },
  {
    "x": 4550,
    "y": 1057,
  },
  {
    "x": 4560,
    "y": 1061,
  },
  {
    "x": 4570,
    "y": 1061,
  },
  {
    "x": 4580,
    "y": 1063,
  },
  {
    "x": 4590,
    "y": 1067,
  },
  {
    "x": 4600,
    "y": 1073,
  },
  {
    "x": 4611,
    "y": 1077,
  },
  {
    "x": 4621,
    "y": 1078,
  },
  {
    "x": 4631,
    "y": 1077,
  },
  {
    "x": 4641,
    "y": 1081,
  },
  {
    "x": 4651,
    "y": 1083,
  },
  {
    "x": 4661,
    "y": 1084,
  },
  {
    "x": 4671,
    "y": 1087,
  },
  {
    "x": 4681,
    "y": 1088,
  },
  {
    "x": 4691,
    "y": 1087,
  },
  {
    "x": 4701,
    "y": 1088,
  },
  {
    "x": 4711,
    "y": 1091,
  },
  {
    "x": 4721,
    "y": 1094,
  },
  {
    "x": 4731,
    "y": 1094,
  },
  {
    "x": 4741,
    "y": 1095,
  },
  {
    "x": 4751,
    "y": 1094,
  },
  {
    "x": 4761,
    "y": 1094,
  },
  {
    "x": 4771,
    "y": 1095,
  },
  {
    "x": 4781,
    "y": 1098,
  },
  {
    "x": 4791,
    "y": 1100,
  },
  {
    "x": 4801,
    "y": 1101,
  },
  {
    "x": 4803,
    "y": 1106,
  },
  {
    "x": 4811,
    "y": 1126,
  },
  {
    "x": 4821,
    "y": 1103,
  },
  {
    "x": 4831,
    "y": 1106,
  },
  {
    "x": 4841,
    "y": 1107,
  },
  {
    "x": 4851,
    "y": 1108,
  },
  {
    "x": 4861,
    "y": 1109,
  },
  {
    "x": 4871,
    "y": 1112,
  },
  {
    "x": 4881,
    "y": 1111,
  },
  {
    "x": 4891,
    "y": 1110,
  },
  {
    "x": 4901,
    "y": 1110,
  },
  {
    "x": 4911,
    "y": 1113,
  },
  {
    "x": 4921,
    "y": 1114,
  },
  {
    "x": 4931,
    "y": 1115,
  },
  {
    "x": 4941,
    "y": 1116,
  },
  {
    "x": 4951,
    "y": 1117,
  },
  {
    "x": 4961,
    "y": 1118,
  },
  {
    "x": 4971,
    "y": 1120,
  },
  {
    "x": 4981,
    "y": 1121,
  },
  {
    "x": 4991,
    "y": 1123,
  },
  {
    "x": 5001,
    "y": 1124,
  },
  {
    "x": 5011,
    "y": 1126,
  },
  {
    "x": 5021,
    "y": 1128,
  },
  {
    "x": 5031,
    "y": 1129,
  },
  {
    "x": 5041,
    "y": 1130,
  },
  {
    "x": 5051,
    "y": 1134,
  },
  {
    "x": 5061,
    "y": 1136,
  },
  {
    "x": 5071,
    "y": 1137,
  },
  {
    "x": 5081,
    "y": 1140,
  },
  {
    "x": 5091,
    "y": 1141,
  },
  {
    "x": 5101,
    "y": 1143,
  },
  {
    "x": 5111,
    "y": 1142,
  },
  {
    "x": 5121,
    "y": 1146,
  },
  {
    "x": 5131,
    "y": 1149,
  },
  {
    "x": 5141,
    "y": 1150,
  },
  {
    "x": 5151,
    "y": 1151,
  },
  {
    "x": 5161,
    "y": 1152,
  },
  {
    "x": 5171,
    "y": 1153,
  },
  {
    "x": 5181,
    "y": 1154,
  },
  {
    "x": 5191,
    "y": 1154,
  },
  {
    "x": 5201,
    "y": 1155,
  },
  {
    "x": 5211,
    "y": 1155,
  },
  {
    "x": 5221,
    "y": 1154,
  },
  {
    "x": 5231,
    "y": 1154,
  },
  {
    "x": 5241,
    "y": 1154,
  },
  {
    "x": 5251,
    "y": 1154,
  },
  {
    "x": 5261,
    "y": 1154,
  },
  {
    "x": 5271,
    "y": 1153,
  },
  {
    "x": 5281,
    "y": 1152,
  },
  {
    "x": 5291,
    "y": 1151,
  },
  {
    "x": 5301,
    "y": 1151,
  },
  {
    "x": 5311,
    "y": 1151,
  },
  {
    "x": 5321,
    "y": 1151,
  },
  {
    "x": 5331,
    "y": 1153,
  },
  {
    "x": 5341,
    "y": 1153,
  },
  {
    "x": 5351,
    "y": 1154,
  },
  {
    "x": 5361,
    "y": 1155,
  },
  {
    "x": 5371,
    "y": 1154,
  },
  {
    "x": 5381,
    "y": 1155,
  },
  {
    "x": 5391,
    "y": 1156,
  },
  {
    "x": 5401,
    "y": 1156,
  },
  {
    "x": 5411,
    "y": 1157,
  },
  {
    "x": 5421,
    "y": 1158,
  },
  {
    "x": 5431,
    "y": 1159,
  },
  {
    "x": 5441,
    "y": 1159,
  },
  {
    "x": 5451,
    "y": 1159,
  },
  {
    "x": 5461,
    "y": 1158,
  },
  {
    "x": 5471,
    "y": 1159,
  },
  {
    "x": 5481,
    "y": 1159,
  },
  {
    "x": 5491,
    "y": 1158,
  },
  {
    "x": 5501,
    "y": 1158,
  },
  {
    "x": 5511,
    "y": 1157,
  },
  {
    "x": 5521,
    "y": 1156,
  },
  {
    "x": 5531,
    "y": 1155,
  },
  {
    "x": 5541,
    "y": 1153,
  },
  {
    "x": 5551,
    "y": 1150,
  },
  {
    "x": 5561,
    "y": 1148,
  },
  {
    "x": 5571,
    "y": 1147,
  },
  {
    "x": 5581,
    "y": 1149,
  },
  {
    "x": 5591,
    "y": 1148,
  },
  {
    "x": 5601,
    "y": 1145,
  },
  {
    "x": 5611,
    "y": 1141,
  },
  {
    "x": 5621,
    "y": 1139,
  },
  {
    "x": 5631,
    "y": 1136,
  },
  {
    "x": 5641,
    "y": 1134,
  },
  {
    "x": 5651,
    "y": 1135,
  },
  {
    "x": 5661,
    "y": 1135,
  },
  {
    "x": 5671,
    "y": 1128,
  },
  {
    "x": 5681,
    "y": 1127,
  },
  {
    "x": 5691,
    "y": 1124,
  },
  {
    "x": 5701,
    "y": 1122,
  },
  {
    "x": 5711,
    "y": 1121,
  },
  {
    "x": 5721,
    "y": 1120,
  },
  {
    "x": 5731,
    "y": 1117,
  },
  {
    "x": 5741,
    "y": 1118,
  },
  {
    "x": 5751,
    "y": 1114,
  },
  {
    "x": 5761,
    "y": 1112,
  },
  {
    "x": 5771,
    "y": 1110,
  },
  {
    "x": 5781,
    "y": 1109,
  },
  {
    "x": 5791,
    "y": 1108,
  },
  {
    "x": 5801,
    "y": 1106,
  },
  {
    "x": 5811,
    "y": 1103,
  },
  {
    "x": 5821,
    "y": 1103,
  },
  {
    "x": 5831,
    "y": 1099,
  },
  {
    "x": 5841,
    "y": 1096,
  },
  {
    "x": 5846,
    "y": 1096,
  },
  {
    "x": 5851,
    "y": 1095,
  },
  {
    "x": 5861,
    "y": 1095,
  },
  {
    "x": 5871,
    "y": 1093,
  },
  {
    "x": 5881,
    "y": 1092,
  },
  {
    "x": 5891,
    "y": 1090,
  },
  {
    "x": 5901,
    "y": 1087,
  },
  {
    "x": 5911,
    "y": 1082,
  },
  {
    "x": 5921,
    "y": 1082,
  },
  {
    "x": 5931,
    "y": 1080,
  },
  {
    "x": 5941,
    "y": 1080,
  },
  {
    "x": 5951,
    "y": 1077,
  },
  {
    "x": 5961,
    "y": 1075,
  },
  {
    "x": 5971,
    "y": 1073,
  },
  {
    "x": 5981,
    "y": 1074,
  },
  {
    "x": 5991,
    "y": 1073,
  },
  {
    "x": 6001,
    "y": 1070,
  },
  {
    "x": 6011,
    "y": 1067,
  },
  {
    "x": 6021,
    "y": 1064,
  },
  {
    "x": 6031,
    "y": 1064,
  },
  {
    "x": 6041,
    "y": 1063,
  },
  {
    "x": 6051,
    "y": 1060,
  },
  {
    "x": 6061,
    "y": 1058,
  },
  {
    "x": 6071,
    "y": 1059,
  },
  {
    "x": 6081,
    "y": 1057,
  },
  {
    "x": 6091,
    "y": 1055,
  },
  {
    "x": 6101,
    "y": 1055,
  },
  {
    "x": 6111,
    "y": 1055,
  },
  {
    "x": 6121,
    "y": 1052,
  },
  {
    "x": 6131,
    "y": 1050,
  },
  {
    "x": 6141,
    "y": 1049,
  },
  {
    "x": 6151,
    "y": 1048,
  },
  {
    "x": 6161,
    "y": 1047,
  },
  {
    "x": 6171,
    "y": 1045,
  },
  {
    "x": 6181,
    "y": 1044,
  },
  {
    "x": 6191,
    "y": 1042,
  },
  {
    "x": 6201,
    "y": 1041,
  },
  {
    "x": 6211,
    "y": 1041,
  },
  {
    "x": 6221,
    "y": 1038,
  },
  {
    "x": 6231,
    "y": 1037,
  },
  {
    "x": 6241,
    "y": 1035,
  },
  {
    "x": 6251,
    "y": 1033,
  },
  {
    "x": 6261,
    "y": 1032,
  },
  {
    "x": 6271,
    "y": 1030,
  },
  {
    "x": 6281,
    "y": 1030,
  },
  {
    "x": 6291,
    "y": 1027,
  },
  {
    "x": 6301,
    "y": 1028,
  },
  {
    "x": 6311,
    "y": 1026,
  },
  {
    "x": 6321,
    "y": 1024,
  },
  {
    "x": 6331,
    "y": 1023,
  },
  {
    "x": 6341,
    "y": 1022,
  },
  {
    "x": 6351,
    "y": 1021,
  },
  {
    "x": 6361,
    "y": 1021,
  },
  {
    "x": 6371,
    "y": 1019,
  },
  {
    "x": 6381,
    "y": 1019,
  },
  {
    "x": 6391,
    "y": 1018,
  },
  {
    "x": 6401,
    "y": 1016,
  },
  {
    "x": 6411,
    "y": 1013,
  },
  {
    "x": 6421,
    "y": 1012,
  },
  {
    "x": 6431,
    "y": 1025,
  },
  {
    "x": 6441,
    "y": 1014,
  },
  {
    "x": 6451,
    "y": 1013,
  },
  {
    "x": 6461,
    "y": 1012,
  },
  {
    "x": 6471,
    "y": 1011,
  },
  {
    "x": 6481,
    "y": 1011,
  },
  {
    "x": 6491,
    "y": 1012,
  },
  {
    "x": 6501,
    "y": 1013,
  },
  {
    "x": 6511,
    "y": 1013,
  },
  {
    "x": 6521,
    "y": 1013,
  },
  {
    "x": 6531,
    "y": 1011,
  },
  {
    "x": 6541,
    "y": 1010,
  },
  {
    "x": 6551,
    "y": 1007,
  },
  {
    "x": 6561,
    "y": 1006,
  },
  {
    "x": 6571,
    "y": 1005,
  },
  {
    "x": 6581,
    "y": 1003,
  },
  {
    "x": 6591,
    "y": 1003,
  },
  {
    "x": 6601,
    "y": 1002,
  },
  {
    "x": 6611,
    "y": 1015,
  },
  {
    "x": 6621,
    "y": 999,
  },
  {
    "x": 6631,
    "y": 998,
  },
  {
    "x": 6641,
    "y": 996,
  },
  {
    "x": 6651,
    "y": 995,
  },
  {
    "x": 6661,
    "y": 992,
  },
  {
    "x": 6671,
    "y": 991,
  },
  {
    "x": 6681,
    "y": 990,
  },
  {
    "x": 6691,
    "y": 986,
  },
  {
    "x": 6701,
    "y": 984,
  },
  {
    "x": 6711,
    "y": 981,
  },
  {
    "x": 6721,
    "y": 982,
  },
  {
    "x": 6731,
    "y": 980,
  },
  {
    "x": 6741,
    "y": 978,
  },
  {
    "x": 6751,
    "y": 976,
  },
  {
    "x": 6761,
    "y": 976,
  },
  {
    "x": 6771,
    "y": 971,
  },
  {
    "x": 6781,
    "y": 971,
  },
  {
    "x": 6791,
    "y": 1004,
  },
  {
    "x": 6801,
    "y": 965,
  },
  {
    "x": 6811,
    "y": 961,
  },
  {
    "x": 6821,
    "y": 958,
  },
  {
    "x": 6831,
    "y": 955,
  },
  {
    "x": 6841,
    "y": 952,
  },
  {
    "x": 6851,
    "y": 948,
  },
  {
    "x": 6861,
    "y": 947,
  },
  {
    "x": 6871,
    "y": 941,
  },
  {
    "x": 6881,
    "y": 917,
  },
  {
    "x": 6891,
    "y": 932,
  },
  {
    "x": 6901,
    "y": 927,
  },
  {
    "x": 6911,
    "y": 920,
  },
  {
    "x": 6920,
    "y": 917,
  },
  {
    "x": 6930,
    "y": 912,
  },
  {
    "x": 6940,
    "y": 908,
  },
  {
    "x": 6950,
    "y": 904,
  },
  {
    "x": 6960,
    "y": 898,
  },
  {
    "x": 6970,
    "y": 877,
  },
  {
    "x": 6980,
    "y": 889,
  },
  {
    "x": 6990,
    "y": 883,
  },
  {
    "x": 7000,
    "y": 879,
  },
  {
    "x": 7010,
    "y": 877,
  },
  {
    "x": 7020,
    "y": 870,
  },
  {
    "x": 7030,
    "y": 864,
  },
  {
    "x": 7040,
    "y": 861,
  },
  {
    "x": 7050,
    "y": 853,
  },
  {
    "x": 7060,
    "y": 840,
  },
  {
    "x": 7070,
    "y": 845,
  },
  {
    "x": 7080,
    "y": 839,
  },
  {
    "x": 7090,
    "y": 830,
  },
  {
    "x": 7100,
    "y": 830,
  },
  {
    "x": 7110,
    "y": 820,
  },
  {
    "x": 7120,
    "y": 814,
  },
  {
    "x": 7130,
    "y": 811,
  },
  {
    "x": 7140,
    "y": 803,
  },
  {
    "x": 7150,
    "y": 790,
  },
  {
    "x": 7160,
    "y": 793,
  },
  {
    "x": 7170,
    "y": 787,
  },
  {
    "x": 7180,
    "y": 781,
  },
  {
    "x": 7190,
    "y": 776,
  },
  {
    "x": 7200,
    "y": 774,
  },
  {
    "x": 7210,
    "y": 765,
  },
  {
    "x": 7220,
    "y": 763,
  },
  {
    "x": 7230,
    "y": 758,
  },
  {
    "x": 7240,
    "y": 755,
  },
  {
    "x": 7250,
    "y": 747,
  },
  {
    "x": 7260,
    "y": 743,
  },
  {
    "x": 7270,
    "y": 737,
  },
  {
    "x": 7280,
    "y": 728,
  },
  {
    "x": 7290,
    "y": 725,
  },
  {
    "x": 7300,
    "y": 719,
  },
  {
    "x": 7310,
    "y": 706,
  },
  {
    "x": 7320,
    "y": 697,
  },
  {
    "x": 7330,
    "y": 694,
  },
  {
    "x": 7340,
    "y": 689,
  },
  {
    "x": 7350,
    "y": 685,
  },
  {
    "x": 7360,
    "y": 683,
  },
  {
    "x": 7370,
    "y": 675,
  },
  {
    "x": 7380,
    "y": 673,
  },
  {
    "x": 7390,
    "y": 669,
  },
  {
    "x": 7400,
    "y": 668,
  },
  {
    "x": 7410,
    "y": 664,
  },
  {
    "x": 7420,
    "y": 661,
  },
  {
    "x": 7430,
    "y": 659,
  },
  {
    "x": 7440,
    "y": 658,
  },
  {
    "x": 7450,
    "y": 656,
  },
  {
    "x": 7460,
    "y": 655,
  },
  {
    "x": 7470,
    "y": 655,
  },
  {
    "x": 7480,
    "y": 656,
  },
  {
    "x": 7490,
    "y": 655,
  },
  {
    "x": 7500,
    "y": 656,
  },
  {
    "x": 7510,
    "y": 665,
  },
  {
    "x": 7520,
    "y": 660,
  },
  {
    "x": 7530,
    "y": 660,
  },
  {
    "x": 7540,
    "y": 660,
  },
  {
    "x": 7550,
    "y": 663,
  },
  {
    "x": 7560,
    "y": 666,
  },
  {
    "x": 7570,
    "y": 667,
  },
  {
    "x": 7580,
    "y": 667,
  },
  {
    "x": 7590,
    "y": 673,
  },
  {
    "x": 7600,
    "y": 683,
  },
  {
    "x": 7610,
    "y": 688,
  },
  {
    "x": 7620,
    "y": 693,
  },
  {
    "x": 7630,
    "y": 699,
  },
  {
    "x": 7640,
    "y": 705,
  },
  {
    "x": 7650,
    "y": 712,
  },
  {
    "x": 7660,
    "y": 716,
  },
  {
    "x": 7670,
    "y": 720,
  },
  {
    "x": 7680,
    "y": 725,
  },
  {
    "x": 7690,
    "y": 729,
  },
  {
    "x": 7700,
    "y": 733,
  },
  {
    "x": 7710,
    "y": 738,
  },
  {
    "x": 7720,
    "y": 743,
  },
  {
    "x": 7730,
    "y": 750,
  },
  {
    "x": 7740,
    "y": 757,
  },
  {
    "x": 7750,
    "y": 767,
  },
  {
    "x": 7760,
    "y": 773,
  },
  {
    "x": 7770,
    "y": 782,
  },
  {
    "x": 7780,
    "y": 783,
  },
  {
    "x": 7790,
    "y": 789,
  },
  {
    "x": 7800,
    "y": 794,
  },
  {
    "x": 7810,
    "y": 801,
  },
  {
    "x": 7820,
    "y": 806,
  },
  {
    "x": 7830,
    "y": 813,
  },
  {
    "x": 7840,
    "y": 819,
  },
  {
    "x": 7850,
    "y": 822,
  },
  {
    "x": 7860,
    "y": 832,
  },
  {
    "x": 7870,
    "y": 841,
  },
  {
    "x": 7880,
    "y": 847,
  },
  {
    "x": 7891,
    "y": 857,
  },
  {
    "x": 7901,
    "y": 860,
  },
  {
    "x": 7911,
    "y": 864,
  },
  {
    "x": 7921,
    "y": 870,
  },
  {
    "x": 7931,
    "y": 871,
  },
  {
    "x": 7941,
    "y": 876,
  },
  {
    "x": 7951,
    "y": 885,
  },
  {
    "x": 7961,
    "y": 888,
  },
  {
    "x": 7971,
    "y": 891,
  },
  {
    "x": 7981,
    "y": 893,
  },
  {
    "x": 7991,
    "y": 906,
  },
  {
    "x": 8001,
    "y": 904,
  },
  {
    "x": 8011,
    "y": 908,
  },
  {
    "x": 8021,
    "y": 915,
  },
  {
    "x": 8031,
    "y": 921,
  },
  {
    "x": 8041,
    "y": 926,
  },
  {
    "x": 8051,
    "y": 932,
  },
  {
    "x": 8061,
    "y": 930,
  },
  {
    "x": 8071,
    "y": 937,
  },
  {
    "x": 8081,
    "y": 945,
  },
  {
    "x": 8091,
    "y": 951,
  },
  {
    "x": 8101,
    "y": 955,
  },
  {
    "x": 8111,
    "y": 958,
  },
  {
    "x": 8121,
    "y": 963,
  },
  {
    "x": 8131,
    "y": 968,
  },
  {
    "x": 8141,
    "y": 972,
  },
  {
    "x": 8151,
    "y": 976,
  },
  {
    "x": 8161,
    "y": 982,
  },
  {
    "x": 8171,
    "y": 986,
  },
  {
    "x": 8181,
    "y": 988,
  },
  {
    "x": 8191,
    "y": 972,
  },
  {
    "x": 8201,
    "y": 999,
  },
  {
    "x": 8211,
    "y": 1003,
  },
  {
    "x": 8221,
    "y": 1009,
  },
  {
    "x": 8231,
    "y": 1010,
  },
  {
    "x": 8241,
    "y": 1015,
  },
  {
    "x": 8251,
    "y": 1018,
  },
  {
    "x": 8261,
    "y": 1021,
  },
  {
    "x": 8271,
    "y": 1023,
  },
  {
    "x": 8281,
    "y": 1027,
  },
  {
    "x": 8291,
    "y": 1029,
  },
  {
    "x": 8301,
    "y": 1032,
  },
  {
    "x": 8311,
    "y": 1036,
  },
  {
    "x": 8321,
    "y": 1040,
  },
  {
    "x": 8331,
    "y": 1043,
  },
  {
    "x": 8341,
    "y": 1044,
  },
  {
    "x": 8351,
    "y": 1045,
  },
  {
    "x": 8361,
    "y": 1052,
  },
  {
    "x": 8371,
    "y": 1055,
  },
  {
    "x": 8381,
    "y": 1056,
  },
  {
    "x": 8391,
    "y": 1054,
  },
  {
    "x": 8401,
    "y": 1062,
  },
  {
    "x": 8411,
    "y": 1065,
  },
  {
    "x": 8421,
    "y": 1064,
  },
  {
    "x": 8431,
    "y": 1070,
  },
  {
    "x": 8441,
    "y": 1071,
  },
  {
    "x": 8451,
    "y": 1074,
  },
  {
    "x": 8461,
    "y": 1075,
  },
  {
    "x": 8471,
    "y": 1078,
  },
  {
    "x": 8481,
    "y": 1078,
  },
  {
    "x": 8491,
    "y": 1081,
  },
  {
    "x": 8501,
    "y": 1083,
  },
  {
    "x": 8511,
    "y": 1085,
  },
  {
    "x": 8521,
    "y": 1087,
  },
  {
    "x": 8531,
    "y": 1087,
  },
  {
    "x": 8541,
    "y": 1087,
  },
  {
    "x": 8551,
    "y": 1092,
  },
  {
    "x": 8561,
    "y": 1095,
  },
  {
    "x": 8571,
    "y": 1100,
  },
  {
    "x": 8581,
    "y": 1099,
  },
  {
    "x": 8591,
    "y": 1098,
  },
  {
    "x": 8601,
    "y": 1103,
  },
  {
    "x": 8611,
    "y": 1104,
  },
  {
    "x": 8621,
    "y": 1107,
  },
  {
    "x": 8631,
    "y": 1110,
  },
  {
    "x": 8641,
    "y": 1110,
  },
  {
    "x": 8651,
    "y": 1113,
  },
  {
    "x": 8661,
    "y": 1116,
  },
  {
    "x": 8671,
    "y": 1118,
  },
  {
    "x": 8681,
    "y": 1121,
  },
  {
    "x": 8691,
    "y": 1121,
  },
  {
    "x": 8701,
    "y": 1123,
  },
  {
    "x": 8711,
    "y": 1126,
  },
  {
    "x": 8721,
    "y": 1131,
  },
  {
    "x": 8731,
    "y": 1131,
  },
  {
    "x": 8741,
    "y": 1132,
  },
  {
    "x": 8751,
    "y": 1135,
  },
  {
    "x": 8761,
    "y": 1136,
  },
  {
    "x": 8772,
    "y": 1139,
  },
  {
    "x": 8782,
    "y": 1143,
  },
  {
    "x": 8792,
    "y": 1144,
  },
  {
    "x": 8802,
    "y": 1143,
  },
  {
    "x": 8812,
    "y": 1144,
  },
  {
    "x": 8822,
    "y": 1146,
  },
  {
    "x": 8832,
    "y": 1147,
  },
  {
    "x": 8842,
    "y": 1156,
  },
  {
    "x": 8852,
    "y": 1149,
  },
  {
    "x": 8862,
    "y": 1151,
  },
  {
    "x": 8872,
    "y": 1153,
  },
  {
    "x": 8882,
    "y": 1155,
  },
  {
    "x": 8892,
    "y": 1172,
  },
  {
    "x": 8902,
    "y": 1158,
  },
  {
    "x": 8912,
    "y": 1159,
  },
  {
    "x": 8922,
    "y": 1163,
  },
  {
    "x": 8932,
    "y": 1162,
  },
  {
    "x": 8942,
    "y": 1162,
  },
  {
    "x": 8952,
    "y": 1160,
  },
  {
    "x": 8962,
    "y": 1160,
  },
  {
    "x": 8972,
    "y": 1161,
  },
  {
    "x": 8982,
    "y": 1163,
  },
  {
    "x": 8992,
    "y": 1162,
  },
  {
    "x": 9002,
    "y": 1159,
  },
  {
    "x": 9012,
    "y": 1160,
  },
  {
    "x": 9022,
    "y": 1160,
  },
  {
    "x": 9032,
    "y": 1158,
  },
  {
    "x": 9042,
    "y": 1158,
  },
  {
    "x": 9052,
    "y": 1157,
  },
  {
    "x": 9062,
    "y": 1157,
  },
  {
    "x": 9072,
    "y": 1155,
  },
  {
    "x": 9082,
    "y": 1155,
  },
  {
    "x": 9092,
    "y": 1156,
  },
  {
    "x": 9102,
    "y": 1155,
  },
  {
    "x": 9112,
    "y": 1155,
  },
  {
    "x": 9122,
    "y": 1155,
  },
  {
    "x": 9132,
    "y": 1154,
  },
  {
    "x": 9142,
    "y": 1155,
  },
  {
    "x": 9152,
    "y": 1154,
  },
  {
    "x": 9162,
    "y": 1154,
  },
  {
    "x": 9172,
    "y": 1154,
  },
  {
    "x": 9182,
    "y": 1152,
  },
  {
    "x": 9192,
    "y": 1151,
  },
  {
    "x": 9202,
    "y": 1152,
  },
  {
    "x": 9212,
    "y": 1152,
  },
  {
    "x": 9222,
    "y": 1151,
  },
  {
    "x": 9232,
    "y": 1151,
  },
  {
    "x": 9242,
    "y": 1151,
  },
  {
    "x": 9252,
    "y": 1152,
  },
  {
    "x": 9262,
    "y": 1152,
  },
  {
    "x": 9272,
    "y": 1152,
  },
  {
    "x": 9282,
    "y": 1151,
  },
  {
    "x": 9292,
    "y": 1150,
  },
  {
    "x": 9302,
    "y": 1149,
  },
  {
    "x": 9312,
    "y": 1145,
  },
  {
    "x": 9322,
    "y": 1144,
  },
  {
    "x": 9332,
    "y": 1145,
  },
  {
    "x": 9342,
    "y": 1144,
  },
  {
    "x": 9352,
    "y": 1144,
  },
  {
    "x": 9362,
    "y": 1141,
  },
  {
    "x": 9372,
    "y": 1139,
  },
  {
    "x": 9382,
    "y": 1141,
  },
  {
    "x": 9392,
    "y": 1140,
  },
  {
    "x": 9402,
    "y": 1137,
  },
  {
    "x": 9412,
    "y": 1134,
  },
  {
    "x": 9422,
    "y": 1133,
  },
  {
    "x": 9432,
    "y": 1132,
  },
  {
    "x": 9442,
    "y": 1131,
  },
  {
    "x": 9452,
    "y": 1128,
  },
  {
    "x": 9462,
    "y": 1127,
  },
  {
    "x": 9472,
    "y": 1127,
  },
  {
    "x": 9482,
    "y": 1125,
  },
  {
    "x": 9492,
    "y": 1124,
  },
  {
    "x": 9502,
    "y": 1125,
  },
  {
    "x": 9512,
    "y": 1123,
  },
  {
    "x": 9522,
    "y": 1122,
  },
  {
    "x": 9532,
    "y": 1121,
  },
  {
    "x": 9542,
    "y": 1119,
  },
  {
    "x": 9552,
    "y": 1117,
  },
  {
    "x": 9562,
    "y": 1116,
  },
  {
    "x": 9572,
    "y": 1114,
  },
  {
    "x": 9582,
    "y": 1113,
  },
  {
    "x": 9592,
    "y": 1113,
  },
  {
    "x": 9602,
    "y": 1112,
  },
  {
    "x": 9612,
    "y": 1110,
  },
  {
    "x": 9622,
    "y": 1109,
  },
  {
    "x": 9632,
    "y": 1107,
  },
  {
    "x": 9642,
    "y": 1106,
  },
  {
    "x": 9652,
    "y": 1104,
  },
  {
    "x": 9662,
    "y": 1103,
  },
  {
    "x": 9672,
    "y": 1101,
  },
  {
    "x": 9682,
    "y": 1099,
  },
  {
    "x": 9692,
    "y": 1098,
  },
  {
    "x": 9697,
    "y": 1097,
  },
  {
    "x": 9702,
    "y": 1095,
  },
  {
    "x": 9712,
    "y": 1094,
  },
  {
    "x": 9722,
    "y": 1092,
  },
  {
    "x": 9732,
    "y": 1090,
  },
  {
    "x": 9742,
    "y": 1088,
  },
  {
    "x": 9752,
    "y": 1087,
  },
  {
    "x": 9762,
    "y": 1084,
  },
  {
    "x": 9772,
    "y": 1082,
  },
  {
    "x": 9782,
    "y": 1080,
  },
  {
    "x": 9792,
    "y": 1078,
  },
  {
    "x": 9802,
    "y": 1077,
  },
  {
    "x": 9812,
    "y": 1074,
  },
  {
    "x": 9822,
    "y": 1069,
  },
  {
    "x": 9832,
    "y": 1067,
  },
  {
    "x": 9842,
    "y": 1065,
  },
  {
    "x": 9851,
    "y": 1062,
  },
  {
    "x": 9861,
    "y": 1061,
  },
  {
    "x": 9871,
    "y": 1062,
  },
  {
    "x": 9881,
    "y": 1058,
  },
  {
    "x": 9891,
    "y": 1054,
  },
  {
    "x": 9901,
    "y": 1053,
  },
  {
    "x": 9911,
    "y": 1053,
  },
  {
    "x": 9921,
    "y": 1049,
  },
  {
    "x": 9931,
    "y": 1050,
  },
  {
    "x": 9941,
    "y": 1048,
  },
  {
    "x": 9951,
    "y": 1047,
  },
  {
    "x": 9961,
    "y": 1045,
  },
  {
    "x": 9971,
    "y": 1041,
  },
  {
    "x": 9981,
    "y": 1038,
  },
  {
    "x": 9991,
    "y": 1037,
  },
  {
    "x": 10001,
    "y": 1038,
  },
  {
    "x": 10011,
    "y": 1037,
  },
  {
    "x": 10021,
    "y": 1031,
  },
  {
    "x": 10031,
    "y": 1028,
  },
  {
    "x": 10041,
    "y": 1024,
  },
  {
    "x": 10051,
    "y": 1024,
  },
  {
    "x": 10061,
    "y": 1023,
  },
  {
    "x": 10071,
    "y": 1019,
  },
  {
    "x": 10081,
    "y": 1017,
  },
  {
    "x": 10091,
    "y": 1018,
  },
  {
    "x": 10101,
    "y": 1016,
  },
  {
    "x": 10111,
    "y": 1013,
  },
  {
    "x": 10121,
    "y": 1011,
  },
  {
    "x": 10131,
    "y": 1013,
  },
  {
    "x": 10141,
    "y": 1013,
  },
  {
    "x": 10151,
    "y": 1011,
  },
  {
    "x": 10161,
    "y": 1008,
  },
  {
    "x": 10171,
    "y": 1007,
  },
  {
    "x": 10181,
    "y": 1006,
  },
  {
    "x": 10191,
    "y": 1004,
  },
  {
    "x": 10201,
    "y": 1003,
  },
  {
    "x": 10211,
    "y": 1002,
  },
  {
    "x": 10221,
    "y": 1002,
  },
  {
    "x": 10231,
    "y": 1003,
  },
  {
    "x": 10241,
    "y": 1002,
  },
  {
    "x": 10251,
    "y": 1001,
  },
  {
    "x": 10261,
    "y": 998,
  },
  {
    "x": 10271,
    "y": 996,
  },
  {
    "x": 10281,
    "y": 994,
  },
  {
    "x": 10291,
    "y": 994,
  },
  {
    "x": 10301,
    "y": 992,
  },
  {
    "x": 10311,
    "y": 991,
  },
  {
    "x": 10321,
    "y": 991,
  },
  {
    "x": 10331,
    "y": 990,
  },
  {
    "x": 10341,
    "y": 988,
  },
  {
    "x": 10351,
    "y": 988,
  },
  {
    "x": 10361,
    "y": 987,
  },
  {
    "x": 10371,
    "y": 986,
  },
  {
    "x": 10381,
    "y": 985,
  },
  {
    "x": 10391,
    "y": 985,
  },
  {
    "x": 10401,
    "y": 985,
  },
  {
    "x": 10411,
    "y": 985,
  },
  {
    "x": 10421,
    "y": 983,
  },
  {
    "x": 10431,
    "y": 982,
  },
  {
    "x": 10441,
    "y": 982,
  },
  {
    "x": 10451,
    "y": 980,
  },
  {
    "x": 10461,
    "y": 979,
  },
  {
    "x": 10471,
    "y": 978,
  },
  {
    "x": 10481,
    "y": 976,
  },
  {
    "x": 10491,
    "y": 974,
  },
  {
    "x": 10501,
    "y": 973,
  },
  {
    "x": 10511,
    "y": 971,
  },
  {
    "x": 10521,
    "y": 971,
  },
  {
    "x": 10531,
    "y": 970,
  },
  {
    "x": 10541,
    "y": 967,
  },
  {
    "x": 10551,
    "y": 966,
  },
  {
    "x": 10561,
    "y": 965,
  },
  {
    "x": 10571,
    "y": 964,
  },
  {
    "x": 10581,
    "y": 962,
  },
  {
    "x": 10591,
    "y": 960,
  },
  {
    "x": 10601,
    "y": 957,
  },
  {
    "x": 10611,
    "y": 958,
  },
  {
    "x": 10621,
    "y": 956,
  },
  {
    "x": 10631,
    "y": 957,
  },
  {
    "x": 10641,
    "y": 956,
  },
  {
    "x": 10651,
    "y": 954,
  },
  {
    "x": 10661,
    "y": 951,
  },
  {
    "x": 10671,
    "y": 950,
  },
  {
    "x": 10681,
    "y": 949,
  },
  {
    "x": 10691,
    "y": 948,
  },
  {
    "x": 10701,
    "y": 947,
  },
  {
    "x": 10711,
    "y": 947,
  },
  {
    "x": 10721,
    "y": 945,
  },
  {
    "x": 10731,
    "y": 943,
  },
  {
    "x": 10741,
    "y": 942,
  },
  {
    "x": 10751,
    "y": 941,
  },
  {
    "x": 10761,
    "y": 940,
  },
  {
    "x": 10771,
    "y": 939,
  },
  {
    "x": 10781,
    "y": 937,
  },
  {
    "x": 10791,
    "y": 936,
  },
  {
    "x": 10801,
    "y": 935,
  },
  {
    "x": 10811,
    "y": 935,
  },
  {
    "x": 10821,
    "y": 932,
  },
  {
    "x": 10831,
    "y": 931,
  },
  {
    "x": 10841,
    "y": 930,
  },
  {
    "x": 10851,
    "y": 930,
  },
  {
    "x": 10861,
    "y": 929,
  },
  {
    "x": 10871,
    "y": 929,
  },
  {
    "x": 10881,
    "y": 928,
  },
  {
    "x": 10891,
    "y": 928,
  },
  {
    "x": 10901,
    "y": 926,
  },
  {
    "x": 10911,
    "y": 925,
  },
  {
    "x": 10921,
    "y": 922,
  },
  {
    "x": 10931,
    "y": 921,
  },
  {
    "x": 10941,
    "y": 920,
  },
  {
    "x": 10951,
    "y": 920,
  },
  {
    "x": 10961,
    "y": 920,
  },
  {
    "x": 10971,
    "y": 920,
  },
  {
    "x": 10981,
    "y": 919,
  },
  {
    "x": 10991,
    "y": 916,
  },
  {
    "x": 11001,
    "y": 915,
  },
  {
    "x": 11011,
    "y": 916,
  },
  {
    "x": 11021,
    "y": 915,
  },
  {
    "x": 11031,
    "y": 914,
  },
  {
    "x": 11041,
    "y": 916,
  },
  {
    "x": 11051,
    "y": 914,
  },
  {
    "x": 11061,
    "y": 912,
  },
  {
    "x": 11071,
    "y": 911,
  },
  {
    "x": 11081,
    "y": 909,
  },
  {
    "x": 11091,
    "y": 909,
  },
  {
    "x": 11101,
    "y": 909,
  },
  {
    "x": 11111,
    "y": 910,
  },
  {
    "x": 11121,
    "y": 912,
  },
  {
    "x": 11131,
    "y": 911,
  },
  {
    "x": 11141,
    "y": 911,
  },
  {
    "x": 11151,
    "y": 909,
  },
  {
    "x": 11161,
    "y": 909,
  },
  {
    "x": 11171,
    "y": 906,
  },
  {
    "x": 11181,
    "y": 904,
  },
  {
    "x": 11191,
    "y": 903,
  },
  {
    "x": 11201,
    "y": 901,
  },
  {
    "x": 11211,
    "y": 900,
  },
  {
    "x": 11221,
    "y": 898,
  },
  {
    "x": 11231,
    "y": 897,
  },
  {
    "x": 11241,
    "y": 897,
  },
  {
    "x": 11251,
    "y": 897,
  },
  {
    "x": 11261,
    "y": 896,
  },
  {
    "x": 11271,
    "y": 894,
  },
  {
    "x": 11281,
    "y": 893,
  },
  {
    "x": 11291,
    "y": 891,
  },
  {
    "x": 11301,
    "y": 890,
  },
  {
    "x": 11311,
    "y": 890,
  },
  {
    "x": 11321,
    "y": 890,
  },
  {
    "x": 11331,
    "y": 890,
  },
  {
    "x": 11341,
    "y": 891,
  },
  {
    "x": 11351,
    "y": 891,
  },
  {
    "x": 11361,
    "y": 891,
  },
  {
    "x": 11371,
    "y": 890,
  },
  {
    "x": 11381,
    "y": 889,
  },
  {
    "x": 11391,
    "y": 886,
  },
  {
    "x": 11401,
    "y": 883,
  },
  {
    "x": 11411,
    "y": 883,
  },
  {
    "x": 11421,
    "y": 882,
  },
  {
    "x": 11431,
    "y": 881,
  },
  {
    "x": 11441,
    "y": 879,
  },
  {
    "x": 11451,
    "y": 877,
  },
  {
    "x": 11461,
    "y": 877,
  },
  {
    "x": 11471,
    "y": 876,
  },
  {
    "x": 11481,
    "y": 877,
  },
  {
    "x": 11491,
    "y": 875,
  },
  {
    "x": 11501,
    "y": 874,
  },
  {
    "x": 11511,
    "y": 873,
  },
  {
    "x": 11521,
    "y": 873,
  },
  {
    "x": 11531,
    "y": 876,
  },
  {
    "x": 11541,
    "y": 874,
  },
  {
    "x": 11551,
    "y": 873,
  },
  {
    "x": 11561,
    "y": 871,
  },
  {
    "x": 11571,
    "y": 870,
  },
  {
    "x": 11581,
    "y": 869,
  },
  {
    "x": 11591,
    "y": 869,
  },
  {
    "x": 11601,
    "y": 868,
  },
  {
    "x": 11611,
    "y": 867,
  },
  {
    "x": 11621,
    "y": 868,
  },
  {
    "x": 11631,
    "y": 867,
  },
  {
    "x": 11641,
    "y": 866,
  },
  {
    "x": 11651,
    "y": 865,
  },
  {
    "x": 11661,
    "y": 866,
  },
  {
    "x": 11671,
    "y": 865,
  },
  {
    "x": 11681,
    "y": 864,
  },
  {
    "x": 11691,
    "y": 865,
  },
  {
    "x": 11701,
    "y": 864,
  },
  {
    "x": 11711,
    "y": 864,
  },
  {
    "x": 11721,
    "y": 863,
  },
  {
    "x": 11731,
    "y": 864,
  },
  {
    "x": 11741,
    "y": 863,
  },
  {
    "x": 11751,
    "y": 863,
  },
  {
    "x": 11761,
    "y": 864,
  },
  {
    "x": 11771,
    "y": 864,
  },
  {
    "x": 11781,
    "y": 863,
  },
  {
    "x": 11791,
    "y": 862,
  },
  {
    "x": 11801,
    "y": 863,
  },
  {
    "x": 11811,
    "y": 862,
  },
  {
    "x": 11821,
    "y": 861,
  },
  {
    "x": 11831,
    "y": 861,
  },
  {
    "x": 11841,
    "y": 862,
  },
  {
    "x": 11851,
    "y": 862,
  },
  {
    "x": 11861,
    "y": 861,
  },
  {
    "x": 11871,
    "y": 861,
  },
  {
    "x": 11881,
    "y": 861,
  },
  {
    "x": 11891,
    "y": 860,
  },
  {
    "x": 11901,
    "y": 861,
  },
  {
    "x": 11911,
    "y": 860,
  },
  {
    "x": 11921,
    "y": 858,
  },
  {
    "x": 11931,
    "y": 856,
  },
  {
    "x": 11941,
    "y": 856,
  },
  {
    "x": 11951,
    "y": 856,
  },
  {
    "x": 11961,
    "y": 855,
  },
  {
    "x": 11971,
    "y": 852,
  },
  {
    "x": 11981,
    "y": 850,
  },
  {
    "x": 11991,
    "y": 849,
  },
  {
    "x": 12001,
    "y": 849,
  },
  {
    "x": 12011,
    "y": 849,
  },
  {
    "x": 12021,
    "y": 852,
  },
  {
    "x": 12031,
    "y": 851,
  },
  {
    "x": 12041,
    "y": 850,
  },
  {
    "x": 12051,
    "y": 849,
  },
  {
    "x": 12061,
    "y": 849,
  },
  {
    "x": 12071,
    "y": 848,
  },
  {
    "x": 12081,
    "y": 846,
  },
  {
    "x": 12091,
    "y": 846,
  },
  {
    "x": 12101,
    "y": 846,
  },
  {
    "x": 12111,
    "y": 846,
  },
  {
    "x": 12121,
    "y": 845,
  },
  {
    "x": 12131,
    "y": 847,
  },
  {
    "x": 12141,
    "y": 846,
  },
  {
    "x": 12151,
    "y": 845,
  },
  {
    "x": 12161,
    "y": 845,
  },
  {
    "x": 12171,
    "y": 842,
  },
  {
    "x": 12181,
    "y": 842,
  },
  {
    "x": 12191,
    "y": 841,
  },
  {
    "x": 12201,
    "y": 840,
  },
  {
    "x": 12211,
    "y": 838,
  },
  {
    "x": 12221,
    "y": 839,
  },
  {
    "x": 12231,
    "y": 839,
  },
  {
    "x": 12241,
    "y": 838,
  },
  {
    "x": 12251,
    "y": 838,
  },
  {
    "x": 12261,
    "y": 837,
  },
  {
    "x": 12271,
    "y": 836,
  },
  {
    "x": 12281,
    "y": 835,
  },
  {
    "x": 12291,
    "y": 835,
  },
  {
    "x": 12301,
    "y": 833,
  },
  {
    "x": 12311,
    "y": 833,
  },
  {
    "x": 12321,
    "y": 832,
  },
  {
    "x": 12331,
    "y": 833,
  },
  {
    "x": 12341,
    "y": 832,
  },
  {
    "x": 12351,
    "y": 832,
  },
  {
    "x": 12361,
    "y": 832,
  },
  {
    "x": 12371,
    "y": 831,
  },
  {
    "x": 12381,
    "y": 831,
  },
  {
    "x": 12391,
    "y": 831,
  },
  {
    "x": 12401,
    "y": 831,
  },
  {
    "x": 12411,
    "y": 831,
  },
  {
    "x": 12421,
    "y": 830,
  },
  {
    "x": 12431,
    "y": 829,
  },
  {
    "x": 12441,
    "y": 828,
  },
  {
    "x": 12451,
    "y": 827,
  },
  {
    "x": 12461,
    "y": 826,
  },
  {
    "x": 12471,
    "y": 825,
  },
  {
    "x": 12481,
    "y": 824,
  },
  {
    "x": 12491,
    "y": 822,
  },
  {
    "x": 12501,
    "y": 821,
  },
  {
    "x": 12511,
    "y": 819,
  },
  {
    "x": 12521,
    "y": 818,
  },
  {
    "x": 12531,
    "y": 816,
  },
  {
    "x": 12541,
    "y": 814,
  },
  {
    "x": 12551,
    "y": 812,
  },
  {
    "x": 12561,
    "y": 810,
  },
  {
    "x": 12571,
    "y": 811,
  },
  {
    "x": 12581,
    "y": 812,
  },
  {
    "x": 12591,
    "y": 810,
  },
  {
    "x": 12601,
    "y": 808,
  },
  {
    "x": 12611,
    "y": 806,
  },
  {
    "x": 12621,
    "y": 806,
  },
  {
    "x": 12631,
    "y": 806,
  },
  {
    "x": 12641,
    "y": 806,
  },
  {
    "x": 12651,
    "y": 804,
  },
  {
    "x": 12661,
    "y": 801,
  },
  {
    "x": 12671,
    "y": 801,
  },
  {
    "x": 12681,
    "y": 801,
  },
  {
    "x": 12691,
    "y": 801,
  },
  {
    "x": 12701,
    "y": 798,
  },
  {
    "x": 12711,
    "y": 797,
  },
  {
    "x": 12721,
    "y": 797,
  },
  {
    "x": 12731,
    "y": 796,
  },
  {
    "x": 12741,
    "y": 794,
  },
  {
    "x": 12751,
    "y": 791,
  },
  {
    "x": 12761,
    "y": 790,
  },
  {
    "x": 12771,
    "y": 790,
  },
  {
    "x": 12781,
    "y": 790,
  },
  {
    "x": 12791,
    "y": 789,
  },
  {
    "x": 12801,
    "y": 789,
  },
  {
    "x": 12811,
    "y": 788,
  },
  {
    "x": 12821,
    "y": 788,
  },
  {
    "x": 12831,
    "y": 786,
  },
  {
    "x": 12841,
    "y": 786,
  },
  {
    "x": 12851,
    "y": 785,
  },
  {
    "x": 12861,
    "y": 784,
  },
  {
    "x": 12871,
    "y": 782,
  },
  {
    "x": 12881,
    "y": 781,
  },
  {
    "x": 12891,
    "y": 781,
  },
  {
    "x": 12901,
    "y": 780,
  },
  {
    "x": 12911,
    "y": 779,
  },
  {
    "x": 12921,
    "y": 777,
  },
  {
    "x": 12931,
    "y": 776,
  },
  {
    "x": 12941,
    "y": 776,
  },
  {
    "x": 12951,
    "y": 775,
  },
  {
    "x": 12961,
    "y": 774,
  },
  {
    "x": 12971,
    "y": 772,
  },
  {
    "x": 12981,
    "y": 772,
  },
  {
    "x": 12991,
    "y": 771,
  },
  {
    "x": 13001,
    "y": 770,
  },
  {
    "x": 13011,
    "y": 768,
  },
  {
    "x": 13021,
    "y": 767,
  },
  {
    "x": 13031,
    "y": 767,
  },
  {
    "x": 13041,
    "y": 765,
  },
  {
    "x": 13051,
    "y": 764,
  },
  {
    "x": 13061,
    "y": 761,
  },
  {
    "x": 13071,
    "y": 760,
  },
  {
    "x": 13081,
    "y": 759,
  },
  {
    "x": 13091,
    "y": 760,
  },
  {
    "x": 13101,
    "y": 759,
  },
  {
    "x": 13111,
    "y": 759,
  },
  {
    "x": 13121,
    "y": 758,
  },
  {
    "x": 13131,
    "y": 757,
  },
  {
    "x": 13141,
    "y": 754,
  },
  {
    "x": 13151,
    "y": 753,
  },
  {
    "x": 13161,
    "y": 758,
  },
  {
    "x": 13171,
    "y": 749,
  },
  {
    "x": 13181,
    "y": 749,
  },
  {
    "x": 13191,
    "y": 748,
  },
  {
    "x": 13201,
    "y": 748,
  },
  {
    "x": 13211,
    "y": 749,
  },
  {
    "x": 13221,
    "y": 748,
  },
  {
    "x": 13231,
    "y": 747,
  },
  {
    "x": 13241,
    "y": 745,
  },
  {
    "x": 13251,
    "y": 744,
  },
  {
    "x": 13261,
    "y": 744,
  },
  {
    "x": 13271,
    "y": 743,
  },
  {
    "x": 13281,
    "y": 741,
  },
  {
    "x": 13291,
    "y": 739,
  },
  {
    "x": 13301,
    "y": 738,
  },
  {
    "x": 13311,
    "y": 738,
  },
  {
    "x": 13321,
    "y": 738,
  },
  {
    "x": 13331,
    "y": 737,
  },
  {
    "x": 13341,
    "y": 736,
  },
  {
    "x": 13351,
    "y": 735,
  },
  {
    "x": 13361,
    "y": 734,
  },
  {
    "x": 13371,
    "y": 734,
  },
  {
    "x": 13381,
    "y": 734,
  },
  {
    "x": 13391,
    "y": 731,
  },
  {
    "x": 13401,
    "y": 732,
  },
  {
    "x": 13411,
    "y": 730,
  },
  {
    "x": 13421,
    "y": 731,
  },
  {
    "x": 13431,
    "y": 730,
  },
  {
    "x": 13441,
    "y": 731,
  },
  {
    "x": 13451,
    "y": 729,
  },
  {
    "x": 13461,
    "y": 728,
  },
  {
    "x": 13471,
    "y": 728,
  },
  {
    "x": 13481,
    "y": 727,
  },
  {
    "x": 13491,
    "y": 726,
  },
  {
    "x": 13501,
    "y": 725,
  },
  {
    "x": 13511,
    "y": 724,
  },
  {
    "x": 13521,
    "y": 724,
  },
  {
    "x": 13531,
    "y": 724,
  },
  {
    "x": 13541,
    "y": 724,
  },
  {
    "x": 13551,
    "y": 722,
  },
  {
    "x": 13561,
    "y": 722,
  },
  {
    "x": 13571,
    "y": 721,
  },
  {
    "x": 13581,
    "y": 719,
  },
  {
    "x": 13591,
    "y": 718,
  },
  {
    "x": 13601,
    "y": 717,
  },
  {
    "x": 13611,
    "y": 717,
  },
  {
    "x": 13621,
    "y": 717,
  },
  {
    "x": 13631,
    "y": 737,
  },
  {
    "x": 13641,
    "y": 717,
  },
  {
    "x": 13651,
    "y": 717,
  },
  {
    "x": 13661,
    "y": 713,
  },
  {
    "x": 13671,
    "y": 712,
  },
  {
    "x": 13681,
    "y": 712,
  },
  {
    "x": 13691,
    "y": 711,
  },
  {
    "x": 13701,
    "y": 712,
  },
  {
    "x": 13711,
    "y": 711,
  },
  {
    "x": 13721,
    "y": 708,
  },
  {
    "x": 13731,
    "y": 707,
  },
  {
    "x": 13741,
    "y": 707,
  },
  {
    "x": 13751,
    "y": 707,
  },
  {
    "x": 13761,
    "y": 705,
  },
  {
    "x": 13771,
    "y": 705,
  },
  {
    "x": 13781,
    "y": 704,
  },
  {
    "x": 13791,
    "y": 704,
  },
  {
    "x": 13801,
    "y": 700,
  },
  {
    "x": 13811,
    "y": 727,
  },
  {
    "x": 13821,
    "y": 697,
  },
  {
    "x": 13831,
    "y": 698,
  },
  {
    "x": 13841,
    "y": 697,
  },
  {
    "x": 13851,
    "y": 694,
  },
  {
    "x": 13861,
    "y": 692,
  },
  {
    "x": 13871,
    "y": 692,
  },
  {
    "x": 13881,
    "y": 691,
  },
  {
    "x": 13891,
    "y": 691,
  },
  {
    "x": 13901,
    "y": 688,
  },
  {
    "x": 13911,
    "y": 685,
  },
  {
    "x": 13921,
    "y": 683,
  },
  {
    "x": 13931,
    "y": 683,
  },
  {
    "x": 13941,
    "y": 683,
  },
  {
    "x": 13951,
    "y": 680,
  },
  {
    "x": 13961,
    "y": 676,
  },
  {
    "x": 13971,
    "y": 675,
  },
  {
    "x": 13981,
    "y": 677,
  },
  {
    "x": 13991,
    "y": 680,
  },
  {
    "x": 14001,
    "y": 680,
  },
  {
    "x": 14011,
    "y": 676,
  },
  {
    "x": 14021,
    "y": 674,
  },
  {
    "x": 14031,
    "y": 673,
  },
  {
    "x": 14041,
    "y": 674,
  },
  {
    "x": 14051,
    "y": 675,
  },
  {
    "x": 14061,
    "y": 672,
  },
  {
    "x": 14071,
    "y": 670,
  },
  {
    "x": 14081,
    "y": 670,
  },
  {
    "x": 14091,
    "y": 669,
  },
  {
    "x": 14101,
    "y": 669,
  },
  {
    "x": 14111,
    "y": 671,
  },
  {
    "x": 14121,
    "y": 671,
  },
  {
    "x": 14131,
    "y": 670,
  },
  {
    "x": 14141,
    "y": 669,
  },
  {
    "x": 14151,
    "y": 666,
  },
  {
    "x": 14161,
    "y": 665,
  },
  {
    "x": 14171,
    "y": 665,
  },
  {
    "x": 14181,
    "y": 664,
  },
  {
    "x": 14191,
    "y": 663,
  },
  {
    "x": 14201,
    "y": 663,
  },
  {
    "x": 14211,
    "y": 663,
  },
  {
    "x": 14221,
    "y": 663,
  },
  {
    "x": 14231,
    "y": 663,
  },
  {
    "x": 14241,
    "y": 661,
  },
  {
    "x": 14251,
    "y": 660,
  },
  {
    "x": 14261,
    "y": 659,
  },
  {
    "x": 14271,
    "y": 658,
  },
  {
    "x": 14281,
    "y": 658,
  },
  {
    "x": 14291,
    "y": 657,
  },
  {
    "x": 14301,
    "y": 658,
  },
  {
    "x": 14311,
    "y": 658,
  },
  {
    "x": 14321,
    "y": 660,
  },
  {
    "x": 14331,
    "y": 660,
  },
  {
    "x": 14341,
    "y": 663,
  },
  {
    "x": 14351,
    "y": 662,
  },
  {
    "x": 14361,
    "y": 659,
  },
  {
    "x": 14371,
    "y": 659,
  },
  {
    "x": 14381,
    "y": 658,
  },
  {
    "x": 14391,
    "y": 657,
  },
  {
    "x": 14401,
    "y": 656,
  },
  {
    "x": 14411,
    "y": 655,
  },
  {
    "x": 14421,
    "y": 656,
  },
  {
    "x": 14431,
    "y": 658,
  },
  {
    "x": 14441,
    "y": 657,
  },
  {
    "x": 14451,
    "y": 656,
  },
  {
    "x": 14461,
    "y": 655,
  },
  {
    "x": 14471,
    "y": 654,
  },
  {
    "x": 14481,
    "y": 653,
  },
  {
    "x": 14491,
    "y": 654,
  },
  {
    "x": 14501,
    "y": 653,
  },
  {
    "x": 14511,
    "y": 652,
  },
  {
    "x": 14521,
    "y": 651,
  },
  {
    "x": 14531,
    "y": 648,
  },
  {
    "x": 14541,
    "y": 648,
  },
  {
    "x": 14551,
    "y": 647,
  },
  {
    "x": 14561,
    "y": 646,
  },
  {
    "x": 14571,
    "y": 642,
  },
  {
    "x": 14581,
    "y": 641,
  },
  {
    "x": 14591,
    "y": 642,
  },
  {
    "x": 14601,
    "y": 643,
  },
  {
    "x": 14611,
    "y": 642,
  },
  {
    "x": 14621,
    "y": 640,
  },
  {
    "x": 14631,
    "y": 637,
  },
  {
    "x": 14641,
    "y": 636,
  },
  {
    "x": 14651,
    "y": 637,
  },
  {
    "x": 14661,
    "y": 637,
  },
  {
    "x": 14671,
    "y": 634,
  },
  {
    "x": 14681,
    "y": 633,
  },
  {
    "x": 14691,
    "y": 632,
  },
  {
    "x": 14701,
    "y": 631,
  },
  {
    "x": 14711,
    "y": 631,
  },
  {
    "x": 14721,
    "y": 632,
  },
  {
    "x": 14731,
    "y": 632,
  },
  {
    "x": 14741,
    "y": 630,
  },
  {
    "x": 14751,
    "y": 628,
  },
  {
    "x": 14761,
    "y": 628,
  },
  {
    "x": 14771,
    "y": 628,
  },
  {
    "x": 14781,
    "y": 628,
  },
  {
    "x": 14791,
    "y": 628,
  },
  {
    "x": 14801,
    "y": 629,
  },
  {
    "x": 14811,
    "y": 627,
  },
  {
    "x": 14821,
    "y": 624,
  },
  {
    "x": 14831,
    "y": 624,
  },
  {
    "x": 14841,
    "y": 624,
  },
  {
    "x": 14851,
    "y": 625,
  },
  {
    "x": 14861,
    "y": 625,
  },
  {
    "x": 14871,
    "y": 624,
  },
  {
    "x": 14881,
    "y": 622,
  },
  {
    "x": 14891,
    "y": 623,
  },
  {
    "x": 14901,
    "y": 622,
  },
  {
    "x": 14911,
    "y": 623,
  },
  {
    "x": 14921,
    "y": 622,
  },
  {
    "x": 14931,
    "y": 620,
  },
  {
    "x": 14941,
    "y": 620,
  },
  {
    "x": 14951,
    "y": 621,
  },
  {
    "x": 14961,
    "y": 621,
  },
  {
    "x": 14971,
    "y": 620,
  },
  {
    "x": 14981,
    "y": 619,
  },
  {
    "x": 14991,
    "y": 618,
  },
  {
    "x": 15001,
    "y": 618,
  },
  {
    "x": 15011,
    "y": 617,
  },
  {
    "x": 15021,
    "y": 616,
  },
  {
    "x": 15031,
    "y": 615,
  },
  {
    "x": 15041,
    "y": 614,
  },
  {
    "x": 15051,
    "y": 611,
  },
  {
    "x": 15061,
    "y": 606,
  },
  {
    "x": 15071,
    "y": 602,
  },
  {
    "x": 15081,
    "y": 600,
  },
  {
    "x": 15091,
    "y": 600,
  },
  {
    "x": 15101,
    "y": 602,
  },
  {
    "x": 15111,
    "y": 600,
  },
  {
    "x": 15121,
    "y": 598,
  },
  {
    "x": 15131,
    "y": 595,
  },
  {
    "x": 15141,
    "y": 595,
  },
  {
    "x": 15151,
    "y": 598,
  },
  {
    "x": 15161,
    "y": 598,
  },
  {
    "x": 15171,
    "y": 595,
  },
  {
    "x": 15181,
    "y": 592,
  },
  {
    "x": 15191,
    "y": 592,
  },
  {
    "x": 15201,
    "y": 592,
  },
  {
    "x": 15211,
    "y": 592,
  },
  {
    "x": 15221,
    "y": 591,
  },
  {
    "x": 15231,
    "y": 589,
  },
  {
    "x": 15241,
    "y": 588,
  },
  {
    "x": 15251,
    "y": 587,
  },
  {
    "x": 15261,
    "y": 586,
  },
  {
    "x": 15271,
    "y": 585,
  },
  {
    "x": 15281,
    "y": 584,
  },
  {
    "x": 15291,
    "y": 582,
  },
  {
    "x": 15301,
    "y": 578,
  },
  {
    "x": 15311,
    "y": 577,
  },
  {
    "x": 15321,
    "y": 580,
  },
  {
    "x": 15331,
    "y": 578,
  },
  {
    "x": 15341,
    "y": 572,
  },
  {
    "x": 15351,
    "y": 577,
  },
  {
    "x": 15361,
    "y": 577,
  },
  {
    "x": 15371,
    "y": 576,
  },
  {
    "x": 15381,
    "y": 576,
  },
  {
    "x": 15391,
    "y": 574,
  },
  {
    "x": 15401,
    "y": 573,
  },
  {
    "x": 15411,
    "y": 572,
  },
  {
    "x": 15421,
    "y": 572,
  },
  {
    "x": 15431,
    "y": 571,
  },
  {
    "x": 15441,
    "y": 570,
  },
  {
    "x": 15451,
    "y": 569,
  },
  {
    "x": 15461,
    "y": 567,
  },
  {
    "x": 15471,
    "y": 568,
  },
  {
    "x": 15481,
    "y": 568,
  },
  {
    "x": 15491,
    "y": 567,
  },
  {
    "x": 15501,
    "y": 567,
  },
  {
    "x": 15511,
    "y": 566,
  },
  {
    "x": 15521,
    "y": 565,
  },
  {
    "x": 15531,
    "y": 565,
  },
  {
    "x": 15541,
    "y": 565,
  },
  {
    "x": 15551,
    "y": 564,
  },
  {
    "x": 15561,
    "y": 564,
  },
  {
    "x": 15571,
    "y": 562,
  },
  {
    "x": 15581,
    "y": 562,
  },
  {
    "x": 15591,
    "y": 562,
  },
  {
    "x": 15601,
    "y": 561,
  },
  {
    "x": 15611,
    "y": 559,
  },
  {
    "x": 15621,
    "y": 557,
  },
  {
    "x": 15631,
    "y": 555,
  },
  {
    "x": 15641,
    "y": 555,
  },
  {
    "x": 15651,
    "y": 554,
  },
  {
    "x": 15661,
    "y": 554,
  },
  {
    "x": 15671,
    "y": 554,
  },
  {
    "x": 15681,
    "y": 553,
  },
  {
    "x": 15691,
    "y": 552,
  },
  {
    "x": 15701,
    "y": 552,
  },
  {
    "x": 15711,
    "y": 551,
  },
  {
    "x": 15721,
    "y": 549,
  },
  {
    "x": 15731,
    "y": 546,
  },
  {
    "x": 15741,
    "y": 544,
  },
  {
    "x": 15751,
    "y": 543,
  },
  {
    "x": 15761,
    "y": 542,
  },
  {
    "x": 15771,
    "y": 541,
  },
  {
    "x": 15781,
    "y": 541,
  },
  {
    "x": 15791,
    "y": 540,
  },
  {
    "x": 15801,
    "y": 539,
  },
  {
    "x": 15811,
    "y": 537,
  },
  {
    "x": 15821,
    "y": 536,
  },
  {
    "x": 15831,
    "y": 535,
  },
  {
    "x": 15841,
    "y": 534,
  },
  {
    "x": 15851,
    "y": 534,
  },
  {
    "x": 15861,
    "y": 533,
  },
  {
    "x": 15871,
    "y": 532,
  },
  {
    "x": 15881,
    "y": 531,
  },
  {
    "x": 15891,
    "y": 531,
  },
  {
    "x": 15901,
    "y": 531,
  },
  {
    "x": 15911,
    "y": 530,
  },
  {
    "x": 15921,
    "y": 530,
  },
  {
    "x": 15931,
    "y": 532,
  },
  {
    "x": 15941,
    "y": 532,
  },
  {
    "x": 15951,
    "y": 532,
  },
  {
    "x": 15961,
    "y": 532,
  },
  {
    "x": 15971,
    "y": 533,
  },
  {
    "x": 15981,
    "y": 533,
  },
  {
    "x": 15991,
    "y": 533,
  },
  {
    "x": 16001,
    "y": 533,
  },
  {
    "x": 16011,
    "y": 532,
  },
  {
    "x": 16021,
    "y": 531,
  },
  {
    "x": 16031,
    "y": 531,
  },
  {
    "x": 16041,
    "y": 530,
  },
  {
    "x": 16051,
    "y": 530,
  },
  {
    "x": 16061,
    "y": 528,
  },
  {
    "x": 16071,
    "y": 529,
  },
  {
    "x": 16081,
    "y": 529,
  },
  {
    "x": 16091,
    "y": 528,
  },
  {
    "x": 16101,
    "y": 527,
  },
  {
    "x": 16111,
    "y": 525,
  },
  {
    "x": 16121,
    "y": 526,
  },
  {
    "x": 16131,
    "y": 525,
  },
  {
    "x": 16141,
    "y": 524,
  },
  {
    "x": 16151,
    "y": 523,
  },
  {
    "x": 16161,
    "y": 523,
  },
  {
    "x": 16171,
    "y": 523,
  },
  {
    "x": 16181,
    "y": 523,
  },
  {
    "x": 16191,
    "y": 522,
  },
  {
    "x": 16201,
    "y": 521,
  },
  {
    "x": 16211,
    "y": 520,
  },
  {
    "x": 16221,
    "y": 519,
  },
  {
    "x": 16231,
    "y": 518,
  },
  {
    "x": 16241,
    "y": 518,
  },
  {
    "x": 16251,
    "y": 517,
  },
  {
    "x": 16261,
    "y": 516,
  },
  {
    "x": 16271,
    "y": 515,
  },
  {
    "x": 16281,
    "y": 514,
  },
  {
    "x": 16291,
    "y": 514,
  },
  {
    "x": 16301,
    "y": 512,
  },
  {
    "x": 16311,
    "y": 510,
  },
  {
    "x": 16321,
    "y": 510,
  },
  {
    "x": 16331,
    "y": 493,
  },
  {
    "x": 16341,
    "y": 509,
  },
  {
    "x": 16351,
    "y": 509,
  },
  {
    "x": 16361,
    "y": 508,
  },
  {
    "x": 16371,
    "y": 508,
  },
  {
    "x": 16381,
    "y": 507,
  },
  {
    "x": 16391,
    "y": 505,
  },
  {
    "x": 16401,
    "y": 504,
  },
  {
    "x": 16411,
    "y": 503,
  },
  {
    "x": 16421,
    "y": 492,
  },
  {
    "x": 16431,
    "y": 502,
  },
  {
    "x": 16441,
    "y": 501,
  },
  {
    "x": 16451,
    "y": 502,
  },
  {
    "x": 16461,
    "y": 501,
  },
  {
    "x": 16471,
    "y": 500,
  },
  {
    "x": 16481,
    "y": 500,
  },
  {
    "x": 16491,
    "y": 498,
  },
  {
    "x": 16501,
    "y": 495,
  },
  {
    "x": 16511,
    "y": 489,
  },
  {
    "x": 16521,
    "y": 492,
  },
  {
    "x": 16531,
    "y": 494,
  },
  {
    "x": 16541,
    "y": 495,
  },
  {
    "x": 16551,
    "y": 496,
  },
  {
    "x": 16561,
    "y": 494,
  },
  {
    "x": 16571,
    "y": 493,
  },
  {
    "x": 16581,
    "y": 490,
  },
  {
    "x": 16591,
    "y": 489,
  },
  {
    "x": 16601,
    "y": 489,
  },
  {
    "x": 16611,
    "y": 490,
  },
  {
    "x": 16621,
    "y": 490,
  },
  {
    "x": 16631,
    "y": 490,
  },
  {
    "x": 16641,
    "y": 484,
  },
  {
    "x": 16651,
    "y": 482,
  },
  {
    "x": 16661,
    "y": 481,
  },
  {
    "x": 16671,
    "y": 481,
  },
  {
    "x": 16681,
    "y": 482,
  },
  {
    "x": 16691,
    "y": 481,
  },
  {
    "x": 16701,
    "y": 479,
  },
  {
    "x": 16711,
    "y": 477,
  },
  {
    "x": 16721,
    "y": 476,
  },
  {
    "x": 16731,
    "y": 476,
  },
  {
    "x": 16741,
    "y": 474,
  },
  {
    "x": 16751,
    "y": 471,
  },
  {
    "x": 16761,
    "y": 472,
  },
  {
    "x": 16771,
    "y": 474,
  },
  {
    "x": 16781,
    "y": 472,
  },
  {
    "x": 16791,
    "y": 467,
  },
  {
    "x": 16801,
    "y": 466,
  },
  {
    "x": 16811,
    "y": 463,
  },
  {
    "x": 16821,
    "y": 460,
  },
  {
    "x": 16831,
    "y": 458,
  },
  {
    "x": 16841,
    "y": 458,
  },
  {
    "x": 16851,
    "y": 460,
  },
  {
    "x": 16861,
    "y": 458,
  },
  {
    "x": 16871,
    "y": 421,
  },
  {
    "x": 16881,
    "y": 453,
  },
  {
    "x": 16891,
    "y": 452,
  },
  {
    "x": 16901,
    "y": 451,
  },
  {
    "x": 16911,
    "y": 452,
  },
  {
    "x": 16921,
    "y": 452,
  },
  {
    "x": 16931,
    "y": 453,
  },
  {
    "x": 16941,
    "y": 453,
  },
  {
    "x": 16951,
    "y": 454,
  },
  {
    "x": 16961,
    "y": 456,
  },
  {
    "x": 16971,
    "y": 457,
  },
  {
    "x": 16981,
    "y": 462,
  },
  {
    "x": 16991,
    "y": 463,
  },
  {
    "x": 17001,
    "y": 471,
  },
  {
    "x": 17011,
    "y": 473,
  },
  {
    "x": 17021,
    "y": 477,
  },
  {
    "x": 17031,
    "y": 482,
  },
  {
    "x": 17041,
    "y": 489,
  },
  {
    "x": 17051,
    "y": 494,
  },
  {
    "x": 17061,
    "y": 504,
  },
  {
    "x": 17071,
    "y": 507,
  },
  {
    "x": 17078,
    "y": 512,
  },
  {
    "x": 17079,
    "y": 513,
  },
  {
    "x": 17081,
    "y": 515,
  },
  {
    "x": 17091,
    "y": 522,
  },
  {
    "x": 17101,
    "y": 534,
  },
  {
    "x": 17111,
    "y": 541,
  },
  {
    "x": 17121,
    "y": 548,
  },
  {
    "x": 17131,
    "y": 558,
  },
  {
    "x": 17141,
    "y": 565,
  },
  {
    "x": 17151,
    "y": 569,
  },
  {
    "x": 17162,
    "y": 579,
  },
  {
    "x": 17172,
    "y": 589,
  },
  {
    "x": 17182,
    "y": 601,
  },
  {
    "x": 17192,
    "y": 616,
  },
  {
    "x": 17202,
    "y": 630,
  },
  {
    "x": 17212,
    "y": 639,
  },
  {
    "x": 17222,
    "y": 645,
  },
  {
    "x": 17232,
    "y": 655,
  },
  {
    "x": 17242,
    "y": 660,
  },
  {
    "x": 17252,
    "y": 672,
  },
  {
    "x": 17262,
    "y": 681,
  },
  {
    "x": 17272,
    "y": 690,
  },
  {
    "x": 17282,
    "y": 701,
  },
  {
    "x": 17292,
    "y": 716,
  },
  {
    "x": 17302,
    "y": 727,
  },
  {
    "x": 17312,
    "y": 740,
  },
  {
    "x": 17322,
    "y": 751,
  },
  {
    "x": 17332,
    "y": 761,
  },
  {
    "x": 17342,
    "y": 770,
  },
  {
    "x": 17352,
    "y": 782,
  },
  {
    "x": 17362,
    "y": 787,
  },
  {
    "x": 17372,
    "y": 796,
  },
  {
    "x": 17382,
    "y": 808,
  },
  {
    "x": 17392,
    "y": 817,
  },
  {
    "x": 17402,
    "y": 829,
  },
  {
    "x": 17412,
    "y": 835,
  },
  {
    "x": 17422,
    "y": 848,
  },
  {
    "x": 17432,
    "y": 858,
  },
  {
    "x": 17442,
    "y": 869,
  },
  {
    "x": 17452,
    "y": 874,
  },
  {
    "x": 17462,
    "y": 884,
  },
  {
    "x": 17472,
    "y": 888,
  },
  {
    "x": 17482,
    "y": 894,
  },
  {
    "x": 17493,
    "y": 896,
  },
  {
    "x": 17503,
    "y": 902,
  },
  {
    "x": 17513,
    "y": 909,
  },
  {
    "x": 17523,
    "y": 916,
  },
  {
    "x": 17533,
    "y": 919,
  },
  {
    "x": 17543,
    "y": 925,
  },
  {
    "x": 17553,
    "y": 926,
  },
  {
    "x": 17563,
    "y": 929,
  },
  {
    "x": 17573,
    "y": 933,
  },
  {
    "x": 17583,
    "y": 935,
  },
  {
    "x": 17593,
    "y": 942,
  },
  {
    "x": 17603,
    "y": 940,
  },
  {
    "x": 17613,
    "y": 941,
  },
  {
    "x": 17623,
    "y": 942,
  },
  {
    "x": 17633,
    "y": 943,
  },
  {
    "x": 17643,
    "y": 943,
  },
  {
    "x": 17653,
    "y": 943,
  },
  {
    "x": 17663,
    "y": 943,
  },
  {
    "x": 17673,
    "y": 939,
  },
  {
    "x": 17683,
    "y": 897,
  },
  {
    "x": 17693,
    "y": 934,
  },
  {
    "x": 17703,
    "y": 931,
  },
  {
    "x": 17713,
    "y": 924,
  },
  {
    "x": 17723,
    "y": 916,
  },
  {
    "x": 17733,
    "y": 911,
  },
  {
    "x": 17743,
    "y": 906,
  },
  {
    "x": 17753,
    "y": 896,
  },
  {
    "x": 17763,
    "y": 893,
  },
  {
    "x": 17773,
    "y": 858,
  },
  {
    "x": 17783,
    "y": 882,
  },
  {
    "x": 17793,
    "y": 877,
  },
  {
    "x": 17802,
    "y": 870,
  },
  {
    "x": 17812,
    "y": 865,
  },
  {
    "x": 17822,
    "y": 856,
  },
  {
    "x": 17832,
    "y": 848,
  },
  {
    "x": 17842,
    "y": 838,
  },
  {
    "x": 17852,
    "y": 824,
  },
  {
    "x": 17862,
    "y": 787,
  },
  {
    "x": 17872,
    "y": 814,
  },
  {
    "x": 17882,
    "y": 803,
  },
  {
    "x": 17892,
    "y": 793,
  },
  {
    "x": 17902,
    "y": 790,
  },
  {
    "x": 17912,
    "y": 785,
  },
  {
    "x": 17922,
    "y": 778,
  },
  {
    "x": 17932,
    "y": 772,
  },
  {
    "x": 17942,
    "y": 767,
  },
  {
    "x": 17952,
    "y": 737,
  },
  {
    "x": 17962,
    "y": 749,
  },
  {
    "x": 17972,
    "y": 742,
  },
  {
    "x": 17982,
    "y": 736,
  },
  {
    "x": 17992,
    "y": 728,
  },
  {
    "x": 18002,
    "y": 723,
  },
  {
    "x": 18012,
    "y": 720,
  },
  {
    "x": 18022,
    "y": 711,
  },
  {
    "x": 18032,
    "y": 711,
  },
  {
    "x": 18042,
    "y": 687,
  },
  {
    "x": 18052,
    "y": 699,
  },
  {
    "x": 18062,
    "y": 696,
  },
  {
    "x": 18072,
    "y": 693,
  },
  {
    "x": 18082,
    "y": 685,
  },
  {
    "x": 18092,
    "y": 680,
  },
  {
    "x": 18102,
    "y": 675,
  },
  {
    "x": 18112,
    "y": 667,
  },
  {
    "x": 18122,
    "y": 660,
  },
  {
    "x": 18132,
    "y": 644,
  },
  {
    "x": 18142,
    "y": 654,
  },
  {
    "x": 18152,
    "y": 648,
  },
  {
    "x": 18162,
    "y": 644,
  },
  {
    "x": 18172,
    "y": 640,
  },
  {
    "x": 18182,
    "y": 636,
  },
  {
    "x": 18192,
    "y": 634,
  },
  {
    "x": 18202,
    "y": 629,
  },
  {
    "x": 18212,
    "y": 629,
  },
  {
    "x": 18222,
    "y": 623,
  },
  {
    "x": 18232,
    "y": 619,
  },
  {
    "x": 18242,
    "y": 620,
  },
  {
    "x": 18252,
    "y": 612,
  },
  {
    "x": 18262,
    "y": 610,
  },
  {
    "x": 18272,
    "y": 609,
  },
  {
    "x": 18282,
    "y": 605,
  },
  {
    "x": 18292,
    "y": 603,
  },
  {
    "x": 18302,
    "y": 601,
  },
  {
    "x": 18312,
    "y": 598,
  },
  {
    "x": 18322,
    "y": 597,
  },
  {
    "x": 18332,
    "y": 594,
  },
  {
    "x": 18342,
    "y": 593,
  },
  {
    "x": 18352,
    "y": 591,
  },
  {
    "x": 18362,
    "y": 588,
  },
  {
    "x": 18372,
    "y": 587,
  },
  {
    "x": 18382,
    "y": 582,
  },
  {
    "x": 18392,
    "y": 579,
  },
  {
    "x": 18402,
    "y": 577,
  },
  {
    "x": 18412,
    "y": 574,
  },
  {
    "x": 18422,
    "y": 573,
  },
  {
    "x": 18432,
    "y": 566,
  },
  {
    "x": 18442,
    "y": 568,
  },
  {
    "x": 18452,
    "y": 561,
  },
  {
    "x": 18462,
    "y": 559,
  },
  {
    "x": 18472,
    "y": 558,
  },
  {
    "x": 18482,
    "y": 558,
  },
  {
    "x": 18492,
    "y": 555,
  },
  {
    "x": 18502,
    "y": 552,
  },
  {
    "x": 18512,
    "y": 551,
  },
  {
    "x": 18522,
    "y": 549,
  },
  {
    "x": 18532,
    "y": 549,
  },
  {
    "x": 18542,
    "y": 547,
  },
  {
    "x": 18552,
    "y": 548,
  },
  {
    "x": 18562,
    "y": 545,
  },
  {
    "x": 18572,
    "y": 543,
  },
  {
    "x": 18582,
    "y": 541,
  },
  {
    "x": 18592,
    "y": 541,
  },
  {
    "x": 18602,
    "y": 537,
  },
  {
    "x": 18612,
    "y": 537,
  },
  {
    "x": 18622,
    "y": 535,
  },
  {
    "x": 18632,
    "y": 532,
  },
  {
    "x": 18642,
    "y": 534,
  },
  {
    "x": 18652,
    "y": 529,
  },
  {
    "x": 18662,
    "y": 526,
  },
  {
    "x": 18672,
    "y": 525,
  },
  {
    "x": 18682,
    "y": 527,
  },
  {
    "x": 18692,
    "y": 524,
  },
  {
    "x": 18702,
    "y": 522,
  },
  {
    "x": 18712,
    "y": 520,
  },
  {
    "x": 18722,
    "y": 517,
  },
  {
    "x": 18732,
    "y": 516,
  },
  {
    "x": 18742,
    "y": 516,
  },
  {
    "x": 18752,
    "y": 515,
  },
  {
    "x": 18762,
    "y": 512,
  },
  {
    "x": 18772,
    "y": 511,
  },
  {
    "x": 18782,
    "y": 510,
  },
  {
    "x": 18792,
    "y": 511,
  },
  {
    "x": 18802,
    "y": 510,
  },
  {
    "x": 18812,
    "y": 510,
  },
  {
    "x": 18822,
    "y": 511,
  },
  {
    "x": 18832,
    "y": 511,
  },
  {
    "x": 18842,
    "y": 511,
  },
  {
    "x": 18852,
    "y": 511,
  },
  {
    "x": 18862,
    "y": 511,
  },
  {
    "x": 18872,
    "y": 510,
  },
  {
    "x": 18882,
    "y": 510,
  },
  {
    "x": 18892,
    "y": 510,
  },
  {
    "x": 18902,
    "y": 509,
  },
  {
    "x": 18912,
    "y": 509,
  },
  {
    "x": 18922,
    "y": 508,
  },
  {
    "x": 18932,
    "y": 507,
  },
  {
    "x": 18942,
    "y": 507,
  },
  {
    "x": 18952,
    "y": 507,
  },
  {
    "x": 18962,
    "y": 506,
  },
  {
    "x": 18972,
    "y": 506,
  },
  {
    "x": 18982,
    "y": 505,
  },
  {
    "x": 18992,
    "y": 502,
  },
  {
    "x": 19002,
    "y": 501,
  },
  {
    "x": 19012,
    "y": 501,
  },
  {
    "x": 19022,
    "y": 501,
  },
  {
    "x": 19032,
    "y": 502,
  },
  {
    "x": 19042,
    "y": 501,
  },
  {
    "x": 19052,
    "y": 500,
  },
  {
    "x": 19062,
    "y": 499,
  },
  {
    "x": 19072,
    "y": 500,
  },
  {
    "x": 19082,
    "y": 499,
  },
  {
    "x": 19092,
    "y": 499,
  },
  {
    "x": 19102,
    "y": 497,
  },
  {
    "x": 19112,
    "y": 497,
  },
  {
    "x": 19122,
    "y": 497,
  },
  {
    "x": 19132,
    "y": 499,
  },
  {
    "x": 19142,
    "y": 498,
  },
  {
    "x": 19152,
    "y": 497,
  },
  {
    "x": 19162,
    "y": 495,
  },
  {
    "x": 19172,
    "y": 494,
  },
  {
    "x": 19182,
    "y": 493,
  },
  {
    "x": 19192,
    "y": 493,
  },
  {
    "x": 19202,
    "y": 492,
  },
  {
    "x": 19212,
    "y": 494,
  },
  {
    "x": 19222,
    "y": 494,
  },
  {
    "x": 19232,
    "y": 490,
  },
  {
    "x": 19242,
    "y": 488,
  },
  {
    "x": 19252,
    "y": 487,
  },
  {
    "x": 19262,
    "y": 486,
  },
  {
    "x": 19272,
    "y": 486,
  },
  {
    "x": 19282,
    "y": 486,
  },
  {
    "x": 19292,
    "y": 483,
  },
  {
    "x": 19302,
    "y": 482,
  },
  {
    "x": 19312,
    "y": 481,
  },
  {
    "x": 19322,
    "y": 479,
  },
  {
    "x": 19332,
    "y": 478,
  },
  {
    "x": 19342,
    "y": 477,
  },
  {
    "x": 19352,
    "y": 478,
  },
  {
    "x": 19362,
    "y": 477,
  },
  {
    "x": 19372,
    "y": 477,
  },
  {
    "x": 19382,
    "y": 476,
  },
  {
    "x": 19392,
    "y": 475,
  },
  {
    "x": 19402,
    "y": 474,
  },
  {
    "x": 19412,
    "y": 473,
  },
  {
    "x": 19422,
    "y": 470,
  },
  {
    "x": 19432,
    "y": 467,
  },
  {
    "x": 19442,
    "y": 465,
  },
  {
    "x": 19452,
    "y": 464,
  },
  {
    "x": 19462,
    "y": 465,
  },
  {
    "x": 19472,
    "y": 465,
  },
  {
    "x": 19482,
    "y": 462,
  },
  {
    "x": 19492,
    "y": 460,
  },
  {
    "x": 19502,
    "y": 458,
  },
  {
    "x": 19512,
    "y": 456,
  },
  {
    "x": 19522,
    "y": 454,
  },
  {
    "x": 19532,
    "y": 451,
  },
  {
    "x": 19542,
    "y": 450,
  },
  {
    "x": 19552,
    "y": 449,
  },
  {
    "x": 19562,
    "y": 447,
  },
  {
    "x": 19572,
    "y": 444,
  },
  {
    "x": 19582,
    "y": 444,
  },
  {
    "x": 19592,
    "y": 444,
  },
  {
    "x": 19602,
    "y": 443,
  },
  {
    "x": 19612,
    "y": 440,
  },
  {
    "x": 19622,
    "y": 438,
  },
  {
    "x": 19632,
    "y": 437,
  },
  {
    "x": 19642,
    "y": 435,
  },
  {
    "x": 19652,
    "y": 434,
  },
  {
    "x": 19662,
    "y": 434,
  },
  {
    "x": 19672,
    "y": 433,
  },
  {
    "x": 19682,
    "y": 432,
  },
  {
    "x": 19692,
    "y": 432,
  },
  {
    "x": 19702,
    "y": 431,
  },
  {
    "x": 19712,
    "y": 429,
  },
  {
    "x": 19722,
    "y": 428,
  },
  {
    "x": 19732,
    "y": 427,
  },
  {
    "x": 19742,
    "y": 426,
  },
  {
    "x": 19752,
    "y": 425,
  },
  {
    "x": 19762,
    "y": 424,
  },
  {
    "x": 19772,
    "y": 424,
  },
  {
    "x": 19782,
    "y": 424,
  },
  {
    "x": 19792,
    "y": 423,
  },
  {
    "x": 19802,
    "y": 423,
  },
  {
    "x": 19812,
    "y": 423,
  },
  {
    "x": 19822,
    "y": 422,
  },
  {
    "x": 19832,
    "y": 421,
  },
  {
    "x": 19842,
    "y": 421,
  },
  {
    "x": 19852,
    "y": 421,
  },
  {
    "x": 19862,
    "y": 421,
  },
  {
    "x": 19872,
    "y": 422,
  },
  {
    "x": 19882,
    "y": 423,
  },
  {
    "x": 19892,
    "y": 424,
  },
  {
    "x": 19902,
    "y": 425,
  },
  {
    "x": 19912,
    "y": 427,
  },
  {
    "x": 19922,
    "y": 432,
  },
  {
    "x": 19932,
    "y": 434,
  },
  {
    "x": 19942,
    "y": 437,
  },
  {
    "x": 19952,
    "y": 442,
  },
  {
    "x": 19962,
    "y": 442,
  },
  {
    "x": 19972,
    "y": 445,
  },
  {
    "x": 19982,
    "y": 445,
  },
  {
    "x": 19992,
    "y": 451,
  },
  {
    "x": 20002,
    "y": 457,
  },
  {
    "x": 20012,
    "y": 457,
  },
  {
    "x": 20022,
    "y": 462,
  },
  {
    "x": 20032,
    "y": 466,
  },
  {
    "x": 20042,
    "y": 467,
  },
  {
    "x": 20052,
    "y": 471,
  },
  {
    "x": 20062,
    "y": 475,
  },
  {
    "x": 20072,
    "y": 478,
  },
  {
    "x": 20082,
    "y": 485,
  },
  {
    "x": 20092,
    "y": 488,
  },
  {
    "x": 20102,
    "y": 492,
  },
  {
    "x": 20112,
    "y": 499,
  },
  {
    "x": 20122,
    "y": 505,
  },
  {
    "x": 20132,
    "y": 511,
  },
  {
    "x": 20132,
    "y": 511,
  },
  {
    "x": 20133,
    "y": 512,
  },
  {
    "x": 20142,
    "y": 520,
  },
  {
    "x": 20152,
    "y": 523,
  },
  {
    "x": 20162,
    "y": 531,
  },
  {
    "x": 20172,
    "y": 531,
  },
  {
    "x": 20182,
    "y": 538,
  },
  {
    "x": 20192,
    "y": 539,
  },
  {
    "x": 20202,
    "y": 548,
  },
  {
    "x": 20212,
    "y": 561,
  },
  {
    "x": 20222,
    "y": 569,
  },
  {
    "x": 20232,
    "y": 580,
  },
  {
    "x": 20242,
    "y": 582,
  },
  {
    "x": 20252,
    "y": 591,
  },
  {
    "x": 20262,
    "y": 597,
  },
  {
    "x": 20272,
    "y": 602,
  },
  {
    "x": 20282,
    "y": 606,
  },
  {
    "x": 20292,
    "y": 614,
  },
  {
    "x": 20302,
    "y": 621,
  },
  {
    "x": 20312,
    "y": 637,
  },
  {
    "x": 20322,
    "y": 645,
  },
  {
    "x": 20332,
    "y": 652,
  },
  {
    "x": 20342,
    "y": 659,
  },
  {
    "x": 20352,
    "y": 666,
  },
  {
    "x": 20362,
    "y": 670,
  },
  {
    "x": 20372,
    "y": 677,
  },
  {
    "x": 20382,
    "y": 684,
  },
  {
    "x": 20392,
    "y": 695,
  },
  {
    "x": 20402,
    "y": 699,
  },
  {
    "x": 20413,
    "y": 714,
  },
  {
    "x": 20423,
    "y": 723,
  },
  {
    "x": 20433,
    "y": 731,
  },
  {
    "x": 20443,
    "y": 739,
  },
  {
    "x": 20453,
    "y": 744,
  },
  {
    "x": 20463,
    "y": 753,
  },
  {
    "x": 20473,
    "y": 758,
  },
  {
    "x": 20483,
    "y": 763,
  },
  {
    "x": 20493,
    "y": 776,
  },
  {
    "x": 20503,
    "y": 773,
  },
  {
    "x": 20513,
    "y": 787,
  },
  {
    "x": 20523,
    "y": 798,
  },
  {
    "x": 20533,
    "y": 803,
  },
  {
    "x": 20543,
    "y": 814,
  },
  {
    "x": 20553,
    "y": 822,
  },
  {
    "x": 20563,
    "y": 826,
  },
  {
    "x": 20573,
    "y": 837,
  },
  {
    "x": 20583,
    "y": 847,
  },
  {
    "x": 20593,
    "y": 857,
  },
  {
    "x": 20603,
    "y": 882,
  },
  {
    "x": 20613,
    "y": 868,
  },
  {
    "x": 20623,
    "y": 874,
  },
  {
    "x": 20633,
    "y": 884,
  },
  {
    "x": 20643,
    "y": 887,
  },
  {
    "x": 20653,
    "y": 893,
  },
  {
    "x": 20663,
    "y": 906,
  },
  {
    "x": 20673,
    "y": 905,
  },
  {
    "x": 20683,
    "y": 914,
  },
  {
    "x": 20693,
    "y": 919,
  },
  {
    "x": 20703,
    "y": 929,
  },
  {
    "x": 20713,
    "y": 933,
  },
  {
    "x": 20723,
    "y": 943,
  },
  {
    "x": 20733,
    "y": 949,
  },
  {
    "x": 20743,
    "y": 954,
  },
  {
    "x": 20753,
    "y": 960,
  },
  {
    "x": 20763,
    "y": 965,
  },
  {
    "x": 20773,
    "y": 973,
  },
  {
    "x": 20783,
    "y": 981,
  },
  {
    "x": 20793,
    "y": 990,
  },
  {
    "x": 20803,
    "y": 994,
  },
  {
    "x": 20813,
    "y": 998,
  },
  {
    "x": 20823,
    "y": 1003,
  },
  {
    "x": 20833,
    "y": 1011,
  },
  {
    "x": 20844,
    "y": 1016,
  },
  {
    "x": 20854,
    "y": 1021,
  },
  {
    "x": 20864,
    "y": 1026,
  },
  {
    "x": 20874,
    "y": 1032,
  },
  {
    "x": 20884,
    "y": 1035,
  },
  {
    "x": 20894,
    "y": 1042,
  },
  {
    "x": 20904,
    "y": 1045,
  },
  {
    "x": 20914,
    "y": 1050,
  },
  {
    "x": 20924,
    "y": 1055,
  },
  {
    "x": 20934,
    "y": 1062,
  },
  {
    "x": 20944,
    "y": 1067,
  },
  {
    "x": 20954,
    "y": 1072,
  },
  {
    "x": 20964,
    "y": 1078,
  },
  {
    "x": 20974,
    "y": 1080,
  },
  {
    "x": 20984,
    "y": 1082,
  },
  {
    "x": 20994,
    "y": 1087,
  },
  {
    "x": 21004,
    "y": 1090,
  },
  {
    "x": 21014,
    "y": 1093,
  },
  {
    "x": 21024,
    "y": 1096,
  },
  {
    "x": 21034,
    "y": 1101,
  },
  {
    "x": 21044,
    "y": 1104,
  },
  {
    "x": 21054,
    "y": 1106,
  },
  {
    "x": 21057,
    "y": 1106,
  },
  {
    "x": 21059,
    "y": 1106,
  },
  {
    "x": 21064,
    "y": 1106,
  },
  {
    "x": 21074,
    "y": 1112,
  },
  {
    "x": 21084,
    "y": 1117,
  },
  {
    "x": 21094,
    "y": 1117,
  },
  {
    "x": 21104,
    "y": 1120,
  },
  {
    "x": 21114,
    "y": 1123,
  },
  {
    "x": 21124,
    "y": 1128,
  },
  {
    "x": 21134,
    "y": 1128,
  },
  {
    "x": 21144,
    "y": 1128,
  },
  {
    "x": 21154,
    "y": 1133,
  },
  {
    "x": 21164,
    "y": 1135,
  },
  {
    "x": 21174,
    "y": 1136,
  },
  {
    "x": 21184,
    "y": 1137,
  },
  {
    "x": 21194,
    "y": 1139,
  },
  {
    "x": 21204,
    "y": 1142,
  },
  {
    "x": 21214,
    "y": 1146,
  },
  {
    "x": 21224,
    "y": 1147,
  },
  {
    "x": 21234,
    "y": 1145,
  },
  {
    "x": 21244,
    "y": 1146,
  },
  {
    "x": 21254,
    "y": 1148,
  },
  {
    "x": 21264,
    "y": 1149,
  },
  {
    "x": 21274,
    "y": 1149,
  },
  {
    "x": 21284,
    "y": 1152,
  },
  {
    "x": 21294,
    "y": 1150,
  },
  {
    "x": 21304,
    "y": 1151,
  },
  {
    "x": 21314,
    "y": 1151,
  },
  {
    "x": 21324,
    "y": 1152,
  },
  {
    "x": 21334,
    "y": 1152,
  },
  {
    "x": 21344,
    "y": 1152,
  },
  {
    "x": 21354,
    "y": 1153,
  },
  {
    "x": 21364,
    "y": 1153,
  },
  {
    "x": 21374,
    "y": 1153,
  },
  {
    "x": 21384,
    "y": 1152,
  },
  {
    "x": 21394,
    "y": 1152,
  },
  {
    "x": 21404,
    "y": 1152,
  },
  {
    "x": 21414,
    "y": 1152,
  },
  {
    "x": 21424,
    "y": 1151,
  },
  {
    "x": 21434,
    "y": 1151,
  },
  {
    "x": 21444,
    "y": 1151,
  },
  {
    "x": 21454,
    "y": 1150,
  },
  {
    "x": 21464,
    "y": 1149,
  },
  {
    "x": 21474,
    "y": 1149,
  },
  {
    "x": 21484,
    "y": 1148,
  },
  {
    "x": 21494,
    "y": 1149,
  },
  {
    "x": 21504,
    "y": 1147,
  },
  {
    "x": 21514,
    "y": 1146,
  },
  {
    "x": 21524,
    "y": 1147,
  },
  {
    "x": 21534,
    "y": 1146,
  },
  {
    "x": 21544,
    "y": 1146,
  },
  {
    "x": 21554,
    "y": 1145,
  },
  {
    "x": 21564,
    "y": 1146,
  },
  {
    "x": 21574,
    "y": 1145,
  },
  {
    "x": 21584,
    "y": 1146,
  },
  {
    "x": 21594,
    "y": 1144,
  },
  {
    "x": 21604,
    "y": 1141,
  },
  {
    "x": 21614,
    "y": 1141,
  },
  {
    "x": 21624,
    "y": 1139,
  },
  {
    "x": 21634,
    "y": 1138,
  },
  {
    "x": 21644,
    "y": 1137,
  },
  {
    "x": 21654,
    "y": 1134,
  },
  {
    "x": 21664,
    "y": 1134,
  },
  {
    "x": 21674,
    "y": 1133,
  },
  {
    "x": 21684,
    "y": 1131,
  },
  {
    "x": 21694,
    "y": 1132,
  },
  {
    "x": 21704,
    "y": 1131,
  },
  {
    "x": 21714,
    "y": 1131,
  },
  {
    "x": 21724,
    "y": 1131,
  },
  {
    "x": 21734,
    "y": 1130,
  },
  {
    "x": 21744,
    "y": 1129,
  },
  {
    "x": 21754,
    "y": 1129,
  },
  {
    "x": 21764,
    "y": 1128,
  },
  {
    "x": 21774,
    "y": 1127,
  },
  {
    "x": 21784,
    "y": 1126,
  },
  {
    "x": 21794,
    "y": 1125,
  },
  {
    "x": 21804,
    "y": 1123,
  },
  {
    "x": 21814,
    "y": 1122,
  },
  {
    "x": 21824,
    "y": 1121,
  },
  {
    "x": 21834,
    "y": 1121,
  },
  {
    "x": 21844,
    "y": 1121,
  },
  {
    "x": 21854,
    "y": 1120,
  },
  {
    "x": 21864,
    "y": 1121,
  },
  {
    "x": 21874,
    "y": 1123,
  },
  {
    "x": 21884,
    "y": 1123,
  },
  {
    "x": 21894,
    "y": 1122,
  },
  {
    "x": 21904,
    "y": 1121,
  },
  {
    "x": 21914,
    "y": 1119,
  },
  {
    "x": 21924,
    "y": 1117,
  },
  {
    "x": 21934,
    "y": 1117,
  },
  {
    "x": 21944,
    "y": 1115,
  },
  {
    "x": 21954,
    "y": 1115,
  },
  {
    "x": 21964,
    "y": 1114,
  },
  {
    "x": 21974,
    "y": 1113,
  },
  {
    "x": 21984,
    "y": 1111,
  },
  {
    "x": 21994,
    "y": 1112,
  },
  {
    "x": 22004,
    "y": 1109,
  },
  {
    "x": 22014,
    "y": 1108,
  },
  {
    "x": 22024,
    "y": 1107,
  },
  {
    "x": 22034,
    "y": 1106,
  },
  {
    "x": 22044,
    "y": 1106,
  },
  {
    "x": 22054,
    "y": 1106,
  },
  {
    "x": 22064,
    "y": 1105,
  },
  {
    "x": 22074,
    "y": 1105,
  },
  {
    "x": 22084,
    "y": 1104,
  },
  {
    "x": 22094,
    "y": 1104,
  },
  {
    "x": 22104,
    "y": 1106,
  },
  {
    "x": 22114,
    "y": 1106,
  },
  {
    "x": 22124,
    "y": 1105,
  },
  {
    "x": 22134,
    "y": 1105,
  },
  {
    "x": 22144,
    "y": 1104,
  },
  {
    "x": 22154,
    "y": 1103,
  },
  {
    "x": 22164,
    "y": 1103,
  },
  {
    "x": 22174,
    "y": 1102,
  },
  {
    "x": 22184,
    "y": 1101,
  },
  {
    "x": 22194,
    "y": 1100,
  },
  {
    "x": 22204,
    "y": 1098,
  },
  {
    "x": 22214,
    "y": 1098,
  },
  {
    "x": 22224,
    "y": 1098,
  },
  {
    "x": 22234,
    "y": 1098,
  },
  {
    "x": 22244,
    "y": 1097,
  },
  {
    "x": 22254,
    "y": 1099,
  },
  {
    "x": 22264,
    "y": 1099,
  },
  {
    "x": 22274,
    "y": 1099,
  },
  {
    "x": 22284,
    "y": 1097,
  },
  {
    "x": 22294,
    "y": 1095,
  },
  {
    "x": 22304,
    "y": 1094,
  },
  {
    "x": 22314,
    "y": 1094,
  },
  {
    "x": 22324,
    "y": 1094,
  },
  {
    "x": 22334,
    "y": 1093,
  },
  {
    "x": 22344,
    "y": 1093,
  },
  {
    "x": 22354,
    "y": 1092,
  },
  {
    "x": 22364,
    "y": 1093,
  },
  {
    "x": 22374,
    "y": 1093,
  },
  {
    "x": 22384,
    "y": 1093,
  },
  {
    "x": 22394,
    "y": 1092,
  },
  {
    "x": 22404,
    "y": 1092,
  },
  {
    "x": 22414,
    "y": 1091,
  },
  {
    "x": 22424,
    "y": 1091,
  },
  {
    "x": 22434,
    "y": 1091,
  },
  {
    "x": 22444,
    "y": 1090,
  },
  {
    "x": 22454,
    "y": 1091,
  },
  {
    "x": 22464,
    "y": 1090,
  },
  {
    "x": 22474,
    "y": 1090,
  },
  {
    "x": 22484,
    "y": 1090,
  },
  {
    "x": 22494,
    "y": 1091,
  },
  {
    "x": 22504,
    "y": 1092,
  },
  {
    "x": 22514,
    "y": 1092,
  },
  {
    "x": 22524,
    "y": 1092,
  },
  {
    "x": 22534,
    "y": 1092,
  },
  {
    "x": 22544,
    "y": 1092,
  },
  {
    "x": 22554,
    "y": 1092,
  },
  {
    "x": 22564,
    "y": 1092,
  },
  {
    "x": 22574,
    "y": 1093,
  },
  {
    "x": 22584,
    "y": 1092,
  },
  {
    "x": 22594,
    "y": 1093,
  },
  {
    "x": 22604,
    "y": 1092,
  },
  {
    "x": 22614,
    "y": 1092,
  },
  {
    "x": 22624,
    "y": 1092,
  },
  {
    "x": 22634,
    "y": 1066,
  },
  {
    "x": 22644,
    "y": 1093,
  },
  {
    "x": 22654,
    "y": 1092,
  },
  {
    "x": 22664,
    "y": 1092,
  },
  {
    "x": 22674,
    "y": 1092,
  },
  {
    "x": 22684,
    "y": 1092,
  },
  {
    "x": 22694,
    "y": 1093,
  },
  {
    "x": 22704,
    "y": 1093,
  },
  {
    "x": 22714,
    "y": 1093,
  },
  {
    "x": 22724,
    "y": 1092,
  },
  {
    "x": 22734,
    "y": 1092,
  },
  {
    "x": 22744,
    "y": 1092,
  },
  {
    "x": 22754,
    "y": 1091,
  },
  {
    "x": 22764,
    "y": 1092,
  },
  {
    "x": 22774,
    "y": 1091,
  },
  {
    "x": 22784,
    "y": 1091,
  },
  {
    "x": 22794,
    "y": 1091,
  },
  {
    "x": 22804,
    "y": 1091,
  },
  {
    "x": 22814,
    "y": 1075,
  },
  {
    "x": 22824,
    "y": 1090,
  },
  {
    "x": 22834,
    "y": 1089,
  },
  {
    "x": 22844,
    "y": 1090,
  },
  {
    "x": 22854,
    "y": 1089,
  },
  {
    "x": 22864,
    "y": 1089,
  },
  {
    "x": 22874,
    "y": 1088,
  },
  {
    "x": 22884,
    "y": 1088,
  },
  {
    "x": 22894,
    "y": 1089,
  },
  {
    "x": 22904,
    "y": 1089,
  },
  {
    "x": 22914,
    "y": 1089,
  },
  {
    "x": 22924,
    "y": 1088,
  },
  {
    "x": 22934,
    "y": 1088,
  },
  {
    "x": 22944,
    "y": 1086,
  },
  {
    "x": 22954,
    "y": 1083,
  },
  {
    "x": 22964,
    "y": 1087,
  },
  {
    "x": 22974,
    "y": 1085,
  },
  {
    "x": 22984,
    "y": 1086,
  },
  {
    "x": 22994,
    "y": 1084,
  },
  {
    "x": 23004,
    "y": 1083,
  },
  {
    "x": 23014,
    "y": 1083,
  },
  {
    "x": 23024,
    "y": 1083,
  },
  {
    "x": 23034,
    "y": 1082,
  },
  {
    "x": 23044,
    "y": 1082,
  },
  {
    "x": 23054,
    "y": 1082,
  },
  {
    "x": 23064,
    "y": 1082,
  },
  {
    "x": 23074,
    "y": 1082,
  },
  {
    "x": 23084,
    "y": 1082,
  },
  {
    "x": 23094,
    "y": 1083,
  },
  {
    "x": 23104,
    "y": 1083,
  },
  {
    "x": 23114,
    "y": 1084,
  },
  {
    "x": 23124,
    "y": 1083,
  },
  {
    "x": 23134,
    "y": 1083,
  },
  {
    "x": 23144,
    "y": 1084,
  },
  {
    "x": 23154,
    "y": 1083,
  },
  {
    "x": 23164,
    "y": 1083,
  },
  {
    "x": 23174,
    "y": 1083,
  },
  {
    "x": 23184,
    "y": 1082,
  },
  {
    "x": 23194,
    "y": 1082,
  },
  {
    "x": 23204,
    "y": 1082,
  },
  {
    "x": 23214,
    "y": 1081,
  },
  {
    "x": 23224,
    "y": 1081,
  },
  {
    "x": 23234,
    "y": 1081,
  },
  {
    "x": 23244,
    "y": 1080,
  },
  {
    "x": 23254,
    "y": 1080,
  },
  {
    "x": 23264,
    "y": 1080,
  },
  {
    "x": 23274,
    "y": 1081,
  },
  {
    "x": 23284,
    "y": 1081,
  },
  {
    "x": 23294,
    "y": 1081,
  },
  {
    "x": 23304,
    "y": 1081,
  },
  {
    "x": 23314,
    "y": 1081,
  },
  {
    "x": 23324,
    "y": 1081,
  },
  {
    "x": 23334,
    "y": 1081,
  },
  {
    "x": 23344,
    "y": 1082,
  },
  {
    "x": 23354,
    "y": 1082,
  },
  {
    "x": 23364,
    "y": 1082,
  },
  {
    "x": 23374,
    "y": 1082,
  },
  {
    "x": 23384,
    "y": 1082,
  },
  {
    "x": 23394,
    "y": 1080,
  },
  {
    "x": 23404,
    "y": 1080,
  },
  {
    "x": 23414,
    "y": 1080,
  },
  {
    "x": 23424,
    "y": 1080,
  },
  {
    "x": 23434,
    "y": 1079,
  },
  {
    "x": 23444,
    "y": 1079,
  },
  {
    "x": 23454,
    "y": 1079,
  },
  {
    "x": 23464,
    "y": 1079,
  },
  {
    "x": 23474,
    "y": 1078,
  },
  {
    "x": 23484,
    "y": 1079,
  },
  {
    "x": 23494,
    "y": 1079,
  },
  {
    "x": 23504,
    "y": 1079,
  },
  {
    "x": 23514,
    "y": 1078,
  },
  {
    "x": 23524,
    "y": 1079,
  },
  {
    "x": 23534,
    "y": 1078,
  },
  {
    "x": 23544,
    "y": 1077,
  },
  {
    "x": 23554,
    "y": 1077,
  },
  {
    "x": 23564,
    "y": 1076,
  },
  {
    "x": 23574,
    "y": 1077,
  },
  {
    "x": 23584,
    "y": 1076,
  },
  {
    "x": 23594,
    "y": 1076,
  },
  {
    "x": 23604,
    "y": 1075,
  },
  {
    "x": 23614,
    "y": 1075,
  },
  {
    "x": 23624,
    "y": 1075,
  },
  {
    "x": 23634,
    "y": 1075,
  },
  {
    "x": 23644,
    "y": 1075,
  },
  {
    "x": 23654,
    "y": 1074,
  },
  {
    "x": 23664,
    "y": 1074,
  },
  {
    "x": 23674,
    "y": 1074,
  },
  {
    "x": 23684,
    "y": 1073,
  },
  {
    "x": 23694,
    "y": 1073,
  },
  {
    "x": 23704,
    "y": 1073,
  },
  {
    "x": 23714,
    "y": 1073,
  },
  {
    "x": 23724,
    "y": 1072,
  },
  {
    "x": 23734,
    "y": 1072,
  },
  {
    "x": 23744,
    "y": 1073,
  },
];

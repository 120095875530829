// To keep sync with Protocol table
export const PROTOCOL_CODE_IF = "IF";
export const PROTOCOL_CODE_SD_SP = "SD_SP";
export const PROTOCOL_CODE_SL = "SL";
export const PROTOCOL_CODE_RM = "RM";
export const PROTOCOL_CODE_IM = "IM";
export const PROTOCOL_CODE_RFV = "RFV";
export const PROTOCOL_CODE_FE = "FE";
export const PROTOCOL_CODE_FMAX = "FMAX";
export const PROTOCOL_CODE_FMCP = "FMCP";

export type ProtocolCode =
    | typeof PROTOCOL_CODE_IF
    | typeof PROTOCOL_CODE_SD_SP
    | typeof PROTOCOL_CODE_SL
    | typeof PROTOCOL_CODE_RM
    | typeof PROTOCOL_CODE_IM
    | typeof PROTOCOL_CODE_RFV
    | typeof PROTOCOL_CODE_FE
    | typeof PROTOCOL_CODE_FMAX
    | typeof PROTOCOL_CODE_FMCP

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<{isAccordion: boolean; hasOnlyChildren: boolean; titleTypography; descriptionTypography; itemOrder: number; textElement: boolean}>()(
  (theme, props, classes) => ({
    listItemWrapper: {
      zIndex: `calc(99 - ${ props.itemOrder })`,
      position: "relative",
      padding: "16px 12px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: theme.colors.darkTint,
      color: theme.colors.pureWhite,
      "&:after": {
        content: "\"\"",
        display: "block",
        position: "absolute",
        bottom: 0,
        left: 20,
        right: 20,
        height: 1,
        zIndex: 1,
        backgroundColor: theme.colors.pureBlack,
      },
      "&:last-of-type": {
        "&:after": {
          display: "none",
        },
      },
    },
    listItemSeparator: {
      minHeight: 1,
      width: "calc(100% - 32px)",
      bottom: 0,
      left: 16,
      position: "absolute",
    },
    listItemIcon: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
    pressed: { backgroundColor: theme.colors.darkTintPressed },

    navigationItem: {
      cursor: "pointer",
      position: "relative",
      zIndex: 1,
      "&:after": {
        content: "\"\"",
        display: "block",
        position: "absolute",
        bottom: 0,
        left: 20,
        right: 20,
        height: 1,
        zIndex: 2,
        backgroundColor: theme.colors.pureBlack,
      },
      "&:last-of-type": {
        "&:after": {
          display: "none",
        },
      },
    },

    switch: {
      paddingTop: 16,
      paddingRight: 16,
    },

    listItemTextContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    hasImage: { width: "calc(100% - 100px)" },
    hasNoImage: { width: "calc(100% - 34px)" },
    hasSwitch: { width: "calc(100% - 55px)" },
    fullWidth: { width: "100%" },
    title: {
      margin: "4px 0",
      padding: 0,
      wordBreak: "break-word",
      ...(props.titleTypography ? props.titleTypography : theme.typography.RM18),
    },
    requiredAsterisk: {
      fontSize: 10,
      verticalAlign: "super",
      paddingLeft: 8,
      color: theme.colors.redSmorzed,
    },
    description: {
      margin: 0,
      padding: 0,
      marginTop: 4,
      marginBottom: 4,
      color: theme.colors.grey,
      maxWidth: "100%",
      overflow: "hidden",
      ...(props.descriptionTypography ? props.descriptionTypography : theme.typography.RR14),
      "> span": { marginRight: 8 },
    },
    childrenWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
    },
    hasChildrens: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  })
);

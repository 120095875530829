import { Sheet } from "@common/type-graphql/generated";
import { UseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { selectRunState } from "@feature/run/slice/runSlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAppSelector } from "@core/redux/store";
import { useSheetGetQuery } from "@feature/sheet/api/sheetApi";

type Output = {
  endpoints: UseQueryHookResult<any>[];
  runSheet: Sheet | null;
};

export const useRunSheet = (): Output => {
  const runState = useAppSelector(selectRunState);

  const sheetApi = useSheetGetQuery({ where: { uuid: runState.sheetUuid } }, { skip: !runState.sheetUuid });

  const {
    isApiDataReady,
    endpoints,
  } = useApiDataLogic([ sheetApi ]);

  const getRunSheet = () => {
    if (!isApiDataReady) {
      return null;
    }

    return sheetApi.data;
  };

  return {
    endpoints: endpoints,
    runSheet: getRunSheet(),
  };
};

import React, {
  useEffect,
  useState,
} from "react";
import {
  ButtonUi,
  ButtonUiProps,
} from "@ui/ButtonUi/ButtonUi";
import { IonAlert } from "@ionic/react";
import { t } from "@lingui/macro";

interface Props extends ButtonUiProps {
  min: number;
  max: number;
  step: number;
  onChange?: (value: number) => void;
  initialValue: number;
  title?: string;
  labelPre?: string;
  labelPost?: string;
}

export const ButtonInputNumberUi = (props: Props) => {
  const [
    currentValue,
    setCurrentValue,
  ] = useState<number>(props.initialValue);

  const updateValue = (number: number) => {
    let newValue = number;
    if (newValue > props.max) {
      newValue = props.max;
    } else if (newValue < props.min) {
      newValue = props.min;
    }
    newValue = Math.round(newValue * 2) / 2;
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const getLabel = () => {
    let label = "";
    if (props.labelPre) {
      label = label + props.labelPre;
    }
    label = label + currentValue;
    if (props.labelPost) {
      label = label + props.labelPost;
    }
    return label;
  };

  const edit = () => {
    setIsOpen(true);
  };

  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false);

  const onCancel = () => {
    setIsOpen(false);
  };

  const onConfirm = (input: { numberInput: string }) => {
    let value = parseFloat(input.numberInput);
    if (!value) {
      value = currentValue;
    }
    updateValue(value);
  };

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        const input = document.querySelector(".numberInput input");
        if (input) {
          (input as HTMLInputElement).focus();
        }
      }, 100);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [ isOpen ]);

  useEffect(() => {
    // Redraw the label when the initialValue changes
    setCurrentValue(props.initialValue);
  }, [ props.initialValue ]);

  return <>
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onCancel}
      className={"numberInput"}
      header={props.title ?? t`Enter the new value`}
      inputs={[
        {
          name: "numberInput",
          type: "tel",
          placeholder: props.labelPost,
          min: props.min,
          max: props.max,
          value: props.initialValue.toString(),
        },
      ]}
      buttons={[
        {
          text: t`Confirm`,
          role: "confirm",
          handler: onConfirm,
        },
      ]}
    ></IonAlert>
    <ButtonUi
      {...props}
      label={getLabel()}
      onClick={edit}
    />
  </>;
};

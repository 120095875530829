import {
  API_CACHE_TAG_SHEET,
  API_CACHE_TAG_SHEET_QUICK_VALUE,
} from "@feature/sheet/sheetConstants";
import {
  CreateOneSheetArgs,
  FindUniqueSheetArgs,
  FindUniqueTrainingArgs,
  Sheet,
  UpdateOneSheetArgs,
} from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  createOneSheetQuery,
  createOneSheetTransformResponse,
} from "@common/type-graphql/sheet/gql/sheet-create.g";
import {
  duplicateSheetQuery,
  duplicateSheetTransformResponse,
} from "@common/type-graphql/sheet/gql/sheet-duplicate.g";
import {
  sheetListQuery,
  sheetListTransformResponse,
} from "@common/type-graphql/sheet/gql/sheet-list.g";
import {
  sheetQuery,
  sheetTransformResponse,
} from "@common/type-graphql/sheet/gql/sheet-get.g";
import {
  sheetResetQuickQuery,
} from "@common/type-graphql/sheet/gql/sheet-reset-quick.g";
import {
  updateOneSheetQuery,
  updateOneSheetTransformResponse,
} from "@common/type-graphql/sheet/gql/sheet-update.g";

export const sheetApi = api.injectEndpoints({
  endpoints: builder => ({
    sheetList: builder.query<Sheet[], void>({
      query: () => ({ document: sheetListQuery }),
      transformResponse: sheetListTransformResponse,
      providesTags: (result, error, input) => {
        return getTags(API_CACHE_TAG_SHEET, result);
      },
    }),
    sheetGet: builder.query<Sheet, FindUniqueSheetArgs>({
      query: variables => ({
        document: sheetQuery,
        variables: variables,
      }),
      transformResponse: sheetTransformResponse,
      providesTags: (result, error, input) => {
        const tags = [];
        if (result.isQuick) {
          tags.push({
            type: API_CACHE_TAG_SHEET,
            id: API_CACHE_TAG_SHEET_QUICK_VALUE,
          });
        } else {
          tags.push({
            type: API_CACHE_TAG_SHEET,
            id: result.uuid,
          });
        }
        return tags;
      },
    }),
    sheetDuplicate: builder.mutation<Sheet, FindUniqueTrainingArgs>({
      query: variables => ({
        document: duplicateSheetQuery,
        variables: variables,
      }),
      transformResponse: duplicateSheetTransformResponse,
      invalidatesTags: (result, error, input) => {
        return getTags(API_CACHE_TAG_SHEET, result);
      },
    }),
    sheetCreate: builder.mutation<Sheet, CreateOneSheetArgs>({
      query: variables => ({
        document: createOneSheetQuery,
        variables: variables,
      }),
      transformResponse: createOneSheetTransformResponse,
      invalidatesTags: (result, error, input) => {
        return getTags(API_CACHE_TAG_SHEET, result);
      },
    }),
    sheetEdit: builder.mutation<Sheet, UpdateOneSheetArgs>({
      query: variables => ({
        document: updateOneSheetQuery,
        variables: variables,
      }),
      transformResponse: updateOneSheetTransformResponse,
      invalidatesTags: (result, error, input) => {
        return getTags(API_CACHE_TAG_SHEET, result);
      },
    }),
    sheetDelete: builder.mutation<Sheet, UpdateOneSheetArgs>({
      query: variables => ({
        document: updateOneSheetQuery,
        variables: variables,
      }),
      transformResponse: updateOneSheetTransformResponse,
      invalidatesTags: (result, error, input) => {
        return getTags(API_CACHE_TAG_SHEET, result);
      },
    }),
    sheetResetQuick: builder.mutation<boolean, void>({
      query: () => ({
        document: sheetResetQuickQuery,
      }),
      invalidatesTags: (result, error, input) => {
        return [
          {
            type: API_CACHE_TAG_SHEET,
            id: API_CACHE_TAG_SHEET_QUICK_VALUE,
          },
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSheetListQuery,
  useSheetGetQuery,
  useSheetDuplicateMutation,
  useSheetCreateMutation,
  useSheetEditMutation,
  useSheetDeleteMutation,
  useSheetResetQuickMutation,
} = sheetApi;

import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
} from "react";
import { t } from "@lingui/macro";
import { useStyles } from "./useStyles";

interface Props {
  disabled?: boolean;
  placeholder?: string;
  formId?: string;
  maxLength?: number;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  isValid?: boolean;
}

const phText = t`Type your message here`;

export const TextareaUi = forwardRef(({
  disabled,
  placeholder = phText,
  formId,
  maxLength,
  name,
  readOnly,
  required,
  onChange,
  onBlur,
  isValid = true,
}: Props, ref: ForwardedRef<HTMLTextAreaElement>) => {
  const {
    classes, cx,
  } = useStyles();
  return <>
    <textarea
      className={
        cx(
          classes.textAreaRoot,
          { [classes.isInvalid]: !isValid }
        )
      }
      disabled={disabled}
      placeholder={placeholder}
      form={formId}
      maxLength={maxLength}
      name={name}
      readOnly={readOnly}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
    />
  </>;
});

import {
  Color,
  HeaderBackground,
} from "@types-custom";
import {
  PHASE_FEEDBACK_IS_FAST,
  PHASE_FEEDBACK_IS_GOOD,
  PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER,
  PHASE_FEEDBACK_IS_HIGH,
  PHASE_FEEDBACK_IS_INVALID,
  PHASE_FEEDBACK_IS_LOW,
  PHASE_FEEDBACK_IS_SLOW,
  PHASE_FEEDBACK_IS_TOO_FAST,
  PHASE_FEEDBACK_IS_TOO_GOOD,
  PHASE_FEEDBACK_IS_TOO_HIGH,
  PHASE_FEEDBACK_IS_TOO_LOW,
  PHASE_FEEDBACK_IS_TOO_SLOW,
  PhaseFeedback,
} from "@common/model/Phase";
import {
  RUN_STATUS_PREPARING,
  RUN_STATUS_READY,
  RUN_STATUS_RESTING,
  RUN_STATUS_RUNNING,
  RunStatus,
} from "@feature/run/slice/runSlice";

type Output = {
  runGetHeaderBackgroundColor: (runStatus: RunStatus, phaseFeedback: PhaseFeedback) => HeaderBackground;
  runGetHeaderTextColor: (runStatus: RunStatus, backgroundColor: HeaderBackground) => Color;
  runGetFeedbackTextColor: (runStatus: RunStatus, backgroundColor: HeaderBackground) => Color;
};

export const useRunColors = (): Output => {
  const getHeaderBackgroundColor = (
    runStatus: RunStatus,
    phaseFeedback: PhaseFeedback
  ): HeaderBackground => {
    if (runStatus === RUN_STATUS_READY) {
      return "black";
    }
    if (runStatus === RUN_STATUS_PREPARING) {
      return "black";
    }
    if (runStatus === RUN_STATUS_RUNNING) {
      switch (phaseFeedback) {
        case PHASE_FEEDBACK_IS_INVALID:
          return "grey";
        case PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER:
        case PHASE_FEEDBACK_IS_GOOD:
        case PHASE_FEEDBACK_IS_TOO_GOOD:
        case PHASE_FEEDBACK_IS_LOW:
          return "neon";
        case PHASE_FEEDBACK_IS_TOO_LOW:
          return "orange";
        case PHASE_FEEDBACK_IS_HIGH:
        case PHASE_FEEDBACK_IS_TOO_HIGH:
        case PHASE_FEEDBACK_IS_SLOW:
        case PHASE_FEEDBACK_IS_FAST:
        case PHASE_FEEDBACK_IS_TOO_SLOW:
        case PHASE_FEEDBACK_IS_TOO_FAST:
          return "yellow";
        default:
          return "grey";
      }
    }
    if (runStatus === RUN_STATUS_RESTING) {
      return "black";
    }

    return "grey";
  };

  const getHeaderTextColor = (
    runStatus: RunStatus,
    phaseFeedback: PhaseFeedback
  ): Color => {
    if (runStatus === RUN_STATUS_READY) {
      return "grey";
    }
    if (runStatus === RUN_STATUS_PREPARING) {
      return "neon";
    }
    if (runStatus === RUN_STATUS_RUNNING) {
      return "pureBlack";
    }
    if (runStatus === RUN_STATUS_RESTING) {
      return "orange";
    }

    return "pureBlack";
  };

  const getFeedbackTextColor = (
    runStatus: RunStatus,
    phaseFeedback: PhaseFeedback
  ): Color => {
    if (runStatus === RUN_STATUS_READY) {
      return "neon";
    }
    if (runStatus === RUN_STATUS_PREPARING) {
      return "neon";
    }
    if (runStatus === RUN_STATUS_RUNNING) {
      return "pureBlack";
    }
    if (runStatus === RUN_STATUS_RESTING) {
      return "orange";
    }

    return "darkTint";
  };

  return {
    runGetHeaderBackgroundColor: getHeaderBackgroundColor,
    runGetHeaderTextColor: getHeaderTextColor,
    runGetFeedbackTextColor: getFeedbackTextColor,
  };
};

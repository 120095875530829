import { historyController } from "@core/redux/store";
import { usePress } from "react-aria";

export const useLogic = (props: any) => {
  const {
    pressProps,
    isPressed,
  } = usePress({});

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.href) {
      historyController.replace(props.href);
    }
  };

  return {
    pressProps: pressProps,
    isPressed: isPressed,
    handleClick: handleClick,
  };
};

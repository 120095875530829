import {
  ButtonClick,
  MultipleChildrens,
  SingleChildren,
} from "@types-custom";
import { useStyles } from "./useStyles";

interface Props {
  title?: string;
  addButton?: SingleChildren;
  children?: MultipleChildrens;
  rounded?: boolean;
  sectionListing?: boolean;
  onClick?: ButtonClick;
}

export const SectionUi = ({
  title,
  addButton,
  children,
  rounded,
  sectionListing = false,
  onClick,
}: Props) => {
  const {
    classes, cx,
  } = useStyles({ children: children });

  return <>
    <div
      className={cx({
        [classes.sectionRoot]: true,
        [classes.rounded]: rounded,
      })}
      onClick={() => {
        if (onClick) {
          onClick(null);
        }
      }}>
      {
        title &&
        <h3 className={cx({
          [classes.sectionTitle]: true,
          [classes.hasAddButton]: addButton !== null,
        })}>{title}</h3>
      }
      { addButton }
      { children }
    </div>
  </>;
};

import CapacitorStorage from "redux-persist-capacitor";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistCombineReducers,
  persistStore,
} from "redux-persist";
import { STORE_ACCOUNT_KEY } from "@feature/account/accountConstants";
import {
  STORE_DEVICE_AUTO_CONNECT_KEY,
  STORE_DEVICE_KEY,
} from "@feature/device/deviceConstants";
import { STORE_HISTORY_KEY } from "@feature/history/historyConstants";
import { STORE_LOADING_KEY } from "@feature/loading/loadingConstants";
import {
  STORE_OFFLINE_KEY,
  STORE_ONLINE_KEY,
} from "@feature/offline/offlineConstants";
import { STORE_ROUTER_KEY } from "@feature/router/routerConstants";
import {
  STORE_RUN_KEY,
  STORE_TIMER_KEY,
} from "@feature/run/runConstants";
import { STORE_SETTING_KEY } from "@feature/setting/settingConstants";
import { STORE_SUSPENSION_KEY } from "@feature/suspension/suspensionConstants";
import { STORE_TABS_KEY } from "@feature/tab/tabsConstants";
import { STORE_TOAST_KEY } from "@feature/toast/toastConstants";
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux";
import { accountApi } from "@feature/account/api/accountApi";
import { accountSlice } from "@feature/account/slice/accountSlice";
import { algorithmApi } from "@feature/algorithm/api/algorithmApi";
import { api } from "@core/api";
import { athleteApi } from "@feature/athlete/api/athleteApi";
import {
  autoBatchEnhancer,
  configureStore,
} from "@reduxjs/toolkit";
import { configurationApi } from "@feature/configuration/api/configurationApi";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import { deviceAutoConnectSlice } from "@feature/device/slice/deviceAutoConnectSlice";
import { deviceSlice } from "@feature/device/slice/deviceSlice";
import { errorHandlerMiddleware } from "@core/redux/errorHandlerMiddleware";
import { exerciseApi } from "@feature/exercise/api/exerciseApi";
import { historyApi } from "@feature/history/api/historyApi";
import { historySlice } from "@feature/history/slice/historySlice";
import { i18nRehydrateMiddleware } from "@core/redux/i18nRehydrateMiddleware";
import { loadingSlice } from "@feature/loading/slice/loadingSlice";
import { offlineMiddleware } from "@feature/offline/redux/offlineMiddleware";
import { offlineSlice } from "@feature/offline/slice/offlineSlice";
import { onlineSlice } from "@feature/offline/slice/onlineSlice";
import { powerApi } from "@feature/power/api/powerApi";
import { profileApi } from "@feature/profile/api/profileApi";
import { protocolApi } from "@feature/protocol/api/protocolApi";
import { rangeApi } from "@feature/range/api/rangeApi";
import { relationApi } from "@feature/relation/api/relationApi";
import { runSlice } from "@feature/run/slice/runSlice";
import { settingSlice } from "@feature/setting/slice/settingSlice";
import { sheetApi } from "@feature/sheet/api/sheetApi";
import { suspensionSlice } from "@feature/suspension/slice/suspensionSlice";
import { tabsSlice } from "@feature/tab/slice/tabsSlice";
import { timerSlice } from "@feature/run/slice/timerSlice";
import { toastSlice } from "@feature/toast/slice/toastSlice";
import { trainingApi } from "@feature/training/api/trainingApi";

export const STORE_ROOT_KEY = "root";

const routerSlice = createReduxHistoryContext({
  history: createBrowserHistory(),
  routerReducerKey: STORE_ROUTER_KEY,
  reduxTravelling: true,
  // selectRouterState: null,
  // savePreviousLocations: 0,
  // batch: null,
  // reachGlobalHistory: null,
});

export const persistConfig = {
  key: STORE_ROOT_KEY,
  storage: CapacitorStorage,
  timeout: 2000,
  throttle: 0,
  version: 1,
  whitelist: [
    STORE_ACCOUNT_KEY,
    STORE_RUN_KEY,
    STORE_HISTORY_KEY,
    STORE_TIMER_KEY,
    STORE_DEVICE_AUTO_CONNECT_KEY,
    STORE_SETTING_KEY,
    accountApi.reducerPath,
    algorithmApi.reducerPath,
    athleteApi.reducerPath,
    configurationApi.reducerPath,
    exerciseApi.reducerPath,
    profileApi.reducerPath,
    protocolApi.reducerPath,
    relationApi.reducerPath,
    trainingApi.reducerPath,
    rangeApi.reducerPath,
    powerApi.reducerPath,
    sheetApi.reducerPath,
  ],
  blacklist: [
    STORE_ROUTER_KEY,
    STORE_TOAST_KEY,
    STORE_TABS_KEY,
    STORE_LOADING_KEY,
    STORE_ONLINE_KEY,
    STORE_OFFLINE_KEY,
    STORE_DEVICE_KEY,
    STORE_SUSPENSION_KEY,
    historyApi.reducerPath,
  ],
};

// @todlete this save only one into different storage
// const historyApiPersistConfig = {
//   key: "historyApi",
//   storage: sqliteStoreStorage,
//   timeout: 2000,
//   throttle: 0,
//   version: 1,
// };
// const persistedHistoryApiReducer = persistReducer(historyApiPersistConfig, historyApi.reducer);

const rootReducer = {
  [STORE_ROUTER_KEY]: routerSlice.routerReducer,
  [STORE_TOAST_KEY]: toastSlice.reducer,
  [STORE_RUN_KEY]: runSlice.reducer,
  [STORE_HISTORY_KEY]: historySlice.reducer,
  [STORE_TIMER_KEY]: timerSlice.reducer,
  [STORE_TABS_KEY]: tabsSlice.reducer,
  [STORE_ACCOUNT_KEY]: accountSlice.reducer,
  [STORE_LOADING_KEY]: loadingSlice.reducer,
  [STORE_ONLINE_KEY]: onlineSlice.reducer,
  [STORE_OFFLINE_KEY]: offlineSlice.reducer,
  [STORE_DEVICE_KEY]: deviceSlice.reducer,
  [STORE_DEVICE_AUTO_CONNECT_KEY]: deviceAutoConnectSlice.reducer,
  [STORE_SETTING_KEY]: settingSlice.reducer,
  [STORE_SUSPENSION_KEY]: suspensionSlice.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [algorithmApi.reducerPath]: algorithmApi.reducer,
  [athleteApi.reducerPath]: athleteApi.reducer,
  [configurationApi.reducerPath]: configurationApi.reducer,
  [exerciseApi.reducerPath]: exerciseApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [protocolApi.reducerPath]: protocolApi.reducer,
  [relationApi.reducerPath]: relationApi.reducer,
  [trainingApi.reducerPath]: trainingApi.reducer,
  [rangeApi.reducerPath]: rangeApi.reducer,
  [powerApi.reducerPath]: powerApi.reducer,
  [sheetApi.reducerPath]: sheetApi.reducer,
  [historyApi.reducerPath]: historyApi.reducer,
  // [historyApi.reducerPath]: persistedHistoryApiReducer, // @todlete this save only one into different storage
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== "production",
  enhancers: existingEnhancers => {
    return existingEnhancers.concat(autoBatchEnhancer());
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
        ignoredActionPaths: [
          new RegExp(".*.dateTime"),
          "payload.expiresAt",
          "meta.baseQueryMeta.response.headers",
        ],
      },
    })
      .concat(routerSlice.routerMiddleware)
      .concat(api.middleware)
      .concat(offlineMiddleware)
      .concat(errorHandlerMiddleware)
      .concat(i18nRehydrateMiddleware),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export const historyController = routerSlice.createReduxHistory(store);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import {
  v4,
  v5,
} from "uuid";

export const generateUuid = (name: string, useSeed = false) => {
  const uuidBodygon = "bc211815-70c7-4769-926c-bd21d7389060";

  if (useSeed) {
    const seedUuid = v5(name, uuidBodygon);
    const newUuid = v4();
    return v5(newUuid, seedUuid);
  }

  return v5(name, uuidBodygon);
};

import {
  ButtonClick,
  Callback,
  MultipleChildrens,
} from "@types-custom";
import { EmptyListMessageUi } from "@ui/EmptyListMessageUi/EmptyListMessageUi";
import {
  IonRefresher,
  RefresherEventDetail,
} from "@ionic/react";
import { LoaderUi } from "@ui/LoaderUi/LoaderUi";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { UseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { t } from "@lingui/macro";
import { useState } from "react";

type Props = {
  endpoint: UseQueryHookResult<any>;
  children?: MultipleChildrens;
  isFullScreen?: boolean;
  useRefresher?: boolean;
  afterRefreshCallback?: Callback;
  message?: string;
  ctaLabel?: string;
  onButtonClick?: ButtonClick;
  showEmptyListMessageUi?: boolean;
}

export const ApiDataList = (props: Props) => {
  const [
    hasRefetchError,
    setHasRefetchError,
  ] = useState(false);

  const getMessage = (): string => {
    return props.message ?? t`There are no entries yet.`;
  };

  const getButtonLabel = (): string => {
    return props.ctaLabel ?? "";
  };

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    props.endpoint.refetch()
      .then(() => {
        event.detail.complete();
        if (props.afterRefreshCallback) {
          props.afterRefreshCallback();
        }
      })
      .catch(() => {
        setHasRefetchError(true);
      });
  };

  if (
    props.endpoint.isError ||
    hasRefetchError
  ) {
    return <EmptyListMessageUi
      message={t`An error occurred, please try to refetch`}
      buttonLabel={t`Refetch`}
      onClick={props.endpoint.refetch}
    />;
  }

  return <>
    {
      props.useRefresher &&
			<IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
			  <SeparatorUi marginTop={38} />
			  <LoaderUi
			    background={"transparent"}
			    appearance={"default"} />
			</IonRefresher>
    }
    {
      props.endpoint.isLoading &&
      <LoaderUi
        background={"transparent"}
        appearance={props.isFullScreen ? "fullscreen" : "default"} />
    }
    {
      !props.endpoint.isLoading && (
        !props.endpoint.data || (
          Array.isArray(props.endpoint.data) && !props.endpoint.data.length
        )
      ) &&
      props.showEmptyListMessageUi &&
		  <EmptyListMessageUi
			  message={getMessage()}
			  buttonLabel={getButtonLabel()}
			  onClick={() => {
		      if (props.onButtonClick) {
		        props.onButtonClick(null);
		      } else {
		        props.endpoint.refetch();
		      }
		    }}
		  />
    }
    {
      !props.endpoint.isLoading &&
      props.endpoint &&
      props.endpoint.data &&
      props.children &&
		  <>
		    {props.children}
		  </>
    }
  </>;
};

import { makeStyles } from "@theme/makeStyles";

interface Props {
  background?: "transparent" | "blur" | "neon";
}

export const useStyles = makeStyles<Props>()(
  (theme, props, classes) => ({
    loaderRoot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: props.background === "blur" ? theme.colors.realBlackTrasparent : props.background === "transparent" ? "transparent" : theme.colors.neon,
      backdropFilter: props.background === "blur" ? "blur(2px)" : "none",
    },
    fullScreen: {
      width: "100%",
      height: "100%",
      background: props.background === "neon" ? theme.colors.neon : theme.colors.realBlackTrasparent,
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 999,
    },
  })
);

import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import { HistoryTrainingSessionListComponent } from "@feature/history/component/historyTrainingSessionListComponent";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";
import { selectHistoryState } from "@feature/history/slice/historySlice";
import { useAppSelector } from "@core/redux/store";
import { useParams } from "react-router";

type Params = {
  page?: string;
}

export const HistoryListPage = () => {
  const historyState = useAppSelector(selectHistoryState);
  const params = useParams<Params>();
  const page = params.page || historyState.historyTrainingSessionListPage || "1";

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.historyList.title)}
        backToRoute={routes.dashboard} />
      <IonContent>
        <HistoryTrainingSessionListComponent
          urlPage={page}
        />
      </IonContent>
    </IonPage>
  </>;
};

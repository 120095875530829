import { ApiData } from "@feature/api/component/ApiData";
import {
  IonChip,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { Protocol } from "@common/type-graphql/generated";
import { documentOutline } from "ionicons/icons";
import { getProtocolNameByCode } from "@feature/protocol/service/protocolService";
import { useProtocolListQuery } from "@feature/protocol/api/protocolApi";

type Props = {
  protocolUuid: string;
}

export const ProtocolChipComponent = (props: Props) => {
  const protocolListApi = useProtocolListQuery();

  return <>
    <ApiData endpoint={protocolListApi}>
      <ProtocolChip protocol={protocolListApi.data?.find(protocol => protocol.uuid === props.protocolUuid)} />
    </ApiData>
  </>;
};

type ProtocolProps = {
  protocol: Protocol;
}

const ProtocolChip = (props: ProtocolProps) => {
  return <>
    <IonChip
      class="ionChipStyle"
      color="primary"
    >
      <IonIcon
        icon={documentOutline}
        size="small" />
      <IonLabel style={{
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      }}>
        {
          getProtocolNameByCode(props.protocol.code)
        }
      </IonLabel>
    </IonChip>
  </>;
};

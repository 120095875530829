import { confirm } from "@feature/confirm/service/confirm";
import { t } from "@lingui/macro";
import { useIonActionSheet } from "@ionic/react";
import { usePowerResetMutation } from "@feature/power/api/powerApi";

type Props = {
  athleteUuid: string;
  exerciseUuid: string;
  loadWeight: number;
}

export const usePowerReset = () => {
  const [ present ] = useIonActionSheet();
  const [ powerReset ] = usePowerResetMutation();

  const resetPower = (props: Props) => {
    powerReset({
      data: {
        athleteUuid: props.athleteUuid,
        exerciseUuid: props.exerciseUuid,
        loadWeight: props.loadWeight,
      },
    });
  };

  const resetPowerConfirm = (props: Props) => {
    return () => {
      if (
        !props.athleteUuid ||
        !props.exerciseUuid
      ) {
        return;
      }

      confirm({
        header: t`Do you want to reset your power reference?`,
        subHeader: t`Your new power reference will be calculated in the next serie`,
        present: present,
        action: () => resetPower(props),
      });
    };
  };

  return {
    resetPower: resetPower,
    resetPowerConfirm: resetPowerConfirm,
  };
};

import { EmptyListMessageUi } from "@ui/EmptyListMessageUi/EmptyListMessageUi";
import {
  IonRefresher,
  RefresherEventDetail,
} from "@ionic/react";
import { LoaderUi } from "@ui/LoaderUi/LoaderUi";
import { MultipleChildrens } from "@types-custom";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { UseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { t } from "@lingui/macro";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useState } from "react";

type Props = {
  endpoint: UseQueryHookResult<any> | UseQueryHookResult<any>[];
  children: MultipleChildrens;
  useRefresher?: boolean;
  hideLoader?: boolean;
}

export const ApiData = (props: Props) => {
  const {
    endpoints,
    isError,
    isLoading,
    isUnitialized,
    isOnline,
  } = useApiDataLogic(props.endpoint);

  const [
    hasRefetchError,
    setHasRefetchError,
  ] = useState(false);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    Promise.all(endpoints.map(api => api.refetch()))
      .then(() => {
        event.detail.complete();
      })
      .catch(() => {
        setHasRefetchError(true);
      });
  };

  if (isLoading) {
    if (props.hideLoader) {
      return <></>;
    }
    return <>
      <LoaderUi
        appearance={"fullscreen"}
        background={"blur"}
      />
    </>;
  }

  if (
    isUnitialized ||
    isError ||
    hasRefetchError
  ) {
    return <>
      <EmptyListMessageUi
        message={t`An error occurred`}
        buttonLabel={t`Refetch`}
        onClick={() => {
          for (const api of endpoints) {
            if (api.isError) {
              api.refetch();
            }
          }
        }}
      />
    </>;
  }

  for (const api of endpoints) {
    if (api.isError) {
      if (!isOnline) {
        return <>
          <EmptyListMessageUi
            message={t`Please check your internet connection`}
            buttonLabel={t`Refetch`}
            onClick={api.refetch}
          />
        </>;
      }

      return <>
        <EmptyListMessageUi
          message={t`Maintenance in progress, temporary malfunctions`}
          buttonLabel={t`Refetch`}
          onClick={api.refetch}
        />
      </>;
    }
  }

  return <>
    {
      props.useRefresher &&
		  <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
			  <SeparatorUi marginTop={38} />
			  <LoaderUi
				  background={"transparent"}
				  appearance={"default"} />
		  </IonRefresher>
    }
    {props.children}
  </>;
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<{ background }>()(
  (theme, props, classes) => ({
    badgeRoot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: -2,
      right: -2,
      zIndex: 2,
      width: 16,
      height: 16,
      position: "absolute",
      borderRadius: "50%",
      backgroundColor: theme.colors[props.background],
      transition: "background-color 0.3s",
    },
    numberRoot: {
      width: 9,
      height: 9,
      borderRadius: "50%",
      backgroundColor: theme.colors.red,
      ...theme.typography.RB12,
    },
  })
);

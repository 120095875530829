import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    root: {
      display: "flex",
      flexDirection: "row",
      gap: 2,
      color: theme.isDarkMode ? theme.colors.pureWhite : theme.colors.black,
      "& ion-chip:not(:first-of-type)": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      "& ion-chip:not(:last-child)": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      "& ion-chip": {
        marginInline: 0,
        maxWidth: "initial",
        flex: 1,
        marginTop: 0,
        marginBottom: 2,
        backgroundColor: "rgba(var(--ion-color-base-rgb), 0.08) !important",
      },
    },
  })
);

import { doubleDigits } from "./doubleDigits";

export const parseSeconds = (seconds: number): string => {
  if (seconds >= 6000 || seconds === 0) {
    return "00:00";
  }
  const m = Math.floor(seconds / 60);
  const s = seconds % 60;
  return `${ doubleDigits(m) }:${ doubleDigits(s) }`;
};

import { Training } from "@common/type-graphql/generated";
import { UseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { selectRunState } from "@feature/run/slice/runSlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAppSelector } from "@core/redux/store";
import { useTrainingGetQuery } from "@feature/training/api/trainingApi";

type Output = {
  endpoints: UseQueryHookResult<any>[];
  runTraining: Training | null;
};

export const useRunTraining = (): Output => {
  const runState = useAppSelector(selectRunState);

  const trainingApi = useTrainingGetQuery({ where: { uuid: runState.trainingUuid } }, { skip: !runState.trainingUuid });

  const {
    isApiDataReady,
    endpoints,
  } = useApiDataLogic([ trainingApi ]);

  const getRunTraining = () => {
    if (!isApiDataReady) {
      return null;
    }

    return trainingApi.data;
  };

  return {
    endpoints: endpoints,
    runTraining: getRunTraining(),
  };
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100% - 35px)",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    mainContainerWithForm: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    headerContainer: {
      minHeight: "140px",
      position: "relative",
      zIndex: 1,
    },
    contentContainer: {
      zIndex: 2,
      backgroundColor: theme.colors.pureBlack,
    },
    ionContentLoginOrSignin: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    forgotPasswordLink: {
      textAlign: "center",
      textDecoration: "underline",
      color: theme.colors.grey,
      ...theme.typography.RR12,
      ":hover": { cursor: "pointer" },
    },
    disclaimerContainer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 20,
    },
    disclaimerText: {
      maxWidth: "280px",
      opacity: 1,
      color: theme.colors.grey,
      fontSize: "12px",
      fontWeight: "400",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "center",
      lineHeight: "18px",
      a: {
        color: theme.colors.grey,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    logoFixed: {
      position: "fixed",
      top: "calc(40px + var(--ion-safe-area-top))",
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    logo: {
      position: "relative",
      left: "50%",
      padding: 40,
      transform: "translate(-50%, 0)",
      height: 150,
      display: "flex",
      justifyContent: "center",
    },
    iconButton: {
      marginLeft: "4px",
      marginRight: "4px",
    },
    forgotPasswordFormContainer: { marginBottom: "140px" },
    formInfo: {
      color: theme.colors.grey,
      ...theme.typography.RR12,
      marginTop: "32px",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      span: {
        maxWidth: "195px",
        textAlign: "center",
        padding: "0px",
      },
    },
  }));

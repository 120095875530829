import { DeviceDevelpomentFabComponent } from "@feature/device/component/deviceDevelpomentFabComponent";
import { DeviceListComponent } from "@feature/device/component/deviceListComponent";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";
import { selectRunState } from "@feature/run/slice/runSlice";
import { useAppSelector } from "@core/redux/store";

export const DeviceListPage = () => {
  const runState = useAppSelector(selectRunState);

  const backPath = runState.hasSheetUuid ?
    routes.run.redirectPath :
    routes.dashboard.routePath;

  const backTitle = runState.hasSheetUuid ?
    routes.run.title :
    routes.dashboard.title;

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.deviceList.title)}
        backToPath={backPath}
        backToTitle={backTitle}
        rightChildren={<DeviceDevelpomentFabComponent />}
      />
      <IonContent>
        <DeviceListComponent />
      </IonContent>
    </IonPage>
  </>;
};

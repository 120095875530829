import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  RUN_STATUS_PREPARING,
  RUN_STATUS_RUNNING,
  selectRunState,
} from "@feature/run/slice/runSlice";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { i18n } from "@lingui/core";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAppSelector } from "@core/redux/store";
import { useRunColors } from "@feature/run/hook/useRunColors";
import { useRunSheet } from "@feature/run/hook/useRunSheet";

export const RunHeaderComponent = () => {
  const {
    endpoints,
    runSheet,
  } = useRunSheet();

  const {
    isApiDataReady,
  } = useApiDataLogic(endpoints);

  const runState = useAppSelector(selectRunState);
  const { runGetHeaderBackgroundColor } = useRunColors();

  const backgroundColor = runGetHeaderBackgroundColor(runState.runStatus, runState.phaseFeedback);
  let title = routes.sheetEdit.title;
  let path = routes.sheetEdit.routePath.replace(getRouteUuidPlaceholder(), runState.sheetUuid);

  if (runState.runStatus === RUN_STATUS_RUNNING || runState.runStatus === RUN_STATUS_PREPARING) {
    title = "";
    path = "";
  }

  if (isApiDataReady && runSheet.isQuick) {
    title = routes.quickEdit.title;
    path = routes.quickEdit.routePath;
  }

  return <>
		  <HeaderUi
			  background={backgroundColor}
			  backToTitle={i18n._(title)}
			  backToPath={path}
		  />
  </>;
};

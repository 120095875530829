import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<{ hasTitle; background }>()(
  (theme, props, classes) => ({
    iosHeaderPlaceholder: {
      paddingTop: "var(--ion-safe-area-top)",
      width: "100%",
      backgroundColor: props.background !== "realBlack" ? theme.colors.black : theme.colors.realBlack,
    },
    headerRoot: {
      maxHeight: 166,
      minHeight: props.hasTitle ? 60 : 40,
      backgroundColor: "transparent",
      userSelect: "none",
      marginBottom: -40,
    },
  })
);

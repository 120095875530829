export const transformFormErrors = (error: any, meta: any): string => {
  const messages: string[] = [];
  if (meta.response &&
    meta.response.errors &&
    meta.response.errors.length > 0) {
    for (const responseError of meta.response.errors) {
      messages.push(responseError.message);
    }
  }
  return {
    ...error,
    message: messages.join(", "),
  };
};

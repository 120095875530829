import {
  CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL,
  CONFIGURATION_CODE_LOAD_WEIGHT_USED,
  CONFIGURATION_CODE_POWER_REFERENCE_USED,
  CONFIGURATION_CODE_RANGE_MAX_USED,
  CONFIGURATION_CODE_RANGE_MIN_USED,
  ConfigurationCode,
} from "@common/model/Configuration";
import { ConfigurationValue } from "@common/model/ConfigurationValue";
import { getUniqueId } from "@util/getUniqueId";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useStyles } from "@ui/HistorySerieDetailsUi/useStyles";

export interface HistorySerieDetailsProps {
  historySerieUuid: string;
  commentPreview?: string;
  configurations?: ConfigurationValue[];
}

const specialConfigurations: ConfigurationCode[] = [
  CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL,
  CONFIGURATION_CODE_LOAD_WEIGHT_USED,
  CONFIGURATION_CODE_POWER_REFERENCE_USED,
];

const excludedConfigurations: ConfigurationCode[] = [
  CONFIGURATION_CODE_RANGE_MIN_USED,
  CONFIGURATION_CODE_RANGE_MAX_USED,
];

export const HistorySerieDetailsUi = (props: HistorySerieDetailsProps) => {
  const styles = useStyles();

  return <>
    <div className={styles.cx({ [styles.classes.root]: true })}>
      {
        props.commentPreview &&
				<span
				  className={styles.cx({ [styles.classes.comment]: true })}>
				  { t`Last comment: ` } { props.commentPreview }
				</span>
      }
      {
        props.configurations &&
				<LoadWeightUsedOfInitial {...props} /> &&
				<PowerReference {...props} />
      }
      {
        props.configurations &&
				<NonSpecialConfigurations {...props} />
      }
    </div>
  </>;
};

const LoadWeightUsedOfInitial = (props: HistorySerieDetailsProps) => {
  const loadWeight = props.configurations.find(c => c.code === CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL);
  const loadWeightUsed = props.configurations.find(c => c.code === CONFIGURATION_CODE_LOAD_WEIGHT_USED);

  if (
    !loadWeight &&
    !loadWeightUsed
  ) {
    return null;
  }

  if (
    !loadWeight &&
    loadWeightUsed
  ) {
    return <>
      <span>
        { t`Load weight: ` } { loadWeightUsed.value } Kg
      </span>
    </>;
  }

  return <>
    <span>
      { t`Load weight: ` } { loadWeightUsed.value } Kg / { loadWeight.value } Kg
    </span>
  </>;
};

const PowerReference = (props: HistorySerieDetailsProps) => {
  const powerReference = props.configurations.find(c => c.code === CONFIGURATION_CODE_POWER_REFERENCE_USED);

  if (
    !powerReference
  ) {
    return null;
  }

  return <>
    <span>
      { t`Power reference: ` } { powerReference.value } W
    </span>
  </>;
};

const NonSpecialConfigurations = (props: HistorySerieDetailsProps) => {
  const otherConfigurations = props.configurations.filter(c =>
    !specialConfigurations.includes(c.code) &&
    !excludedConfigurations.includes(c.code)
  );

  return <>
    {
      otherConfigurations.map(configuration =>
        <span key={getUniqueId()}>
          {`${ i18n._(configuration.code) }_TITLE`}: {configuration.value}
        </span>
      )
    }
  </>;
};

import { STORE_ONLINE_KEY } from "@feature/offline/offlineConstants";
import { addOfflineRequest } from "@feature/offline/slice/offlineSlice";
import { offlineMiddlewares } from "@core/api";

export const offlineMiddleware = (store: any) => (next: any) => (action: any) => {
  if (
    action.type === "api/executeQuery/rejected" &&
    !store.getState()[STORE_ONLINE_KEY].isOnline
  ) {
    return;
  }

  next(action);

  if (
    action.type &&
    action.type.startsWith("api/execute") &&
    action.type.endsWith("/pending") &&
    !store.getState()[STORE_ONLINE_KEY].isOnline
  ) {
    if (action.type === "api/executeMutation/pending") {
      store.dispatch(addOfflineRequest(action));
    }

    for (const offlineMiddleware of offlineMiddlewares) {
      offlineMiddleware(store, action);
    }
  }
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    tooltipRoot: {
      visibility: "hidden",
      backgroundColor: theme.colors.black,
      color: theme.colors.grey,
      textAlign: "left",
      borderRadius: 16,
      borderBottomRightRadius: 10,
      padding: 16,
      position: "absolute",
      zIndex: 100,
      top: "82%",
      left: 8,
      transform: "translateY(-100%)",
      ...theme.typography.RR16,
      "&::after": {
        // eslint-disable-next-line
        content: '""',
        display: "block",
        position: "absolute",
        bottom: "8%",
        left: "100%",
        borderTopWidth: 8,
        borderLeftWidth: 16,
        borderBottomWidth: 8,
        borderStyle: "solid",
        pointerEvents: "none",
        borderColor: `transparent transparent transparent ${ theme.colors.black }`,
      },
    },
  })
);

// To keep sync with Chart table
export const CHART_CODE_CONTINUOUS = "CONTINUOUS";
export const CHART_CODE_FULL_CONTINUOUS = "FULL_CONTINUOUS";
export const CHART_CODE_SOLO_CONCENTRIC = "SOLO_CONCENTRIC";
export const CHART_CODE_ECCENTRIC_CONCENTRIC = "ECCENTRIC_CONCENTRIC";
export const CHART_CODE_SOLO_CONCENTRIC_AUTO = "SOLO_CONCENTRIC_AUTO";
export const CHART_CODE_POWER_BAR = "POWER_BAR";
export const CHART_CODE_AVERAGE_SPEED = "AVERAGE_SPEED";
export const CHART_CODE_AVERAGE_SPEED_BAR = "AVERAGE_SPEED_BAR";
export const CHART_CODE_DYNAMIC_STATE_SPECIFIC = "DYNAMIC_STATE_SPECIFIC";
export const CHART_CODE_JUMP = "JUMP";
export const CHART_CODE_FUNCTIONAL_ISOMETRY = "FUNCTIONAL_ISOMETRY";
export const CHART_CODE_POWER_AND_FLY_BAR = "POWER_AND_FLY_BAR";

export type ChartCode =
  | typeof CHART_CODE_CONTINUOUS
  | typeof CHART_CODE_FULL_CONTINUOUS
  | typeof CHART_CODE_SOLO_CONCENTRIC
  | typeof CHART_CODE_ECCENTRIC_CONCENTRIC
  | typeof CHART_CODE_SOLO_CONCENTRIC_AUTO
  | typeof CHART_CODE_POWER_BAR
  | typeof CHART_CODE_AVERAGE_SPEED
  | typeof CHART_CODE_AVERAGE_SPEED_BAR
  | typeof CHART_CODE_DYNAMIC_STATE_SPECIFIC
  | typeof CHART_CODE_JUMP
  | typeof CHART_CODE_FUNCTIONAL_ISOMETRY
  | typeof CHART_CODE_POWER_AND_FLY_BAR

import React, { useState } from "react";
import { ListActionBarUi } from "@ui/ListActionBarUi/ListActionBarUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { Profile } from "@common/type-graphql/generated";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import {
  historyController,
  useAppDispatch,
} from "@core/redux/store";
import { t } from "@lingui/macro";

type Props = {
  athleteUuid: string;
  data?: Profile[];
  onClick?: (uuid: string) => void;
  hideAddButtonClick?: boolean;
}

export const ProfileListComponent = (props: Props) => {
  const dispatch = useAppDispatch();

  const [
    filteredData,
    setFilteredData,
  ] = useState<Profile[]>(props.data);

  const searchChange = (value: string) => {
    setFilteredData(props.data.filter(element => element.name.toLowerCase().includes(value.toLowerCase())));
  };

  const onAddButtonClick = () => {
    historyController.replace(routes.profileCreate.routePath.replace(getRouteUuidPlaceholder("athlete"), props.athleteUuid));
  };

  const handleClick = (uuid: string) => {
    if (props.onClick) {
      props.onClick(uuid);
    } else {
      const path = routes.profileEdit.routePath
        .replace(getRouteUuidPlaceholder("athlete"), props.athleteUuid)
        .replace(getRouteUuidPlaceholder(), uuid);
      historyController.replace(path);
    }
  };

  return <>
    <ListActionBarUi
      addButtonLabel={t`Profile`}
      onAddButtonClick={!props.hideAddButtonClick && onAddButtonClick}
      onSearchChange={searchChange}
    />
    <SectionUi rounded sectionListing={true}>
      {
        filteredData.map(item =>
          <ListItemUi
            key={item.uuid}
            title={item.name}
            onClick={() => handleClick(item.uuid)}
          // avatarSrc={item.avatarSrc}
          />
        )
      }
    </SectionUi>
  </>;
};

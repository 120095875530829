import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import { Serie } from "@common/model/Serie";
import { calculateMaxPower } from "@common/service/serieService";
import { usePowerCreateMutation } from "@feature/power/api/powerApi";

export const useRunAutoUpdatePowerReference = () => {
  const [ powerCreate ] = usePowerCreateMutation();

  const checkAutoUpdatePowerReference = (props: {
    serie: Serie;
    athleteUuid: string;
    exerciseUuid: string;
    powerReference: PowerReferenceValue;
    loadWeight: number;
  }) => {
    const maxPower = calculateMaxPower(props.serie);

    if (
      !maxPower ||
      maxPower <= props.powerReference
    ) {
      return;
    }

    powerCreate({
      data: {
        Athlete: { connect: { uuid: props.athleteUuid } },
        Exercise: { connect: { uuid: props.exerciseUuid } },
        loadWeight: props.loadWeight,
        powerReference: maxPower,
        isDefault: true,
      },
    });
  };

  return {
    runCheckAutoUpdatePowerReference: checkAutoUpdatePowerReference,
  };
};

import { Callback } from "@types-custom";
import { t } from "@lingui/macro";
import { useStyles } from "./useStyles";

interface Props {
  message: string;
  buttonLabel?: string;
  onClick?: Callback;
}

export const EmptyListMessageUi = (props: Props) => {
  const {
    classes, cx,
  } = useStyles();
  return <>
    <div
      className={cx({
        [classes.emptyListMessageRoot]: true,
        [classes.fullScreen]: false,
      })}>
      <span className={classes.text}>
        { props.message }
        {
          props.buttonLabel &&
          <p className={classes.link}>
            <span onClick={() => {
              if (props.onClick) {
                props.onClick();
              }
            }}>{ props.buttonLabel ? props.buttonLabel : t`Retry now` }
            </span>
          </p>
        }
      </span>
    </div>
  </>;
};

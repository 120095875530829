import {
  CapacitorSQLite,
  SQLiteConnection,
} from "@capacitor-community/sqlite";

const sqliteConnection = new SQLiteConnection(CapacitorSQLite);

const DATABASE_NAME = "redux_kv";
const TABLE_NAME = "history";

let db = null;

async function initDBConnection() {
  if (db === null) {
    db = await sqliteConnection.createConnection(DATABASE_NAME, false, "no-encryption", 1, false);
    if (db) {
      await db.open();
    } else {
      console.error("Failed to open database");
    }
    if (db) {
      await db.execute(`CREATE TABLE IF NOT EXISTS ${ TABLE_NAME } (key TEXT PRIMARY KEY, value TEXT);`);
    } else {
      console.error("Failed to open database");
    }
  }
}

export const reduxSqliteStoreStorage = {
  getItem: async function(key) {
    await initDBConnection();
    try {
      const { values } = await db.query(`SELECT value FROM ${ TABLE_NAME } WHERE key = ?;`, [ key ]);
      if (values.length > 0) {
        return values[0].value; // Presuming your values are stored in a column named 'value'
      }
      return null; // If the key doesn't exist, return null
    } catch (e) {
      console.error("SQLite getItem error: ", e);
      throw e;
    } finally {
      //await db.close();
    }
  },
  setItem: async function(key, value) {
    await initDBConnection();
    try {
      const statement = `INSERT INTO ${ TABLE_NAME } (key, value) VALUES (?, ?) ON CONFLICT(key) DO UPDATE SET value = ?;`;
      await db.query(statement, [
        key,
        value,
        value,
      ]);
    } catch (e) {
      console.error("SQLite setItem error: ", e);
      throw e;
    } finally {
      //await db.close();
    }
  },
  removeItem: async function(key) {
    await initDBConnection();
    try {
      await db.query(`DELETE FROM ${ TABLE_NAME } WHERE key = ?;`, [ key ]);
    } catch (e) {
      console.error("SQLite removeItem error: ", e);
      throw e;
    } finally {
      //await db.close();
    }
  },
  dropDatabase: async function() {
    await initDBConnection();
    if (db) {
      await db.delete();
    }
    db = null;
    //await db.close();
  },
};

import { ButtonInputNumberUi } from "@ui/ButtonInputNumberUi/ButtonInputNumberUi";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import {
  CONFIGURATION_CODE_LOAD_WEIGHT_MAXIMAL,
  CONFIGURATION_CODE_LOAD_WEIGHT_MAX_PERCENT,
  CONFIGURATION_CODE_LOAD_WEIGHT_MIN_PERCENT,
} from "@common/model/Configuration";
import {
  Configuration,
  RelationConfiguration,
  TrainingConfigurationCreateManyTrainingInput,
} from "@common/type-graphql/generated";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import { UseFormReturn } from "react-hook-form";
import {
  getConfigurationUuidByCode,
  getValueByRelationConfigurations,
} from "@common/service/configurationService";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { useStyles } from "./useStyles";

type Props = {
  configurations: Configuration[];
  configurationUuid: string;
  form: UseFormReturn;
  relationConfigurations: RelationConfiguration[];
}

export const LoadWeightFormElement = (props: Props) => {
  const {
    classes,
    theme,
  } = useStyles();

  const loadWeightMinPercentConfiguratioUuid = getConfigurationUuidByCode(props.configurations, CONFIGURATION_CODE_LOAD_WEIGHT_MIN_PERCENT);
  const loadWeightMinPercentValue = getValueByRelationConfigurations(props.relationConfigurations, loadWeightMinPercentConfiguratioUuid);

  const loadWeightMaxPercentConfiguratioUuid = getConfigurationUuidByCode(props.configurations, CONFIGURATION_CODE_LOAD_WEIGHT_MAX_PERCENT);
  const loadWeightMaxPercentValue = getValueByRelationConfigurations(props.relationConfigurations, loadWeightMaxPercentConfiguratioUuid);

  // useEffect(() => {
  //   const existingValue = getConfigurationValue();
  //
  //   if (existingValue) {
  //     return existingValue;
  //   }
  //
  //   const maximalValue = getMaximal();
  //   if (!maximalValue) {
  //     return 0;
  //   }
  //
  //   const loadWeightInitialPercentConfiguratioUuid = getConfigurationUuidByCode(props.configurations, CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL);
  //   const loadWeightInitialPercentValue = getValueByRelationConfigurations(props.relationConfigurations, loadWeightInitialPercentConfiguratioUuid);
  //
  //   return (maximalValue / 100) * parseInt(loadWeightInitialPercentValue || "0", 10);
  //
  //   if (!getConfigurationFormValue()) {
  //     updateForm(recoveryTimeInitialValue);
  //   }
  // }, []);

  const configurationListApi = useConfigurationListQuery();
  if (!configurationListApi.isSuccess) {
    return <></>;
  }
  const configuration = configurationListApi.data.find(configuration => configuration.uuid === props.configurationUuid);

  const getConfigurationValue = () => {
    return props.form.getValues("TrainingConfiguration")?.find(
      trainingConfiguration =>
        trainingConfiguration.configurationUuid === props.configurationUuid
    )?.value || "0";
  };

  const update = (amount: number, absolute = false): void => {
    let val = Number(getConfigurationValue());
    val = val + amount;
    if (val < 0) {
      val = 0;
    }
    if (val > 999) {
      val = 999;
    }

    if (absolute) {
      val = amount;
    }

    updateForm(val);
  };

  const updateForm = (val: number) => {
    const trainingConfigurations = props.form.getValues("TrainingConfiguration") ?? [];
    let created = false;

    trainingConfigurations.forEach(trainingConfiguration => {
      if (trainingConfiguration.configurationUuid === props.configurationUuid) {
        trainingConfiguration.value = val.toString();
        created = true;
      }
    });
    if (!created) {
      const newConfiguration : TrainingConfigurationCreateManyTrainingInput = {
        value: val.toString(),
        configurationUuid: props.configurationUuid,
      };
      trainingConfigurations.push(newConfiguration);
      props.form.setValue("TrainingConfiguration", trainingConfigurations);
    }
    props.form.setValue(`configurations.${ configuration.code }`, val.toString());
  };

  const getMaximal = () => {
    if (!props.form.getValues("TrainingConfiguration")) {
      return 0;
    }
    const maximalConfiguratioUuid = getConfigurationUuidByCode(props.configurations, CONFIGURATION_CODE_LOAD_WEIGHT_MAXIMAL);
    const maximal = props.form.getValues("TrainingConfiguration").find(p => p.configurationUuid === maximalConfiguratioUuid);
    return parseInt(maximal?.value || "0", 10);
  };

  const calculateMin = () => {
    const maximalValue = getMaximal();
    const min = (maximalValue / 100) * parseInt(loadWeightMinPercentValue || "0", 10);
    return Math.floor(min);
  };

  const calculateMax = () => {
    const maximalValue = getMaximal();
    const min = (maximalValue / 100) * parseInt(loadWeightMaxPercentValue || "0", 10);
    return Math.floor(min);
  };

  const isOutOfRange = () => {
    const maximal = getConfigurationValue();
    return maximal > calculateMax() || maximal < calculateMin();
  };

  return <>
    <ListItemUi
      title={`${ t`Initial Load Weight` } (${ configuration.unit })` }
      className={classes.root}>
      <div className={classes.controls}>
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"- 10"}
          onClick={() => update(-10)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconPosition="left"
          hasPressed
          label={"- 1"}
          onClick={() => update(-1)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <input
          type="hidden"
          id={configuration.code}
          {...props.form.register(`configurations.${ configuration.code }`, {
            required: true,
            min: 1,
            max: 999,
            value: getConfigurationValue(),
          })} />
        <ButtonInputNumberUi
          type={"primary"}
          appearance={"rounded"}
          min={1}
          max={999}
          step={0.5}
          backgroundColor={"black"}
          labelColor={"pureWhite"}
          initialValue={getConfigurationValue()}
          fontStyle={"RM16"}
          onChange={(value: number) => {
            update(value, true);
          }}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"+ 1"}
          onClick={() => update(1)}
          type="primary"
          width="50px"
          isActive={false}
        />
        <ButtonUi
          appearance="rounded"
          backgroundColor={"black"}
          labelColor={"neon"}
          fontStyle={"RM16"}
          iconColor="grey"
          iconPosition="left"
          hasPressed
          label={"+ 10"}
          onClick={() => update(10)}
          type="primary"
          width="50px"
          isActive={false}
        />
      </div>
      <div className={classes.details}>
        <p>
          <Trans>Minimum load suggested:</Trans> { calculateMin() } Kg ({ loadWeightMinPercentValue || "0" }% <Trans>of your 1RM</Trans>)
        </p>
        <p>
          <Trans>Maximum load suggested:</Trans> { calculateMax() } Kg ({ loadWeightMaxPercentValue || "0" }% <Trans>of your 1RM</Trans>)
        </p>
        {
          isOutOfRange() &&
	        <span style={{ color: theme.colors.orange }}>
	          <Trans>Your load weight is out of suggested range</Trans>
	        </span>
        }
        {
          !isOutOfRange() &&
          <span style={{ color: theme.colors.neonPressed }}>
            <Trans>Your load weight is on line with suggested range</Trans>
          </span>
        }
      </div>
    </ListItemUi>
  </>;
};

import { Chart } from "@common/type-graphql/generated";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useChartListQuery } from "@feature/chart/api/chartApi";
import { useRelation } from "@feature/relation/hook/useRelation";

export const useChart = () => {
  const chartListApi = useChartListQuery();

  const {
    endpoints : relationEndpoints,
    getReleationByProtocolAndExercise,
  } = useRelation();

  const {
    endpoints,
    isApiDataReady,
  } = useApiDataLogic([
    chartListApi,
    ...relationEndpoints,
  ]);

  const getRelationChartsByProtocolAndExercise = (
    protocolUuid: string,
    exerciseUuid: string
  ): Chart[] => {
    if (!isApiDataReady) {
      return [];
    }

    const relation = getReleationByProtocolAndExercise(protocolUuid, exerciseUuid);
    if (!relation) {
      return [];
    }

    return relation.RelationChart.map(relationChart => chartListApi.data.find(c => c.uuid === relationChart.chartUuid));
  };

  const getRelationLiveChartsByProtocolAndExercise = (
    protocolUuid: string,
    exerciseUuid: string
  ): Chart[] => {
    if (!isApiDataReady) {
      return [];
    }

    const relation = getReleationByProtocolAndExercise(protocolUuid, exerciseUuid);
    if (!relation) {
      return [];
    }

    return relation.RelationChartLive.map(
      relationChart => chartListApi.data.find(c => c.uuid === relationChart.chartUuid));
  };

  return {
    endpoints: endpoints,
    getRelationChartsByProtocolAndExercise: getRelationChartsByProtocolAndExercise,
    getRelationLiveChartsByProtocolAndExercise: getRelationLiveChartsByProtocolAndExercise,
  };
};

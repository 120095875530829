import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    root: {},
    controls: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "> div": {
        display: "flex",
        flexDirection: "row",
        gap: 10,
        "> div": {
          flexGrow: 1,
          marginTop: 4,
        },
      },
      "button": { width: 60 },
      "input": {
        width: "100%",
        minWidth: 60,
        height: 50,
        textAlign: "center",
        fontSize: 18,
        WebkitAppearance: "none",
        margin: 0,
        MozAppearance: "textfield",
      },
    },
    details: {},
  })
);

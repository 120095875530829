import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { SheetForm } from "@feature/sheet/component/form/sheetForm";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";

export const SheetCreatePage = () => {
  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.sheetCreate.title)}
        backToRoute={routes.sheetList} />
      <IonContent>
        <SheetForm />
      </IonContent>
    </IonPage>
  </>;
};

import { GraphQLError } from "graphql";
import { i18n } from "@lingui/core";

export const ERROR_MAINTENANCE_TYPE = "MAINTENANCE";

export class GraphQLErrorMaintenance extends GraphQLError {
  constructor(
    message: string
  ) {
    super(
      i18n._(message),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      {
        code: ERROR_MAINTENANCE_TYPE,
      }
    );
  }
}

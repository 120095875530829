import { API_CACHE_TAG_EXERCISE } from "@feature/exercise/exerciseConstants";
import { Exercise } from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  exercisesQuery,
  exercisesTransformResponse,
} from "@common/type-graphql/exercise/gql/exercise-list.g";

export const exerciseApi = api.injectEndpoints({
  endpoints: builder => ({
    exerciseList: builder.query<Exercise[], void>({
      query: () => ({ document: exercisesQuery }),
      transformResponse: exercisesTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_EXERCISE, result),
    }),
  }),
  overrideExisting: false,
});

export const { useExerciseListQuery } = exerciseApi;

import React from "react";
import { DisclaimerComponent } from "@feature/account/component/disclaimerComponent";
import {
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import { LanguageSwitchComponent } from "@feature/setting/component/LanguageSwitchComponent";
import { LoginOrBuyButtonsComponent } from "@feature/account/component/loginOrBuyButtonsComponent";
import { LogoUi } from "@ui/LogoUi/LogoUi";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { useStyles } from "../style";

export const AccountLoginOrBuyPage = () => {
  const { classes } = useStyles();

  return <>
    <IonPage>
      <IonContent>
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <IonRow className={classes.logoFixed}>
              <LogoUi full />
            </IonRow>
          </div>
          <div className={classes.contentContainer}>
            <LanguageSwitchComponent />
            <SeparatorUi />
            <LoginOrBuyButtonsComponent />
            <DisclaimerComponent />
          </div>
        </div>
      </IonContent>
    </IonPage>
  </>;
};

import {
  API_CACHE_TAG_HISTORY_SERIE,
  API_CACHE_TAG_HISTORY_SERIE_COMMENT,
  API_CACHE_TAG_HISTORY_SERIE_LIST_LINKED,
  API_CACHE_TAG_HISTORY_TRAINING_SESSION,
  API_CACHE_TAG_HISTORY_TRAINING_SESSION_LINKED,
  API_CACHE_TAG_HISTORY_WORKOUT,
} from "@feature/history/historyConstants";
import {
  API_CACHE_TAG_LIST_VALUE,
  api,
  getTags,
} from "@core/api";
import {
  CreateOneHistorySerieArgs,
  CreateOneHistorySerieCommentArgs,
  FindManyHistorySerieCommentArgs,
  FindUniqueHistorySerieArgs,
  FindUniqueHistoryTrainingSessionArgs,
  FindUniqueHistoryWorkoutArgs,
  HistorySerie,
  HistorySerieComment,
  HistorySerieOrderByWithRelationInput,
  HistorySerieWhereInput,
  HistoryTrainingSession,
  HistoryTrainingSessionOrderByWithRelationInput,
  HistoryWorkout,
  HistoryWorkoutOrderByWithRelationInput,
  HistoryWorkoutWhereInput,
  UpdateOneHistorySerieArgs,
  UpdateOneHistoryTrainingSessionArgs,
} from "@common/type-graphql/generated";
import {
  HistorySeriesListLinkedToHistoryTrainingSessionInputType,
} from "@common/type-graphql/history/input-type/history-series-list-linked-to-history-training-session.input-type";
import { PageCountOutput } from "@common/type-graphql/pagination/output-type/page-count-output";
import {
  createOneHistorySerieCommentQuery,
  createOneHistorySerieCommentTransformResponse,
} from "@common/type-graphql/history/gql/history-serie-comment-create.g";
import {
  createOneHistorySerieQuery,
  createOneHistorySerieTransformResponse,
} from "@common/type-graphql/history/gql/history-serie-create.g";
import {
  historySerieCommentsQuery,
  historySerieCommentsTransformResponse,
} from "@common/type-graphql/history/gql/history-serie-comment-list.g";
import {
  historySerieQuery,
  historySerieTransformResponse,
} from "@common/type-graphql/history/gql/history-serie-get.g";
import {
  historySeriesListLinkedToHistoryTrainingSessionQuery,
  historySeriesListLinkedToHistoryTrainingSessionTransformResponse,
} from "@common/type-graphql/history/gql/history-series-list-linked-to-history-training-session.g";
import {
  historySeriesQuery,
  historySeriesTransformResponse,
} from "@common/type-graphql/history/gql/history-series-list.g";
import {
  historyTrainingSessionListQuery,
  historyTrainingSessionListTransformResponse,
} from "@common/type-graphql/history/gql/history-training-session-list.g";
import {
  historyTrainingSessionPageCountQuery,
  historyTrainingSessionPageCountTransformResponse,
} from "@common/type-graphql/history/gql/history-training-session-page-count.g";
import {
  historyTrainingSessionQuery,
  historyTrainingSessionTransformResponse,
} from "@common/type-graphql/history/gql/history-training-session-get.g";
import {
  historyWorkoutQuery,
  historyWorkoutTransformResponse,
} from "@common/type-graphql/history/gql/history-workout-get.g";
import {
  historyWorkoutsQuery,
  historyWorkoutsTransformResponse,
} from "@common/type-graphql/history/gql/history-workout-list.g";
import {
  updateOneHistorySerieQuery,
  updateOneHistorySerieTransformResponse,
} from "@common/type-graphql/history/gql/history-serie-delete.g";
import {
  updateOneHistoryTrainingSessionQuery,
  updateOneHistoryTrainingSessionTransformResponse,
} from "@common/type-graphql/history/gql/history-training-session-delete.g";

export const historyApi = api.injectEndpoints({
  endpoints: builder => ({
    historyTrainingSessionPageCount: builder.query<PageCountOutput, void>({
      query: variables => ({
        document: historyTrainingSessionPageCountQuery,
        variables: variables,
      }),
      transformResponse: historyTrainingSessionPageCountTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_TRAINING_SESSION,
          id: API_CACHE_TAG_LIST_VALUE,
        },
      ],
    }),
    historyTrainingSessionList: builder.query<HistoryTrainingSession[], {
      orderBy?: HistoryTrainingSessionOrderByWithRelationInput;
      page: string;
    }>({
      query: variables => ({
        document: historyTrainingSessionListQuery,
        variables: variables,
      }),
      transformResponse: historyTrainingSessionListTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_TRAINING_SESSION,
          id: API_CACHE_TAG_LIST_VALUE,
        },
      ],
    }),
    historyTrainingSessionGet: builder.query<HistoryTrainingSession, FindUniqueHistoryTrainingSessionArgs>({
      query: variables => ({
        document: historyTrainingSessionQuery,
        variables: variables,
      }),
      transformResponse: historyTrainingSessionTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_TRAINING_SESSION,
          id: result.uuid,
        },
      ],
    }),
    historyTrainingSessionEdit: builder.mutation<HistoryTrainingSession, UpdateOneHistoryTrainingSessionArgs>({
      query: variables => ({
        document: updateOneHistoryTrainingSessionQuery,
        variables: variables,
      }),
      transformResponse: updateOneHistoryTrainingSessionTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_TRAINING_SESSION,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_TRAINING_SESSION,
          id: result.uuid,
        },
      ],
    }),
    historyWorkoutList: builder.query<HistoryWorkout[], {
      where?: HistoryWorkoutWhereInput;
      orderBy?: [HistoryWorkoutOrderByWithRelationInput];
    }>({
      query: variables => ({
        document: historyWorkoutsQuery,
        variables: variables,
      }),
      transformResponse: historyWorkoutsTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_WORKOUT,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_WORKOUT,
          id: input?.where?.historyTrainingSessionUuid?.equals,
        },
      ],
    }),
    historyWorkoutGet: builder.query<HistoryWorkout, FindUniqueHistoryWorkoutArgs>({
      query: variables => ({
        document: historyWorkoutQuery,
        variables: variables,
      }),
      transformResponse: historyWorkoutTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_WORKOUT,
          id: result.uuid,
        },
      ],
    }),
    historySerieList: builder.query<HistorySerie[], {
      where?: HistorySerieWhereInput;
      orderBy?: [HistorySerieOrderByWithRelationInput];
    }>({
      query: variables => ({
        document: historySeriesQuery,
        variables: variables,
      }),
      transformResponse: historySeriesTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_SERIE,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_SERIE,
          id: input?.where?.historyWorkoutUuid?.equals,
        },
      ],
    }),
    historySeriesListLinkedToHistoryTrainingSession: builder.query<HistorySerie[], {
      where?: HistorySeriesListLinkedToHistoryTrainingSessionInputType;
    }>({
      query: variables => ({
        document: historySeriesListLinkedToHistoryTrainingSessionQuery,
        variables: variables,
      }),
      transformResponse: historySeriesListLinkedToHistoryTrainingSessionTransformResponse,
      providesTags: (result, error, input) => [
        ...getTags(API_CACHE_TAG_HISTORY_SERIE_LIST_LINKED, result),
        {
          type: API_CACHE_TAG_HISTORY_TRAINING_SESSION_LINKED,
          id: `${ input?.where?.uuid }_${ input?.where?.profileUuid }`,
        },
      ],
    }),
    historySerieGet: builder.query<HistorySerie, FindUniqueHistorySerieArgs>({
      query: variables => ({
        document: historySerieQuery,
        variables: variables,
      }),
      transformResponse: historySerieTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_SERIE,
          id: result.uuid,
        },
      ],
    }),
    historySerieCreate: builder.mutation<HistorySerie, CreateOneHistorySerieArgs>({
      query: variables => ({
        document: createOneHistorySerieQuery,
        variables: variables,
      }),
      transformResponse: createOneHistorySerieTransformResponse,
      invalidatesTags:  (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_SERIE,
          id: result.uuid,
        },
      ],
    }),
    historySerieEdit: builder.mutation<HistorySerie, UpdateOneHistorySerieArgs>({
      query: variables => ({
        document: updateOneHistorySerieQuery,
        variables: variables,
      }),
      transformResponse: updateOneHistorySerieTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_SERIE,
          id: result.uuid,
        },
      ],
    }),
    historySerieCommentList: builder.query<HistorySerieComment[], FindManyHistorySerieCommentArgs>({
      query: variables => ({
        document: historySerieCommentsQuery,
        variables: variables,
      }),
      transformResponse: historySerieCommentsTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_SERIE_COMMENT,
          id: input?.where?.historySerieUuid?.equals,
        },
      ],
    }),
    historySerieCommentCreate: builder.mutation<HistorySerieComment, CreateOneHistorySerieCommentArgs>({
      query: variables => ({
        document: createOneHistorySerieCommentQuery,
        variables: variables,
      }),
      transformResponse: createOneHistorySerieCommentTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_HISTORY_SERIE_COMMENT,
          id: input?.data?.HistorySerie?.connect?.uuid,
        },
      ],
    }),
  }),
});

export const {
  useHistoryTrainingSessionPageCountQuery,
  useHistoryTrainingSessionListQuery,
  useHistoryTrainingSessionGetQuery,
  useHistoryTrainingSessionEditMutation,
  useHistorySeriesListLinkedToHistoryTrainingSessionQuery,
  useHistoryWorkoutListQuery,
  useHistoryWorkoutGetQuery,
  useHistorySerieListQuery,
  useHistorySerieGetQuery,
  useHistorySerieCreateMutation,
  useHistorySerieEditMutation,
  useHistorySerieCommentListQuery,
  useHistorySerieCommentCreateMutation,
} = historyApi;

import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { TrainingForm } from "@feature/training/component/form/trainingForm";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { i18n } from "@lingui/core";
import { useParams } from "react-router";

type Params = {
  sheetUuid: string;
};

export const TrainingCreatePage = () => {
  const params = useParams<Params>();

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.trainingCreate.title)}
        backToPath={routes.sheetEdit.routePath.replace(getRouteUuidPlaceholder(), params.sheetUuid)}
        backToTitle={i18n._(routes.sheetEdit.title)} />
      <IonContent>
        <TrainingForm sheetUuid={params.sheetUuid} />
      </IonContent>
    </IonPage>
  </>;
};

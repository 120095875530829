import { useStyles } from "./useStyles";

interface ListActionBarMaskProps {
  className?: string;
}

export const ListActionBarMaskUi = ({ className }: ListActionBarMaskProps) => {
  const {
    classes, cx,
  } = useStyles();

  return <>
    <div className={cx({
      [classes.maskRoot]: true,
      [className]: className !== "",
    })}>
      <svg className={cx({ [classes.maskCorner]: true })}>
        <use href="#ico-mask-corner" />
      </svg>
      <svg className={cx({
        [classes.maskCorner]: true,
        [classes.rightCorner]: true,
      })}>
        <use href="#ico-mask-corner" />
      </svg>
    </div>
  </>;
};

import React, { useEffect } from "react";
import { FormErrorUi } from "@ui/FormErrorUi/FormErrorUi";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import {
  historyController,
  useAppDispatch,
} from "@core/redux/store";
import {
  t,
} from "@lingui/macro";
import { toast } from "@feature/toast/slice/toastSlice";
import {
  useAthleteConnectMutation,
} from "@feature/athlete/api/athleteApi";
import { useForm } from "react-hook-form";

type Props = {
  athleteUuid?: string;
}

type FormData = {
  connectCode?: string;
}

export const AthleteConnectForm = (props: Props) => {
  const dispatch = useAppDispatch();

  const [ connect ] = useAthleteConnectMutation();

  const {
    handleSubmit,
    register,
    setError,
    setFocus,
    formState: {
      errors,
    },
  } = useForm<FormData>();

  useEffect(() => {
    setFocus("connectCode");
    // eslint-disable-next-line
  }, []);

  const onSubmit = async(formData: FormData) => {
    try {
      await connect({
        data: {
          athleteUuid: props.athleteUuid,
          connectCode: formData.connectCode,
        },
      }).unwrap();
      dispatch(toast({ message: t`Athlete connected successfully.` }));
      historyController.replace(routes.athleteView.routePath.replace(getRouteUuidPlaceholder(), props.athleteUuid));
    } catch (err) {
      console.error(err);
      setError("root", err);
    }
  };

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <SectionUi title={t`Connect Athlete`} rounded>
        <ListItemUi title={t`Connect Code`} isRequired={true}>
          <InputUi
            type="number"
            min={0}
            max={999999}
            autoComplete="off"
            id="connect-code"
            placeholder={t`type here the connect code...`}
            isValid={!Boolean(errors.connectCode)}
            autoFocus={true}
            {...register("connectCode", { required: true })}
          />
          <FormErrorUi error={errors.connectCode} />
        </ListItemUi>
        <ListItemUi>
          <PrimaryPositiveButtonUi
            label={ t`Connect` }
          />
        </ListItemUi>
      </SectionUi>
    </FormUi>
  </>;
};

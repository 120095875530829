import {
  ColorPickerColors,
  useLogic,
} from "./useLogic";
import { theme } from "@theme/index";
import { useEffect } from "react";
import { useStyles } from "./useStyles";

export interface Props {
  colors: ColorPickerColors[];
  currentColor?: ColorPickerColors;
  onChange?: (color : ColorPickerColors) => void;
}

export const ColorPickerUi = ({
  colors, currentColor, onChange,
}: Props) => {
  const {
    classes, cx,
  } = useStyles();
  const {
    currentOption,
    setCurrentOption,
    dropdownVisible,
    setDropdownVisible,
  } = useLogic(currentColor);

  useEffect(() => {
    setCurrentOption(currentColor);
  }, [
    currentColor,
    setCurrentOption,
  ]);

  return <>
    <div className={classes.colorPickerRoot}>
      {colors.map((color: ColorPickerColors) => (
        <button
          type="button"
          key={color}
          className={cx({
            [classes.colorButton]: true,
            [classes.selected]: color === currentOption,
          })}
          onClick={e => {
            let locColor = color;
            if (color !== currentOption) {
              locColor = color;
            }
            setCurrentOption(locColor);
            if (onChange) {
              onChange(locColor);
            }
          }}
        >
          <span
            className={classes.color}
            style={{ backgroundColor: theme.colors[color] }}
          />
        </button>
      ))}
    </div>
  </>;
};

import { useState } from "react";

export const useLogic = (selectedOptionId: string) => {
  const [
    currentOption,
    setCurrentOption,
  ] = useState<string>(selectedOptionId);

  const [
    dropdownVisible,
    setDropdownVisible,
  ] = useState(false);

  return {
    currentOption: currentOption,
    setCurrentOption: setCurrentOption,
    dropdownVisible: dropdownVisible,
    setDropdownVisible: setDropdownVisible,
  };
};

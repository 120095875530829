import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { t } from "@lingui/macro";

export const InfoRangePage = () => {
  return <>
    <IonPage>
      <HeaderUi
        pageTitle={t`Informations`}
        backHistory={true}
      />
      <IonContent>
        <p>Info sul range...</p>
      </IonContent>
    </IonPage>
  </>;
};

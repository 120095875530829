import { useState } from "react";

export const useLogic = () => {
  const [
    isActive,
    setActive,
  ] = useState(false);

  const handleToggle = () => {
    setActive(Boolean(isActive));
  };

  return {
    isActive: isActive,
    handleToggle: handleToggle,
  };
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      color: theme.isDarkMode ? theme.colors.pureWhite : theme.colors.black,
      maxWidth: "max-content",
    },
    labelContainer: {
      maxWidth: "100px", // Set a max-width to trigger the scrolling
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    "@keyframes scroll": {
      "0%": { transform: "translateX(100%)" },
      "100%": { transform: "translateX(-100%)" },
    },
  })
);

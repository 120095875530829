import { currentSerie } from "@feature/run/data/currentSerie";
import { deviceReadsService } from "@feature/device/service/deviceReadsService";

import { selectDeviceState } from "@feature/device/slice/deviceSlice";
import {
  updateLastCalculation,
} from "@feature/run/slice/runSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useEffect } from "react";
import { useRunFeedback } from "@feature/run/hook/useRunFeedback";
import { useSerieCalculate } from "@feature/serie/hook/useSerieCalculate";

export const RunAlgorithmComponent = () => {
  const dispatch = useAppDispatch();

  const deviceState = useAppSelector(selectDeviceState);

  const { calculateRunSerie } = useSerieCalculate();

  const { runCalculateFeedback } = useRunFeedback();

  /**
   * Update the component when the device sends data
   */
  useEffect(() => {
    const newPoints = deviceReadsService.getDataAndEmpty();
    if (!newPoints) {
      return;
    }

    const {
      isCalculated,
      serie,
    } = calculateRunSerie({
      serie: currentSerie,
      newPoints: newPoints,
    });

    if (!isCalculated) {
      return;
    }

    dispatch(updateLastCalculation());

    runCalculateFeedback(serie);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ deviceState.lastReceived ]);

  return null;
};

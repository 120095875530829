/* eslint-disable no-process-env,no-magic-numbers */
import { IConfigApp } from "./IConfigApp";

export const configStaging: IConfigApp = {
  debug: {
    enabled: false,
    autofill: false,
  },
  server: {
    baseUrl: "https://server-staging.bodygon.com/",
    graphqlEndpoint: "graphql",
  },
};

import { ReactNode } from "react";
import { useStyles } from "./useStyles";

type Props = {
  children?: ReactNode | ReactNode[];
}

export const FooterUi = (props: Props) => {
  const {
    classes,
  } = useStyles();

  return <>
    <div className={classes.footerRoot}>
      <span className={classes.footerParsedHTML}>
        { props.children }
      </span>
    </div>
  </>;
};

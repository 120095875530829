import { RangeMinMax } from "@common/model/Range";
import { selectRunState } from "@feature/run/slice/runSlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAppSelector } from "@core/redux/store";
import { useRangeListQuery } from "@feature/range/api/rangeApi";
import { useTrainingGetQuery } from "@feature/training/api/trainingApi";

export const useRunRange = () => {
  const runState = useAppSelector(selectRunState);
  const trainingApi = useTrainingGetQuery({ where: { uuid: runState.trainingUuid } }, { skip: !runState.trainingUuid });
  const rangeListApi = useRangeListQuery({ where: { uuid: trainingApi.data?.athleteUuid } }, { skip: !trainingApi.data || !trainingApi.data.athleteUuid });

  const {
    endpoints,
    isApiDataReady,
  } = useApiDataLogic([
    trainingApi,
    rangeListApi,
  ]);

  const getRangeMinMax = (): RangeMinMax | null => {
    let range = null;

    if (!isApiDataReady) {
      return range;
    }

    if (rangeListApi.data) {
      const rangeByApi = rangeListApi.data.find(r => r.exerciseUuid === trainingApi.data.exerciseUuid);
      if (
        rangeByApi &&
        rangeByApi.data &&
        rangeByApi.data !== "{}" &&
        rangeByApi.data !== "null"
      ) {
        range = JSON.parse(rangeByApi.data);
      }
    }

    return range;
  };

  return {
    endpoints: endpoints,
    runRangeMinMax: getRangeMinMax(),
  };
};

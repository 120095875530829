export const wrapSetInput = <F, O>(formData: F): O => {
  const result : any = {};
  // eslint-disable-next-line guard-for-in
  for (const property in formData) {
    const rawValue = formData[property];
    if (rawValue === "") {
      result[property] = { set: null };
    } else {
      result[property] = { set: rawValue };
    }
  }
  return result;
};

import {
  ButtonClick,
  Callback,
} from "@types-custom";
import {
  historyController,
} from "@core/redux/store";
import {
  useButton,
  useLongPress,
  usePress,
} from "react-aria";
import {
  useEffect,
  useRef,
  useState,
} from "react";

interface Props {
  hasToggle?: boolean;
  href?: string;
  branded?: boolean;
  toggle?: boolean;
  rounded?: boolean;
  onClick?: ButtonClick;
  onLongPress?: Callback;
}

export const useLogic = (props: Props) => {
  const [
    isToggled,
    setToggled,
  ] = useState<boolean>(false);
  const $button = useRef(null);

  // Handle and normalize press event
  const {
    pressProps,
    isPressed,
  } = usePress({});

  // Handle and normalize button behaviour
  const { buttonProps } = useButton(props as any, $button);

  const handleClick = e => {
    if (props.onClick) {
      e.stopPropagation();
      props.onClick(e);
    }

    if (props.href) {
      e.stopPropagation();
      historyController.replace(props.href);
    }
    if (props.hasToggle === true) {
      setToggled(!isToggled);
    }
  };

  const { longPressProps } = useLongPress({
    // onLongPressStart: (e) =>
    // onLongPressEnd: (e) =>
    onLongPress: () => {
      if (props.onLongPress) {
        props.onLongPress();
      }
    },
  });

  useEffect(() => {
    if (props.branded && props.rounded) {
      throw Error("A ButtonUi component cannot have both 'rounded' and 'branded' props, please check your Buttons and choose one of the two.");
    }
    if (props.branded && props.toggle) {
      console.warn("A button with 'branded' prop doesn't need a 'toggle' prop.");
    }
  }, [
    props.branded,
    props.rounded,
    props.toggle,
  ]);

  return {
    isToggled: isToggled,
    pressProps: pressProps,
    longPressProps: longPressProps,
    isPressed: isPressed,
    buttonProps: buttonProps,
    $button: $button,
    handleClick: handleClick,
  };
};

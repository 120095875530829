import {
  CUSTOMER_SERVICE_EMAIL,
  CUSTOMER_SERVICE_EMAIL_URL,
  CUSTOMER_SERVICE_PHONE_NUMBER,
  CUSTOMER_SERVICE_WHATSAPP_URL,
} from "@common/commonConstants";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@theme/makeStyles";

export const CustomerServiceContactComponent = () => {
  const { classes } = styles();

  return <>
    <span className={classes.text}>
      <Trans>Customer Service email</Trans> <a
        target={"_blank"}
        href={CUSTOMER_SERVICE_EMAIL_URL}
        rel="noreferrer">{CUSTOMER_SERVICE_EMAIL}</a><br /><Trans>or</Trans> <a
        target={"_blank"}
        href={CUSTOMER_SERVICE_WHATSAPP_URL}
        rel="noreferrer">{CUSTOMER_SERVICE_PHONE_NUMBER}</a> <Trans>tel/whatsapp</Trans>
    </span>
  </>;
};

const styles = makeStyles()(
  theme => (
    {
      text: {
        opacity: 1,
        color: theme.colors.grey,
        fontSize: "12px",
        fontWeight: "400",
        fontStyle: "normal",
        letterSpacing: "0px",
        lineHeight: "18px",
        a: {
          color: theme.colors.grey,
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    }
  )
);

import React from "react";
import {
  IonToast,
  ToastOptions,
} from "@ionic/react";
import {
  TOAST_COLOR_DEFAULT,
  TOAST_DURATION_DEFAULT,
} from "@feature/toast/toastConstants";
import { toastDone } from "../slice/toastSlice";
import { useAppDispatch } from "@core/redux/store";
import { useStyles } from "@feature/toast/style";

type Prop = {
  toast: ToastOptions;
}

export const ToastComponent = (prop: Prop) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(toastDone(prop.toast.id ?? ""));
  };

  return <>
    <IonToast
      cssClass={classes.toastMessage}
      position="top"
      isOpen={true}
      translucent={true}
      duration={prop.toast.duration ?? TOAST_DURATION_DEFAULT}
      color={prop.toast.color ?? TOAST_COLOR_DEFAULT}
      {...prop.toast}
      onDidDismiss={close}
      key={prop.toast.id}
      onClick={close}
    />
  </>;
};

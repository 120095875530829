import { ButtonClick } from "@types-custom";
import { ExerciseListComponent } from "@feature/exercise/component/modal/exerciseListComponent";
import { ModalListUi } from "@ui/ModalListUi/ModalListUi";
import { getUniqueId } from "@util/getUniqueId";
import { t } from "@lingui/macro";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useRelation } from "@feature/relation/hook/useRelation";

type Props = {
  isOpen: boolean;
  protocolUuid?: string;
  onClose: ButtonClick;
  onClick: ((exerciseUuid: string) => void);
}

export const ExerciseChooseModal = (props: Props) => {
  const {
    endpoints: relationsEndpoints,
    getExercisesFromRelationsByProtocol,
  } = useRelation();

  const {
    isApiDataReady,
  } = useApiDataLogic([ ...relationsEndpoints ]);

  if (!isApiDataReady) {
    return null;
  }

  return <>
    <ModalListUi
      title={t`Exercises`}
      isOpen={props.isOpen}
      onClose={props.onClose}>
      <ExerciseListComponent
        data={getExercisesFromRelationsByProtocol(props.protocolUuid)}
        onClick={props.onClick}
        key={getUniqueId()}
      />
    </ModalListUi>
  </>;
};

import { ApiData } from "@feature/api/component/ApiData";
import {
  ButtonClick,
  Callback,
} from "@types-custom";
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import { RunActionsBarComponent } from "@feature/run/component/RunActionBarComponent";
import { Training } from "@common/type-graphql/generated";
import {
  historyController,
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { routes } from "@core/route";
import {
  runSetTrainingUuid,
  selectRunState,
} from "@feature/run/slice/runSlice";
import { selectDeviceState } from "@feature/device/slice/deviceSlice";
import { useTrainingListQuery } from "@feature/training/api/trainingApi";

type Props = {
  onStop: (elapsedSeconds: number) => Callback;
  onStart: (wait?: number) => Callback;
  athleteUuid: string;
  exerciseUuid: string;
  loadWeight: number;
  powerReference?: PowerReferenceValue;
}

export const ActionBarComponent = (props: Props) => {
  const runState = useAppSelector(selectRunState);
  const trainingListApi = useTrainingListQuery({ where: { uuid: runState.sheetUuid } }, { skip: !runState.sheetUuid });

  return <>
    <ApiData endpoint={trainingListApi}>
      <ActionBarComponentChild
        {...props}
        trainingList={trainingListApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  trainingList: Training[];
}

const ActionBarComponentChild = (props: PropsChild) => {
  const dispatch = useAppDispatch();

  const deviceState = useAppSelector(selectDeviceState);
  const runState = useAppSelector(selectRunState);

  const getPrevAction = (): ButtonClick => {
    const previousTraining = getPreviousTraining(props.trainingList, runState.trainingUuid);
    if (previousTraining) {
      return () => {
        historyController.replace(routes.run.redirectPath);
        dispatch(runSetTrainingUuid(previousTraining.uuid));
      };
    }
    return null;
  };

  const getNextAction = (): ButtonClick => {
    const nextTraining = getNextTraining(props.trainingList, runState.trainingUuid);
    if (nextTraining) {
      return () => {
        historyController.replace(routes.run.redirectPath);
        dispatch(runSetTrainingUuid(nextTraining.uuid));
      };
    }
    return null;
  };

  const getPreviousTraining = (elements: Training[], uuid: string): Training => {
    const element = elements.find(e => e.uuid === uuid);
    const i = elements.indexOf(element);
    if (i <= 0) {
      return null;
    }
    return elements[i - 1];
  };

  const getNextTraining = (elements: Training[], uuid: string): Training => {
    const element = elements.find(e => e.uuid === uuid);
    const i = elements.indexOf(element);
    if (i < 0) {
      return null;
    }
    if (i + 1 >= elements.length) {
      return null;
    }
    return elements[i + 1];
  };

  return <>
    <RunActionsBarComponent
      isConnected={Boolean(deviceState.connectedDevice)}
      phase={runState.runStatus}
      onConnectClick={() => historyController.replace(routes.deviceList.redirectPath)}
      onStartClick={props.onStart(0)}
      onStopClick={props.onStop(0)}
      onPlusFiveClick={props.onStart(5)}
      onPlusTenClick={props.onStart(10)}
      onPrevClick={getPrevAction()}
      onNextClick={getNextAction()}
      athleteUuid={props.athleteUuid}
      exerciseUuid={props.exerciseUuid}
      loadWeight={props.loadWeight}
      powerReference={props.powerReference}
    />
  </>;
};

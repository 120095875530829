import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    colorPickerRoot: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    colorButton: {
      margin: 0,
      padding: 0,
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.darkTint,
    },
    color: {
      display: "block",
      width: 24,
      height: 24,
      borderRadius: "50%",
    },
    selected: { backgroundColor: theme.colors.black },
  })
);

import { makeStyles } from "@theme/makeStyles";

const getColor = (props, theme) => {
  if (props.hasToggle === false) {
    return theme.colors[props.labelColor];
  } else if (props.type === "secondary" && props.hasToggle === true) {
    return theme.colors.grey;
  }
  return theme.colors.grey;
};

export const useStyles = makeStyles<{ label; type; backgroundColor; labelColor; fontStyle; width; appearance; iconPosition; hasToggle }, "isPressed">()(
  (theme, props, classes) => ({
    // Base styles
    buttonRoot: {
      height: props.appearance === "eptagon" ? 63 : props.type === "secondary" ? "auto" : 50,
      padding: props.width === undefined ? "0 8px" : props.type === "secondary" ? "10px 0" : 0,
      display: "flex",
      appearance: "none",
      flexDirection: props.iconPosition === "top" ? "column" : "row",
      justifyContent: props.appearance !== "eptagon" ? "center" : "flex-start",
      alignItems: "center",
      width: props.width ?? "auto",
      backgroundColor: props.type === "primary" && props.appearance !== "eptagon" ? theme.colors[props.backgroundColor] : "transparent",
      borderRadius: props.appearance !== "circle" ? 16 : "50%",
      "&:focus": { outline: "none" },
      "&:disabled": { opacity: 0.5 },
    },
    buttonLabel: {
      padding: props.type === "secondary" ? 4 : 0,
      height: props.type === "primary" && props.appearance === "eptagon" ? "100%" : 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: props.appearance === "eptagon" ? 16 : null,
      whiteSpace: "nowrap",
      color: getColor(props, theme),
      ...theme.typography[props.fontStyle],
      width: props.width ?? "100%",
      backgroundColor:
        props.type === "secondary" ? theme.colors[props.backgroundColor] : "transparent",
      borderRadius:
        props.type === "secondary" &&
        props.appearance === "rounded" ? 8 : "50%",
      textTransform:
        (
          props.type === "secondary" && props.fontStyle !== "RR16") ||
        props.appearance === "eptagon" ? "uppercase" : "none",
    },
    buttonIcon: {
      margin: "0 5px",
      height: props.iconPosition === "left" ? 50 : "auto",
    },
    isCircle: {
      width: 35,
      height: 35,
      maxHeight: "none",
      margin: 0,
      padding: 0,
      borderRadius: "50%",
      backgroundColor: theme.colors[props.backgroundColor],
    },
    isEptagon: {
      width: 63,
      height: 63,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      zIndex: 1,
      "& svg": {
        position: "absolute",
        zIndex: 2,
      },
      "& div": {
        position: "absolute",
        zIndex: 2,
      },
    },
    isToggled: { color: theme.colors.neon },
    isActive: {
      color: theme.colors.neon,
      "& span": { color: theme.colors.neon },
      "& svg": { fill: theme.colors.neon },
    },
    isPressed: {
      "&:not(:disabled)": {
        backgroundColor: props.appearance !== "circle" && props.type === "primary" ? theme.colors[`${ props.backgroundColor }Pressed`] : "none",
        "& div": { backgroundColor: props.appearance === "circle" && props.type === "secondary" ? theme.colors[`${ props.backgroundColor }Pressed`] : "none" },
        "& span": { backgroundColor: props.type === "secondary" ? theme.colors[`${ props.backgroundColor }Pressed`] : "none" },
      },
    },
    isLoading: { transform: "scale(0.75)" },
  })
);

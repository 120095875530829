export const convertDatesToTimestamps = (object: any) => {
  for (const key in object) {
    if (object[key] instanceof Date) {
      object[key] = object[key].getTime();
    } else if (typeof object[key] === "object" && object[key] !== null) {
      convertDatesToTimestamps(object[key]);
    }
  }
  return object;
};

import {
  CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL,
  CONFIGURATION_CODE_LOAD_WEIGHT_MAXIMAL,
  CONFIGURATION_CODE_RECOVERY_TIME_INITIAL,
} from "@common/model/Configuration";
import { LoadWeightFormElement } from "@feature/training/component/formElement/loadWeightFormElement";
import { MaximalFormElement } from "@feature/training/component/formElement/maximalFormElement";
import { RecoveryTimeFormElement } from "@feature/training/component/formElement/recoveryTimeFormElement";
import { RelationConfiguration } from "@common/type-graphql/generated";
import { UseFormReturn } from "react-hook-form";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";

type Props = {
  configurationUuid: string;
  configurations: RelationConfiguration[];
  form: UseFormReturn;
  protocolUuid: string;
  exerciseUuid: string;
}

export const ConfigurationForm = (props: Props) => {
  const configurationListApi = useConfigurationListQuery();

  if (!configurationListApi.isSuccess) {
    return <></>;
  }

  const configuration = configurationListApi.data.find(configuration => configuration.uuid === props.configurationUuid);

  switch (configuration.code) {
    case CONFIGURATION_CODE_LOAD_WEIGHT_MAXIMAL:
      return <>
        <MaximalFormElement
          configurationUuid={props.configurationUuid}
          form={props.form} />
      </>;
    case CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL:
      return <>
        <LoadWeightFormElement
          configurations={configurationListApi.data}
          configurationUuid={props.configurationUuid}
          form={props.form}
          relationConfigurations={props.configurations} />
      </>;
    case CONFIGURATION_CODE_RECOVERY_TIME_INITIAL:
      return <>
        <RecoveryTimeFormElement
          configurations={configurationListApi.data}
          configurationUuid={props.configurationUuid}
          form={props.form}
          relationConfigurations={props.configurations} />
      </>;
    default:
      return <></>;
  }
};

import { ApiData } from "@feature/api/component/ApiData";
import {
  IonChip,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { Profile } from "@common/type-graphql/generated";
import { personCircle } from "ionicons/icons";
import { useProfileGetQuery } from "@feature/profile/api/profileApi";

type Props = {
  profileUuid: string;
}

export const ProfileChipComponent = (props: Props) => {
  const profileApi = useProfileGetQuery({ where: { uuid: props.profileUuid } });

  return <>
    <ApiData endpoint={profileApi}>
      <ProfileChip profile={profileApi.data} />
    </ApiData>
  </>;
};

type ProfileProps = {
  profile: Profile;
}

const ProfileChip = (props: ProfileProps) => {
  return <>
    <IonChip
      class="ionChipStyle"
      color="primary">
      <IonIcon
        icon={personCircle}
        size="small" />
      <IonLabel style={{
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      }}>
        {props.profile.name}
      </IonLabel>
    </IonChip>
  </>;
};

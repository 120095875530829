import reportWebVitals from "./reportWebVitals";
import { App } from "@core/app";
import { Capacitor } from "@capacitor/core";
import {
  CapacitorSQLite,
  SQLiteConnection,
} from "@capacitor-community/sqlite";
import {
  StrictMode,
} from "react";
import {
  applyPolyfills,
  defineCustomElements as jeepSqlite,
} from "jeep-sqlite/loader";
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "./core/service-worker/serviceWorkerRegistration";

//jeepSqlite init
// type StencilToReact<T> = {
//   [P in keyof T]?: T[P] & Omit<HTMLAttributes<Element>, "className"> & {
//     class?: string;
//   }
// };
// declare global {
//   export namespace JSX {
//     interface IntrinsicElements extends StencilToReact<LocalJSX.IntrinsicElements> {
//     }
//   }
// }
applyPolyfills().then(() => {
  jeepSqlite(window);
});

window.addEventListener("DOMContentLoaded", async() => {
  //jeepSqlite init
  try {
    const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);
    const platform = Capacitor.getPlatform();
    if (platform === "web") {
      const jeepEl = document.createElement("jeep-sqlite");
      document.body.appendChild(jeepEl);
      await customElements.whenDefined("jeep-sqlite");
      await sqlite.initWebStore();
    }
  } catch (err) {
    console.log(`Error: ${ err }`);
    throw new Error(`Error: ${ err }`);
  }

  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    // StrictMode will render twice all the components
    // @see https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
    <StrictMode>
      <App />
    </StrictMode>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});

import { SpinnerUi } from "@ui/SpinnerUi/SpinnerUi";
import { useStyles } from "./useStyles";

interface Props {
  appearance?: "default" | "fullscreen";
  background?: "transparent" | "blur" | "neon";
}

export const LoaderUi = (props: Props) => {
  const {
    classes, theme, cx,
  } = useStyles(props);

  return <>
    <div
      className={cx({
        [classes.loaderRoot]: true,
        [classes.fullScreen]: props.appearance === "fullscreen",
      })}
    >
      <SpinnerUi
        color={props.background === "neon" ? "black" : "neon"}
        duration={0.4}
      />
    </div>
  </>;
};

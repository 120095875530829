import React from "react";
import { ApiData } from "@feature/api/component/ApiData";
import { Color } from "@types-custom";
import { HistoryTrainingSession } from "@common/type-graphql/generated";
import { HistoryWorkoutListComponent } from "@feature/history/component/historyWorkoutListComponent";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { formatItalianDateTime } from "@util/dateFormat";
import { i18n } from "@lingui/core";
import {
  selectHistoryState,
  toggleHistoryTrainingSessionListOpened,
} from "@feature/history/slice/historySlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import {
  useHistoryTrainingSessionGetQuery,
} from "@feature/history/api/historyApi";

type Props = {
  uuid: string;
}

export const HistoryTrainingSessionItemComponent = (props: Props) => {
  const historyTrainingSessionApi = useHistoryTrainingSessionGetQuery({ where: { uuid: props.uuid } });

  return <>
    <ApiData
      endpoint={historyTrainingSessionApi}
    >
      <HistoryTrainingSessionItemComponentChild
        historyTrainingSession={historyTrainingSessionApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  historyTrainingSession: HistoryTrainingSession;
}

const HistoryTrainingSessionItemComponentChild = (props: PropsChild) => {
  const dispatch = useAppDispatch();
  const historyState = useAppSelector(selectHistoryState);

  const translateQuick = (inputString: string) => {
    if (!inputString.startsWith("Quick")) {
      return inputString;
    }

    const nameMatch = inputString.match(/for (\w+)$/);
    const name = nameMatch ? nameMatch[1] : "";
    const stringWithoutName = name ? inputString.replace(` for ${ name }`, "") : inputString;
    const translatedString = i18n._(stringWithoutName);

    if (translatedString === "Quick") {
      return inputString;
    }

    return name ? `${ translatedString } di ${ name }` : translatedString;
  };

  const isContentVisible = historyState.historyTrainingSessionListOpened.indexOf(props.historyTrainingSession.uuid) !== -1;

  return <>
    <ListItemUi
      avatarColor={props.historyTrainingSession.sheetColor as Color}
      title={translateQuick(props.historyTrainingSession.sheetName)}
      isAccordion={true}
      isAccordionOpen={isContentVisible}
      description={formatItalianDateTime(props.historyTrainingSession.dateTime)}
      onClick={() => {
        dispatch(toggleHistoryTrainingSessionListOpened(props.historyTrainingSession.uuid));
      }}
    />
    {
      isContentVisible &&
      props.historyTrainingSession.uuid &&
      <HistoryWorkoutListComponent
        historyTrainingSessionUuid={props.historyTrainingSession.uuid}
      />
    }
  </>;
};

import { ListActionBarUi } from "@ui/ListActionBarUi/ListActionBarUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { Protocol } from "@common/type-graphql/generated";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { getProtocolNameByCode } from "@feature/protocol/service/protocolService";
import { t } from "@lingui/macro";
import {
  useEffect,
  useState,
} from "react";

type Props = {
  protocols?: Protocol[];
  onClick?: (uuid: string) => void;
}

type Element = {
  uuid: string;
  label: string;
}

export const ProtocolListComponent = (props: Props) => {
  const [
    filteredData,
    setFilteredData,
  ] = useState<Element[]>([]);

  const searchChange = (value: string) => {
    const elements = props.protocols.filter(element => getProtocolNameByCode(element.code).toLowerCase().includes(value.toLowerCase()));
    update(elements);
  };

  const update = (elements: Protocol[]) => {
    const protocolsSortedByTitle: Element[] = elements.map(element => {
      return {
        uuid: element.uuid,
        label: getProtocolNameByCode(element.code),
      };
    });
    setFilteredData(protocolsSortedByTitle);
  };

  useEffect(() => {
    if (props.protocols) {
      update(props.protocols);
    }
  }, [ props.protocols ]);

  if (!props.protocols || !props.protocols.length) {
    return <></>;
  }

  return <>
    <ListActionBarUi
      addButtonLabel={t`Protocol`}
      onSearchChange={searchChange}
    />
    <SectionUi rounded>
      {
        filteredData.map(item =>
          <ListItemUi
            key={item.uuid}
            title={item.label}
            onClick={() => {
              props.onClick(item.uuid);
            }} />
        )
      }
    </SectionUi>
  </>;
};

import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  SegmentChangeEventDetail,
} from "@ionic/react";
import { IonSegmentCustomEvent } from "@ionic/core/dist/types/components";
import {
  selectSettingState,
  setLanguage,
} from "@feature/setting/slice/settingSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";

export const LanguageSwitchComponent = () => {
  const dispatch = useAppDispatch();

  const settingState = useAppSelector(selectSettingState);

  const switchLanguage = (event: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
    const detail = event.detail;
    const value = detail.value.toString();
    dispatch(setLanguage(value));
  };

  return <>
    <IonSegment
      value={settingState.language}
      onIonChange={switchLanguage}
    >
      <IonSegmentButton value="it">
        <IonLabel>Italiano</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton value="en">
        <IonLabel>English</IonLabel>
      </IonSegmentButton>
    </IonSegment>
  </>;
};

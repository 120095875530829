import React, { useEffect } from "react";
import useFormPersist from "react-hook-form-persist";
import { AccountForgotPasswordCreateInput } from "@common/type-graphql/generated";
import { FormErrorUi } from "@ui/FormErrorUi/FormErrorUi";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import { LoaderUi } from "@ui/LoaderUi/LoaderUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { STORE_FORM_ACCOUNT } from "@feature/account/accountConstants";
import { config } from "@config";
import {
  emailPattern,
} from "@common/patterns";
import {
  historyController,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";
import { t } from "@lingui/macro";
import {
  toast,
  toastUntilOk,
} from "@feature/toast/slice/toastSlice";
import { useForgotPasswordMutation } from "../../api/accountApi";
import { useForm } from "react-hook-form";

type FormData = {
  email?: string;
}

export const ForgotPasswordForm = () => {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    setFocus,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>();

  useFormPersist(STORE_FORM_ACCOUNT, {
    watch: watch,
    setValue: setValue,
    exclude: [
      "password",
      "confirmPassword",
    ],
  });

  const [ forgotPassword ] = useForgotPasswordMutation();

  const onFormErrors = e => {
    if (e.email) {
      dispatch(toast({
        message: t`Please enter a valid email address`,
        color: "danger",
      }));
    }
  };

  const onSubmit = async(formData: FormData) => {
    try {
      await forgotPassword({ data: formData as AccountForgotPasswordCreateInput }).unwrap();
      dispatch(toastUntilOk({ message: t`If there is an account associated with that e-mail, you will receive a link to recover your password` }));
      historyController.replace(routes.accountLogin.redirectPath);
    } catch (err) {
      console.error(err);
    }
  };

  const initialValue = config.debug.enabled && config.debug.autofill ? config.debug.stubData.email : "";

  useEffect(() => {
    setFocus("email");
  }, [ setFocus ]);

  return <>
    {
      isSubmitting ?
        <LoaderUi appearance="default" background={"transparent"} /> :
        <FormUi onSubmit={handleSubmit(onSubmit, onFormErrors)}>
          <InputUi
            autoFocus
            type="email"
            inputMode={"email"}
            isValid={!Boolean(errors.email)}
            id="password-recovery-email"
            placeholder={t`Email`}
            autoComplete={"email"}
            {...register("email", {
              required: true,
              pattern: emailPattern,
              value: initialValue,
            })}
          />
          <FormErrorUi error={errors.email} />
          <PrimaryPositiveButtonUi label={t`Reset Password`} />
        </FormUi>
    }
  </>;
};

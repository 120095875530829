import React from "react";
import { APP_BASE_URL } from "@common/commonConstants";
import { Capacitor } from "@capacitor/core";
import { FooterComponent } from "@feature/layout/component/FooterComponent";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonButton,
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import {
  LINK_APP_ANDROID,
  LINK_APP_IOS,
} from "@feature/maintenance/maintenanceConstants";
import { LogoUi } from "@ui/LogoUi/LogoUi";
import { Trans } from "@lingui/macro";
import { useStyles } from "../style";

export const VersionMandatoryPage = () => {
  const { classes } = useStyles();

  const platform = Capacitor.getPlatform();

  const handleRedirect = () => {
    if (platform === "ios") {
      window.open(LINK_APP_IOS, "_system");
    } else if (platform === "android") {
      window.open(LINK_APP_ANDROID, "_system");
    } else {
      window.location.href = `${ APP_BASE_URL }?timestamp=${ new Date().getTime() }`;
    }
  };

  return <>
    <IonPage>
      <IonContent>
        <HeaderUi background={"realBlack"} />
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <IonRow className={classes.logoFixed}>
              <LogoUi full />
            </IonRow>
          </div>
          <div className={classes.contentContainer}>
            {
              (
                platform === "ios" ||
                platform === "android"
              ) &&
	            <>
		            <h1 style={{ textAlign: "center" }}>
			            <Trans>Please download the latest version of the app to enjoy new features and improvements.</Trans>
		            </h1>

		            <br />

		            <IonButton onClick={handleRedirect}>
			            <Trans>Update the app</Trans>
		            </IonButton>
		            <br />
	            </>
            }
            {
              platform !== "ios" &&
              platform !== "android" &&
	            <>
		            <h1 style={{ textAlign: "center" }}>
			            <Trans>Please reload the window to use the lastes version of the app.</Trans>
		            </h1>

		            <br />

		            <IonButton onClick={handleRedirect}>
			            <Trans>Reload the window</Trans>
		            </IonButton>
		            <br />
	            </>
            }

            <FooterComponent />
          </div>
        </div>
      </IonContent>
    </IonPage>
  </>
  ;
};

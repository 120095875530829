import {
  API_CACHE_TAG_ATHLETE,
  API_CACHE_TAG_ATHLETE_LIST_LINKED,
} from "@feature/athlete/athleteConstants";
import {
  API_CACHE_TAG_HISTORY_SERIE,
  API_CACHE_TAG_HISTORY_TRAINING_SESSION,
  API_CACHE_TAG_HISTORY_WORKOUT,
} from "@feature/history/historyConstants";
import {
  API_CACHE_TAG_LIST_VALUE,
  api,
} from "@core/api";
import {
  API_CACHE_TAG_SHEET,
  API_CACHE_TAG_SHEET_QUICK_VALUE,
} from "@feature/sheet/sheetConstants";
import { AccountListLinkedToAthleteInputType } from "@common/type-graphql/athlete/input-type/account-list-linked-to-athlete.input-type";
import {
  AccountListLinkedToAthleteOutput,
} from "@common/type-graphql/athlete/output-type/account-list-linked-to-athlete-output";
import {
  Athlete,
  CreateOneAthleteArgs,
  FindUniqueAthleteArgs,
  UpdateOneAthleteArgs,
} from "@common/type-graphql/generated";
import { AthleteConnectInputType } from "@common/type-graphql/athlete/input-type/athlete-connect.input-type";
import { AthleteDisconnectInputType } from "@common/type-graphql/athlete/input-type/athlete-disconnect.input-type";
import {
  AthleteToggleConnectCodeInputType,
} from "@common/type-graphql/athlete/input-type/athlete-toggle-connect-code.input-type";
import { AthleteUnshareInputType } from "@common/type-graphql/athlete/input-type/athlete-unshare.input-type";
import {
  accountListLinkedToAthleteQuery,
  accountListLinkedToAthleteTransformResponse,
} from "@common/type-graphql/athlete/gql/account-list-linked-to-athlete.g";
import {
  athleteConnectQuery,
  athleteConnectTransformResponse,
} from "@common/type-graphql/athlete/gql/athlete-connect.g";
import {
  athleteDisconnectQuery,
  athleteDisconnectTransformResponse,
} from "@common/type-graphql/athlete/gql/athlete-disconnect.g";
import {
  athleteQuery,
  athleteTransformResponse,
} from "@common/type-graphql/athlete/gql/athlete-get.g";
import {
  athleteToggleConnectCodeQuery,
  athleteToggleConnectCodeTransformResponse,
} from "@common/type-graphql/athlete/gql/athlete-toggle-connect-code.g";
import {
  athleteUnshareQuery,
  athleteUnshareTransformResponse,
} from "@common/type-graphql/athlete/gql/athlete-unshare.g";
import {
  athletesQuery,
  athletesTransformResponse,
} from "@common/type-graphql/athlete/gql/athlete-list.g";
import {
  createOneAthleteQuery,
  createOneAthleteTransformResponse,
} from "@common/type-graphql/athlete/gql/athlete-create.g";
import { transformFormErrors } from "@util/transformFormErrors";
import {
  updateOneAthleteQuery,
  updateOneAthleteTransformResponse,
} from "@common/type-graphql/athlete/gql/athlete-update.g";

export const athleteApi = api.injectEndpoints({
  endpoints: builder => ({
    athleteList: builder.query<Athlete[], void>({
      query: () => ({ document: athletesQuery }),
      transformResponse: athletesTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE,
          id: API_CACHE_TAG_LIST_VALUE,
        },
      ],
    }),
    athleteGet: builder.query<Athlete, FindUniqueAthleteArgs>({
      query: variables => ({
        document: athleteQuery,
        variables: variables,
      }),
      transformResponse: athleteTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE,
          id: input.where.uuid,
        },
      ],
    }),
    athleteCreate: builder.mutation<Athlete, CreateOneAthleteArgs>({
      query: variables => ({
        document: createOneAthleteQuery,
        variables: variables,
      }),
      transformResponse: createOneAthleteTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE,
          id: API_CACHE_TAG_LIST_VALUE,
        },
      ],
      transformErrorResponse: transformFormErrors,
    }),
    athleteEdit: builder.mutation<Athlete, UpdateOneAthleteArgs>({
      query: variables => ({
        document: updateOneAthleteQuery,
        variables: variables,
      }),
      transformResponse: updateOneAthleteTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_ATHLETE,
          id: input.where.uuid,
        },
        {
          type: API_CACHE_TAG_SHEET,
          id: API_CACHE_TAG_SHEET_QUICK_VALUE,
        },
      ],
    }),
    athleteToggleConnectCode: builder.mutation<boolean, { data: AthleteToggleConnectCodeInputType }>({
      query: variables => ({
        document: athleteToggleConnectCodeQuery,
        variables: variables,
      }),
      transformResponse: athleteToggleConnectCodeTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE,
          id: input.data.athleteUuid,
        },
      ],
    }),
    accountListLinkedToAthlete: builder.query<AccountListLinkedToAthleteOutput[], { data: AccountListLinkedToAthleteInputType }>({
      query: variables => ({
        document: accountListLinkedToAthleteQuery,
        variables: variables,
      }),
      transformResponse: accountListLinkedToAthleteTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE_LIST_LINKED,
          id: input.data.athleteUuid,
        },
      ],
    }),
    athleteDisconnect: builder.mutation<boolean, { data: AthleteDisconnectInputType }>({
      query: variables => ({
        document: athleteDisconnectQuery,
        variables: variables,
      }),
      transformResponse: athleteDisconnectTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_ATHLETE,
          id: input.data.athleteUuid,
        },
        {
          type: API_CACHE_TAG_SHEET,
          id: API_CACHE_TAG_SHEET_QUICK_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_TRAINING_SESSION,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_WORKOUT,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_SERIE,
          id: API_CACHE_TAG_LIST_VALUE,
        },
      ],
    }),
    athleteUnshare: builder.mutation<boolean, { data: AthleteUnshareInputType }>({
      query: variables => ({
        document: athleteUnshareQuery,
        variables: variables,
      }),
      transformResponse: athleteUnshareTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE_LIST_LINKED,
          id: input.data.athleteUuid,
        },
      ],
    }),
    athleteConnect: builder.mutation<boolean, { data: AthleteConnectInputType }>({
      query: variables => ({
        document: athleteConnectQuery,
        variables: variables,
      }),
      transformResponse: athleteConnectTransformResponse,
      invalidatesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ATHLETE,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_SHEET,
          id: API_CACHE_TAG_SHEET_QUICK_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_TRAINING_SESSION,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_WORKOUT,
          id: API_CACHE_TAG_LIST_VALUE,
        },
        {
          type: API_CACHE_TAG_HISTORY_SERIE,
          id: API_CACHE_TAG_LIST_VALUE,
        },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAthleteListQuery,
  useAthleteGetQuery,
  useAthleteCreateMutation,
  useAthleteEditMutation,
  useAthleteToggleConnectCodeMutation,
  useAccountListLinkedToAthleteQuery,
  useAthleteDisconnectMutation,
  useAthleteUnshareMutation,
  useAthleteConnectMutation,
} = athleteApi;

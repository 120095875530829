import { BadgeUi } from "@ui/BadgeUi/BadgeUi";
import {
  Color,
  HeaderBackground,
  Icons,
} from "@types-custom";
import { useLogic } from "./useLogic";
import { useStyles } from "./useStyles";

export interface Props {
  isToggled: boolean;
  branded: boolean;
  position?: string;
  icon: Icons;
  hidden?: boolean;
  className?: string;
  iconColored?: boolean;
  iconColor?: Color;
  hasBadge?: boolean;
  badgeBackground?: HeaderBackground;
  isAccordion?: boolean;
  isAccordionOpen?: boolean;
}

export const IconUi = ({
  isToggled,
  branded,
  position,
  icon,
  hidden,
  className,
  iconColored,
  iconColor,
  hasBadge,
  badgeBackground,
  isAccordion,
  isAccordionOpen,
}: Props) => {
  const {
    classes, cx,
  } = useStyles({ iconColor: iconColor });

  const { $icon } = useLogic({
    isAccordion: isAccordion,
    isAccordionOpen: isAccordionOpen,
  });

  return <>
    <div
      ref={$icon}
      className={cx({
        [classes.iconRoot]: true,
        [classes.iconLeft]: position === "left",
        [classes.hidden]: hidden,
        [className]: className !== undefined || className !== "",
      })}>
      <svg
        className={cx({
          [classes.icon]: true,
          [classes.isToggled]: isToggled,
          [classes.branded]: branded || iconColored,
        })}
        fill="#ffffff"
      >
        <use href={`#ico-${ icon }`} />
      </svg>
      {
        icon.includes("bell") && hasBadge &&
        <BadgeUi background={badgeBackground} />
      }
    </div>
  </>;
};

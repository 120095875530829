import { ApiData } from "@feature/api/component/ApiData";
import { Athlete } from "@common/type-graphql/generated";
import {
  IonChip,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { bodyOutline } from "ionicons/icons";
import { useAthleteGetQuery } from "@feature/athlete/api/athleteApi";
import { useProfileGetQuery } from "@feature/profile/api/profileApi";

type Props = {
  athleteUuid?: string;
  profileUuid?: string;
}

export const AthleteChipComponent = (props: Props) => {
  if (
    !props.profileUuid &&
    !props.athleteUuid
  ) {
    return <></>;
  }

  return props.profileUuid ?
    <AthleteByProfileChipComponent uuid={props.profileUuid} /> :
    <AthleteByAthleteChipComponent uuid={props.athleteUuid} />;
};

type PropsAthlete = {
  uuid: string;
}

const AthleteByAthleteChipComponent = (props: PropsAthlete) => {
  const athleteApi = useAthleteGetQuery({ where: { uuid: props.uuid } });

  return <>
    <ApiData endpoint={athleteApi}>
      <AthleteChip athlete={athleteApi.data} />
    </ApiData>
  </>;
};

type PropsProfile = {
  uuid: string;
}

const AthleteByProfileChipComponent = (props: PropsProfile) => {
  const profileApi = useProfileGetQuery({ where: { uuid: props.uuid } });

  return <>
    <ApiData endpoint={profileApi}>
      <AthleteByAthleteChipComponent uuid={profileApi.data?.athleteUuid} />
    </ApiData>
  </>;
};

type AthleteProps = {
  athlete: Athlete;
}

const AthleteChip = (props: AthleteProps) => {
  return <>
    <IonChip
      color="primary">
      <IonIcon
        icon={bodyOutline}
        size="small" />
      <IonLabel style={{
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      }}>
        {props.athlete.name}
      </IonLabel>
    </IonChip>
  </>;
};

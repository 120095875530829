import { FieldError } from "react-hook-form/dist/types/errors";
import {
  Trans,
  t,
} from "@lingui/macro";
import { useStyles } from "./useStyles";

export interface Props {
  error: FieldError | string | null;
}

export const FormErrorUi = (props: Props) => {
  const { classes } = useStyles();

  if (!props.error) {
    return null;
  }

  let message = t`Error`;

  if (typeof props.error === "string") {
    message = props.error;
  } else if (props.error.type === "required") {
    message = t`This field is required`;
  } else if (props.error.message !== "") {
    message = props.error.message;
  }

  return <>
    <div className={classes.formErrorRoot}>
      <Trans>{message}</Trans>
    </div>
  </>;
};

import { Athlete } from "@common/type-graphql/generated";
import { ListActionBarUi } from "@ui/ListActionBarUi/ListActionBarUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { clearAthleteFormPreferences } from "@feature/athlete/service/athleteService";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import {
  historyController,
} from "@core/redux/store";
import { t } from "@lingui/macro";
import {
  useEffect,
  useState,
} from "react";

type Props = {
  data?: Athlete[];
  onClick?: (uuid: string) => void;
  hideAddButtonClick?: boolean;
}

type Element = {
  uuid: string;
  name: string;
}

export const AthleteListComponent = (props: Props) => {
  const [
    filteredData,
    setFilteredData,
  ] = useState<Element[]>([]);

  const searchChange = (value: string) => {
    const elements = props.data.filter(element => element.name.toLowerCase().includes(value.toLowerCase()));
    update(elements);
  };

  const update = (elements: Athlete[]) => {
    const protocolsSortedByTitle: Element[] = elements.map(element => {
      return {
        uuid: element.uuid,
        name: element.name,
      };
    });
    setFilteredData(protocolsSortedByTitle);
  };

  useEffect(() => {
    update(props.data);
  }, [ props.data ]);

  const onAddButtonClick = async() => {
    await clearAthleteFormPreferences();
    historyController.replace(routes.athleteCreate.redirectPath);
  };

  const handleClick = (uuid: string) => {
    if (props.onClick) {
      props.onClick(uuid);
    } else {
      historyController.replace(routes.athleteView.routePath.replace(getRouteUuidPlaceholder(), uuid));
    }
  };

  return <>
    <ListActionBarUi
      addButtonLabel={t`Athlete`}
      onAddButtonClick={onAddButtonClick}
      onSearchChange={searchChange}
    />
    <SectionUi rounded sectionListing={true}>
      {
        filteredData.map(item =>
          <ListItemUi
            key={item.uuid}
            title={item.name}
            onClick={() => handleClick(item.uuid)}
          />
        )
      }
    </SectionUi>
  </>;
};

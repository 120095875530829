import {
  useEffect,
  useState,
} from "react";

export const useSafeAreaHeight = () => {
  const [
    usableHeight,
    setUsableHeight,
  ] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const topSafeArea = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--ion-safe-area-top"), 10);
      const bottomSafeArea = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--ion-safe-area-bottom"), 10);
      const viewportHeight = window.innerHeight;
      const calculatedHeight = viewportHeight - topSafeArea - bottomSafeArea;
      setUsableHeight(calculatedHeight);
    };

    // Calcola l'altezza iniziale
    updateHeight();

    // Ascolta i cambiamenti della dimensione della finestra per aggiornare l'altezza
    window.addEventListener("resize", updateHeight);

    // Cleanup function per rimuovere l'event listener quando il componente che usa l'hook viene smontato
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return usableHeight;
};

import { numberToUUID } from "@capacitor-community/bluetooth-le";

export const STORE_DEVICE_KEY = "device";
export const STORE_DEVICE_AUTO_CONNECT_KEY = "device_auto_connect";

export const DEVICE_FAKE_NAME = "Fake Device";
export const DEVICE_FAKE_ID = "00000000-0000-0000-0000-000000000000";

export const DEVICE_NAME_PREFIX_PT = "PerfecTraining";
export const DEVICE_NAME_PREFIX = "Bodygon";
export const DEVICE_NAME_PREFIX_CORE = "Argon";
export const DEVICE_BODYGON_SERVICE = numberToUUID(0x0700);
export const DEVICE_BODYGON_SEND_CHARACTERISTIC = numberToUUID(0x0710);
export const DEVICE_BODYGON_SET_RANGE_CHARACTERISTIC = numberToUUID(0x0711);
export const DEVICE_BODYGON_SET_TEMPERATURE_CHARACTERISTIC = numberToUUID(0x0712);
export const DEVICE_BODYGON_SET_NEW_NAME_CHARACTERISTIC = numberToUUID(0x0713);
export const DEVICE_BODYGON_GET_BATTERY_LEVEL_CHARACTERISTIC = numberToUUID(0x0714);
export const DEVICE_BODYGON_FLOW_CHARACTERISTIC = numberToUUID(0x0740);

export const DEVICE_SCAN_TIMEOUT = 120000; // ms
export const DEVICE_LAST_RECEIVED_THRESHOLD = 450; // ms

export const BLUETOOTH_STATUS_IDLE = "idle";
export const BLUETOOTH_STATUS_SCANNING = "scanning";

export const BLUETOOTH_DEVICE_STATUS_CONNECTED = "connected";
export const BLUETOOTH_DEVICE_STATUS_DISCONNECTED = "disconnected";

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    listWrapper: {
      width: "100%",
      zIndex: 1,
      "&$hasReorder": { padding: "16px 0 16px 40px" },
      position: "relative",
      display: "block",
      justifyContent: "flex-start",
      margin: 0,
      iconInner: { width: "80%" },
      "--ion-item-border-color": "transparent",
    },
    listLabelWrapper: {
      borderRadius: "10px",
      div: { paddingLeft: "25px" },
    },
    reorderlistLabelWrapper: {
      div: { paddingLeft: "25px" },
    },
    reorder: {
      width: 12,
      position: "absolute",
      padding: 12,
      zIndex: "99",
    },
  })
);

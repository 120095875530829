import moment from "moment";
import { DEVICE_LAST_RECEIVED_THRESHOLD } from "@feature/device/deviceConstants";
import { Point } from "@common/model/Point";
import { deviceReadsReceived } from "@feature/device/slice/deviceSlice";
import { deviceReadsService } from "@feature/device/service/deviceReadsService";
import { misto1Mock } from "../../../mock/misto1.mock";
import {
  storageGet,
  storageSet,
} from "@feature/storage/service/storageService";
import { store } from "@core/redux/store";

class DeviceStubService {
  private timer: any;
  private pivot: number;
  private lastSend: number;

  private data: Point[] = misto1Mock;

  constructor() {
    this.pivot = 0;
    this.lastSend = 0;

    storageGet<Point[]>("stubServiceData").then(data => {
      if (data && data.length) {
        this.data = data;
      }
    });
  }

  send(value: number) {
    clearTimeout(this.timer);
    this.pivot = 0;
    if (value === 1) {
      this.timer = setInterval(() => {
        if (this.pivot < this.data.length) {
          const now = Date.now();
          if (this.lastSend === 0) {
            this.lastSend = now;
          }
          deviceReadsService.add(this.data[this.pivot++]);
          if (
            moment(now) > moment(this.lastSend).add(DEVICE_LAST_RECEIVED_THRESHOLD, "milliseconds") &&
            this.pivot % 5 === 0
          ) {
            this.lastSend = now;
            store.dispatch(deviceReadsReceived());
          }
        }
      // }, 8); // 1000ms / 125hz = 8ms
      }, 10); // 1000ms / 100hz = 10ms
    }
  }

  async saveInStorage(mockData: Point[]) {
    await storageSet("stubServiceData", JSON.stringify(mockData));
    this.data = mockData;
  }
}

export const deviceStubService = new DeviceStubService();

import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { ProfileForm } from "@feature/profile/component/form/profileForm";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { i18n } from "@lingui/core";
import { useParams } from "react-router-dom";

type Params = {
  athleteUuid: string;
  uuid?: string;
}

export const ProfileEditPage = () => {
  const params = useParams<Params>();

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.profileEdit.title)}
        backToTitle={i18n._(routes.profileList.title)}
        backToPath={routes.profileList.routePath.replace(getRouteUuidPlaceholder("athlete"), params.athleteUuid)} />
      <IonContent>
        <ProfileForm athleteUuid={params.athleteUuid} uuid={ params.uuid } />
      </IonContent>
    </IonPage>
  </>;
};

import { CustomerServiceContactComponent } from "@feature/layout/component/CustomerServiceContactComponent";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { Trans } from "@lingui/macro";

export const SystemErrorMessageComponent = () => {
  return <>
    <SectionUi>
      <p>
        <Trans>System error, please contact customer support.</Trans>
      </p>
      <p>
        <CustomerServiceContactComponent />
      </p>
    </SectionUi>
  </>;
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    root: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
      color: theme.isDarkMode ? theme.colors.pureWhite : theme.colors.black,
    },
    comment: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
    },
  })
);

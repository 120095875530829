import React from "react";
import { DisclaimerComponent } from "@feature/account/component/disclaimerComponent";
import {
  EmailOrPhoneNumber,
  LoginForm,
} from "@feature/account/component/form/loginForm";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import { LogoUi } from "@ui/LogoUi/LogoUi";
import { routes } from "@core/route";
import { useParams } from "react-router-dom";
import { useStyles } from "../style";

type Params = {
  autoLoginWith?: EmailOrPhoneNumber;
}

export const AccountLoginPage = () => {
  const { classes } = useStyles();
  const params = useParams<Params>();

  return <>
    <IonPage>
      <IonContent>
        <HeaderUi
          background={"realBlack"}
          backToPath={routes.accountLoginOrBuy.routePath}
          backToTitle={""} />
        <div className={classes.mainContainerWithForm}>
          <IonRow className={classes.logo}>
            <LogoUi full />
          </IonRow>
          <LoginForm autoLoginWith={params.autoLoginWith} />
          <DisclaimerComponent />
        </div>
      </IonContent>
    </IonPage>
  </>;
};

import {
  AvatarSize,
  Color,
} from "@types-custom";
import { useStyles } from "./useStyles";

export interface Props {
  color: Color | null | string;
  size?: AvatarSize;
}

export const AvatarColorUi = (props: Props) => {
  const {
    classes,
    cx,
  } = useStyles({ color: props.color });
  return <>
    <picture className={cx({
      [classes.avatarWrapper]: true,
      [classes.sizeBig]: props.size === "big",
      [classes.sizeSmall]: props.size === "small",
    })}></picture>
  </>;
};

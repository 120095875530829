import { FooterComponent } from "@feature/layout/component/FooterComponent";
import { IonPage } from "@ionic/react";
import { LogoUi } from "@ui/LogoUi/LogoUi";
import { useLogic } from "./useLogic";
import { useRef } from "react";
import { useStyles } from "./useStyles";

interface Props {
  onSplashScreenTimeout?: () => void;
}

export const SplashScreenUi = ({ onSplashScreenTimeout }: Props) => {
  const { classes } = useStyles();

  const contentRef = useRef();
  useLogic(contentRef, onSplashScreenTimeout);

  return <>
    <IonPage className={classes.splashScreen}>
      <div className={classes.splashScreenContent} ref={contentRef}>
        <div className={classes.splashScreenLogo}>
          <LogoUi full background={"colored"} />
        </div>
        <div className={classes.splashScreenFooter}>
          <FooterComponent />
        </div>
      </div>
    </IonPage>
  </>;
};

import { API_CACHE_TAG_ALGORITHM } from "@feature/algorithm/algorithmConstants";
import { Algorithm } from "@common/type-graphql/generated";
import {
  algorithmsQuery,
  algorithmsTransformResponse,
} from "@common/type-graphql/algorithm/gql/algorithm-list.g";
import {
  api,
  getTags,
} from "@core/api";

export const algorithmApi = api.injectEndpoints({
  endpoints: builder => ({
    algorithmList: builder.query<Algorithm[], void>({
      query: () => ({ document: algorithmsQuery }),
      transformResponse: algorithmsTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_ALGORITHM, result),
    }),
  }),
  overrideExisting: false,
});

export const { useAlgorithmListQuery } = algorithmApi;

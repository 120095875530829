import { ApiDataList } from "@feature/api/component/ApiDataList";
import { AthleteListComponent } from "@feature/athlete/component/athleteListComponent";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { clearAthleteFormPreferences } from "@feature/athlete/service/athleteService";
import { historyController } from "@core/redux/store";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";
import { useAthleteListQuery } from "@feature/athlete/api/athleteApi";

export const AthleteListPage = () => {
  const athleteListApi = useAthleteListQuery();

  const goToAthleteCreate = async() => {
    await clearAthleteFormPreferences();
    historyController.replace(routes.athleteCreate.redirectPath);
  };

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.athleteList.title)}
        backToRoute={routes.dashboard} />
      <IonContent>
        <ApiDataList
          endpoint={athleteListApi}
          useRefresher={true}
          onButtonClick={goToAthleteCreate}
        >
          <AthleteListComponent
            data={athleteListApi.data}
          />
        </ApiDataList>
      </IonContent>
    </IonPage>
  </>;
};

/* eslint-disable max-len */
import { memo } from "react";

const SVGSpriteUi = () => {
  return <>
    <svg style={{ display: "none" }}>
      <symbol id="ico-history" viewBox="0 0 24 22">
        <path d="M13 3c-4.97 0-9 4.03-9 9 0 4.97 4.03 9 9 9s9-4.03 9-9c0-4.97-4.03-9-9-9zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"></path>
        <path d="M12.5 8H11v5l4.25 2.52.75-1.23-3.5-2.09z"></path>
      </symbol>
      <symbol id="ico-add-round" viewBox="0 0 24 24">
        <path
          d="M18 11h-5V6c0-.55-.45-1-1-1s-1 .45-1 1v5H6c-.55 0-1 .45-1 1s.45 1 1 1h5v5c0 .55.45 1 1 1s1-.45 1-1v-5h5c.55 0 1-.45 1-1s-.45-1-1-1Z" />
      </symbol>
      <symbol id="ico-arrow-left" viewBox="0 0 24 24">
        <path
          d="M4 12L3.29289 11.2929L2.58579 12L3.29289 12.7071L4 12ZM19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11V13ZM9.29289 5.29289L3.29289 11.2929L4.70711 12.7071L10.7071 6.70711L9.29289 5.29289ZM3.29289 12.7071L9.29289 18.7071L10.7071 17.2929L4.70711 11.2929L3.29289 12.7071ZM4 13H19V11H4V13Z" />
      </symbol>
      <symbol id="ico-bell" viewBox="0 0 24 24">
        <path
          d="m20.35 13.97-.61-1.01c-.51-.85-.83-1.79-.95-2.84l-.25-2.27c-.37-3.34-3.18-5.86-6.55-5.86S5.82 4.51 5.44 7.85l-.26 2.32c-.11.99-.43 1.93-.97 2.83l-.58.97c-.63 1.04-.97 1.62-.86 2.34.08.48.32.91.69 1.22.56.47 1.23.47 2.45.47h1.17c.06.31.15.62.28.91.25.61.62 1.16 1.08 1.62a5.006 5.006 0 0 0 5.44 1.08c.61-.25 1.16-.62 1.62-1.08.47-.46.83-1.01 1.08-1.62.12-.3.21-.6.28-.91h1.17c1.22 0 1.89 0 2.45-.47.37-.31.61-.74.69-1.22.11-.72-.23-1.29-.86-2.34Zm-5.58 4.18c-.15.37-.37.69-.65.97s-.61.5-.97.65c-.73.3-1.56.3-2.3 0-.37-.15-.69-.37-.97-.65s-.5-.61-.65-.97c-.02-.05-.03-.1-.04-.15h5.63s-.02.1-.04.15Zm3.3-2.15H5.93c-.44 0-.88 0-1.12-.03.1-.21.33-.6.55-.97l.61-1.02c.66-1.1 1.06-2.3 1.21-3.64l.25-2.27c.26-2.33 2.22-4.08 4.56-4.08s4.3 1.75 4.56 4.08l.26 2.33c.15 1.27.55 2.48 1.19 3.54l.64 1.06c.22.37.45.75.55.96-.24.03-.68.03-1.12.03Z" />
      </symbol>
      <symbol id="ico-bodygon-devices" viewBox="0 0 24 24">
        <path
          d="M2.63 5.78c.28.48.89.64 1.37.37a16.03 16.03 0 0 1 16 0c.48.28 1.09.11 1.37-.37s.11-1.09-.37-1.37a18.006 18.006 0 0 0-18 0c-.48.28-.64.89-.37 1.37Zm2.5 4.33c.28.48.89.64 1.37.37 1.67-.97 3.57-1.47 5.5-1.47s3.83.51 5.5 1.47c.48.28 1.09.11 1.37-.37s.11-1.09-.37-1.37C16.52 7.6 14.28 7 12 7s-4.52.6-6.5 1.74c-.48.28-.64.89-.37 1.37Zm11.13 6.53-.45-2.03c-.13-.59-.53-1.1-1.07-1.36l-1.87-.92c-.55-.27-1.19-.27-1.74-.01l-1.88.89c-.55.26-.95.76-1.09 1.35l-.48 2.02c-.14.59 0 1.22.37 1.69l1.28 1.64c.38.48.95.76 1.56.76l2.08.02c.61 0 1.19-.27 1.57-.74l1.31-1.62c.38-.47.53-1.09.4-1.69Zm-2.36 1.44c-.47.59-1.19.92-1.94.92s-1.46-.35-1.93-.95c-.47-.59-.64-1.36-.46-2.1a2.472 2.472 0 0 1 3.5-1.65c.68.33 1.16.95 1.33 1.69s-.02 1.5-.49 2.09Z" />
      </symbol>
      <symbol id="ico-close-round-fill" viewBox="0 0 24 24">
        <path
          d="M12 3a9 9 0 1 0 .001 18.001A9 9 0 0 0 12 3Zm4.71 12.29a.996.996 0 1 1-1.41 1.41l-3.29-3.29-3.29 3.29a.996.996 0 1 1-1.41-1.41L10.6 12 7.31 8.71A.996.996 0 1 1 8.72 7.3l3.29 3.29L15.3 7.3a.996.996 0 1 1 1.41 1.41L13.42 12l3.29 3.29Z" />
      </symbol>
      <symbol id="ico-close-round" viewBox="0 0 24 24">
        <path
          d="m13.41 12 5.29-5.29a.996.996 0 1 0-1.41-1.41L12 10.59l-5.29-5.3A.996.996 0 1 0 5.3 6.7l5.29 5.29-5.29 5.29a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29l5.29-5.29 5.29 5.29c.2.2.45.29.71.29s.51-.1.71-.29a.996.996 0 0 0 0-1.41l-5.29-5.29Z" />
      </symbol>
      <symbol id="ico-date-range" viewBox="0 0 24 24">
        <path
          d="M19 5h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v2H8V3c0-.55-.45-1-1-1s-1 .45-1 1v2H5C3.35 5 2 6.35 2 8v11c0 1.65 1.35 3 3 3h14c1.65 0 3-1.35 3-3V8c0-1.65-1.35-3-3-3Zm1 14c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-9h16v9ZM7 13.5v-1c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5v1c0 .28-.22.5-.5.5h-3c-.28 0-.5-.22-.5-.5Zm0 4v-1c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5v1c0 .28-.22.5-.5.5h-3c-.28 0-.5-.22-.5-.5Zm6-4v-1c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5v1c0 .28-.22.5-.5.5h-3c-.28 0-.5-.22-.5-.5Zm0 4v-1c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5v1c0 .28-.22.5-.5.5h-3c-.28 0-.5-.22-.5-.5Z" />
      </symbol>
      <symbol id="ico-eptagon" viewBox="0 0 97.49 100">
        <path d="M97.49 50 78.18 90.1 34.79 100 0 72.25v-44.5L34.79 0l43.39 9.9L97.49 50z" />
      </symbol>
      <symbol id="ico-expand-down" viewBox="0 0 24 24">
        <path
          d="m12 15 .71.71-.71.71-.71-.71L12 15Zm6.71-5.29-6 6-1.41-1.41 6-6 1.41 1.41Zm-7.41 6-6-6L6.71 8.3l6 6-1.41 1.41Z" />
      </symbol>
      <symbol id="ico-expand-left-double" viewBox="0 0 24 24">
        <path
          d="m6 12-.71.71-.71-.71.71-.71L6 12Zm5.29 6.71-6-6L6.7 11.3l6 6-1.41 1.41Zm-6-7.41 6-6 1.41 1.41-6 6-1.41-1.41Zm6.71.71-.71.71-.71-.71.71-.71.71.71Zm5.29 6.71-6-6 1.41-1.41 6 6-1.41 1.41Zm-6-7.41 6-6 1.41 1.41-6 6-1.41-1.41Z" />
      </symbol>
      <symbol id="ico-expand-left" viewBox="0 0 24 24">
        <path
          d="m9 12-.71-.71-.71.71.71.71L9 12Zm5.29-6.71-6 6L9.7 12.7l6-6-1.41-1.41Zm-6 7.41 6 6 1.41-1.41-6-6-1.41 1.41Z" />
      </symbol>
      <symbol id="ico-expand-right-double" viewBox="0 0 24 24">
        <path
          d="m18 12 .71.71.71-.71-.71-.71L18 12Zm-5.29 6.71 6-6-1.41-1.41-6 6 1.41 1.41Zm6-7.41-6-6-1.41 1.41 6 6 1.41-1.41Zm-6.71.71.71.71.71-.71-.71-.71-.71.71Zm-5.29 6.71 6-6-1.41-1.41-6 6 1.41 1.41Zm6-7.41-6-6.02L5.3 6.7l6 6 1.41-1.41Z" />
      </symbol>
      <symbol id="ico-expand-right" viewBox="0 0 24 24">
        <path
          d="m15 12 .71-.71.71.71-.71.71L15 12ZM9.71 5.29l6 6-1.41 1.41-6-6 1.41-1.41Zm6 7.41-6 6-1.41-1.41 6-6 1.41 1.41Z" />
      </symbol>
      <symbol id="ico-expand-up" viewBox="0 0 24 24">
        <path
          d="m12 9 .71-.71-.71-.71-.71.71L12 9Zm6.71 5.29-6-6L11.3 9.7l6 6 1.41-1.41Zm-7.41-6-6 6 1.41 1.41 6-6-1.41-1.41Z" />
      </symbol>
      <symbol id="ico-filter-alt-fill" viewBox="0 0 24 24">
        <path
          d="M20 3v2H4V3c0-.55.45-1 1-1h14c.55 0 1 .45 1 1ZM4.68 7.6 10 12.25v6.45l4-2v-4.45l5.32-4.65c.19-.17.35-.37.47-.6H4.22c.11.22.27.43.47.6Z" />
      </symbol>
      <symbol id="ico-filter-alt" viewBox="0 0 24 24">
        <path
          d="m9.66 19.78.32.95-.32-.95Zm5-1.67.32.95-.32-.95Zm5.05-10.82.71.71-.71-.71ZM15.3 11.7l-.71-.71.71.71ZM5 5h14V3H5v2Zm0 1.59V5H3v1.59h2ZM9.41 11 5 6.59 3.59 8 8 12.41 9.41 11ZM8 12.41v6.89h2v-6.89H8Zm0 6.89c0 1.02 1 1.75 1.97 1.42l-.63-1.9a.5.5 0 0 1 .66.47H8Zm1.97 1.42 5-1.67-.63-1.9-5 1.67.63 1.9Zm5-1.67c.61-.2 1.03-.78 1.03-1.42h-2c0-.22.14-.41.34-.47l.63 1.9ZM16 17.63V12.4h-2v5.23h2Zm3-11.05-4.41 4.41L16 12.4l4.41-4.41L19 6.58Zm0-1.59v1.59h2V4.99h-2Zm1.41 3c.38-.38.59-.88.59-1.41h-2l1.41 1.41ZM16 12.4l-1.41-1.41c-.38.38-.59.88-.59 1.41h2Zm-8 0h2c0-.53-.21-1.04-.59-1.41L8 12.4ZM3 6.59c0 .53.21 1.04.59 1.41L5 6.59H3ZM19 5h2c0-1.1-.9-2-2-2v2ZM5 3c-1.1 0-2 .9-2 2h2V3Z" />
      </symbol>

      <symbol id="ico-home-fill" viewBox="0 0 24 24">
        <path
          d="M5.27 9.15C5 9.75 5 10.43 5 11.78v4.24c0 1.89 0 2.83.59 3.41.53.53 1.36.58 2.91.59v-5c0-1.1.9-2 2-2h3c1.1 0 2 .9 2 2v5c1.56 0 2.38-.05 2.91-.59.59-.59.59-1.53.59-3.41v-4.24c0-1.36 0-2.04-.27-2.63-.27-.6-.79-1.04-1.82-1.92l-1-.86c-1.86-1.6-2.79-2.4-3.9-2.4s-2.04.8-3.9 2.4l-1 .86c-1.03.88-1.55 1.33-1.82 1.92Zm8.23 10.87v-5h-3v5h3Z" />
      </symbol>
      <symbol id="ico-home" viewBox="0 0 24 24">
        <path
          d="M19.63 8.73c-.37-.79-.97-1.31-2.08-2.26l-1-.86c-1.98-1.7-3.07-2.64-4.55-2.64s-2.57.94-4.56 2.64l-1.01.86c-1.1.94-1.71 1.46-2.07 2.26-.37.8-.37 1.6-.37 3.05v4.24c0 2.09 0 3.24.88 4.12s2.03.88 4.12.88h6c2.09 0 3.24 0 4.12-.88s.88-2.03.88-4.12v-4.24c0-1.45 0-2.26-.37-3.05ZM13.5 19.02h-3v-4h3v4Zm4.5-3c0 1.45 0 2.41-.29 2.71-.26.26-1.03.29-2.21.29v-4c0-1.1-.9-2-2-2h-3c-1.1 0-2 .9-2 2v4c-1.17 0-1.95-.03-2.21-.29C6 18.44 6 17.48 6 16.02v-4.24c0-1.29 0-1.83.18-2.22.18-.38.58-.74 1.56-1.58l1-.86c1.79-1.53 2.54-2.15 3.25-2.15s1.46.62 3.25 2.16l1 .86c.98.84 1.38 1.2 1.56 1.58.18.38.18.92.18 2.22v4.24Z" />
      </symbol>
      <symbol id="ico-img-box-fill" viewBox="0 0 24 24">
        <path
          d="M22 14V8.48c0-.25 0-.5-.01-.73v-.36c0-.29-.02-.56-.04-.82v-.11c-.02-.27-.04-.52-.07-.76v-.09c0-.04-.02-.08-.02-.12-.03-.19-.06-.37-.1-.54-.01-.07-.03-.13-.05-.2-.05-.2-.11-.4-.19-.57-.17-.4-.39-.73-.67-1.02-.56-.56-1.29-.87-2.44-1.03-1.09-.15-2.47-.15-4.39-.15h-4c-1.92 0-3.3 0-4.39.15-.94.13-1.59.36-2.11.76-.12.07-.22.17-.33.27-.02.02-.04.05-.06.07-.12.13-.23.26-.33.4-.02.04-.04.08-.06.11-.08.14-.16.28-.23.44-.03.07-.05.14-.07.21-.05.14-.1.28-.14.43-.06.23-.1.48-.14.74v.05c-.11.82-.14 1.8-.14 3.04v6.66c0 1.18.03 2.11.13 2.9.04.34.09.65.16.93.04.15.09.28.13.42.03.07.05.16.07.23.07.16.15.31.24.45.02.03.03.07.05.1.11.17.24.33.38.47h.01c.14.14.29.27.46.38.03.02.06.03.09.05.14.09.29.17.46.24.07.03.15.05.22.07.14.05.27.1.43.14.22.05.46.1.71.13.02 0 .04 0 .06.01.82.11 1.8.14 3.06.14h6.66c1.25 0 2.24-.03 3.06-.14h.05c.26-.04.5-.08.72-.13.13-.03.25-.08.37-.12.09-.03.19-.06.28-.09.15-.06.28-.14.4-.21.05-.03.1-.05.15-.08.15-.1.28-.21.41-.33.02-.02.04-.03.06-.05s.03-.04.05-.06c.12-.13.23-.26.33-.41.03-.05.05-.1.08-.15.07-.13.15-.26.21-.4.04-.09.06-.19.09-.28.04-.12.08-.23.12-.37.06-.23.1-.48.14-.75v-.03c.14-1.09.15-2.47.15-4.38ZM4 10c0-1.84 0-3.17.13-4.12.11-.85.29-1.13.46-1.29s.44-.34 1.29-.46C6.84 4 8.16 4 10 4h4c1.84 0 3.17 0 4.12.13.85.11 1.13.29 1.29.46.16.16.34.44.46 1.29.13.96.13 2.28.13 4.12v4c0 1.04 0 1.91-.03 2.65-.38-.18-.73-.41-1.03-.71l-.75-.75c-.72-.72-1.08-1.08-1.49-1.23-.45-.17-.94-.17-1.39 0-.41.15-.77.51-1.49 1.23l-.11.11c-.59.59-.88.88-1.19.93-.24.04-.49 0-.7-.13-.27-.17-.43-.55-.76-1.31l-.05-.12c-.75-1.75-1.12-2.62-1.78-2.95-.33-.16-.7-.24-1.06-.21-.73.06-1.4.73-2.75 2.08L4.01 15v-5Zm11-3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Z" />
      </symbol>
      <symbol id="ico-img-box" viewBox="0 0 24 24">
        <path
          d="M13 22h-2c-3.91 0-6.07 0-7.54-1.46C2 19.08 2 16.92 2 13v-2c0-3.91 0-6.07 1.46-7.54C4.92 2 7.08 2 11 2h2c3.92 0 6.07 0 7.54 1.46C22 4.92 22 7.08 22 11v2c0 3.92 0 6.07-1.46 7.54C19.08 22 16.92 22 13 22ZM11 4c-3.49 0-5.24 0-6.12.88C4 5.76 4 7.51 4 11v2c0 3.49 0 5.24.88 6.12.88.88 2.63.88 6.12.88h2c3.49 0 5.24 0 6.12-.88.88-.88.88-2.63.88-6.12v-2c0-3.49 0-5.24-.88-6.12C18.24 4 16.49 4 13 4h-2Zm8 9.59-.02-.02c-.39-.39-.73-.73-1.03-.98-.32-.27-.66-.5-1.09-.63-.57-.17-1.17-.17-1.73 0-.43.13-.77.36-1.09.63-.3.25-.64.59-1.03.98l-.03.03c-.31.31-.49.49-.63.6l-.02.02v-.02c-.1-.16-.2-.39-.37-.79l-.05-.12-.02-.05c-.36-.83-.65-1.53-.95-2.05-.3-.54-.68-1.05-1.28-1.35-.49-.25-1.05-.35-1.6-.31-.66.06-1.2.39-1.69.78-.41.33-.86.77-1.39 1.3v2.82l1.11-1.11c.69-.69 1.15-1.15 1.52-1.45.37-.3.53-.34.6-.34.18-.02.37.02.53.1.06.03.2.12.43.54.24.42.49 1.02.88 1.91l.05.12.02.04c.15.35.29.67.43.92.14.27.35.58.71.8.42.26.91.35 1.4.27.41-.07.72-.29.96-.48.22-.18.47-.43.74-.7l.03-.03c.43-.43.7-.7.93-.89.22-.18.32-.23.38-.24.19-.06.39-.06.58 0 .06.02.16.06.38.24.23.19.5.47.93.89l1.35 1.35c.05-.72.05-1.61.06-2.77ZM16.5 6c.83 0 1.5.67 1.5 1.5S17.33 9 16.5 9 15 8.33 15 7.5 15.67 6 16.5 6Z" />
      </symbol>
      <symbol id="ico-info-fill" viewBox="0 0 24 24">
        <path
          d="M12 3a9 9 0 1 0 .001 18.001A9 9 0 0 0 12 3Zm1 14h-2v-6h2v6Zm-1-8c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Z" />
      </symbol>
      <symbol id="ico-info" viewBox="0 0 24 24">
        <path
          d="M12 22C6.49 22 2 17.51 2 12S6.49 2 12 2s10 4.49 10 10-4.49 10-10 10Zm0-18c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8Zm.5 3.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5.22-.5.5-.5.5.22.5.5Zm-.5 1c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm0-1h.5-.5Zm1 2.5h-2v7h2v-7Z" />
      </symbol>
      <symbol id="ico-list" viewBox="0 0 24 24">
        <path
          d="M19 18h-9c-.55 0-1-.45-1-1s.45-1 1-1h9c.55 0 1 .45 1 1s-.45 1-1 1ZM6 18H5c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1Zm13-5h-9c-.55 0-1-.45-1-1s.45-1 1-1h9c.55 0 1 .45 1 1s-.45 1-1 1ZM6 13H5c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1Zm13-5h-9c-.55 0-1-.45-1-1s.45-1 1-1h9c.55 0 1 .45 1 1s-.45 1-1 1ZM6 8H5c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1Z" />
      </symbol>
      <symbol id="ico-on-button-fill" viewBox="0 0 24 24">
        <path
          d="M12 21a9 9 0 1 0-.001-18.001A9 9 0 0 0 12 21Zm0-16c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1s-1-.45-1-1V6c0-.55.45-1 1-1Zm3.54 2.05a.996.996 0 0 1 1.41 0c.98.98 1.65 2.23 1.92 3.58.27 1.36.13 2.77-.4 4.04a7.008 7.008 0 0 1-10.36 3.14 6.992 6.992 0 0 1-2.98-7.18c.27-1.36.94-2.61 1.92-3.58a.996.996 0 1 1 1.41 1.41c-.7.7-1.18 1.59-1.37 2.56-.19.97-.09 1.98.28 2.89s1.02 1.69 1.84 2.24c.82.55 1.79.84 2.78.84a4.992 4.992 0 0 0 4.62-3.08c.38-.91.48-1.92.28-2.89-.19-.97-.67-1.86-1.37-2.56a.996.996 0 0 1 0-1.41Z" />
      </symbol>
      <symbol id="ico-on-button" viewBox="0 0 24 24">
        <path
          d="M12 21c-1.79 0-3.52-.52-5-1.52a8.962 8.962 0 0 1-3.31-4.04 8.988 8.988 0 0 1-.51-5.2 9 9 0 0 1 2.46-4.61.996.996 0 1 1 1.41 1.41c-.98.98-1.64 2.22-1.92 3.58s-.13 2.76.4 4.04a6.944 6.944 0 0 0 2.58 3.14c2.31 1.54 5.47 1.54 7.78 0a6.944 6.944 0 0 0 2.58-3.14c.53-1.28.67-2.68.4-4.04a6.91 6.91 0 0 0-1.92-3.58.996.996 0 1 1 1.41-1.41 8.927 8.927 0 0 1 2.46 4.61c.35 1.75.17 3.55-.51 5.2A8.962 8.962 0 0 1 17 19.48 8.97 8.97 0 0 1 12 21Zm1-13V4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1Z" />
      </symbol>
      <symbol id="ico-search-fill" viewBox="0 0 24 24">
        <path
          d="M18 11c0 3.87-3.13 7-7 7s-7-3.13-7-7 3.13-7 7-7 7 3.13 7 7Zm2.71 9.71a.996.996 0 0 0 0-1.41l-2-2a.996.996 0 1 0-1.41 1.41l2 2c.2.2.45.29.71.29s.51-.1.71-.29Z" />
      </symbol>
      <symbol id="ico-search" viewBox="0 0 24 24">
        <path
          d="M11 19c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm0-14c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6Zm9.71 15.71a.996.996 0 0 0 0-1.41l-3-3a.996.996 0 1 0-1.41 1.41l3 3c.2.2.45.29.71.29s.51-.1.71-.29Z" />
      </symbol>
      <symbol id="ico-settings-fill" viewBox="0 0 24 24">
        <path
          d="m19.26 18.67-6.32 3.11c-.59.29-1.29.29-1.88 0l-6.32-3.11c-.56-.28-.96-.78-1.1-1.38l-1.6-7.15c-.13-.58 0-1.19.38-1.66L6.85 2.8c.39-.5 1.01-.8 1.66-.8h6.97c.65 0 1.27.3 1.66.8l4.43 5.68c.37.47.51 1.08.38 1.66l-1.6 7.15c-.13.6-.54 1.1-1.1 1.38ZM12 15.5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Z" />
      </symbol>
      <symbol id="ico-settings" viewBox="0 0 24 24">
        <path
          d="M21.54 8.42 17.15 2.8c-.4-.51-1-.8-1.63-.8H8.48c-.64 0-1.24.29-1.63.8L2.46 8.42c-.4.51-.54 1.17-.4 1.8l1.57 7.01c.14.63.56 1.16 1.13 1.44l6.35 3.12c.57.28 1.24.28 1.81 0l6.35-3.12c.57-.28.99-.81 1.13-1.44l1.57-7.01c.14-.63 0-1.29-.4-1.8Zm-1.63 2.27-1.16 5.21c-.14.63-.56 1.16-1.13 1.44l-4.71 2.32c-.57.28-1.24.28-1.81 0l-4.71-2.32c-.57-.28-.99-.81-1.13-1.44L4.1 10.69c-.14-.63 0-1.29.4-1.8l3.26-4.17c.4-.51 1-.8 1.63-.8h5.23c.64 0 1.24.3 1.63.8l3.26 4.17c.4.51.54 1.17.4 1.8ZM12 7.33c-2.3 0-4.17 1.91-4.17 4.26S9.7 15.85 12 15.85s4.17-1.91 4.17-4.26S14.3 7.33 12 7.33Zm0 6.39c-1.15 0-2.09-.95-2.09-2.13s.93-2.13 2.09-2.13 2.09.95 2.09 2.13-.93 2.13-2.09 2.13Z" />
      </symbol>
      <symbol id="ico-upload-fill" viewBox="0 0 24 24">
        <path
          d="M7.17 13H6c-1.66 0-3-1.34-3-3s1.34-3 3-3c.28 0 .42 0 .52-.02.3-.06.44-.15.62-.4.06-.08.14-.26.3-.63C8.22 4.21 9.97 3 12 3s3.78 1.21 4.56 2.95c.16.36.24.55.3.63.18.25.32.34.62.4.1.02.24.02.52.02 1.66 0 3 1.34 3 3s-1.34 3-3 3h-1.17l-3.41-3.41-1.41-1.41-1.41 1.41L7.19 13Zm9.54 2.29-4-4-.71-.71-.71.71-4 4L8.7 16.7l2.29-2.29V21c0 .55.45 1 1 1s1-.45 1-1v-6.59l2.29 2.29 1.41-1.41Z" />
      </symbol>
      <symbol id="ico-upload" viewBox="0 0 24 24">
        <path
          d="M12 2C9.56 2 7.46 3.46 6.52 5.55c-.08.19-.14.31-.18.4-.01.02-.02.04-.03.05h-.32c-2.21 0-4 1.79-4 4s1.79 4 4 4h.17l2-2H5.99c-1.1 0-2-.9-2-2s.9-2 2-2h.06c.21 0 .45 0 .65-.04.21-.04.46-.11.7-.27.24-.16.41-.35.53-.53.09-.12.16-.26.2-.36.05-.11.12-.26.2-.43.63-1.41 2.03-2.38 3.65-2.38s3.03.97 3.65 2.36c.08.18.14.33.2.44.05.1.11.24.2.36.13.18.29.37.53.53.24.16.49.23.7.27.2.04.44.04.65.04h.06c1.1 0 2 .9 2 2s-.9 2-2 2H15.8l2 2h.17c2.21 0 4-1.79 4-4s-1.79-4-4-4h-.32s-.02-.03-.03-.05c-.04-.09-.1-.21-.18-.4a6.011 6.011 0 0 0-5.48-3.55ZM6.3 6.03Zm11.4 0ZM15.29 16.7 13 14.41V21c0 .55-.45 1-1 1s-1-.45-1-1v-6.59L8.71 16.7 7.3 15.29l4-4 .71-.71.71.71 4 4-1.41 1.41Z" />
      </symbol>
      <symbol id="ico-user-fill" viewBox="0 0 24 24">
        <path
          d="M19.64 19.41c.55-.12.88-.69.61-1.19-.61-1.09-1.56-2.04-2.78-2.77-1.57-.94-3.5-1.45-5.48-1.45s-3.91.51-5.48 1.45c-1.22.73-2.17 1.68-2.78 2.77-.27.49.05 1.07.61 1.19a37.55 37.55 0 0 0 15.3 0Zm-8.51-6.82c.55.26 1.19.26 1.74 0l2.17-1.05c.55-.26.95-.76 1.08-1.36l.54-2.35c.14-.59 0-1.22-.39-1.69l-1.5-1.89c-.38-.48-.95-.75-1.56-.75H10.8c-.61 0-1.18.28-1.56.75l-1.5 1.89c-.38.48-.52 1.1-.39 1.69l.54 2.35c.14.59.53 1.09 1.08 1.36l2.17 1.05Z" />
      </symbol>
      <symbol id="ico-user" viewBox="0 0 24 24">
        <path
          d="m16.27 6.13-1.5-1.89a1.99 1.99 0 0 0-1.56-.75H10.8c-.61 0-1.18.28-1.56.75l-1.5 1.89c-.38.48-.52 1.1-.39 1.69l.54 2.35c.14.59.53 1.09 1.08 1.36l2.17 1.05c.55.26 1.19.26 1.74 0l2.17-1.05c.55-.26.95-.76 1.08-1.36l.54-2.35c.14-.59 0-1.22-.39-1.69ZM14.63 8.6c-.18.8-.72 1.48-1.46 1.83-.74.36-1.6.36-2.35 0a2.702 2.702 0 0 1 1.17-5.14c.82 0 1.6.37 2.11 1.02.51.64.7 1.48.52 2.29Zm5.09 12.85c-.41 0-.8-.25-.94-.66-.37-1.05-1.23-2-2.42-2.68-2.48-1.43-6.26-1.43-8.74 0-1.18.68-2.04 1.63-2.41 2.68-.19.52-.76.79-1.28.6-.52-.19-.79-.76-.61-1.28.53-1.49 1.71-2.82 3.3-3.74 3.07-1.77 7.66-1.77 10.74 0 1.59.92 2.77 2.24 3.3 3.74a.998.998 0 0 1-.94 1.34Z" />
      </symbol>
      <symbol id="ico-view-fill" viewBox="0 0 24 24">
        <path
          d="M20.19 10.93C18.77 9.21 15.64 6 12 6s-6.77 3.21-8.19 4.93c-.39.47-.58.71-.58 1.07s.19.59.58 1.07C5.23 14.79 8.36 18 12 18s6.77-3.21 8.19-4.93c.39-.47.58-.71.58-1.07s-.19-.59-.58-1.07ZM12 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3Z" />
      </symbol>
      <symbol id="ico-view-hide-fill" viewBox="0 0 24 24">
        <path
          d="m13.49 14.61 2.3 2.3C14.64 17.56 13.36 18 12 18c-3.64 0-6.77-3.21-8.19-4.93-.39-.47-.58-.71-.58-1.07s.19-.59.58-1.07c.67-.82 1.73-1.97 3.05-2.95l2.53 2.53c-.25.44-.39.95-.39 1.49 0 1.66 1.34 3 3 3 .54 0 1.05-.14 1.49-.39Zm5.41-.12c.51-.51.95-1.01 1.29-1.42.39-.47.58-.71.58-1.07s-.19-.59-.58-1.07C18.77 9.21 15.64 6 12 6c-.48 0-.96.06-1.42.17L5.71 1.29 4.3 2.7l15.99 16.01 1.41-1.41-2.81-2.81Z" />
      </symbol>
      <symbol id="ico-view-hide" viewBox="0 0 24 24">
        <path
          d="M10.14 11.26a2.006 2.006 0 0 0 2.6 2.6l1.47 1.47c-.63.42-1.39.67-2.21.67-2.21 0-4-1.79-4-4 0-.82.25-1.58.67-2.21l1.47 1.47ZM12 17c-1.53 0-3.01-.68-4.34-1.63-1.32-.95-2.4-2.11-3.08-2.94-.1-.12-.17-.21-.24-.29-.05-.06-.08-.11-.1-.14.02-.03.05-.08.1-.14.06-.08.14-.17.24-.29.67-.81 1.71-1.94 2.99-2.88L6.14 7.26c-1.35 1.03-2.42 2.2-3.1 3.03-.02.03-.05.06-.07.09-.31.38-.74.88-.74 1.61s.42 1.24.74 1.61.05.06.07.09c.74.9 1.94 2.2 3.45 3.29 1.5 1.08 3.4 2.01 5.51 2.01 1.67 0 3.21-.58 4.52-1.36l-1.47-1.47c-.97.51-2 .83-3.05.83Zm7.61-1.81 2.1 2.1-1.41 1.41L4.29 2.71 5.7 1.3l4.05 4.05c.71-.21 1.46-.35 2.24-.35 2.11 0 4.01.93 5.51 2.01 1.51 1.09 2.71 2.39 3.45 3.29.02.03.05.06.07.09.31.38.74.88.74 1.61s-.42 1.24-.74 1.61a.55.55 0 0 0-.07.09c-.35.43-.81.95-1.35 1.49Zm-8.15-8.15 1 1c1.84.21 3.28 1.66 3.49 3.49l2.24 2.24c.49-.49.9-.95 1.23-1.35.1-.12.17-.21.24-.29.05-.06.08-.11.1-.14-.02-.03-.05-.08-.1-.14-.06-.08-.14-.17-.24-.29-.68-.83-1.76-1.99-3.08-2.94-1.33-.95-2.82-1.63-4.34-1.63-.18 0-.36.02-.54.04Z" />
      </symbol>
      <symbol id="ico-view" viewBox="0 0 24 24">
        <path
          d="M12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 9c-4 0-7.33-3.32-8.96-5.3-.44-.53-.81-.98-.81-1.7s.37-1.17.81-1.7C4.67 8.32 8 5 12 5s7.33 3.32 8.96 5.3-.77.64-.77.64l.78-.63c.43.53.81.98.81 1.7s-.37 1.17-.81 1.7c-1.63 1.98-4.97 5.3-8.96 5.3Zm-7.77-6.95s.21.2.36.38C5.72 13.81 8.71 17 12.01 17s6.28-3.2 7.42-4.57c.16-.19.33-.4.36-.48-.03.01-.2-.19-.36-.38l.77-.64-.77.64C18.3 10.2 15.31 7 12.01 7s-6.28 3.2-7.42 4.57c-.16.19-.33.4-.36.48Z" />
      </symbol>
      <symbol id="ico-workout" viewBox="0 0 24 24">
        <path
          d="m21.39 5.35-1.31-1.62c-.38-.47-.96-.75-1.57-.74l-2.08.02c-.61 0-1.18.29-1.56.76l-1.28 1.64c-.38.48-.51 1.1-.37 1.69l.48 2.02c.14.59.54 1.09 1.09 1.35l1.88.89c.55.26 1.19.26 1.74-.01l1.87-.92c.55-.27.94-.77 1.07-1.36l.45-2.03c.13-.59-.02-1.22-.4-1.69Zm-1.47 2.34c-.16.74-.65 1.36-1.33 1.69-.68.33-1.47.34-2.15.02-.68-.32-1.18-.94-1.35-1.67-.17-.73 0-1.5.46-2.1a2.5 2.5 0 0 1 1.93-.95 2.475 2.475 0 0 1 2.43 3.01Zm-1.82 6c-.02-.07-.03-.14-.07-.2-.01-.02-.03-.03-.04-.04-.04-.07-.1-.12-.16-.18-.03-.03-.05-.06-.09-.09-.02-.02-.03-.04-.05-.05-.08-.05-.16-.07-.24-.09-.02 0-.03-.01-.04-.02a.497.497 0 0 0-.21-.01c-.18 0-.37.02-.54.12-1.67.97-3.57 1.47-5.5 1.47-1.06 0-2.1-.17-3.1-.46l.07-.31c.09-.42-.01-.85-.28-1.18l-.91-1.13A1.38 1.38 0 0 0 5.85 11H4.41c-.42.01-.82.21-1.08.55l-.89 1.15c-.26.34-.36.77-.26 1.19l.33 1.42c.1.41.37.76.76.94l1.3.62c.38.18.82.18 1.2 0l1.29-.64c.11-.05.2-.12.29-.2 1.23.38 2.5.59 3.8.59 1.44 0 2.86-.27 4.21-.73-.66 1.7-1.14 3.35-1.21 4.12-.11 1.14 1.74 1.57 2 0s.86-3.44 1.87-5.42c.09-.18.11-.37.11-.56 0-.07 0-.14-.01-.21 0-.04-.02-.07-.04-.11Zm-12.05.72c-.03.14-.12.26-.25.32l-.43.21c-.13.06-.27.06-.4 0l-.43-.21a.453.453 0 0 1-.25-.32l-.11-.47c-.03-.14 0-.28.09-.4l.3-.38a.47.47 0 0 1 .36-.18h.48c.14 0 .27.06.36.17l.3.38c.09.11.12.26.09.39l-.1.47Z" />
      </symbol>
      <symbol id="ico-mask-corner" viewBox="0 0 32 32">
        <path d="M0 0h32C14.33 0 0 14.33 0 32V0z" />
      </symbol>

      {/* Social Icons */}
      <symbol id="ico-apple" viewBox="0 0 24 24">
        <path
          d="M12.22 6.88c.83 0 1.88-.58 2.5-1.35.56-.7.97-1.68.97-2.66 0-.13-.01-.27-.04-.37-.93.04-2.04.64-2.71 1.45-.53.62-1.01 1.58-1.01 2.57 0 .14.02.29.04.34.06.01.15.02.25.02ZM9.29 21.5c1.14 0 1.64-.79 3.06-.79s1.76.76 3.02.76 2.07-1.18 2.86-2.34c.88-1.33 1.24-2.63 1.27-2.69-.08-.02-2.46-1.03-2.46-3.84 0-2.44 1.88-3.54 1.98-3.62-1.24-1.84-3.13-1.88-3.64-1.88-1.39 0-2.53.87-3.25.87-.77 0-1.79-.82-3-.82-2.3 0-4.63 1.96-4.63 5.65 0 2.29.87 4.72 1.93 6.29.91 1.33 1.71 2.42 2.86 2.42Z"
          fill="#fff" />
      </symbol>

      <symbol id="ico-facebook" viewBox="0 0 24 24">
        <path
          d="M22 11c0-6.08-4.92-11-11-11S0 4.92 0 11c0 5.49 4.02 10.04 9.28 10.87v-7.69H6.49V11h2.79V8.58c0-2.76 1.64-4.28 4.15-4.28 1.2 0 2.46.21 2.46.21v2.71H14.5c-1.37 0-1.79.85-1.79 1.72V11h3.05l-.49 3.18h-2.56v7.69c5.26-.83 9.28-5.38 9.28-10.87Z"
          fill="#1877f2" />
        <path
          d="m15.28 14.18.49-3.18h-3.05V8.94c0-.87.43-1.72 1.79-1.72h1.39V4.51s-1.26-.21-2.46-.21c-2.51 0-4.15 1.52-4.15 4.28V11H6.5v3.18h2.79v7.69c.56.09 1.13.13 1.72.13s1.16-.05 1.72-.13v-7.69h2.56Z"
          fill="#fff" />
      </symbol>

      <symbol id="ico-google" viewBox="0 0 24 24">
        <path
          d="M20.82 12.2c0-.64-.06-1.25-.16-1.84h-8.48v3.48h4.84a4.15 4.15 0 0 1-1.8 2.72v2.26h2.91c1.7-1.57 2.68-3.87 2.68-6.62Z"
          fill="#4285f4" />
        <path
          d="M12.18 21c2.43 0 4.47-.81 5.96-2.18l-2.91-2.26c-.81.54-1.84.86-3.05.86-2.34 0-4.33-1.58-5.04-3.71H4.13v2.33A8.993 8.993 0 0 0 12.17 21Z"
          fill="#34a853" />
        <path
          d="M7.14 13.71c-.18-.54-.28-1.12-.28-1.71s.1-1.17.28-1.71V7.96H4.13a8.976 8.976 0 0 0 0 8.08l3.01-2.33Z"
          fill="#fbbc05" />
        <path
          d="M12.18 6.58c1.32 0 2.51.45 3.44 1.35l2.58-2.58c-1.56-1.45-3.6-2.34-6.02-2.34-3.52 0-6.56 2.02-8.04 4.96l3.01 2.33c.71-2.13 2.69-3.71 5.04-3.71Z"
          fill="#ea4335" />
      </symbol>
    </svg>
  </>;
};

export default memo(SVGSpriteUi);

import { API_CACHE_TAG_RANGE } from "@feature/range/rangeConstants";
import {
  CreateOneRangeArgs,
  FindUniqueAthleteArgs,
  Range,
} from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  createOneRangeQuery,
  createOneRangeTransformResponse,
} from "@common/type-graphql/range/gql/range-set.g";
import {
  rangeListQuery,
  rangeListTransformResponse,
} from "@common/type-graphql/range/gql/range-list.g";

export const rangeApi = api.injectEndpoints({
  endpoints: builder => ({
    rangeList: builder.query<Range[], FindUniqueAthleteArgs>({
      query: variables => ({
        document: rangeListQuery,
        variables: variables,
      }),
      transformResponse: rangeListTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_RANGE),
    }),
    rangeSet: builder.mutation<Range, CreateOneRangeArgs>({
      query: variables => ({
        document: createOneRangeQuery,
        variables: variables,
      }),
      transformResponse: createOneRangeTransformResponse,
      invalidatesTags: () => getTags(API_CACHE_TAG_RANGE),
    }),
  }),
  overrideExisting: false,
});

export const {
  useRangeListQuery,
  useRangeSetMutation,
} = rangeApi;

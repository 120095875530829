import {
  PayloadAction,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import { STORE_SUSPENSION_KEY } from "@feature/suspension/suspensionConstants";
import {
  runStart,
  runStop,
  setIsAutostartEnabled,
} from "@feature/run/slice/runSlice";
import {
  suspensionAllowSleep,
  suspensionKeepAwake,
} from "@feature/suspension/service/suspensionService";

type InitialStateModelInterface = {
  isKeptAwake: boolean;
}

const initialState: InitialStateModelInterface = { isKeptAwake: false };

export const suspensionSlice = createSlice({
  name: STORE_SUSPENSION_KEY,
  initialState: initialState,
  reducers: {
    keepAwake: state => {
      suspensionKeepAwake();
      state.isKeptAwake = false;
    },
    allowSleep: state => {
      suspensionAllowSleep();
      state.isKeptAwake = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
    builder
      .addMatcher(
        isAnyOf(
          setIsAutostartEnabled
        ), (state, action: PayloadAction<boolean>) => {
          const isAutoStartEnabled = action.payload;
          if (isAutoStartEnabled) {
            suspensionKeepAwake();
          } else {
            suspensionAllowSleep();
          }
          state.isKeptAwake = isAutoStartEnabled;
        }
      )
      .addMatcher(
        isAnyOf(
          runStart
        ), (state, action: PayloadAction<boolean>) => {
          const isAutoStartEnabled = action.payload;
          if (isAutoStartEnabled) {
            return;
          }
          suspensionKeepAwake();
        }
      )
      .addMatcher(
        isAnyOf(
          runStop
        ), (state, action: PayloadAction<boolean>) => {
          const isAutoStartEnabled = action.payload;
          if (isAutoStartEnabled) {
            return;
          }
          suspensionAllowSleep();
        }
      );
  },
});

export const {
  keepAwake,
  allowSleep,
} = suspensionSlice.actions;

export const selectSuspensionState = (state: RootState) => state[STORE_SUSPENSION_KEY];

import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { OfflineNoticeComponent } from "@feature/offline/component/offlineNoticeComponent";
import { RunAlgorithmComponent } from "@feature/run/component/RunAlgorithmComponent";
import { RunBodyComponent } from "@feature/run/component/RunBodyComponent";
import { RunBottomComponent } from "@feature/run/component/RunBottomComponent";
import { RunHeaderComponent } from "@feature/run/component/RunHeaderComponent";
import { RunTopComponent } from "@feature/run/component/RunTopComponent";
import {
  createContext,
  useRef,
} from "react";
import { runPageStyle } from "@feature/run/page/RunPageStyle";

export const RunPageScrollContext = createContext({ scrollToTop: null });

export const RunPage = () => {
  const { classes } = runPageStyle();

  const contentRef = useRef(null);

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(500);
  };

  return <>
    <IonPage>
      <RunHeaderComponent />
      <RunPageScrollContext.Provider value={{ scrollToTop: scrollToTop }}>
        <IonContent ref={contentRef}>
          <div className={classes.runPage}>
            <RunAlgorithmComponent />
            <RunTopComponent />
            <OfflineNoticeComponent />
            <RunBodyComponent />
            <RunBottomComponent />
          </div>
        </IonContent>
      </RunPageScrollContext.Provider>
    </IonPage>
  </>;
};

import {
  ButtonUi,
  PrimaryButtonProps,
} from "@ui/ButtonUi/ButtonUi";

export const PrimaryDestructiveButtonUi = ({
  label,
  onClick,
  isLoading = false,
  formType = "button",
  ...props
}: PrimaryButtonProps) => {
  return <>
    <ButtonUi
      appearance="rounded"
      backgroundColor="black"
      fontStyle="RR16"
      hasPressed
      icon=""
      iconColor="grey"
      iconPosition="left"
      label={label}
      labelColor="red"
      onClick={onClick}
      type="primary"
      width="100%"
      formType={formType}
      isActive={false}
      isLoading={isLoading}
    />
  </>;
};

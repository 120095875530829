import { ApiData } from "@feature/api/component/ApiData";
import { ListButtonUi } from "@ui/ListButtonUi/ListButtonUi";
import { i18n } from "@lingui/core";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";

type Props = {
  uuid?: string;
  onClick: () => void;
}

export const ExerciseListElementComponent = (props: Props) => {
  const exerciseListApi = useExerciseListQuery();

  return <>
    <ApiData endpoint={exerciseListApi}>
      <ListButtonUi
        title={i18n._(`${ exerciseListApi.data?.find(
          exercise => exercise.uuid === props.uuid).code }_TITLE`
        )}
        onClick={props.onClick}
      />
    </ApiData>
  </>;
};

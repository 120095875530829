import {
  ButtonUi,
  PrimaryButtonProps,
} from "@ui/ButtonUi/ButtonUi";

export const PrimaryAccentPositiveButtonUi = ({
  label,
  onClick,
  href,
  isLoading = false,
  formType = "submit",
  disabled = false,
  ...props
}: PrimaryButtonProps) => {
  return <>
    <ButtonUi
      fontStyle="OIB20"
      appearance="rounded"
      backgroundColor="neon"
      hasPressed
      icon=""
      iconColor="grey"
      iconPosition="left"
      label={label}
      labelColor="black"
      onClick={onClick}
      href={href}
      type="primary"
      width="100%"
      formType={formType}
      isActive={false}
      isLoading={isLoading}
      disabled={disabled}
    />
  </>;
};

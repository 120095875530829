import gsap from "gsap";
import { GSAP_CUSTOM_EASE_OUTQUINT } from "@feature/gsap/gsapConstants";
import {
  useEffect,
  useRef,
} from "react";

export const useLogic = (props: any) => {
  const $modalContent = useRef(null);

  useEffect(() => {
    $modalContent.current && gsap.to($modalContent.current, {
      scale: props.isOpen ? 1 : 0.9,
      opacity: props.isOpen ? 1 : 0,
      duration: 0.4,
      ease: GSAP_CUSTOM_EASE_OUTQUINT,
    });
  }, [ props.isOpen ]);

  return { $modalContent: $modalContent };
};

import { makeStyles } from "@theme/makeStyles";

type Props = {
  hasAccountProfileChips: boolean;
  hasExerciseProtocolChips: boolean;
}

export const useStyles = makeStyles<Props>()(
  (theme, props, classes) => ({
    // Base styles
    historyWorkoutRoot: {
      marginBottom : 16,
      borderBottom: `1px solid ${ theme.colors.blackPressed }`,
      //paddingLeft: props.hasAccountProfileChips && props.hasExerciseProtocolChips ? 16 : props.hasAccountProfileChips || props.hasExerciseProtocolChips ? 8 : 0,
      //marginLeft: props.hasAccountProfileChips && props.hasExerciseProtocolChips ? 32 : props.hasAccountProfileChips || props.hasExerciseProtocolChips ? 16 : 0,
      //borderLeft: props.hasAccountProfileChips || props.hasExerciseProtocolChips ? `1px solid ${ theme.colors.grey }` : 0,
    },
  })
);

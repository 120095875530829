import { AvatarSrcUi } from "@ui/AvatarSrcUi/AvatarSrcUi";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import {
  Color,
  Icons,
} from "@types-custom";
import { useStyles } from "./useStyles";

export interface Props {
  title: string;
  description?: string;
  avatarSrc?: string;
  onClick?: () => void;
  icon?: Icons;
  backgroundColor?: Color;
}

export const ListButtonUi = ({
  avatarSrc, title, description, onClick, icon, backgroundColor,
}: Props) => {
  const { classes } = useStyles({ onClick: onClick });

  return <>
    <div
      className={classes.listButtonWrapper}
      onClick={onClick}>
      {
        avatarSrc &&
        <AvatarSrcUi src={avatarSrc} />
      }
      <div className={classes.textContent}>
        <h4>{title}</h4>
        {
          description &&
          <p>{description}</p>
        }
      </div>
      {
        onClick &&
        <ButtonUi
          label=""
          type="primary"
          labelColor="neon"
          backgroundColor={backgroundColor ? backgroundColor : "transparent"}
          icon={icon ? icon : "expand-right"}
          iconColor="grey"
          iconPosition="top"
          fontStyle="RB12"
          appearance="rounded"
          hasToggle={false}
          onClick={onClick}
        />
      }
    </div>
  </>;
};

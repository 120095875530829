import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    tableRoot: {
      width: "100%",
      maxWidth: "fit-content",
      overflow: "hidden",
      overflowX: "auto",

      "& table": {
        borderCollapse: "separate",
        borderSpacing: 0,
        fontFamily: "arial, sans-serif",
        tableLayout: "fixed",
        width: "100%",
        overflowX: "auto",
        display: "block",
        whiteSpace: "nowrap",
        backgroundColor: theme.colors.darkTint,
      },
      "& thead": {
        width: "100%",
        top: 0,
        left: 0,
        position: "sticky",
        zIndex: 3,
        "& tr": {
          height: 40,
          background: theme.colors.black,
          margin: "0",
        },
        "& th": { ...theme.typography.RM14 },
      },
      "& th": {
        borderBottom: `1px solid ${ theme.colors.grey }`,
        borderRight: `1px solid ${ theme.colors.separatorDarkBackground }`,
        padding: "0 16px",
        textAlign: "left",
      },
      "& tbody": {
        "& tr": {
          ...theme.typography.RR14,
          color: theme.colors.grey,
          "& td": {
            "&:first-of-type": { borderLeft: `1px solid ${ theme.colors.separatorDarkBackground }` },
            "&:last-of-type": { borderRight: "none" },
          },
        },
      },
    },
    tdCell: {
      position: "relative",
      zIndex: 1,
      padding: "16px 16px",
      borderColor: theme.colors.grey,
      borderBottom: `1px solid ${ theme.colors.separatorDarkBackground }`,
      borderRight: `1px solid ${ theme.colors.separatorDarkBackground }`,
    },
  })
);

import { UseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { selectOnlineState } from "@feature/offline/slice/onlineSlice";
import { useAppSelector } from "@core/redux/store";

export const useApiDataLogic = (endpoint: UseQueryHookResult<any> | UseQueryHookResult<any>[]) => {
  const endpoints = Array.isArray(endpoint) ? endpoint : [ endpoint ];
  const onlineState = useAppSelector(selectOnlineState);

  const isError = endpoints.some(api => api.isError);
  const isLoading = endpoints.some(api => api.isLoading || api.isFetching);
  const isUnitialized = endpoints.some(api => api.isUninitialized);
  const isSuccess = endpoints.some(api => api.isSuccess);

  return {
    endpoints: endpoints,
    isError: isError,
    isLoading: isLoading,
    isUnitialized: isUnitialized,
    isApiDataReady: !isError && !isLoading && isSuccess,
    isOnline: onlineState.isOnline,
  };
};

import { Power } from "@common/type-graphql/generated";
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";

export const getPowerReferenceByPowerList = (
  powerList: Power[],
  loadWeight: number
): PowerReferenceValue | null => {
  if (!powerList) {
    return null;
  }

  const powerByApi = powerList.find(r =>
    r.loadWeight === loadWeight &&
    r.isDefault === true
  );

  if (powerByApi && powerByApi.powerReference) {
    return powerByApi.powerReference as PowerReferenceValue;
  }

  return null;
};

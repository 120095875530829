import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<{ onClick }>()(
  (theme, props, classes) => ({
    listButtonWrapper: {
      display: "flex",
      alignItems: "center",
      "& > *": { flexShrink: 0 },
      "& p": {
        ...theme.typography.RM14,
        color: theme.colors.pureWhite,
        margin: 0,
        flexShrink: 1,
        flexGrow: 1,
      },
    },
    textContent: {
      flexShrink: 1,
      flexGrow: 1,
      "& h4": {
        ...theme.typography.RR16,
        color: theme.colors.grey,
        margin: 0,
      },
      "& p": {
        ...theme.typography.RR14,
        color: theme.colors.grey,
        margin: 0,
        marginTop: 8,
      },
    },
  })
);

import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { Icons } from "@types-custom";
import { Route } from "@core/route";
import { i18n } from "@lingui/core";
import { useStyles } from "./useStyles";

interface Props {
  items: Route[];
  goTo: (route: Route) => void;
  activeRoute: string;
}

export const TabBarUi = (props: Props) => {
  const { classes } = useStyles();

  return <>
    <div className={classes.tabBarRoot}>
      {props.items?.map((item: Route) => (
        <ButtonUi
          key={item.code}
          label={i18n._(item.title)}
          width=""
          type="primary"
          labelColor="grey"
          backgroundColor="black"
          icon={item.icon as Icons}
          iconColor="grey"
          iconPosition="top"
          fontStyle="RM10"
          appearance="rounded"
          hasToggle={false}
          hasPressed={false}
          onClick={() => props.goTo(item)}
          isActive={props.activeRoute === item.code}
        />
      ))}
    </div>
  </>;
};

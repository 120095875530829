import { ApiData } from "@feature/api/component/ApiData";
import { ButtonClick } from "@types-custom";
import { ModalListUi } from "@ui/ModalListUi/ModalListUi";
import { Protocol } from "@common/type-graphql/generated";
import { ProtocolListComponent } from "@feature/protocol/component/protocolListComponent";
import { getUniqueId } from "@util/getUniqueId";
import { t } from "@lingui/macro";
import { useProtocolListQuery } from "@feature/protocol/api/protocolApi";

type Props = {
  isOpen: boolean;
  onClose: ButtonClick;
  onClick: ((protocolUuid: string) => void);
}

export const ProtocolChooseModal = (props: Props) => {
  const protocolListApi = useProtocolListQuery();

  return <>
    <ApiData endpoint={protocolListApi}>
      <ProtocolChooseModalChild
        protocolList={protocolListApi.data}
        {...props}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  protocolList: Protocol[];
}

const ProtocolChooseModalChild = (props: PropsChild) => {
  return <>
    <ModalListUi
      title={t`Protocols`}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ProtocolListComponent
        protocols={props.protocolList}
        onClick={props.onClick}
        key={getUniqueId()}
      />
    </ModalListUi>
  </>;
};

import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { SheetForm } from "@feature/sheet/component/form/sheetForm";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";
import { useParams } from "react-router";

type Params = {
  uuid?: string;
}
export const SheetEditPage = () => {
  const params = useParams<Params>();

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.sheetEdit.title)}
        backToTitle={i18n._(routes.sheetList.title)}
        backToPath={routes.sheetList.routePath}
        backToRoute={routes.sheetList} />
      <IonContent>
        <SheetForm uuid={params.uuid} />
      </IonContent>
    </IonPage>
  </>;
};

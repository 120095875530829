import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<{ background; navigatorBarHide }>()(
  (theme, props, classes) => ({
    // Base styles
    navigationBarRoot: {
      position: "relative",
      height: "fit-content",
      zIndex: 2,
      border: 0,
      display: props.navigatorBarHide ? "none" : "flex",
      alignItems: "center",
      transition: "background-color 0.3s",
      backgroundColor: props.background !== "realBlack" ? theme.colors.black : theme.colors.realBlack,
      "& button": {
        margin: 0,
        color: theme.colors.pureWhite,
        backgroundColor: "transparent",
      },
      "& svg": { fill: theme.colors.neon },
    },
    coloredBackground: {
      backgroundColor: theme.colors[props.background],
      "& button": { color: theme.colors.black },
      "& svg": { fill: theme.colors.black },
    },
    navigationBarLeft: {
      width: "50%",
      height: 40,
      "& button": {
        height: 50,
        marginLeft: 10,
        "& > div": { height: 50 },
        "& span": {
          color: (
            props.background !== "black" &&
            props.background !== "realBlack"
          ) ?
            theme.colors.black :
            theme.colors.pureWhite,
        },
      },
    },
    navigationBarRight: {
      width: "50%",
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: 8,
      "> ion-fab": { // debug Fab
        top: "calc(var(--ion-safe-area-top) + 130px)",
        right: 5,
      },
    },
    notificationButton: {
      margin: 0,
      "& svg": {
        fill: theme.colors.neon,
        transition: "fill 0.3s",
      },
    },
    notificationButtonInAction: { "& svg": { fill: theme.colors.darkTint } },
  })
);

import {
  IonLoading,
  LoadingOptions,
} from "@ionic/react";
import { getLoadingState } from "../slice/loadingSlice";
import { useAppSelector } from "@core/redux/store";

type Props = {
  loadingOptions?: LoadingOptions;
}

export const LoadingOutlet = (props:Props) => {
  const loadingState = useAppSelector(getLoadingState);

  return <>
    <IonLoading
      isOpen={loadingState.isLoading}
      message={"Please wait....."}
      duration={5000}
      {...props.loadingOptions}
    />
  </>;
};

import { t } from "@lingui/macro";

type Props = {
  header?: string;
  subHeader?: string;
  yesText?: string;
  noText?: string;
  present: ({
    header,
    subHeader,
    buttons,
    onDidDismiss,
  }) => void;
  action: () => void;
}

export const confirm = (props: Props) => {
  props.present({
    header: props.header,
    subHeader: props.subHeader ?? null,
    buttons: [
      {
        text: props.yesText ?? t`Yes`,
        data: { action: "yes" },
      },
      {
        text: props.noText ?? t`No`,
        role: "destructive",
        data: { action: "no" },
      },
    ],
    onDidDismiss: async({ detail }) => {
      if (detail.data && detail.data.action === "yes") {
        props.action();
      }
    },
  });
};

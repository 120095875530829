export const STORE_HISTORY_KEY = "history";

export const API_CACHE_TAG_HISTORY_TRAINING_SESSION = "history_training_session";
export const API_CACHE_TAG_HISTORY_TRAINING_SESSION_LINKED = "history_training_session_linked";
export const API_CACHE_TAG_HISTORY_WORKOUT = "history_workout";
export const API_CACHE_TAG_HISTORY_SERIE = "history_serie";
export const API_CACHE_TAG_HISTORY_SERIE_LIST_LINKED = "history_series_list_linked";
export const API_CACHE_TAG_HISTORY_SERIE_COMMENT = "history_serie_comment";

export const UUID_NAME_HISTORY_TRAINING_SESSION = "history_training_session";
export const UUID_NAME_HISTORY_WORKOUT = "history_workout";
export const UUID_NAME_HISTORY_WORKOUT_CONFIGURATION = "history_workout_configuration";
export const UUID_NAME_HISTORY_SERIE = "history_serie";
export const UUID_NAME_HISTORY_SERIE_CONFIGURATION = "history_serie_configuration";

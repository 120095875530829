import React, {
  useEffect,
  useState,
} from "react";
import { Exercise } from "@common/type-graphql/generated";
import { ListActionBarUi } from "@ui/ListActionBarUi/ListActionBarUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { getExeciseNameByCode } from "@feature/exercise/service/execiseService";
import { t } from "@lingui/macro";

type Props = {
  data?: Exercise[];
  onClick?: (uuid: string) => void;
}

type Element = {
  uuid: string;
  label: string;
}

export const ExerciseListComponent = (props: Props) => {
  const [
    filteredData,
    setFilteredData,
  ] = useState<Element[]>([]);

  const searchChange = (value: string) => {
    const elements = props.data.filter(element => getExeciseNameByCode(element.code).toLowerCase().includes(value.toLowerCase()));
    update(elements);
  };

  const update = (elements: Exercise[]) => {
    const exercisesSortedByTitle: Element[] = elements.map(element => {
      return {
        uuid: element.uuid,
        label: getExeciseNameByCode(element.code),
      };
    });
    setFilteredData(exercisesSortedByTitle);
  };

  useEffect(() => {
    update(props.data);
  }, [ props.data ]);

  if (!props.data || !props.data.length) {
    return <></>;
  }

  return <>
    <ListActionBarUi
      addButtonLabel={t`Exercise`}
      onSearchChange={searchChange}
    />
    <SectionUi rounded>
      {
        filteredData.map(item =>
          <ListItemUi
            key={item.uuid}
            title={item.label}
            onClick={() => {
              props.onClick(item.uuid);
            }}
          />
        )
      }
    </SectionUi>
  </>;
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    switchRoot: {
      "--background": theme.colors.black,
      "--background-checked": theme.colors.neon,
      //"--border-radius": ,
      //"--handle-background": "2",
      //"--handle-background-checked": "2",
      //"--handle-border-radius": "2",
      //"--handle-box-shadow": "2",
      //"--handle-height": "2",
      //"--handle-max-height": "2",
      //"--handle-spacing": "2",
      //"--handle-transition": "2",
      //"--handle-width": "2",
    },
  })
);

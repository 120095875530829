import React, { useEffect } from "react";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import {
  RUN_STATUS_READY,
  RUN_STATUS_RESTING,
  RUN_STATUS_RUNNING,
  RunStatus,
  selectRunState,
  setIsPowerReferenceAvailableAtStart,
} from "@feature/run/slice/runSlice";
import { t } from "@lingui/macro";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { usePowerReset } from "@feature/power/hook/usePowerReset";

type Props = {
  athleteUuid: string;
  exerciseUuid: string;
  powerReference: PowerReferenceValue;
  loadWeight: number;
  isEnabled?: boolean;
}

const enabledPhases: RunStatus[] = [
  RUN_STATUS_READY,
  RUN_STATUS_RESTING,
];

export const ReferencePowerComponent = (props: Props) => {
  const dispatch = useAppDispatch();

  const runState = useAppSelector(selectRunState);
  const { resetPowerConfirm } = usePowerReset();

  const isEnabled = (): boolean => {
    if (
      !props.athleteUuid ||
      !props.exerciseUuid ||
      props.isEnabled === false
    ) {
      return false;
    }
    return enabledPhases.includes(runState.runStatus);
  };

  useEffect(() => {
    if (runState.runStatus !== RUN_STATUS_RUNNING) {
      dispatch(setIsPowerReferenceAvailableAtStart(Boolean(props.powerReference)));
    }
  }, [
    dispatch,
    props.powerReference,
    runState.runStatus,
  ]);

  return <>
    {
      props.powerReference &&
	    <ButtonUi
		    disabled={!isEnabled()}
		    type={"primary"}
		    appearance={"rounded"}
		    backgroundColor={"black"}
		    fontStyle={"OIB18"}
		    labelColor="neon"
		    label={`${ props.powerReference } W`}
		    onClick={resetPowerConfirm({
	        athleteUuid: props.athleteUuid,
	        exerciseUuid: props.exerciseUuid,
	        loadWeight: props.loadWeight,
	      })}
	    />
    }
    {
      !props.powerReference &&
		  <ButtonUi
		    disabled={true}
			  label={t`No Watt`}
			  type="primary"
			  labelColor="neon"
			  backgroundColor="black"
			  iconPosition="left"
			  fontStyle="OIB20"
			  appearance="rounded"
			  formType={"button"}
		  />
    }
  </>;
};

import useEmblaCarousel from "embla-carousel-react";
import useWindowSize from "@hook/useWindowSize";
import {
  useCallback,
  useEffect,
  useState,
} from "react";

export const useLogic = (
  props: any
) => {
  // Get the window inner width
  const { width } = useWindowSize();

  // Configure Embla Carousel options
  const [
    ref,
    embla,
  ] = useEmblaCarousel({
    loop: false,
    draggable: false,
    dragFree: false,
    speed: 20,
    inViewThreshold: 0.5,
    skipSnaps: false,
  }); // move to useLogic

  // Current selected index State
  const [
    selectedIndex,
    setSelectedIndex,
  ] = useState(0);

  const [
    scrollSnaps,
    setScrollSnaps,
  ] = useState([]);

  // Carousel scroll progress from 0 to 1 / items.length
  const [
    scrollProgress,
    setScrollProgress,
  ] = useState<number>(0);

  // Allows scroll to work also using buttons
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [ embla ]);

  // Sets the cuirrent selected index
  const onSelect = useCallback(() => {
    if (!embla) {
      return;
    }
    setSelectedIndex(embla.selectedScrollSnap());
  }, [
    embla,
    setSelectedIndex,
  ]);

  // Scrolls to the next slide
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [ embla ]);

  // Reinitialize carousel on Mount
  // This fixes desktop bug: is not interactive until resize
  useEffect(() => {
    if (!embla) {
      return;
    }
    embla.reInit();
  }, [ embla ]);

  // Set scroll snaps
  useEffect(() => {
    if (!embla) {
      return;
    }
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [
    embla,
    setScrollSnaps,
    onSelect,
  ]);

  // Set progress value on scroll event
  const onScroll = useCallback(() => {
    if (!embla) {
      return;
    }
    const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
    setScrollProgress(progress); // Progress is a value from 0 to 1 / items.length
  }, [ embla ]);

  // On scroll run the on scroll event
  useEffect(() => {
    if (!embla) {
      return;
    }
    embla.on("scroll", onScroll);
  }, [
    embla,
    onScroll,
  ]);

  return {
    width: width,
    ref: ref,
    embla: embla,
    selectedIndex: selectedIndex,
    scrollProgress: scrollProgress,
    setScrollProgress: setScrollProgress,
    scrollTo: scrollTo,
    scrollNext: scrollNext,
  };
};

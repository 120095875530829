import { OfflinePayload } from "@feature/offline/types-custom";
import { api } from "@core/api";

export const historyOnline: OfflinePayload = (store, action) => {
  const apiName = action.meta.arg.endpointName;
  if (apiName === "historySerieCreate") {
    historyOnlineSerieCreate(store, action);
  }
};

const historyOnlineSerieCreate: OfflinePayload = (store, action) => {
  const args = action.meta.arg.originalArgs;
  store.dispatch((api as any).endpoints.historySerieCreate.initiate(args));
  store.dispatch(action);
};

export const historyOffline: OfflinePayload = (store, action) => {
  const apiName = action.meta.arg.endpointName;
  switch (apiName) {
    case "historySerieCreate":
      historyOfflineSerieCreate(store, action);
      break;
    case "historySerieGet":
      historyOfflineSerieGet(store, action);
      break;
    case "historyWorkoutGet":
      historyOfflineWorkoutGet(store, action);
      break;
  }
};

const historyOfflineSerieCreate: OfflinePayload = (store, action) => {
  const stubAction = {
    type: "api/executeMutation/fulfilled",
    payload: { uuid: action.meta.arg.originalArgs.data.uuid },
    meta: {
      fulfilledTimeStamp: (new Date()).getTime(),
      baseQueryMeta: {},
      // eslint-disable-next-line camelcase
      RTK_autoBatch: true,
      arg: {
        type: "mutation",
        endpointName: "historySerieCreate",
        originalArgs: action.meta.arg.originalArgs,
        track: true,
      },
      requestId: action.meta.requestId,
      requestStatus: "fulfilled",
    },
  };

  // Create stub fulfilled
  store.dispatch(stubAction);
};

const getOfflineSerie = (store, searchingUuid): null|any => {
  const mutationApiName = "historySerieCreate";
  const allOfflinePayloads = store.getState().offline.payloads;
  if (allOfflinePayloads.length === 0) {
    return null;
  }

  // Get stub mutation
  const offlinePayload = allOfflinePayloads.find(
    (p: any) =>
      p.meta.arg.endpointName === mutationApiName &&
      p.meta.arg.originalArgs.data.uuid === searchingUuid
  );
  if (!offlinePayload) {
    return null;
  }

  return offlinePayload;
};

const getOfflineWorkout = (store, searchingUuid): null|any => {
  const mutationApiName = "historySerieCreate";
  const allOfflinePayloads = store.getState().offline.payloads;
  if (allOfflinePayloads.length === 0) {
    return null;
  }

  // Get stub mutation
  const offlinePayload = allOfflinePayloads.find(
    (p: any) =>
      p.meta.arg.endpointName === mutationApiName &&
      p.meta.arg.originalArgs.data.HistoryWorkout.connectOrCreate.where.uuid === searchingUuid
  );
  if (!offlinePayload) {
    return null;
  }

  return offlinePayload;
};

const historyOfflineSerieGet: OfflinePayload = (store, action) => {
  const searchingUuid = action.meta.arg.originalArgs.where.uuid;
  const offlinePayload = getOfflineSerie(store, searchingUuid);

  if (!offlinePayload) {
    return;
  }

  const historyWorkoutUuid = offlinePayload.meta.arg.originalArgs.data.HistoryWorkout.connectOrCreate.where.uuid;
  const serieDateTime = offlinePayload.meta.arg.originalArgs.data.dateTime;
  const serieData = offlinePayload.meta.arg.originalArgs.data.data;

  const stubAction = {
    type: "api/executeQuery/fulfilled",
    payload: {
      uuid: searchingUuid,
      dateTime: serieDateTime,
      historyWorkoutUuid: historyWorkoutUuid,
      data: serieData,
    },
    meta: {
      fulfilledTimeStamp: (new Date()).getTime(),
      baseQueryMeta: {},
      // eslint-disable-next-line camelcase
      RTK_autoBatch: true,
      arg: action.meta.arg,
      requestId: action.meta.requestId,
      requestStatus: "fulfilled",
    },
  };

  // Create stub fulfilled
  store.dispatch(stubAction);
};

const historyOfflineWorkoutGet: OfflinePayload = (store, action) => {
  const searchingUuid = action.meta.arg.originalArgs.where.uuid;
  const offlinePayload = getOfflineWorkout(store, searchingUuid);

  if (!offlinePayload) {
    return;
  }

  const workoutUuid = offlinePayload.meta.arg.originalArgs.data.HistoryWorkout.connectOrCreate.create.uuid;
  const profileUuid = offlinePayload.meta.arg.originalArgs.data.HistoryWorkout.connectOrCreate.create.Profile.connect.uuid;
  const protocolUuid = offlinePayload.meta.arg.originalArgs.data.HistoryWorkout.connectOrCreate.create.Protocol.connect.uuid;
  const exerciseUuid = offlinePayload.meta.arg.originalArgs.data.HistoryWorkout.connectOrCreate.create.Exercise.connect.uuid;
  const historyWorkoutConfigurations = offlinePayload.meta.arg.originalArgs.data.HistoryWorkout.connectOrCreate.create.HistoryWorkoutConfiguration.createMany.data;

  const stubAction = {
    type: "api/executeQuery/fulfilled",
    payload: {
      uuid: workoutUuid,
      profileUuid: profileUuid,
      protocolUuid: protocolUuid,
      exerciseUuid: exerciseUuid,
      HistoryWorkoutConfiguration: historyWorkoutConfigurations,
    },
    meta: {
      fulfilledTimeStamp: (new Date()).getTime(),
      baseQueryMeta: {},
      // eslint-disable-next-line camelcase
      RTK_autoBatch: true,
      arg: action.meta.arg,
      requestId: action.meta.requestId,
      requestStatus: "fulfilled",
    },
  };

  // Create stub fulfilled
  store.dispatch(stubAction);
};

import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
} from "react";
import { IconUi } from "@ui/IconUi/IconUi";
import { useLogic } from "./useLogic";
import { useStyles } from "./useStyles";

export interface IOptions {
  value: string;
  label: string;
}

export interface Props {
  options?: IOptions[];
  selectedOptionId?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  formId?: string;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  isValid?: boolean;
  setValue?: any;
}

export const SelectUi = forwardRef(({
  options,
  selectedOptionId,
  placeholder,
  className,
  disabled,
  formId,
  name,
  readOnly,
  required,
  onChange,
  onBlur,
  isValid,
  setValue,
}: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    classes, cx,
  } = useStyles();

  const {
    currentOption,
    setCurrentOption,
    dropdownVisible,
    setDropdownVisible,
  } = useLogic(selectedOptionId);

  const getOption = (value): IOptions => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        return options[i];
      }
    }
    return null;
  };

  return <>
    <div
      className={cx({
        [classes.selectRoot]: true,
        [classes.selectRootDropdownVisible]: dropdownVisible,
        [classes.selectRootOptionSelected]: currentOption !== null,
        [className]: className !== null,
      })
      }>
      <input
        ref={ref}
        type="hidden"
        disabled={disabled}
        form={formId}
        name={name}
        readOnly={readOnly}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
      />
      <div
        className={classes.selectContent}
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        <span>{currentOption ? getOption(currentOption)?.label : placeholder}</span>
        <IconUi
          isToggled={dropdownVisible}
          branded={false}
          position="top"
          icon="expand-down"
          className={classes.icon}
        />
      </div>
      <div className={classes.dropdown}>
        {options?.map((option: IOptions, index) => (
          <button
            key={option.value}
            type="button"
            onClick={() => {
              option.value === currentOption ? setCurrentOption(null) : setCurrentOption(option.value);
              setValue(name, option.value);
              setDropdownVisible(!dropdownVisible);
            }}
            className={cx({
              [classes.optionRoot]: true,
              [classes.optionActive]: option.value === currentOption,
            })}
          >
            <span>
              {option.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  </>;
});

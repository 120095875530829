/* Core */
import {
  IonApp,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Redux */
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import {
  historyController,
  persistor,
  store,
} from "../redux/store";

/* GSAP */
import gsap, { CustomEase } from "gsap/all";
import {
  GSAP_CUSTOM_EASE_INOUTQUINT,
  GSAP_CUSTOM_EASE_OUTCIRC,
  GSAP_CUSTOM_EASE_OUTQUINT,
} from "@feature/gsap/gsapConstants";

/* Style */
import SVGSprite from "@ui/SVGSpriteUi/SVGSpriteUi";
import { GlobalStyles } from "tss-react";
import { globals } from "@theme/globals";
import "@ionic/react/css/core.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@theme/typography.css";
import "@theme/variables.css";
// import "@ionic/react/css/display.css";

/* Lingui */
import { I18nProvider } from "@lingui/react";
import { messages as enI18n } from "@locale/en/messages";
import {
  en as enPlural,
  it as itPlural,
} from "make-plural";
import { i18n } from "@lingui/core";
import { messages as itI18n } from "@locale/it/messages";

/* Chartjs */
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  ScatterController,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

/* Components */
import AppUrlListener from "@core/app/AppUrlListener";
import { AccountCheckOutlet } from "@feature/account/component/accountCheckOutlet";
import { DeviceAutoConnectOutlet } from "@feature/device/component/deviceAutoConnectOutlet";
import { LoaderUi } from "@ui/LoaderUi/LoaderUi";
import { LoadingOutlet } from "@feature/loading/component/loadingOutlet";
import { OfflineProvider } from "@feature/offline/component/offlineProvider";
import { RouterOutlet } from "@feature/router/component/routerOutlet";
import { TabsOutlet } from "@feature/tab/component/tabsOutlet";
import { ToastOutlet } from "@feature/toast/component/toastOutlet";
import { setSystemLanguage } from "@feature/setting/service/settingService";

/* Configure Lingui */
i18n.load("en", enI18n);
i18n.load("it", itI18n);
i18n.loadLocaleData({
  en: { plurals: enPlural },
  it: { plurals: itPlural },
});
setSystemLanguage();

/* Configure Ionic */
setupIonicReact({ mode: "ios" });

/* Configure GSAP */
gsap.registerPlugin(CustomEase);
CustomEase.create(GSAP_CUSTOM_EASE_OUTCIRC, "0.6, 0.55, 0.45, 1"); // Interactive 0.4s
CustomEase.create(GSAP_CUSTOM_EASE_OUTQUINT, "0.25, 1, 0.35, 1"); // Texts 0.8s
CustomEase.create(GSAP_CUSTOM_EASE_INOUTQUINT, "0.7, 0, 0.2, 1"); // Layout 0.8s

/* Configure Chartjs */
Chart.register(
  annotationPlugin,
  TimeScale,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  ScatterController,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  zoomPlugin
);

export const App = () => {
  return <>
    <GlobalStyles styles={{ ...globals }} />
    <SVGSprite />
    <Provider store={store}>
      <PersistGate
        loading={<LoaderUi appearance={"fullscreen"} background={"neon"} />}
        persistor={persistor}
      >
        <I18nProvider i18n={i18n}>
          {/*<GoogleOutlet>*/}
          <IonApp>
            <IonReactRouter history={historyController}>
              <RouterOutlet />
              <AccountCheckOutlet />
              <AppUrlListener />
              <TabsOutlet />
              <OfflineProvider />
              <DeviceAutoConnectOutlet />
              <LoadingOutlet />
              <ToastOutlet />
            </IonReactRouter>
          </IonApp>
          {/*</GoogleOutlet>*/}
        </I18nProvider>
      </PersistGate>
    </Provider>
  </>;
};

import React from "react";
import { ButtonInputNumberUi } from "@ui/ButtonInputNumberUi/ButtonInputNumberUi";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { IonButtons } from "@ionic/react";
import { makeStyles } from "@theme/makeStyles";
import { t } from "@lingui/macro";

type Props = {
  pageCount: number;
  currentPage: string;
  onChange: (page: number) => void;
}

export const PaginationComponent = (props: Props) => {
  const { classes } = style();

  const currentPage = parseInt(props.currentPage, 10);
  const hasPrevious = props.pageCount > 1 && currentPage > 1;
  const hasNext = props.pageCount > 1 && currentPage < props.pageCount;
  const hasMoreThanOnePage = props.pageCount > 1;

  return <>
    <IonButtons className={classes.buttonContainer}>
      <ButtonUi
        type={"primary"}
        labelColor="black"
        backgroundColor="black"
        icon="expand-left"
        iconColor="orange"
        iconPosition="left"
        fontStyle="OIB24"
        appearance="rounded"
        hasToggle={false}
        hasPressed={true}
        onClick={() => {
          props.onChange(currentPage - 1);
        }}
        formType={"button"}
        isLoading={false}
        disabled={!hasPrevious}
      />
      {
        !hasMoreThanOnePage &&
        <ButtonUi
          type={"link"}
          label={currentPage.toString()}
          width={"60px"}
          labelColor="orange"
          backgroundColor="black"
          iconColor="neon"
          fontStyle="RM16"
          iconPosition="left"
          appearance="rounded"
          hasToggle={false}
          hasPressed={true}
          formType={"button"}
        />
      }
      {
        hasMoreThanOnePage &&
	      <ButtonInputNumberUi
		      type={"primary"}
		      appearance={"rounded"}
	        width={"60px"}
		      min={1}
		      max={props.pageCount}
		      step={1}
		      backgroundColor={"black"}
		      labelColor={"neon"}
		      initialValue={currentPage}
		      fontStyle={"OIB18"}
		      onChange={props.onChange}
	        title={t`Specify the page`}
	      />
      }
      <ButtonUi
        type={"primary"}
        labelColor="black"
        backgroundColor="black"
        icon="expand-right"
        iconColor="orange"
        iconPosition="left"
        fontStyle="OIB24"
        appearance="rounded"
        hasToggle={false}
        hasPressed={true}
        onClick={() => {
          props.onChange(currentPage + 1);
        }}
        formType={"button"}
        isLoading={false}
        disabled={!hasNext}
      />
    </IonButtons>
  </>;
};

const style = makeStyles()(
  (theme, props, classes) => ({
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: 16,
      marginBottom: 16,
      "& button": {
        margin: "0 8px",
      },
    },
  })
);

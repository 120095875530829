import React from "react";
import { IonAlert } from "@ionic/react";
import { t } from "@lingui/macro";

type OtpProps = {
  onVerify: (otp: string) => void;
  onCancel: () => void;
}

export const OtpModalComponent = (props: OtpProps) => {
  return <>
    <IonAlert
      isOpen={true}
      onDidDismiss={props.onCancel}
      header={t`Please enter the OTP code`}
      inputs={[
        {
          name: "otp",
          type: "tel",
          placeholder: "OTP Code",
          min: 1,
          max: 999999,
        },
      ]}
      buttons={[
        {
          text: t`Cancel`,
          role: "destructive",
          handler: () => {
            props.onCancel();
          },
        },
        {
          text: t`Verify`,
          handler: data => {
            return props.onVerify(data.otp);
          },
          role: "confirm",
        },
      ]}
    ></IonAlert>
  </>;
};

import { HeaderBackground } from "@types-custom";
import { useStyles } from "./useStyles";

interface Props {
  background: HeaderBackground;
}

export const BadgeUi = ({ background }: Props) => {
  const {
    classes, cx,
  } = useStyles({ background: background });

  return <>
    <div className={classes.badgeRoot}>
      <div className={classes.numberRoot} />
    </div>
  </>;
};

import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles<void, "dropdown" | "icon">()(
  (theme, props, classes) => ({
    // Base styles
    selectRoot: {
      position: "relative",
      zIndex: 2,
      backgroundColor: theme.colors.black,
      width: "100%",
      borderRadius: 16,
      color: theme.colors.grey,
    },
    selectContent: {
      ...theme.typography.RR14,
      position: "relative",
      zIndex: 2,
      backgroundColor: theme.colors.black,
      padding: "16px 24px",
      borderRadius: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    dropdown: {
      ...theme.typography.RR14,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      zIndex: 888,
      bottom: -8,
      left: 0,
      transform: "translateY(100%)",
      backgroundColor: theme.colors.darkTint,
      borderRadius: 16,
      opacity: 0,
      transition: "opacity 0.3s",
      pointerEvents: "none",
      maxHeight: 200,
      overflowY: "scroll",
    },
    optionRoot: {
      color: theme.colors.pureWhite,
      padding: "18px 24px 19px 24px",
      textAlign: "left",
      backgroundColor: theme.colors.darkTintPressed,
      position: "relative",
      zIndex: 3,
      "&:after": {
        content: "\"\"",
        display: "block",
        position: "absolute",
        bottom: 0,
        left: 20,
        right: 20,
        height: 1,
        zIndex: 1,
        backgroundColor: theme.colors.darkTint,
      },
    },
    optionActive: {
      color: theme.colors.neon,
      backgroundColor: theme.colors.darkTintPressed,
    },
    dropdownActive: { opacity: 1 },
    icon: { transition: "all 0.3s" },
    selectRootDropdownVisible: {
      [`& .${ classes.dropdown }`]: {
        opacity: 1,
        pointerEvents: "all",
      },
      [`& .${ classes.icon }`]: { transform: "rotate(180deg)" },
    },
    selectRootOptionSelected: { color: theme.colors.pureWhite },
    separator: {
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
      zIndex: 2,
    },
  })
);

import {
  IonCol,
  IonIcon,
  IonRow,
} from "@ionic/react";
import { ReactNode } from "react";
import { makeStyles } from "@theme/makeStyles";

type Props = {
  icon: string;
  title: string | ReactNode;
  text: string | ReactNode;
}

export const CueMessageUi = (props: Props) => {
  const { classes } = style();

  return <>
    <IonRow className={`ion-align-items-center ${ classes.cueMessageWrapper }`}>
      <IonCol size="auto">
        <IonIcon
          icon={props.icon}
          color={"dark"}
          size={"large"}
        />
      </IonCol>
      <IonCol>
        <span>
          <strong>{props.title}</strong><br />
          {props.text}
        </span>
      </IonCol>
    </IonRow>
  </>;
};

const style = makeStyles()(
  (theme, props, classes) => ({
    cueMessageWrapper: {
      position: "fixed",
      bottom: "calc(env(safe-area-inset-bottom) + 110px)",
      left: 0,
      right: 0,
      paddingTop: 10,
      paddingBottom: 35,
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
      maxWidth: "100%",
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
      zIndex: 98,
      borderTopLeftRadius: 32,
      borderTopRightRadius: 32,
      backgroundColor: theme.colors.yellow,
      span: {
        color: theme.colors.black,
        fontSize: 16,
      },
    },
  })
);

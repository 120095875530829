import {
  ButtonUi,
  PrimaryButtonProps,
} from "@ui/ButtonUi/ButtonUi";

export const AddItemButtonUi = ({
  label,
  onClick,
  isLoading = false,
  formType = "button",
  ...props
}: PrimaryButtonProps) => {
  return <>
    <ButtonUi
      label={label}
      isLoading={isLoading}
      width="fit-content"
      type="primary"
      labelColor="grey"
      backgroundColor="transparent"
      icon="add-round"
      iconColor="grey"
      iconPosition="left"
      fontStyle="RR16"
      appearance="rounded"
      hasToggle={false}
      hasPressed={true}
      formType={formType}
      onClick={onClick}
      isActive={false}
    />
  </>;
};

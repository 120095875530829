import { makeStyles } from "@theme/makeStyles";

interface Props {
  color: string;
  borderColor: string;
}

export const useStyles = makeStyles<Props>()(
  (theme, props, classes) => ({
    // Base styles
    feedbackBoxRoot: {
      flex: 1,
      height: 55,
      display: "flex",
      padding: 8,
      borderRadius: 8,
      backgroundColor: "rgba(24,23,23,0.16)",
      ...theme.typography.OIB18,
      textTransform: "uppercase",
      color: theme.colors[props.color],
      border: `1px solid ${ theme.colors[props.borderColor] }`,
    },
    feedbackSpan: { alignSelf: "center" },
  })
);

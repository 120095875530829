import {
  LegacyRef,
  forwardRef,
} from "react";
import { useStyles } from "./useStyles";

interface Props {
  message: string;
  className: string;
}

export const TooltipUi = forwardRef((
  {
    message, className,
  }: Props,
  ref: LegacyRef<HTMLSpanElement>
) => {
  const {
    classes, cx,
  } = useStyles();
  return <>
    <span
      ref={ref}
      className={cx({
        [classes.tooltipRoot]: true,
        [className]: true,
      })}>
      {message}
    </span>
  </>;
});

import {
  Range,
  Training,
} from "@common/type-graphql/generated";
import {
  RangeMinMax,
  RangeMinMaxDefault,
} from "@common/model/Range";
import { RunPageScrollContext } from "@feature/run/page/RunPage";
import {
  audioPlayCountDown,
  audioPlayStart,
} from "@feature/audio/service/audioService";
import {
  deviceBleSendStart,
  deviceBleSetRange,
} from "@feature/device/slice/deviceSlice";
import { deviceReadsService } from "@feature/device/service/deviceReadsService";
import {
  historyController,
  useAppDispatch,
} from "@core/redux/store";
import { resetCurrentSerie } from "@feature/run/data/currentSerie";
import { routes } from "@core/route";
import {
  runPrepare,
  runStart,
} from "@feature/run/slice/runSlice";
import {
  timerStart,
  timerStop,
} from "@feature/run/slice/timerSlice";
import { useContext } from "react";

type Props = {
  rangeList: Range[];
  training: Training;
  wait: number;
}

type Output = {
  runStart: (props: Props) => void;
}

export const useRunStart = (): Output => {
  const dispatch = useAppDispatch();
  const { scrollToTop } = useContext(RunPageScrollContext);

  const startPerform = () => {
    deviceReadsService.clear();
    dispatch(deviceBleSendStart());
    dispatch(runStart());
    audioPlayStart();
    dispatch(timerStart());
    scrollToTop();
  };

  const start = (props: Props) => {
    historyController.replace(routes.run.redirectPath);
    resetCurrentSerie();
    let rangeMinMax: RangeMinMax = null;
    if (props.rangeList.length) {
      const rangeSaved = props.rangeList.find(range =>
        range.exerciseUuid === props.training.exerciseUuid
      );
      if (rangeSaved && rangeSaved.data && rangeSaved.data !== "{}") {
        rangeMinMax = JSON.parse(rangeSaved.data);
      }
    }

    if (rangeMinMax) {
      dispatch(deviceBleSetRange(rangeMinMax));
    } else {
      dispatch(deviceBleSetRange(RangeMinMaxDefault));
    }
    dispatch(timerStop());
    if (props.wait > 0) {
      dispatch(runPrepare(props.wait));
      audioPlayCountDown(props.wait).then(() => {
        startPerform();
      });
    } else {
      startPerform();
    }
  };

  return { runStart: start };
};

export const hashCode = (s: string): number => {
  let hash = 0;
  let i = 0;
  let chr = 0;
  if (s.length === 0) {
    return hash;
  }
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash = hash | 0; // Converti a 32bit integer
  }
  return hash;
};

export const hashObject = (obj: any): number => {
  const str = JSON.stringify(obj);
  return hashCode(str);
};

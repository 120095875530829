import {
  ALGORITHM_CODE_DYNAMIC_STATE_1T,
  ALGORITHM_CODE_DYNAMIC_STATE_1T_JUMP,
  ALGORITHM_CODE_DYNAMIC_STATE_2T,
  ALGORITHM_CODE_DYNAMIC_STATE_SP,
  ALGORITHM_CODE_FUNCTIONAL_ISOMETRY,
  ALGORITHM_CODE_JUMP,
  ALGORITHM_CODE_SLOW_SERIE,
  ALGORITHM_CODE_SOLO_CONCENTRIC,
  AlgorithmCode,
} from "@common/model/Algorithm";
import {
  DIRECTION_ASCENDING,
  DIRECTION_DESCENDING,
} from "@common/model/Direction";
import {
  PHASE_FEEDBACK_IS_FAST,
  PHASE_FEEDBACK_IS_GOOD,
  PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER,
  PHASE_FEEDBACK_IS_HIGH,
  PHASE_FEEDBACK_IS_INVALID,
  PHASE_FEEDBACK_IS_LOW,
  PHASE_FEEDBACK_IS_SLOW,
  PHASE_FEEDBACK_IS_TOO_FAST,
  PHASE_FEEDBACK_IS_TOO_GOOD,
  PHASE_FEEDBACK_IS_TOO_HIGH,
  PHASE_FEEDBACK_IS_TOO_LOW,
  PHASE_FEEDBACK_IS_TOO_SLOW,
  PHASE_FEEDBACK_NULL,
  PHASE_IGNORE_REASON_SHORT,
  PhaseFeedback,
} from "@common/model/Phase";
import {
  RUN_STATUS_PREPARING,
  RUN_STATUS_READY,
  RUN_STATUS_RESTING,
  RUN_STATUS_RUNNING,
  selectRunState,
  setPhaseFeedback,
} from "@feature/run/slice/runSlice";
import { Serie } from "@common/model/Serie";
import {
  audioPlayAccelerate,
  audioPlayAccelerateSlightly,
  audioPlayBeepBad,
  audioPlayBeepGood,
  audioPlayBeepWellDone,
  audioPlayCount,
  audioPlayGo,
  audioPlaySlowDown,
  audioPlaySlowDownSlightly,
  audioPlayStop,
} from "@feature/audio/service/audioService";
import { getPhasesAscendingCountable } from "@common/service/serieService";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import { t } from "@lingui/macro";
import { useAlgorithm } from "@feature/algorithm/hook/useAlgorithm";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { useRunTraining } from "@feature/run/hook/useRunTraining";

export const useRunFeedback = () => {
  const dispatch = useAppDispatch();
  const settingState = useAppSelector(selectSettingState);
  const runState = useAppSelector(selectRunState);

  const configurationListApi = useConfigurationListQuery();

  const {
    endpoints: runTrainingEndpoints,
    runTraining,
  } = useRunTraining();

  const {
    endpoints: configurationEndpoints,
    getRunConfigurationUsePower,
    getRunConfigurationPowerMin,
    getRunConfigurationPowerMax,
    getRunConfigurationUseSpeed,
    getRunConfigurationSpeedMin,
    getRunConfigurationSpeedMax,
    getRunConfigurationConsecutiveBadRepetitionsCount,
    getRunConfigurationShutupSeconds,
    getRunConfigurationFeedbackGoTollerancePercent,
    getRunConfigurationPowerReferenceCurrent,
  } = useConfiguration();

  const runPowerReference = getRunConfigurationPowerReferenceCurrent();

  const {
    endpoints: algorithmEndpoints,
    getAlgorithmByProtocolAndExercise,
  } = useAlgorithm();

  const {
    isApiDataReady,
  } = useApiDataLogic([
    ...runTrainingEndpoints,
    ...configurationEndpoints,
    ...algorithmEndpoints,
    configurationListApi,
  ]);

  const feedbackSoloConcentric = (serie: Serie): PhaseFeedback => {
    if (!getRunConfigurationUsePower()) {
      return PHASE_FEEDBACK_NULL;
    }

    if (!runPowerReference) {
      return PHASE_FEEDBACK_NULL;
    }

    const powerMin = getRunConfigurationPowerMin();
    const powerMax = getRunConfigurationPowerMax();
    const consecutiveBadRepetitionsCount = getRunConfigurationConsecutiveBadRepetitionsCount();
    const shutUpSeconds = getRunConfigurationShutupSeconds();
    const feedbackGoTollerancePercent = getRunConfigurationFeedbackGoTollerancePercent();

    const powerMaxLimit = Math.round(runPowerReference / 100 * powerMax);
    const powerMinLimit = Math.round(runPowerReference / 100 * powerMin);
    const powerMinLimitPlusMargin = Math.round(((runPowerReference / 100) * feedbackGoTollerancePercent) + powerMinLimit);

    const phases = serie.finalPhases;
    const ascendingPhases = phases.filter(phase => phase.direction === DIRECTION_ASCENDING);
    const startTime = ascendingPhases[0]?.fromMs;
    const endTime = ascendingPhases[ascendingPhases.length - 1]?.toMs;

    const newFeedbackAscendingPhaseCount = ascendingPhases.length;
    if (newFeedbackAscendingPhaseCount <= serie.live.feedbackPhasesDoneCount) {
      return PHASE_FEEDBACK_NULL;
    }

    let phaseFeedback : PhaseFeedback = PHASE_FEEDBACK_NULL;
    let ascendingPhasesDoneCount = 0;
    let consecutiveBadCount = 0;

    for (const phase of ascendingPhases) {
      if (phase.ignoreReason) {
        switch (phase.ignoreReason) {
          case PHASE_IGNORE_REASON_SHORT:
            phaseFeedback = PHASE_FEEDBACK_IS_INVALID;
            break;
        }
      } else if (powerMinLimit && phase.physics.power < powerMinLimit) {
        phaseFeedback = PHASE_FEEDBACK_IS_TOO_LOW;
        ascendingPhasesDoneCount++;
        consecutiveBadCount++;
      } else if (powerMinLimitPlusMargin && phase.physics.power <= powerMinLimitPlusMargin) {
        phaseFeedback = PHASE_FEEDBACK_IS_LOW;
        ascendingPhasesDoneCount++;
        consecutiveBadCount = 0;
      } else if (powerMaxLimit && phase.physics.power > runPowerReference) {
        phaseFeedback = PHASE_FEEDBACK_IS_TOO_HIGH;
        ascendingPhasesDoneCount++;
        consecutiveBadCount = 0;
      } else if (powerMaxLimit && phase.physics.power > powerMaxLimit) {
        phaseFeedback = PHASE_FEEDBACK_IS_HIGH;
        ascendingPhasesDoneCount++;
        consecutiveBadCount = 0;
      } else if (phase.physics.power >= runPowerReference) {
        phaseFeedback = PHASE_FEEDBACK_IS_TOO_GOOD;
        ascendingPhasesDoneCount++;
        consecutiveBadCount = 0;
      } else {
        if (settingState.isCounterEnabled) {
          phaseFeedback = PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER;
        } else {
          phaseFeedback = PHASE_FEEDBACK_IS_GOOD;
        }
        ascendingPhasesDoneCount++;
        consecutiveBadCount = 0;
      }
    }

    // Handle shutup seconds
    if (
      shutUpSeconds &&
      phaseFeedback === PHASE_FEEDBACK_IS_TOO_LOW &&
      startTime &&
      endTime &&
      (endTime - startTime) < (shutUpSeconds * 1000)
    ) {
      phaseFeedback = PHASE_FEEDBACK_IS_LOW;
    }

    // Handle the consecutive bad
    if (
      consecutiveBadRepetitionsCount &&
      consecutiveBadCount < consecutiveBadRepetitionsCount &&
      phaseFeedback === PHASE_FEEDBACK_IS_TOO_LOW
    ) {
      phaseFeedback = PHASE_FEEDBACK_IS_LOW;
    }

    if (ascendingPhasesDoneCount > serie.live.feedbackPhasesDoneCount) {
      serie.live.feedbackPhasesDoneCount = ascendingPhasesDoneCount;
      return phaseFeedback;
    }
    return PHASE_FEEDBACK_NULL;
  };

  const feedbackEccencticConcentricSpeed = (serie: Serie): PhaseFeedback => {
    if (!getRunConfigurationUseSpeed()) {
      return PHASE_FEEDBACK_NULL;
    }

    const speedMin = getRunConfigurationSpeedMin();
    const speedMax = getRunConfigurationSpeedMax();
    const shutUpSeconds = getRunConfigurationShutupSeconds();

    const phases = serie.finalPhases.filter(phase =>
      (phase.direction === DIRECTION_ASCENDING || phase.direction === DIRECTION_DESCENDING) &&
      phase.positions.isAllInRange
    );

    const ascendingPhases = phases.filter(phase => phase.direction === DIRECTION_ASCENDING);

    const startTime = ascendingPhases[0]?.fromMs;
    const endTime = ascendingPhases[ascendingPhases.length - 1]?.toMs;

    const newFeedbackAscendingPhaseCount = phases.length;
    if (newFeedbackAscendingPhaseCount <= serie.live.feedbackPhasesDoneCount) {
      return PHASE_FEEDBACK_NULL;
    }

    let phaseFeedback : PhaseFeedback = PHASE_FEEDBACK_NULL;
    let ascendingPhasesDoneCount = 0;

    for (const phase of phases) {
      if (phase.ignoreReason) {
        if (phase.ignoreReason === PHASE_IGNORE_REASON_SHORT) {
          phaseFeedback = PHASE_FEEDBACK_IS_INVALID;
          ascendingPhasesDoneCount++;
        }
      } else if (phase.physics.speed < speedMin) {
        phaseFeedback = PHASE_FEEDBACK_IS_SLOW;
        ascendingPhasesDoneCount++;
      } else if (phase.physics.speed > speedMax) {
        phaseFeedback = PHASE_FEEDBACK_IS_TOO_FAST;
        ascendingPhasesDoneCount++;
      } else {
        if (phase.direction === DIRECTION_ASCENDING) {
          phaseFeedback = PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER;
        } else {
          phaseFeedback = PHASE_FEEDBACK_IS_GOOD;
        }
        ascendingPhasesDoneCount++;
      }
    }

    // Handle shutup seconds
    if (
      shutUpSeconds &&
      phaseFeedback === PHASE_FEEDBACK_IS_TOO_LOW &&
      startTime &&
      endTime &&
      (endTime - startTime) < (shutUpSeconds * 1000)
    ) {
      phaseFeedback = PHASE_FEEDBACK_IS_LOW;
    }

    if (ascendingPhasesDoneCount > serie.live.feedbackPhasesDoneCount) {
      serie.live.feedbackPhasesDoneCount = ascendingPhasesDoneCount;
      return phaseFeedback;
    }

    return PHASE_FEEDBACK_NULL;
  };

  const feedbackCheck = ({
    algorithmCode,
    serie,
  }: {
    algorithmCode: AlgorithmCode;
    serie: Serie;
  }) : PhaseFeedback => {
    switch (algorithmCode) {
      case ALGORITHM_CODE_SOLO_CONCENTRIC:
        return feedbackSoloConcentric(serie);
      case ALGORITHM_CODE_SLOW_SERIE:
        return feedbackEccencticConcentricSpeed(serie);
      case ALGORITHM_CODE_JUMP:
      case ALGORITHM_CODE_FUNCTIONAL_ISOMETRY:
      case ALGORITHM_CODE_DYNAMIC_STATE_1T:
      case ALGORITHM_CODE_DYNAMIC_STATE_SP:
      case ALGORITHM_CODE_DYNAMIC_STATE_1T_JUMP:
      case ALGORITHM_CODE_DYNAMIC_STATE_2T:
        console.log("Computation not implemented yet");
        break;
    }
    return PHASE_FEEDBACK_NULL;
  };

  const runCalculateFeedback = (serie: Serie) => {
    if (
      !isApiDataReady ||
      !runTraining
    ) {
      return;
    }

    const protocolUuid = runTraining.protocolUuid;
    const exerciseUuid = runTraining.exerciseUuid;

    const algorithm = getAlgorithmByProtocolAndExercise(protocolUuid, exerciseUuid);
    if (!algorithm) {
      return;
    }
    const algorithmCode = algorithm.code as AlgorithmCode;

    const phaseFeedback = feedbackCheck({
      algorithmCode: algorithmCode,
      serie: serie,
    });

    if (phaseFeedback) {
      dispatch(setPhaseFeedback(phaseFeedback));
    }

    const phasesCountable = getPhasesAscendingCountable(serie.finalPhases);

    switch (phaseFeedback) {
      case PHASE_FEEDBACK_NULL:
        break;
      case PHASE_FEEDBACK_IS_INVALID:
        if (settingState.isBeepEnabled) {
          audioPlayBeepBad();
        }
        break;
      case PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER:
        audioPlayCount(phasesCountable);
        break;
      case PHASE_FEEDBACK_IS_GOOD:
        if (settingState.isBeepEnabled) {
          audioPlayBeepGood();
        }
        break;
      case PHASE_FEEDBACK_IS_TOO_GOOD:
        if (settingState.isBeepEnabled) {
          audioPlayBeepWellDone();
        }
        break;
      case PHASE_FEEDBACK_IS_HIGH:
        audioPlaySlowDownSlightly();
        break;
      case PHASE_FEEDBACK_IS_TOO_HIGH:
        audioPlaySlowDown();
        break;
      case PHASE_FEEDBACK_IS_LOW:
        audioPlayGo();
        break;
      case PHASE_FEEDBACK_IS_TOO_LOW:
        audioPlayStop();
        break;
      case PHASE_FEEDBACK_IS_SLOW:
        audioPlayAccelerateSlightly();
        break;
      case PHASE_FEEDBACK_IS_TOO_SLOW:
        audioPlayAccelerate();
        break;
      case PHASE_FEEDBACK_IS_FAST:
        audioPlaySlowDownSlightly();
        break;
      case PHASE_FEEDBACK_IS_TOO_FAST:
        audioPlaySlowDown();
        break;
    }
  };

  const runGetFeedbackText = (): string => {
    if (runState.runStatus === RUN_STATUS_READY) {
      return t`Press start!`;
    }
    if (runState.runStatus === RUN_STATUS_PREPARING) {
      return t`Preparing`;
    }
    if (runState.runStatus === RUN_STATUS_RESTING) {
      return t`Resting`;
    }
    if (runState.runStatus === RUN_STATUS_RUNNING) {
      switch (runState.phaseFeedback) {
        case PHASE_FEEDBACK_NULL:
          return t`Start`;
        case PHASE_FEEDBACK_IS_INVALID:
          return t`Invalid`;
        case PHASE_FEEDBACK_IS_GOOD:
        case PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER:
          return t`Good!`;
        case PHASE_FEEDBACK_IS_TOO_GOOD:
          return t`Well done`;
        case PHASE_FEEDBACK_IS_LOW:
          return t`Go!`;
        case PHASE_FEEDBACK_IS_TOO_LOW:
          return t`Stop!`;
        case PHASE_FEEDBACK_IS_SLOW:
          return t`Forward boost`;
        case PHASE_FEEDBACK_IS_TOO_SLOW:
          return t`Speed up`;
        case PHASE_FEEDBACK_IS_HIGH:
        case PHASE_FEEDBACK_IS_FAST:
          return t`Gentle decel`;
        case PHASE_FEEDBACK_IS_TOO_HIGH:
        case PHASE_FEEDBACK_IS_TOO_FAST:
          return t`Slow down`;
        default:
          return t`Ready`;
      }
    }

    return t`Done`;
  };

  return {
    runCalculateFeedback: runCalculateFeedback,
    runGetFeedbackText: runGetFeedbackText,
  };
};

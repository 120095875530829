import { useSafeAreaHeight } from "@hook/useSafeAreaHeight";
import { useStyles } from "@theme/makeStyles";

type Output = {
  runHalfHeight: number;
};

export const useRunHalfHeight = (): Output => {
  const { theme } = useStyles();
  const usableHeight = useSafeAreaHeight();

  const halfHeight = Math.max(
    250, (
      usableHeight - theme.titleBarHeight - theme.runHeaderHeight - theme.runFooterHeight) / 2
  );

  return {
    runHalfHeight: halfHeight,
  };
};

import React, {
  useEffect,
  useState,
} from "react";
import { AccountGetRefreshTokenInputType } from "@common/type-graphql/account/input-type/account-get-refresh-token.input-type";
import { SplashScreenUi } from "@ui/SplashScreenUi/SplashScreenUi";
import {
  historyController,
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import {
  matchPath,
  useLocation,
} from "react-router";
import { routes } from "@core/route";
import {
  selectAccountState,
  setAsInitialized,
} from "../slice/accountSlice";
import { setAsInitializing } from "../slice/accountSlice";
import { useRefreshTokenMutation } from "@feature/account/api/accountApi";

export const AccountCheckOutlet = () => {
  const dispatch = useAppDispatch();
  const accountState = useAppSelector(selectAccountState);
  const location = useLocation();
  const [ refresh ] = useRefreshTokenMutation();

  const [
    splashScreenEnded,
    setSplashScreenEnded,
  ] = useState(false);

  const checkExpireToken = async(now: Date, expiresAt: Date, refreshToken: string) => {
    let isExpired = now > expiresAt;
    if (isExpired) {
      const accountGetRefreshToken: AccountGetRefreshTokenInputType = { refreshToken: refreshToken };
      try {
        await refresh({ data: accountGetRefreshToken }).unwrap();
      } catch (err) {
        isExpired = true;
      }
    }
    return isExpired;
  };

  const verifyExpiration = async() => {
    if (
      accountState.token &&
      accountState.refreshToken &&
      accountState.expiresAt
    ) {
      const now = new Date();
      const expiresAt = new Date(accountState.expiresAt);
      const isExpired = await checkExpireToken(now, expiresAt, accountState.refreshToken);
      if (isExpired) {
        if (location.pathname !== routes.accountLogout.routePath) {
          historyController.replace(routes.accountLogout.redirectPath);
        }
      }
    } else if (location.pathname !== routes.accountLoginOrBuy.routePath) {
      historyController.replace(routes.accountLogout.redirectPath);
    }
  };

  // This effect is used to check if the token is expired and refresh it
  useEffect(() => {
    if (
      accountState.isInitializing ||
      accountState.isInitialized
    ) {
      return;
    }

    dispatch(setAsInitializing());

    verifyExpiration().then(() => {
      dispatch(setAsInitialized());
    }).catch(() => {
      dispatch(setAsInitialized());
      historyController.replace(routes.accountLogout.redirectPath);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  // This effect is used to redirect the user to the login page if he is not logged in and the route is not public
  useEffect(() => {
    if (
      accountState.isInitializing ||
      accountState.isLoggedIn
    ) {
      return;
    }

    Object.keys(routes).forEach(key => {
      const route = routes[key];
      const match = matchPath(location.pathname, { path: route.routePath });
      if (
        match &&
        match.isExact &&
        !Boolean(route.isPublic)
      ) {
        historyController.replace(routes.accountLogin.redirectPath);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountState.isLoggedIn,
    location.pathname,
  ]);

  const done = () => {
    setSplashScreenEnded(true);
  };

  if (
    accountState.isInitialized && splashScreenEnded
  ) {
    return null;
  }

  return <SplashScreenUi onSplashScreenTimeout={done} />;
};

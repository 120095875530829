import React from "react";
import { Trans } from "@lingui/macro";
import { selectOnlineState } from "@feature/offline/slice/onlineSlice";
import { useAppSelector } from "@core/redux/store";

export const OfflineNoticeComponent = () => {
  const onlineState = useAppSelector(selectOnlineState);

  if (onlineState.isOnline) {
    return null;
  }

  return <Trans>You are offline, please check your internet connection as soon as possible.</Trans>;
};

import { API_CACHE_TAG_ACCOUNT } from "@feature/account/accountConstants";
import {
  Account,
  AccountToken,
  CreateOneAccountForgotPasswordArgs,
} from "@common/type-graphql/generated";
import { AccountChangePasswordInputType } from "@common/type-graphql/account/input-type/account-change-password.input-type";
import { AccountCreateInputType } from "@common/type-graphql/account/input-type/account-create.input-type";
import { AccountDestroyInputType } from "@common/type-graphql/account/input-type/account-destroy.input-type";
import { AccountDestroyPublicInputType } from "@common/type-graphql/account/input-type/account-destroy-public.input-type";
import { AccountExistByEmailInputType } from "@common/type-graphql/account/input-type/account-exist-by-email.input-type";
import { AccountExistByPhoneNumberInputType } from "@common/type-graphql/account/input-type/account-exist-by-phone-number.input-type";
import { AccountGetRefreshTokenInputType } from "@common/type-graphql/account/input-type/account-get-refresh-token.input-type";
import { AccountLoginAppleInputType } from "@common/type-graphql/account/input-type/account-login-apple.input-type";
import { AccountLoginFacebookInputType } from "@common/type-graphql/account/input-type/account-login-facebook.input-type";
import { AccountLoginGoogleInputType } from "@common/type-graphql/account/input-type/account-login-google.input-type";
import { AccountLoginInputType } from "@common/type-graphql/account/input-type/account-login.input-type";
import { AccountLoginOtpInputType } from "@common/type-graphql/account/input-type/account-login-otp.input-type";
import { AccountVerifyOtpInputType } from "@common/type-graphql/account/input-type/account-verify-otp.input-type";
import {
  accountChangePasswordQuery,
  accountChangePasswordTransformResponse,
} from "@common/type-graphql/account/gql/account-change-password.g";
import {
  accountCreateQuery,
  accountCreateTransformResponse,
} from "@common/type-graphql/account/gql/account-create.g";
import {
  accountDestroyPublicQuery,
  accountDestroyPublicTransformResponse,
} from "@common/type-graphql/account/gql/account-destroy-public.g";
import {
  accountDestroyQuery,
  accountDestroyTransformResponse,
} from "@common/type-graphql/account/gql/account-destroy.g";
import {
  accountExistByEmailQuery,
  accountExistByEmailTransformResponse,
} from "@common/type-graphql/account/gql/account-exist-by-email.g";
import {
  accountExistByPhoneNumberQuery,
  accountExistByPhoneNumberTransformResponse,
} from "@common/type-graphql/account/gql/account-exist-by-phone-number.g";
import {
  accountForgotPasswordQuery,
  accountForgotPasswordTransformResponse,
} from "@common/type-graphql/account/gql/account-forgot-password.g";
import {
  accountGetRefreshTokenQuery,
  accountGetRefreshTokenTransformResponse,
} from "@common/type-graphql/account/gql/account-get-refresh-token.g";
import {
  accountLoginAppleQuery,
  accountLoginAppleTransformResponse,
} from "@common/type-graphql/account/gql/account-login-apple.g";
import {
  accountLoginFacebookQuery,
  accountLoginFacebookTransformResponse,
} from "@common/type-graphql/account/gql/account-login-facebook.g";
import {
  accountLoginGoogleQuery,
  accountLoginGoogleTransformResponse,
} from "@common/type-graphql/account/gql/account-login-google.g";
import {
  accountLoginOtpQuery,
  accountLoginOtpTransformResponse,
} from "@common/type-graphql/account/gql/account-login-otp.g";
import {
  accountLoginQuery,
  accountLoginTransformResponse,
} from "@common/type-graphql/account/gql/account-login.g";
import {
  accountVerifyOtpQuery,
  accountVerifyOtpTransformResponse,
} from "@common/type-graphql/account/gql/account-verify-otp.g";
import { api } from "@core/api";
import {
  createAccountGuestWithSampleDataQuery,
  createAccountGuestWithSampleDataTransformResponse,
} from "@common/type-graphql/account/gql/account-create-guest.g";
import {
  getAccountQuery,
  getAccountTransformResponse,
} from "@common/type-graphql/account/gql/account-get.g";

export const accountApi = api.injectEndpoints({
  endpoints: builder => ({
    createAccountGuestWithSampleData: builder.mutation<{ createAccountGuestWithSampleData: AccountToken }, void>({
      query: () => ({
        document: createAccountGuestWithSampleDataQuery,
        transformResponse: createAccountGuestWithSampleDataTransformResponse,
      }),
    }),
    accountExistByEmail: builder.query<boolean, { data: AccountExistByEmailInputType }>({
      query: variables => (
        {
          document: accountExistByEmailQuery,
          variables: variables,
        }
      ),
      transformResponse: accountExistByEmailTransformResponse,
    }),
    accountExistByPhoneNumber: builder.query<boolean, {data:AccountExistByPhoneNumberInputType}>({
      query: variables => (
        {
          document: accountExistByPhoneNumberQuery,
          variables: variables,
        }
      ),
      transformResponse: accountExistByPhoneNumberTransformResponse,
    }),
    signup: builder.mutation<Account, { data: AccountCreateInputType }>({
      query: variables => ({
        document: accountCreateQuery,
        transformResponse: accountCreateTransformResponse,
        variables: variables,
      }),
    }),
    login: builder.mutation<{ accountLogin: AccountToken }, { data: AccountLoginInputType }>({
      query: variables => ({
        document: accountLoginQuery,
        transformResponse: accountLoginTransformResponse,
        variables: variables,
      }),
    }),
    loginOtp: builder.mutation<{ accountLoginOtp: AccountToken }, { data: AccountLoginOtpInputType }>({
      query: variables => ({
        document: accountLoginOtpQuery,
        transformResponse: accountLoginOtpTransformResponse,
        variables: variables,
      }),
    }),
    loginGoogle: builder.mutation<{ accountLoginGoogle: AccountToken }, { data: AccountLoginGoogleInputType }>({
      query: variables => ({
        document: accountLoginGoogleQuery,
        transformResponse: accountLoginGoogleTransformResponse,
        variables: variables,
      }),
    }),
    loginFacebook: builder.mutation<{ accountLoginFacebook: AccountToken }, { data: AccountLoginFacebookInputType }>({
      query: variables => ({
        document: accountLoginFacebookQuery,
        transformResponse: accountLoginFacebookTransformResponse,
        variables: variables,
      }),
    }),
    loginApple: builder.mutation<{ accountLoginApple: AccountToken }, { data: AccountLoginAppleInputType }>({
      query: variables => ({
        document: accountLoginAppleQuery,
        transformResponse: accountLoginAppleTransformResponse,
        variables: variables,
      }),
    }),
    forgotPassword: builder.mutation<{ accountForgotPassword: boolean }, CreateOneAccountForgotPasswordArgs>({
      query: variables => ({
        document: accountForgotPasswordQuery,
        transformResponse: accountForgotPasswordTransformResponse,
        variables: variables,
      }),
    }),
    changePassword: builder.mutation<{ accountChangePassword: boolean }, { data: AccountChangePasswordInputType }>({
      query: variables => ({
        document: accountChangePasswordQuery,
        transformResponse: accountChangePasswordTransformResponse,
        variables: variables,
      }),
    }),
    refreshToken: builder.mutation<{ accountGetRefreshToken: AccountToken }, { data: AccountGetRefreshTokenInputType }>({
      query: variables => ({
        document: accountGetRefreshTokenQuery,
        transformResponse: accountGetRefreshTokenTransformResponse,
        variables: variables,
      }),
    }),
    accountGet: builder.query<Account, void>({
      query: () => ({ document: getAccountQuery }),
      transformResponse: getAccountTransformResponse,
      providesTags: (result, error, input) => [
        {
          type: API_CACHE_TAG_ACCOUNT,
        },
      ],
    }),
    accountDestroy: builder.mutation<boolean, { data: AccountDestroyInputType }>({
      query: variables => ({
        document: accountDestroyQuery,
        transformResponse: accountDestroyTransformResponse,
        variables: variables,
      }),
    }),
    accountDestroyPublic: builder.mutation<boolean, { data: AccountDestroyPublicInputType }>({
      query: variables => ({
        document: accountDestroyPublicQuery,
        transformResponse: accountDestroyPublicTransformResponse,
        variables: variables,
      }),
    }),
    accountVerifyOtp: builder.mutation<boolean, { data: AccountVerifyOtpInputType }>({
      query: variables => ({
        document: accountVerifyOtpQuery,
        transformResponse: accountVerifyOtpTransformResponse,
        variables: variables,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAccountGuestWithSampleDataMutation,
  useLazyAccountExistByEmailQuery,
  useLazyAccountExistByPhoneNumberQuery,
  useSignupMutation,
  useLoginMutation,
  useLoginOtpMutation,
  useLoginGoogleMutation,
  useLoginFacebookMutation,
  useLoginAppleMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useRefreshTokenMutation,
  useAccountGetQuery,
  useAccountDestroyMutation,
  useAccountDestroyPublicMutation,
  useAccountVerifyOtpMutation,
} = accountApi;

import { ApiData } from "@feature/api/component/ApiData";
import { ListButtonUi } from "@ui/ListButtonUi/ListButtonUi";
import { Protocol } from "@common/type-graphql/generated";
import { getProtocolNameByCode } from "@feature/protocol/service/protocolService";
import { useProtocolListQuery } from "@feature/protocol/api/protocolApi";

type Props = {
  uuid?: string;
  onClick: () => void;
}

export const ProtocolListElementComponent = (props: Props) => {
  const protocolListApi = useProtocolListQuery();

  return <>
    <ApiData endpoint={protocolListApi}>
      <ProtocolListElementComponentChild
        {...props}
        protocolList={protocolListApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  protocolList: Protocol[];
}

const ProtocolListElementComponentChild = (props: PropsChild) => {
  if (!props.protocolList) {
    return <></>;
  }

  const protocol = props.protocolList.find(protocol => protocol.uuid === props.uuid);

  return <>
    <ListButtonUi
      title={getProtocolNameByCode(protocol.code)}
      onClick={props.onClick}
    />
  </>;
};

import { API_CACHE_TAG_CONFIGURATION } from "@feature/configuration/configurationConstants";
import { Configuration } from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  configurationsQuery,
  configurationsTransformResponse,
} from "@common/type-graphql/configuration/gql/configuration-list.g";

export const configurationApi = api.injectEndpoints({
  endpoints: builder => ({
    configurationList: builder.query<Configuration[], void>({
      query: () => ({ document: configurationsQuery }),
      transformResponse: configurationsTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_CONFIGURATION, result),
    }),
  }),
  overrideExisting: false,
});

export const { useConfigurationListQuery } = configurationApi;
